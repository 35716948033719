import { Modal } from 'react-bootstrap'

export type ShoppingCartExpiresModalProp = {
    onClick: () => void
    show: boolean
}

const ShoppingCartExpiresModal = ({ onClick, show }: ShoppingCartExpiresModalProp) => {
    return (
        <Modal
            aria-label="Shopping Cart Expiration Modal"
            aria-modal="true"
            animation={false}
            backdrop="static"
            centered
            contentClassName="border-0 rounded-normal p-2 p-md-3"
            className="modal reseat-modal reseat-modal-v2 zIndex1200"
            dialogClassName="m-3 m-sm-2 mx-sm-auto top-50 translate-middle-y"
            tabindex="-1"
            role="dialog"
            show={show}
        >
            <Modal.Body>
                <div className="headline-3 fw-600 text-darker mb-2">Reservation time exceeded</div>
                <p className="paragrah mb-4">
                    Please select start over to begin a new reservation.
                </p>
                <button
                    className="btn btn-purple w-100  m-0 btn-block"
                    data-bs-toggle="modal"
                    onClick={onClick}
                >
                    Start Over
                </button>
            </Modal.Body>
        </Modal>
    )
}

export default ShoppingCartExpiresModal
