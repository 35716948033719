import { makeStyles } from 'tss-react/mui'
import { COLOR_ERROR, COLOR_PRIMARY_50 } from 'styles/theme'

export const useStyles = makeStyles()({
    defaultBorder: {
        borderColor: COLOR_PRIMARY_50
    },
    errorBorder: {
        borderColor: `${COLOR_ERROR} !important`
    }
})
