import {
    COLOR_GRAY_800,
    COLOR_PRIMARY_50,
    COLOR_PRIMARY_600,
    COLOR_PRIMARY_700,
    FONT_SIZE_12,
    FONT_SIZE_14,
    FONT_SIZE_24,
    FONT_SIZE_32,
    FONT_SIZE_48,
    FONT_WEIGHT_500,
    FONT_WEIGHT_700,
    FULL_WIDTH,
    SIZE_2,
    SIZE_4,
    SIZE_8,
    SIZE_10,
    SIZE_12,
    SIZE_16,
    SIZE_18,
    SIZE_20,
    SIZE_24,
    SIZE_40,
    SIZE_46,
    SIZE_60
} from 'styles/theme'
import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()({
    ageRequirement: {
        backgroundColor: COLOR_PRIMARY_50,
        borderRadius: '50rem',
        bottom: SIZE_4,
        color: COLOR_PRIMARY_700,
        fontSize: FONT_SIZE_12,
        fontWeight: FONT_WEIGHT_500,
        lineHeight: SIZE_18,
        marginLeft: SIZE_12,
        padding: `${SIZE_2} ${SIZE_8}`,
        position: 'relative',
        whiteSpace: 'nowrap',
        '@media (min-width: 768px)': {
            bottom: SIZE_10,
            fontSize: FONT_SIZE_14,
            lineHeight: SIZE_20,
            marginLeft: SIZE_20,
            padding: `${SIZE_4} ${SIZE_12}`
        }
    },
    container: {
        margin: `${SIZE_24} auto ${SIZE_40}`,
        padding: `0 ${SIZE_16}`,
        width: FULL_WIDTH,
        '@media (min-width: 576px)': {
            maxWidth: '540px'
        },
        '@media (min-width: 768px)': {
            maxWidth: '720px',
            marginBottom: SIZE_46
        },
        '@media (max-width: 768px)': {
            fontSize: FONT_SIZE_14,
            lineHeight: SIZE_20
        },
        '@media (min-width: 992px)': {
            maxWidth: '960px'
        },
        '@media (min-width: 1200px)': {
            maxWidth: '1140px'
        },
        '@media (min-width: 1400px)': {
            maxWidth: '1320px'
        }
    },
    displayDate: {
        color: COLOR_PRIMARY_600,
        fontWeight: FONT_WEIGHT_500,
        marginBottom: SIZE_8
    },
    name: {
        color: COLOR_GRAY_800,
        display: 'inline-block',
        fontSize: FONT_SIZE_24,
        fontWeight: FONT_WEIGHT_700,
        lineHeight: FONT_SIZE_32,
        marginBottom: FONT_SIZE_12,
        position: 'relative',
        '@media (min-width: 768px)': {
            fontSize: FONT_SIZE_48,
            letterSpacing: '-0.96px',
            lineHeight: SIZE_60
        }
    },
    truncation: {
        display: 'block',
        '@media (min-width: 768px)': {
            marginBottom: FONT_SIZE_24
        }
    }
})
