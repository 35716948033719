import { isMobile } from 'react-device-detect'
import {
    COLOR_GRAY_800,
    FONT_WEIGHT_600,
    SIZE_12,
    SIZE_16,
    SIZE_18,
    SIZE_24,
    SIZE_28,
    SIZE_32,
    SIZE_4
} from 'styles/theme'
import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()({
    categoryTitle: {
        color: COLOR_GRAY_800,
        fontWeight: FONT_WEIGHT_600,
        fontSize: isMobile ? SIZE_18 : SIZE_24,
        lineHeight: isMobile ? SIZE_28 : SIZE_32,
        marginBottom: isMobile ? SIZE_4 : SIZE_12,
        '@media (max-width: 768px)': {
            paddingLeft: SIZE_16
        }
    },
    categoryBox: {
        marginBottom: isMobile ? '40px' : '64px'
    }
})
