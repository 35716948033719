import React from 'react'
import Link from 'next/link'
import { BreadcrumbItemProps } from 'react-bootstrap'

export type BreadCrumbsType = {
    accommodationUrl: string
    addOnsUrl: string
    groupSizeUrl: string
    hasAddonsPage: boolean
    isOnlySellHotel: boolean
    isOnlySellTickets: boolean
    onClickBreadCrumbsNavbar: () => void
    packagesUrl: string
    previewUrl: string
    roomUrl: string
    routerPathName: string
    showNavbar: boolean
}

export default function BreadCrumbsNavbar({
    accommodationUrl,
    addOnsUrl,
    groupSizeUrl,
    hasAddonsPage,
    isOnlySellHotel,
    isOnlySellTickets,
    onClickBreadCrumbsNavbar,
    packagesUrl,
    previewUrl,
    roomUrl,
    routerPathName,
    showNavbar
}: BreadCrumbsType) {
    const BreadcrumbItem = ({
        className,
        onClick,
        url,
        label
    }: BreadcrumbItemProps & { url: string; label: string }) => (
        <li
            className={`breadcrumb-item breadcrumb-item-navbar breadcrumb-mb ${className}`}
            onClick={onClick}
        >
            <Link href={url}>
                <a>{label}</a>
            </Link>
        </li>
    )

    const Breadcrumbs = ({
        accommodationUrl,
        addOnsUrl,
        groupSizeUrl,
        hasAddonsPage,
        isOnlySellTickets,
        onClickBreadCrumbsNavbar,
        packagesUrl,
        previewUrl,
        roomUrl,
        routerPathName
    }: BreadCrumbsType) => {
        let navbarAddOnsClass = ''
        let navbarPreviewClass = ''
        let navbarRoomClass = ''

        let accommodationClass = ''
        let addOnsClass = ''
        let groupSizeClass = ''
        let packagesClass = ''
        let previewClass = ''
        let roomsClass = ''

        if (accommodationUrl === '#') {
            accommodationClass = 'breadcrumbs-navbar-click'
        }
        if (roomUrl === '#') {
            navbarRoomClass = 'breadcrumbs-navbar-click'
        }
        if (addOnsUrl === '#') {
            navbarAddOnsClass = 'breadcrumbs-navbar-click'
        }
        if (previewUrl === '#') {
            navbarPreviewClass = 'breadcrumbs-navbar-click'
        }

        switch (routerPathName) {
            case '/events/[slug]':
                packagesClass = 'active breadcrumbs-navbar-arrows'
                break
            case '/events/[slug]/group-size':
                groupSizeClass = 'active breadcrumbs-navbar-arrows'
                break
            case '/events/[slug]/choose-your-accommodation':
                accommodationClass = 'active breadcrumbs-navbar-arrows'
                break
            case '/events/[slug]/[hotelID]/[groupSize]/[selectedTicketNumber]':
                roomsClass = 'active breadcrumbs-navbar-arrows'
                break
            case '/events/[slug]/[hotelID]/[groupSize]':
                roomsClass = 'active breadcrumbs-navbar-arrows'
                break
            case '/events/[slug]/add-ons':
                addOnsClass = 'active breadcrumbs-navbar-arrows'
                break
            case '/events/[slug]/checkout/preview':
                previewClass = 'active breadcrumbs-navbar-arrows'
                break
        }

        return (
            <nav>
                <ol className="breadcrumb">
                    <BreadcrumbItem
                        className={packagesClass}
                        onClick={onClickBreadCrumbsNavbar}
                        url={packagesUrl}
                        label="Packages"
                    />
                    {!isOnlySellHotel && (
                        <BreadcrumbItem
                            className={groupSizeClass}
                            onClick={onClickBreadCrumbsNavbar}
                            url={groupSizeUrl}
                            label="Group Size"
                        />
                    )}
                    {!isOnlySellTickets && !isOnlySellHotel && (
                        <BreadcrumbItem
                            className={accommodationClass}
                            onClick={onClickBreadCrumbsNavbar}
                            url={accommodationUrl}
                            label="Accommodation"
                        />
                    )}
                    {!isOnlySellTickets && (
                        <BreadcrumbItem
                            className={`${navbarRoomClass} ${roomsClass}`}
                            onClick={onClickBreadCrumbsNavbar}
                            url={roomUrl}
                            label="Rooms"
                        />
                    )}
                    {hasAddonsPage && (
                        <BreadcrumbItem
                            className={`${navbarAddOnsClass} ${addOnsClass}`}
                            onClick={onClickBreadCrumbsNavbar}
                            url={addOnsUrl}
                            label="Add-ons"
                        />
                    )}
                    <BreadcrumbItem
                        className={`${navbarPreviewClass} ${previewClass}`}
                        onClick={onClickBreadCrumbsNavbar}
                        url={previewUrl}
                        label="Review"
                    />
                </ol>
            </nav>
        )
    }

    return (
        <>
            {showNavbar && (
                <div
                    className="container gx-3 bread-crumbs-navbar"
                    style={{
                        marginTop: '80px'
                    }}
                >
                    <div className="row">
                        <div className="col-12 d-md-block d-none">
                            <Breadcrumbs
                                accommodationUrl={accommodationUrl}
                                addOnsUrl={addOnsUrl}
                                groupSizeUrl={groupSizeUrl}
                                hasAddonsPage={hasAddonsPage}
                                isOnlySellHotel={isOnlySellHotel}
                                isOnlySellTickets={isOnlySellTickets}
                                onClickBreadCrumbsNavbar={onClickBreadCrumbsNavbar}
                                packagesUrl={packagesUrl}
                                previewUrl={previewUrl}
                                roomUrl={roomUrl}
                                routerPathName={routerPathName}
                                showNavbar={false}
                            />
                        </div>
                        <div className="col-12 d-block d-md-none">
                            <Breadcrumbs
                                accommodationUrl={accommodationUrl}
                                addOnsUrl={addOnsUrl}
                                groupSizeUrl={groupSizeUrl}
                                hasAddonsPage={hasAddonsPage}
                                isOnlySellHotel={isOnlySellHotel}
                                isOnlySellTickets={isOnlySellTickets}
                                onClickBreadCrumbsNavbar={onClickBreadCrumbsNavbar}
                                packagesUrl={packagesUrl}
                                previewUrl={previewUrl}
                                roomUrl={roomUrl}
                                routerPathName={routerPathName}
                                showNavbar={false}
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}
