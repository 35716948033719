import { FC } from 'react'

type Props = {
    fill?: string
    height?: string
    width?: string
}

export const AIGIcon: FC<Props> = ({ fill = 'none', height = '39', width = '74' }: Props): JSX.Element => (
    <svg width={width} height={height} viewBox="0 0 74 39" fill={fill} xmlns="http://www.w3.org/2000/svg">
        <path d="M19.5614 14.5814L22.8114 22.0239H16.1597L19.5614 14.5814ZM18.9728 8.55811L8.78223 30.4523H12.3428L14.7839 25.1331H24.1547L26.4514 30.4523H30.0661L20.2367 8.55811H18.9728Z" fill="white" />
        <path d="M36.8154 30.4487H35.1615H33.5112V8.55811H35.1615H36.8154V29.127V30.4487Z" fill="white" />
        <path d="M54.1415 19.0484H63.1873V19.7706C63.1873 21.4137 62.9959 22.8726 62.6059 24.1365C62.2304 25.3101 61.5912 26.4043 60.6921 27.4262C58.659 29.7156 56.0734 30.8568 52.9354 30.8568C49.8696 30.8568 47.2479 29.7517 45.0668 27.5417C42.8821 25.3209 41.7915 22.6523 41.7915 19.5431C41.7915 16.369 42.9037 13.6715 45.1246 11.4615C47.3454 9.24063 50.0465 8.12842 53.2351 8.12842C54.9468 8.12842 56.5429 8.47508 58.0271 9.17202C59.4462 9.86897 60.8401 10.9993 62.2087 12.5665L59.8543 14.8198C58.0596 12.4293 55.8676 11.234 53.2893 11.234C50.9746 11.234 49.0282 12.032 47.4609 13.6317C45.8937 15.2026 45.1065 17.167 45.1065 19.5287C45.1065 21.9698 45.9804 23.9776 47.7318 25.5556C49.3676 27.0217 51.1407 27.7548 53.0473 27.7548C54.6723 27.7548 56.1312 27.2059 57.4276 26.1154C58.7204 25.014 59.4462 23.6923 59.5979 22.154H54.1379V19.0484H54.1415Z" fill="white" />
        <path d="M3.91441 3.00445H70.0302V35.9919H3.91441V3.00445ZM0.913574 39H73.0311V0H0.913574V39Z" fill="white" />
    </svg>
)
