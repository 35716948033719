import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock'
import React, { useEffect, useRef } from 'react'
import { RootStateOrAny, useSelector } from 'react-redux'
import { NavbarState } from '../store/index'
import Menu from './expandedNavMenu'
import ForgetPwd from './forgetPwd'
import SignIn from './signIn'
import SignUp from './signUp'
import { useStyles } from './SignInConfirmed/SignIn.styles'

export default function ExpandedNav() {
    const { classes } = useStyles()
    const ref = useRef(null)
    useEffect(() => {
        disableBodyScroll(ref.current!)
        return () => {
            clearAllBodyScrollLocks()
        }
    }, [])

    const info: Record<string, Record<string, React.ReactNode>> = {
        menu: {
            component: <Menu />
        },
        signUp: {
            component: <SignUp />
        },
        signIn: {
            component: <SignIn />
        },
        forgotPassword: {
            component: <ForgetPwd />
        }
    }
    const navState: NavbarState = useSelector((state: RootStateOrAny) => state.navbarReducer)
    let currentShowComponent
    let k: keyof NavbarState
    for (k in navState) {
        if (navState[k] && k !== 'expandedNav') {
            currentShowComponent = info[k]['component']
            break
        }
    }

    return (
        <div
            className={classes.navbarBody}
            ref={ref}
        >
            {currentShowComponent}
        </div>
    )
}
