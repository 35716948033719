import Link from 'next/link'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faUserAstronaut,
    faSpa,
    faCircleQuestion,
    faEnvelope
} from '@fortawesome/pro-regular-svg-icons'
import { AuthContext } from '../auth/context'
import { useContext } from 'react'
import { useDispatch } from 'react-redux'
import Image from './image'
import Jdenticon from '../components/jdenticon'

export default function Menu() {
    const [authState, setAuthState] = useContext(AuthContext)
    const { isLoggedIn } = authState
    const dispatch = useDispatch()
    const closeExpandedNav = () => {
        dispatch({
            type: 'navbar/setState',
            payload: {
                expandedNav: false
            }
        })
    }
    const showSignUp = () => {
        dispatch({
            type: 'navbar/setState',
            payload: {
                expandedNav: true,
                signUp: true
            }
        })
    }

    const showSignIn = () => {
        dispatch({
            type: 'navbar/setState',
            payload: {
                expandedNav: true,
                signIn: true
            }
        })
    }
    const firstName = authState?.user?.firstName ?? ''
    const lastName = authState?.user?.lastName ?? ''
    const userName =
        authState?.user?.name !== '' ? authState?.user?.name : firstName + ' ' + lastName

    return (
        <>
            {!isLoggedIn && (
                <>
                    <div className="login-buttons">
                        <div
                            className="btn btn-block btn-purple mb-75"
                            onClick={showSignUp}
                        >
                            Sign Up
                        </div>
                        <div
                            className="btn btn-block btn-purple100"
                            onClick={showSignIn}
                        >
                            Sign In
                        </div>
                    </div>
                </>
            )}
            <ul className="navbar-nav">
                {isLoggedIn ? (
                    <>
                        <li
                            className="nav-item"
                            style={{ padding: '12px 16px', display: 'flex', alignItems: 'center' }}
                        >
                            {authState.user.profileImage ? (
                                <div
                                    className="rounded-circle img-fluid mine-portrait"
                                    style={{
                                        position: 'relative',
                                        width: '32px',
                                        height: '32px',
                                        marginRight: '12px',
                                        display: 'inline-block'
                                    }}
                                >
                                    <Image
                                        className="rounded-circle img-fluid"
                                        src={authState.user.profileImage}
                                        alt=""
                                        layout="fill"
                                        unoptimized={true}
                                    />
                                </div>
                            ) : (
                                <div
                                    className="rounded-circle img-fluid mine-portrait"
                                    style={{
                                        position: 'relative',
                                        width: '32px',
                                        marginRight: '12px',
                                        display: 'inline-block'
                                    }}
                                >
                                    <Jdenticon
                                        size={32}
                                        value={userName}
                                    />
                                </div>
                            )}
                            <span className="header-mine-name">{userName}</span>
                        </li>
                        <li className="nav-item">
                            <Link href="/account">
                                <a
                                    className="nav-link"
                                    onClick={closeExpandedNav}
                                >
                                    <FontAwesomeIcon
                                        icon={faUserAstronaut}
                                        className="fa-regular"
                                    />
                                    Account
                                </a>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link href="/orders/my-experience">
                                <a
                                    className="nav-link"
                                    onClick={closeExpandedNav}
                                >
                                    <FontAwesomeIcon
                                        icon={faSpa}
                                        className="fa-regular"
                                    />
                                    Experiences
                                </a>
                            </Link>
                        </li>
                    </>
                ) : (
                    ''
                )}
                <li className="nav-item">
                    <Link href="/faq">
                        <a
                            className="nav-link active"
                            onClick={closeExpandedNav}
                        >
                            <FontAwesomeIcon
                                icon={faCircleQuestion}
                                className="fa-regular"
                            />
                            FAQ
                        </a>
                    </Link>
                </li>
                <li className="nav-item mb-4-2">
                    <Link href="/contact-us">
                        <a
                            className="nav-link"
                            onClick={closeExpandedNav}
                        >
                            <FontAwesomeIcon
                                icon={faEnvelope}
                                className="fa-regular"
                            />
                            Contact Us
                        </a>
                    </Link>
                </li>
                <li className="nav-item">
                    <Link href="/web-accessibility">
                        <a
                            className="nav-link opacity-50"
                            onClick={closeExpandedNav}
                        >
                            Web Accessibility
                        </a>
                    </Link>
                </li>
                <li className="nav-item">
                    <Link href="/careers">
                        <a
                            className="nav-link opacity-50"
                            onClick={closeExpandedNav}
                        >
                            Careers
                        </a>
                    </Link>
                </li>
                <li className="nav-item">
                    <Link href="/privacy-policy">
                        <a
                            className="nav-link opacity-50"
                            onClick={closeExpandedNav}
                        >
                            Privacy Policy
                        </a>
                    </Link>
                </li>
                <li className="nav-item">
                    <Link href="/terms-of-purchase">
                        <a
                            className="nav-link opacity-50"
                            onClick={closeExpandedNav}
                        >
                            Terms of Purchase
                        </a>
                    </Link>
                </li>
                <li className="nav-item">
                    <Link href="/privacy-policy#your">
                        <a
                            className="nav-link opacity-50"
                            onClick={closeExpandedNav}
                        >
                            Do Not Sell or Share My Personal Information
                        </a>
                    </Link>
                </li>
            </ul>
            {isLoggedIn && (
                <div className="login-buttons">
                    <div
                        className="btn btn-light btn-block"
                        onClick={() => {
                            setAuthState({ isLoggedIn: false, jwt: '', refresh: '', user: {} })
                            closeExpandedNav()
                        }}
                    >
                        Sign Out
                    </div>
                </div>
            )}
        </>
    )
}
