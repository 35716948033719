import { useCallback, useEffect, useMemo, useState } from 'react'
import { EventJSON } from '../../../events-service/src/models/event'
import { TicketJSON } from '../../../events-service/src/models/ticket'
import { HotelPriceType } from 'types/event'
import { getEarliestOnSaleDate } from 'util/pricing'
import { getTimeZone, timeZoneString } from 'util/dates'
import { cardButtonStatus } from 'enums'
import {
    getAvailableRooms,
    getEarliestRoomOnSaleDate,
    getMinOccupancyFlag,
    isAllRoomsSoldOnFuture,
    isAllRoomsSoldOut,
    isTicketSoldOut
} from 'util/event'
import { HotelJSON } from '../../../events-service/src/models/hotel'
import { newAssignRoom } from 'util/parse'
import { HotelRoomJSON } from '../../../events-service/src/models/hotelRoom'
import { CheapestPriceJSON, hotelTableJSON, roomTableJSON } from 'util/customizeJsonType'

const peopleNumber = 2

type UseTicketStatusType = {
    calculateCheapestPrice: (
        allChoicesArray: any[],
        associateTicketAvailableRoom: HotelRoomJSON[],
        groupSize: number,
        hotelId: string,
        isOnlySellHotel: boolean,
        newTotalTables: Record<number, Record<number, [roomTableJSON[]]>>,
        overBaseOccupancyFee: number,
        unlimitedInventoryRoomTiers?: Record<string, boolean>
    ) => CheapestPriceJSON
    event: EventJSON
    inPreSaleInterval: boolean
    inventory: Record<string, number>
    isCodeValid: boolean
    isOnlySellHotel: boolean
    isOnlySellTickets: boolean
    isPreSale: boolean
    totalTiersData: (excludingInventory: boolean) => hotelTableJSON[]
}
export const defaultTicketState = {
    buttonStatus: '',
    price: 0,
    shouldShowPrice: false,
    ticketId: ''
}
export type TicketStatusType = typeof defaultTicketState

type TicketStatusReturnType = TicketStatusType[]

export const useTicketStatus = ({
    calculateCheapestPrice,
    event,
    inPreSaleInterval,
    inventory,
    isCodeValid,
    isOnlySellHotel,
    isOnlySellTickets,
    isPreSale,
    totalTiersData
}: UseTicketStatusType): TicketStatusReturnType => {
    const { hotels, tickets, ticketExperiences, timezone } = event
    const shippingFee = useMemo(() => {
        return event.addOnItems.reduce((carry, addOn) => {
            if (addOn.required) {
                return (carry += addOn.price + addOn.taxesAndFees)
            }
            return carry
        }, 0)
    }, [event])
    const [ticketsStatus, setTicketsStatus] = useState<TicketStatusType[]>([])
    const [hotelPriceArray, setHotelPriceArray] = useState<Record<string, HotelPriceType[]>>({})
    const getLowestPriceOfHotel = useCallback(
        (selectedGroupSize: number) => {
            let temHotelPriceTest: Record<string, HotelPriceType[]> = {}
            let priceData: Record<string, number> = {}
            let priceDataExcludingInventory: Record<string, number> = {}
            tickets.forEach((ticket: TicketJSON) => {
                temHotelPriceTest[ticket.id] = []
                hotels.forEach((hotel: HotelJSON) => {
                    const availableRooms = getAvailableRooms(
                        inventory,
                        true,
                        hotel.rooms,
                        ticket.id
                    )

                    const totalTiersPriceDataExcludingInventory = totalTiersData(true).find(
                        (tiersData) => tiersData.hotelID === hotel.id
                    )
                    if (totalTiersPriceDataExcludingInventory) {
                        const cheapestPriceData = calculateCheapestPrice(
                            newAssignRoom(selectedGroupSize),
                            availableRooms,
                            selectedGroupSize,
                            hotel.id,
                            false,
                            totalTiersPriceDataExcludingInventory.hotelTable,
                            hotel.overBaseOccupancyFee
                        )
                        priceDataExcludingInventory[ticket.id] = cheapestPriceData.price
                    }

                    const totalTiersPriceData = totalTiersData(false).find(
                        (tiersData) => tiersData.hotelID === hotel.id
                    )
                    if (totalTiersPriceData) {
                        const cheapestPriceData = calculateCheapestPrice(
                            newAssignRoom(selectedGroupSize),
                            availableRooms,
                            selectedGroupSize,
                            hotel.id,
                            false,
                            totalTiersPriceData.hotelTable,
                            hotel.overBaseOccupancyFee
                        )
                        priceData[ticket.id] = cheapestPriceData.price
                    }
                    const cheapestPrice =
                        Object.entries(priceDataExcludingInventory).filter(
                            (priceData) => priceData[0] === ticket.id
                        )?.[0]?.[1] || 0
                    let error = ''
                    if (availableRooms.length === 0) error = 'Sold Out'
                    temHotelPriceTest[ticket.id].push({
                        id: hotel.id,
                        price: Number(cheapestPrice),
                        associate: hotel.tickets,
                        error: error
                    })
                })
            })

            return temHotelPriceTest
        },
        [hotels, tickets, calculateCheapestPrice]
    )
    const getTicketPrice = (ticket: TicketJSON) => {
        let ticketAndExperience = 0
        let ticketTierPrice = 0
        let experiencePrice = 0
        const ticketTier = ticket.tiers
            .filter((tier) => tier.onSale)
            .sort((a, b) => a.price - b.price)
        const experience = ticketExperiences.filter(
            (experience) => experience.ticketID === ticket.id
        )
        if (ticketTier.length > 0) {
            ticketTierPrice = (ticketTier[0].price + ticketTier[0].taxesAndFees) * peopleNumber
            experiencePrice = experience?.reduce((carry, experience) => {
                const experienceTier = experience.tiers.find(
                    (tier) => tier.ticketTierID === ticketTier[0].id
                )
                let price = 0
                if (experienceTier) {
                    price = (experienceTier.price + experienceTier.taxesAndFees) * peopleNumber
                }
                return carry + price
            }, 0)
        }
        ticketAndExperience = ticketTierPrice + experiencePrice
        return ticketAndExperience
    }
    const getHotelPrice = (ticket: TicketJSON, hotelPriceArray: HotelPriceType[] | undefined) => {
        let cheapestHotelPrice = 99999999
        if (!hotelPriceArray) return cheapestHotelPrice
        hotelPriceArray.forEach((value) => {
            const hotelPriceAssociateTicketsArray = value.associate.map((ticket) => ticket.id)
            if (
                !value.error &&
                (value.associate.length === 0 ||
                    hotelPriceAssociateTicketsArray.includes(ticket.id))
            ) {
                cheapestHotelPrice = Math.min(cheapestHotelPrice, value.price)
            }
        })
        return cheapestHotelPrice
    }
    const getTicketsAssociateRooms = (ticket: TicketJSON) => {
        const ticketsAssociateHotels = hotels.filter((hotel) => {
            const haveAvailableHotel =
                hotel.tickets.length === 0 ||
                hotel.tickets.some((hotelTickets) => hotelTickets.id === ticket.id)
            return haveAvailableHotel
        })
        const ticketsAssociateRooms = ticketsAssociateHotels
            .flatMap((hotel) => hotel.rooms)
            .filter((room) => {
                const haveAssociateRoom =
                    room.tickets.length === 0 ||
                    room.tickets.some((_ticket) => _ticket.id === ticket.id)
                return haveAssociateRoom
            })
        return ticketsAssociateRooms
    }
    const getTicketsStatus = () => {
        const ticketsStatus: TicketStatusType[] = []
        tickets.forEach((ticket) => {
            if (ticket.hidden) return

            if (inPreSaleInterval && isPreSale && !isCodeValid) {
                ticketsStatus.push({
                    buttonStatus: cardButtonStatus.UNLOCK,
                    price: 0,
                    shouldShowPrice: false,
                    ticketId: ticket.id
                })
                return
            }

            const isTicketNotOnSale = ticket.tiers.every((tier) => !tier.onSale)
            const ticketsAssociateRooms = getTicketsAssociateRooms(ticket)
            const isAllRoomNotOnSale =
                ticketsAssociateRooms.length > 0 &&
                ticketsAssociateRooms.every(
                    (room) => !room.onSale || room.tiers.every((tier) => !tier.onSale)
                )
            if (isTicketNotOnSale || isAllRoomNotOnSale) {
                ticketsStatus.push({
                    buttonStatus: cardButtonStatus.SOLD_OUT,
                    price: 0,
                    shouldShowPrice: false,
                    ticketId: ticket.id
                })
                return
            }

            const ticketPrice = getTicketPrice(ticket)
            const isMinOccupancy = ticket.tiers.map((tier) =>
                getMinOccupancyFlag(inventory, ticketsAssociateRooms, tier.id)
            )

            if (ticketPrice === 0) {
                ticketsStatus.push({
                    buttonStatus: cardButtonStatus.SOLD_OUT,
                    price: ticketPrice,
                    shouldShowPrice: false,
                    ticketId: ticket.id
                })
                return
            }

            let buttonStatus: string = cardButtonStatus.SELECT_THIS_PACKAGE
            const earliestTicketOnSaleDate = getEarliestOnSaleDate(ticket)
            const isTicketSoldOnFuture =
                earliestTicketOnSaleDate &&
                earliestTicketOnSaleDate.getTime() > new Date().getTime()
            if (isOnlySellTickets) {
                switch (true) {
                    case isTicketSoldOnFuture && !isPreSale && !isCodeValid:
                        buttonStatus = `On Sale ${getTimeZone(
                            earliestTicketOnSaleDate,
                            timezone
                        ).format('MMM D, YYYY @ hh:mm A')} ${timeZoneString(timezone)}`
                        break
                    case isTicketSoldOut(ticket, inventory):
                        buttonStatus = cardButtonStatus.SOLD_OUT
                        break
                    default:
                        break
                }
                ticketsStatus.push({
                    buttonStatus,
                    price: ticketPrice,
                    shouldShowPrice: true,
                    ticketId: ticket.id
                })
                return
            }
            const availableRooms = getAvailableRooms(
                inventory,
                false,
                ticketsAssociateRooms,
                ticket.id
            )
            const hotelPrice = getHotelPrice(ticket, hotelPriceArray[ticket.id])
            const totalPrice = hotelPrice + ticketPrice + shippingFee
            const shouldShowPrice = Object.entries(hotelPriceArray).length > 0
            switch (true) {
                case (isAllRoomsSoldOnFuture(availableRooms) || isTicketSoldOnFuture) &&
                    !isPreSale &&
                    !isCodeValid:
                    const earliestRoomOnSaleDate = getEarliestRoomOnSaleDate(availableRooms)
                    let earliestOnSaleDate = earliestRoomOnSaleDate || earliestTicketOnSaleDate
                    const shouldShowTicketFutureTime =
                        earliestTicketOnSaleDate &&
                        earliestRoomOnSaleDate &&
                        earliestTicketOnSaleDate > earliestRoomOnSaleDate
                    if (shouldShowTicketFutureTime) {
                        earliestOnSaleDate = earliestTicketOnSaleDate
                    }
                    buttonStatus = `On Sale ${getTimeZone(earliestOnSaleDate, timezone).format(
                        'MMM D, YYYY @ hh:mm A'
                    )} ${timeZoneString(timezone)}`
                    break
                case isAllRoomsSoldOut(inventory, availableRooms) ||
                    isTicketSoldOut(ticket, inventory) ||
                    !isMinOccupancy.includes(true):
                    buttonStatus = cardButtonStatus.SOLD_OUT
                    break
                default:
                    break
            }
            ticketsStatus.push({
                buttonStatus,
                price: totalPrice,
                shouldShowPrice,
                ticketId: ticket.id
            })
            return
        })
        return ticketsStatus
    }

    useEffect(() => {
        if (Object.entries(inventory).length > 0) {
            setHotelPriceArray(getLowestPriceOfHotel(peopleNumber))
        }
    }, [inventory])

    useEffect(() => {
        if (isOnlySellHotel) return
        const ticketsState = getTicketsStatus()
        setTicketsStatus(ticketsState)
    }, [
        event,
        hotelPriceArray,
        isCodeValid,
        isOnlySellHotel,
        isOnlySellTickets,
        isPreSale,
        inPreSaleInterval,
        inventory
    ])
    return ticketsStatus
}
