export type StorageReturnType = {
    getLocalStorage: (name: string) => any
    removeLocalStorage: (name: string) => void
    setLocalStorage: (name: string, prop: any) => void
}

export const useLocalStorage = (): StorageReturnType => {
    const initialState = ''
    const getLocalStorage = (name: string) => {
        if (typeof window === 'undefined') return initialState
        const cachedData = window.localStorage.getItem(name)
        if (!cachedData) return initialState
        try {
            const parsedData = JSON.parse(cachedData)
            return parsedData
        } catch {
            return cachedData
        }
    }

    const setLocalStorage = (name: string, eventProp: any) => {
        const isString = typeof eventProp === 'string'
        const item = isString ? eventProp : JSON.stringify(eventProp)
        window.localStorage.setItem(name, item)
    }

    const removeLocalStorage = (name: string) => {
        window.localStorage.removeItem(name)
    }
    return {
        getLocalStorage,
        removeLocalStorage,
        setLocalStorage
    }
}
