import React, { Dispatch, SetStateAction } from 'react'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { ButtonComponent, ButtonVariant } from 'components/Button'
import {
    COLOR_GRAY_200,
    COLOR_GRAY_300,
    COLOR_GRAY_800,
    COLOR_PRIMARY_500,
    COLOR_WHITE,
    FONT_SIZE_14,
    SIZE_20
} from 'styles/theme'
import { FULL_WIDTH } from './datepicker.styles'
import { DateRange } from '@mui/x-date-pickers-pro'
import { Dayjs } from 'dayjs'

export type DateRangePickerToolBarProps = {
    canReset: boolean
    cancelButtonTitle: string
    clickDatesCount: number
    confirmButtonTitle: string
    defaultValueRange: any[] | undefined
    flag: Record<number, DateRange<Dayjs>>
    getCalendarConfirmClassName: (isSameMonth: boolean) => string
    isMobileOnly: boolean
    isSameMonth: boolean
    onConfirm: () => void
    onResetDates: () => void
    setCanReset: (able: boolean) => void
    setCalendarPosition: Dispatch<SetStateAction<2 | 1 | 3 | undefined>>
    setClickDatesCount: (count: number) => void
    setCrossYearPosition: (position: number) => void
    setPreviousRanges: Dispatch<SetStateAction<DateRange<Dayjs>>>
    setShoulderNightRange: (range: { checkInDistance: any; checkOutDistance: any }) => void
    value: any
}

export const DateRangePickerToolBarComponent: React.FC<DateRangePickerToolBarProps> = ({
    canReset,
    cancelButtonTitle,
    clickDatesCount,
    confirmButtonTitle,
    defaultValueRange,
    flag,
    getCalendarConfirmClassName,
    isMobileOnly,
    isSameMonth,
    onConfirm,
    onResetDates,
    setCanReset,
    setCalendarPosition,
    setClickDatesCount,
    setCrossYearPosition,
    setPreviousRanges,
    setShoulderNightRange,
    value
}) => {
    return (
        <>
            {isMobileOnly && (
                <IconButton
                    aria-label="close"
                    onClick={() => {
                        onConfirm()
                        setCalendarPosition(2)
                        flag[0] = value
                    }}
                    sx={{
                        position: 'absolute',
                        right: isSameMonth ? '20px' : '5px',
                        top: isSameMonth ? '20px' : '5px',
                        color: (theme) => theme?.palette?.grey?.[500]
                    }}
                >
                    <CloseIcon />
                </IconButton>
            )}
            <Container
                sx={{ mb: 2, mt: 2 }}
                className={getCalendarConfirmClassName(isSameMonth)}
            >
                <Grid
                    container
                    spacing={0}
                >
                    <Grid
                        item
                        xs={4}
                    >
                        <ButtonComponent
                            onClick={() => {
                                onResetDates()
                                setShoulderNightRange({
                                    checkInDistance: undefined,
                                    checkOutDistance: undefined
                                })
                                setClickDatesCount(clickDatesCount + 1)
                                flag[clickDatesCount] = [
                                    defaultValueRange![0],
                                    defaultValueRange![1]
                                ]
                                setPreviousRanges([defaultValueRange![0], defaultValueRange![1]])
                                setCanReset(false)
                            }}
                            style={{
                                '&.MuiButton-root': {
                                    fontSize: FONT_SIZE_14,
                                    lineHeight: SIZE_20,
                                    width: '100%',
                                    height: isMobileOnly ? '44px' : '40px',
                                    border: `1px solid ${
                                        canReset ? COLOR_GRAY_300 : COLOR_GRAY_200
                                    }`,
                                    color: canReset ? COLOR_GRAY_800 : COLOR_GRAY_300,
                                    cursor: canReset ? 'pointer' : 'not-allowed !important'
                                },
                                '&.MuiButton-root:hover': {
                                    backgroundColor: COLOR_WHITE
                                }
                            }}
                            title={cancelButtonTitle}
                            variant={ButtonVariant.OUTLINED}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={8}
                    >
                        <ButtonComponent
                            onClick={() => {
                                onConfirm()
                                setCrossYearPosition(1)
                                setCalendarPosition(2)
                                flag[0] = value
                            }}
                            style={{
                                width: FULL_WIDTH,
                                '&.MuiButton-root': {
                                    marginLeft: isMobileOnly ? '8px' : '12px',
                                    width: isMobileOnly ? '97%' : '93%',
                                    height: isMobileOnly ? '44px' : '40px',
                                    fontSize: FONT_SIZE_14,
                                    lineHeight: SIZE_20,
                                    color: COLOR_WHITE
                                },
                                '&.MuiButton-root:hover': {
                                    backgroundColor: COLOR_PRIMARY_500
                                }
                            }}
                            title={confirmButtonTitle}
                        />
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}
