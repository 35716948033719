import { useEffect } from 'react'
import { useRef, useState } from 'react'
import { isMobile, isMobileOnly } from 'react-device-detect'
import { TicketJSON } from '../../../../events-service/src/models/ticket'
import { TicketTipJSON } from '../../../../events-service/src/models/ticketTip'
import { formatMoney, handleAmountsPoint } from '../../util/parse'
import { Modal } from 'react-bootstrap'
import { TipsSection } from '../TipsSection'
import Image from '../image'
import {
    COLOR_GRAY_500,
    FONT_SIZE_12,
    FONT_WEIGHT_400,
    FULL_WIDTH,
    SIZE_4,
    SIZE_16,
    SIZE_18,
    SIZE_20,
    SIZE_54,
    SIZE_76,
    SIZE_100
} from 'styles/theme'
import { TruncationComponent } from '../../util/TruncationComponent'
import { Skeleton } from '@mui/material'
import { useStyles } from './styles/ticketTypeSelection.styles'
import { ButtonComponent } from 'components/Button'
import LockIcon from '@mui/icons-material/Lock'
import clsx from 'clsx'
import { TicketStatusType } from 'hooks/useTicketStatus'
import { cardButtonStatus } from 'enums'

export type TicketTypeSelectionProps = {
    cardHasDescription: boolean
    cardHasPrice: boolean
    currency: string
    isLoading: boolean
    onPackageSelectionClick: (ticketId: string) => void
    onUnlockButtonClick: () => void
    peopleNumber: number
    submitting: boolean
    ticket: TicketJSON
    ticketIndex: number
    ticketNameResize: () => void
    ticketStatus: TicketStatusType
    ticketTip: TicketTipJSON | undefined
}

export const TicketTypeSelection = ({
    cardHasDescription,
    cardHasPrice,
    currency,
    isLoading,
    onPackageSelectionClick,
    onUnlockButtonClick,
    peopleNumber,
    submitting,
    ticket,
    ticketIndex,
    ticketNameResize,
    ticketStatus,
    ticketTip
}: TicketTypeSelectionProps) => {
    const { buttonStatus, price, shouldShowPrice } = ticketStatus
    const [needViewMore, setNeedViewMore] = useState<boolean>(false)
    const [showThumbnail, setShowThumbnail] = useState<boolean>(false)
    const [viewMore, setViewMore] = useState<boolean>(false)
    const buttonRef = useRef(null)
    const cardRef = useRef(null)
    const imgRef = useRef(null)
    const titleRef = useRef(null)
    const { classes } = useStyles()
    const ticketNameRef = useRef(null)
    /* ----- event ----- */

    /* ----- render ----- */
    useEffect(() => {
        if (isLoading) return
        if (ticketNameRef) {
            ticketNameResize()
        }
    }, [isLoading, ticketNameRef])
    useEffect(() => {
        if (isLoading) return
        if (ticketTip) {
            const tipElement = document.getElementById(ticketTip.id)
            if (tipElement) {
                if (tipElement.offsetHeight >= 250) {
                    //tip max-height = 250
                    setNeedViewMore(true)
                }
            }
        }
    }, [isLoading, ticketTip])

    const handleViewMore = (b: boolean) => {
        setViewMore(b)
    }

    const renderButton = () => {
        let onButtonClick = () => {}
        let isDisabledButton: boolean = false
        let isFutureButton: boolean = false
        let content: JSX.Element | string = buttonStatus
        switch (buttonStatus) {
            case cardButtonStatus.UNLOCK:
                onButtonClick = onUnlockButtonClick
                content = (
                    <>
                        <span className={classes.lockButton}>
                            <LockIcon />
                        </span>{' '}
                        Unlock
                    </>
                )
                break
            case cardButtonStatus.SELECT_THIS_PACKAGE:
                onButtonClick = () => onPackageSelectionClick(ticket.id)
                break
            case cardButtonStatus.SOLD_OUT:
                isDisabledButton = true
                break
            default:
                isFutureButton = true
                isDisabledButton = true
                break
        }
        return (
            <ButtonComponent
                circularProgressSize={16}
                className={clsx({
                    [classes.button]: true,
                    [classes.futureButton]: isFutureButton
                })}
                disabled={isDisabledButton}
                isLoading={submitting}
                onClick={onButtonClick}
                title={content}
            />
        )
    }

    const renderImage = () => {
        const imageUrl = ticket.ticketImage?.[0]?.url || ''
        if (imageUrl) {
            return (
                <div
                    className={classes.imageWrapper}
                    ref={imgRef}
                >
                    <div className={classes.imageContainer}>
                        {isLoading ? (
                            <Skeleton
                                animation="wave"
                                key={`ticketImageSkeleton_${ticketIndex}`}
                                sx={{
                                    height: FULL_WIDTH,
                                    maskImage: 'none'
                                }}
                                variant="rectangular"
                            />
                        ) : (
                            <Image
                                alt=""
                                className={classes.image}
                                layout="fill"
                                src={imageUrl}
                            />
                        )}
                    </div>

                    {!isLoading && ticket.ticketThumbnail?.[0]?.url && (
                        <div className={classes.thumbnail}>
                            <Image
                                alt=""
                                layout="fill"
                                onClick={() => setShowThumbnail(true)}
                                src={ticket.ticketThumbnail?.[0]?.url}
                            />
                        </div>
                    )}
                </div>
            )
        }
    }
    const renderDescription = () => {
        if (isLoading) {
            return (
                <Skeleton
                    animation="wave"
                    key={`ticketDescriptionSkeleton_${ticketIndex}`}
                    sx={{
                        height: '126px',
                        maskImage: 'none'
                    }}
                    variant="rectangular"
                />
            )
        }
        return (
            <div
                className={classes.nameContainer}
                ref={titleRef}
            >
                <h2
                    className={classes.name}
                    id={`ticket_${ticketIndex}`}
                    ref={ticketNameRef}
                >
                    {ticket.name}
                </h2>
                {ticket.description ? (
                    <TruncationComponent
                        className={classes.description}
                        html={ticket.description}
                        isTicketDescription={true}
                        maxNumberOfLines={3}
                    />
                ) : cardHasDescription ? (
                    <p
                        style={{
                            height: SIZE_54
                        }}
                    ></p>
                ) : null}
            </div>
        )
    }

    const renderPrice = () => {
        if (isLoading)
            return (
                <Skeleton
                    animation="wave"
                    key={`ticketPriceSkeleton_${ticketIndex}`}
                    sx={{
                        height: '148px',
                        maskImage: 'none'
                    }}
                    variant="rectangular"
                />
            )
        return (
            <div
                className={classes.priceWrapper}
                ref={buttonRef}
            >
                {shouldShowPrice ? (
                    <div>
                        <div className={classes.priceContainer}>
                            <span className={classes.priceText}>
                                From{' '}
                                {handleAmountsPoint(
                                    formatMoney(price / 100 / peopleNumber, currency)
                                )}
                            </span>{' '}
                            / Person
                        </div>
                        <p
                            style={{
                                color: COLOR_GRAY_500,
                                fontSize: FONT_SIZE_12,
                                fontWeight: FONT_WEIGHT_400,
                                lineHeight: SIZE_18,
                                marginBottom: 0,
                                marginTop: SIZE_4
                            }}
                        >
                            {handleAmountsPoint(formatMoney(price / 100, currency))} Total (Taxes
                            and fees included)
                        </p>
                        <p
                            style={{
                                color: COLOR_GRAY_500,
                                fontSize: FONT_SIZE_12,
                                fontWeight: FONT_WEIGHT_400,
                                lineHeight: SIZE_18,
                                marginBottom: isMobileOnly ? SIZE_16 : SIZE_20
                            }}
                        >
                            *Price shown based on 2 people
                        </p>
                    </div>
                ) : (
                    <div>
                        <p
                            className={classes.emptyPriceContainer}
                            style={{ height: cardHasPrice ? SIZE_76 : '' }}
                        ></p>
                    </div>
                )}
                {renderButton()}
            </div>
        )
    }
    const renderTip = () => {
        if (!ticketTip) return null
        if (isLoading)
            return (
                <Skeleton
                    animation="wave"
                    key={`ticketTipSkeleton_${ticketIndex}`}
                    sx={{
                        height: SIZE_100,
                        maskImage: 'none'
                    }}
                    variant="rectangular"
                />
            )
        return (
            <TipsSection
                handleViewMore={handleViewMore}
                needViewMore={needViewMore}
                ticketName={ticket.name}
                ticketTip={ticketTip}
                viewMore={viewMore}
            />
        )
    }
    return (
        <div className={classes.wrapper}>
            <div
                className={classes.container}
                ref={cardRef}
                style={{
                    minHeight: isMobile ? '35vh' : undefined
                }}
            >
                {renderImage()}
                {renderDescription()}
                {renderPrice()}
                {renderTip()}
            </div>
            <Modal
                className="modal thumbnail-Modal thumbnail-bg"
                show={showThumbnail}
                contentClassName="border-0"
                dialogClassName="modal-fullscreen modal-dialog-scrollable thumbnail-bg mx-sm-auto"
            >
                <Modal.Header className="py-4 border-0 d-block position-relative">
                    <Modal.Title
                        as={() => {
                            return (
                                <div className="container gx-md-3 gx-0 text-end">
                                    <span
                                        className="btn-close close-image-gallery-btn fs-5 opacity-100 position-absolute"
                                        style={{ zIndex: 2010, color: '#667085', padding: '5px' }}
                                        onClick={() => {
                                            setShowThumbnail(false)
                                        }}
                                    ></span>
                                </div>
                            )
                        }}
                    ></Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-0 modal-body">
                    <div className="container-map-popup gx-md-3 gx-0 p-0 addons-pop">
                        <div
                            style={{
                                position: 'relative',
                                width: '100%',
                                paddingTop: '100%',
                                overflow: 'hidden'
                            }}
                        >
                            <Image
                                className="img-responsive"
                                src={ticket.ticketThumbnail[0]?.url}
                                layout="fill"
                                objectFit="contain"
                                alt=""
                            />
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}
