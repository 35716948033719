import { OrderState, RoomInfo } from 'store'
import { EventJSON } from '../../../events-service/src/models/event'
import { getImage } from 'util/getEventHeaderImageByDevice'
import { getTimeZone } from 'util/dates'
import { HotelJSON } from '../../../events-service/src/models/hotel'
import { useEffect, useState } from 'react'
import { getOrderCheckInAndOut, getOrderCheckInAndOutByHotel } from 'util/order'
import { HotelsShowInfoType } from 'types/orders'

type ExperienceDetailProps = {
    event: EventJSON
    order: OrderState
}

type ExperienceDetailReturnType = {
    checkInDate: string | Date
    checkOutDate: string | Date
    eventImgUrl: string
    hotelsShowInfo: HotelsShowInfoType[]
}

const getNights = (checkInDate: string | Date, checkOutDate: string | Date, timezone: string) => {
    const nights = getTimeZone(checkOutDate, timezone)
        .startOf('day')
        .diff(getTimeZone(checkInDate, timezone).startOf('day'), 'day')
    return nights
}

export const useExperienceDetail = ({
    event,
    order
}: ExperienceDetailProps): ExperienceDetailReturnType => {
    const [hotelsShowInfo, setHotelsShowInfo] = useState<HotelsShowInfoType[]>([])
    const hotels = event?.hotels.filter((hotel) => {
        return hotel.rooms.some((room) => {
            return order.items.some((item) => item.itemID === room.id && item.type === 'room')
        })
    })
    const eventImgUrl = getImage(event?.headerImage, 'Desktop').url
    const [checkInDate, checkOutDate] = getOrderCheckInAndOut(
        event?.checkInDate,
        event?.checkOutDate,
        hotels,
        order.items,
        event?.timezone
    )

    const getNights = (
        checkInDate: string | Date,
        checkOutDate: string | Date,
        timezone: string
    ) => {
        const nights = getTimeZone(checkOutDate, timezone)
            .startOf('day')
            .diff(getTimeZone(checkInDate, timezone).startOf('day'), 'day')
        return nights
    }
    const getRoomInfo = (hotel: HotelJSON) => {
        const roomIds = hotel.rooms.map((room) => {
            return room.id
        })
        const roomInfo = order.roomInfo.filter((info) => roomIds.includes(info.roomID))
        const roomQty = order.items.reduce((carry, item) => {
            if (roomIds.includes(item.itemID)) {
                return carry + item.quantity
            }
            return carry
        }, 0)
        return { roomInfo, roomQty }
    }

    useEffect(() => {
        if (hotels.length === 0) return
        const newHotelsShowInfo: HotelsShowInfoType[] = []
        hotels.forEach((hotel, index) => {
            const { checkInTime, checkOutTime, headerImage, id, location, name, rooms } = hotel
            const [checkInDate, checkOutDate] = getOrderCheckInAndOutByHotel(
                event?.checkInDate,
                event?.checkOutDate,
                hotel,
                order.items,
                event?.timezone
            )
            const nights = getNights(checkInDate, checkOutDate, event?.timezone)
            const { roomInfo, roomQty } = getRoomInfo(hotel)
            const isShowHotelHeaderText = index === 0
            newHotelsShowInfo.push({
                checkInDate: checkInDate,
                checkInTime: checkInTime,
                checkOutDate: checkOutDate,
                checkOutTime: checkOutTime,
                id: id,
                image: headerImage[0]?.url,
                isShowHotelHeaderText: isShowHotelHeaderText,
                location: location,
                name: name,
                nights: nights,
                rooms: rooms,
                roomInfo: roomInfo,
                roomQty: roomQty,
                timezone: event?.timezone
            })
        })
        setHotelsShowInfo(newHotelsShowInfo)
    }, [])

    return {
        checkInDate,
        checkOutDate,
        eventImgUrl,
        hotelsShowInfo
    }
}
