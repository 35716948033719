import {
    COLOR_GRAY_500,
    COLOR_PRIMARY_600,
    COLOR_PRIMARY_700,
    COLOR_WHITE,
    FONT_SIZE,
    FONT_SIZE_12,
    FONT_WEIGHT_600,
    FULL_WIDTH,
    SIZE_18,
    SIZE_50,
    SIZE_68
} from 'styles/theme'
import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()({
    cardBody: {
        flex: '1 1 auto',
        padding: `${FONT_SIZE} ${FONT_SIZE}`
    },
    listBox: {
        color: COLOR_GRAY_500,
        width: '236px',
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    tipHide: {
        display: '-webkit-box',
        maxHeight: '250px',
        position: 'relative',
        overflow: 'hidden',
        WebkitBoxOrient: 'vertical',
        '::after': {
            background: `linear-gradient(180deg, ${COLOR_WHITE}4D 0%, ${COLOR_WHITE} 70%)`,
            bottom: 0,
            content: '""',
            display: 'block',
            height: SIZE_68,
            left: 0,
            position: 'absolute',
            width: FULL_WIDTH,
            zIndex: 1,
            '@media (max-width:767px)': {
                background: `linear-gradient(180deg, ${COLOR_WHITE}4D 0%, ${COLOR_WHITE} 110%)`,
                height: SIZE_50
            }
        }
    },
    viewMore: {
        color: COLOR_PRIMARY_700,
        cursor: 'pointer',
        display: 'inline-block',
        fontSize: FONT_SIZE_12,
        fontWeight: FONT_WEIGHT_600,
        lineHeight: SIZE_18,
        textDecoration: 'none',
        ':hover, :focus': {
            color: COLOR_PRIMARY_600
        }
    }
})
