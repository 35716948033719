import { toaster } from 'evergreen-ui'
import { usersClient } from '../apollo-client'
import { refresh } from './refresh'
import { UPDATE_USER } from 'graphQL/user/userUpdateUser.graphql'
import { OrderItem, braintreePaymentInfo } from 'store'
import { Address } from '../../../shared/ts/types'
import { HotelJSON } from '../../../events-service/src/models/hotel'
import { dateCompareMax, dateCompareMin } from './dates'
declare var HIVE_SDK: any

export const updateUser = async ({
    billingAddress,
    braintreePaymentInfo,
    firstName,
    lastName,
    name,
    phone,
    shippingAddress,
    stripeCustomerID,
    stripePaymentMethodID,
    successCallBack
}: {
    billingAddress: Address
    braintreePaymentInfo: braintreePaymentInfo
    firstName: string
    lastName: string
    name: string
    phone: string
    shippingAddress: Address
    stripeCustomerID: string
    stripePaymentMethodID: string
    successCallBack?: (token: string, refresh: string) => void
}) => {
    try {
        const {
            data: {
                update: { user, token, refresh, success }
            }
        } = await usersClient.mutate({
            fetchPolicy: 'no-cache',
            mutation: UPDATE_USER,
            variables: {
                billingAddress: billingAddress,
                braintreePaymentInfo: braintreePaymentInfo,
                firstName,
                lastName,
                name,
                phone,
                shippingAddress: shippingAddress,
                stripeCustomerId: stripeCustomerID,
                stripePaymentMethodId: stripePaymentMethodID
            }
        })
        if (user && token && refresh && success) {
            successCallBack?.(token, refresh)
        }
    } catch (e: any) {
        if (e.graphQLErrors?.length) {
            if (e.graphQLErrors[0].message.includes('jwt expired')) {
                try {
                    await refresh()
                    await updateUser({
                        billingAddress,
                        braintreePaymentInfo,
                        firstName,
                        name,
                        lastName,
                        phone,
                        shippingAddress,
                        stripeCustomerID,
                        stripePaymentMethodID,
                        successCallBack
                    })
                } catch (error) {}
            }
        }
    }
}

export const subscribeNewsAndOffers = (email: string) => {
    HIVE_SDK(
        'emailSignup',
        {
            email: email
        },
        function (data: any) {
            HIVE_SDK(
                'addToSegment',
                'Keep me up to date on news and offers',
                function () {
                    console.log('Success: Added to segment')
                },
                function (data: any) {
                    console.log(
                        'Error: Add to segment Keep me up to date on news and offers failed:',
                        data
                    )
                }
            )
        },
        function (data: any) {
            toaster.warning('Please enter a valid email address.')
            console.log('Subscription failed')
            return
        }
    )
}

export const getOrderCheckInAndOutByHotel = (
    eventCheckIn: string | Date,
    eventCheckOut: string | Date,
    hotel: HotelJSON,
    items: any[],
    timezone: string
): (string | Date)[] => {
    let checkIn = eventCheckIn
    let checkOut = eventCheckOut
    let shoulderNightItem = items.filter((item) => item.type === 'room.shouldernight')
    if (shoulderNightItem.length === 0) {
        return [checkIn, checkOut]
    }
    const isCheckInDate = (bookDate: string | Date) => {
        return (
            dateCompareMin(bookDate, eventCheckIn, timezone) &&
            dateCompareMin(bookDate, checkIn, timezone)
        )
    }
    const isCheckOutDate = (bookDate: string | Date) => {
        return (
            dateCompareMax(bookDate, eventCheckOut, timezone) &&
            dateCompareMax(bookDate, checkOut, timezone)
        )
    }
    shoulderNightItem.forEach((night) => {
        hotel.rooms.forEach((room) => {
            const roomShoulderNight = room.shoulderNights.find(
                (shoulderNight) => shoulderNight.id === night.itemID
            )
            if (!roomShoulderNight) return
            if (isCheckInDate(roomShoulderNight.bookDate)) {
                checkIn = roomShoulderNight.bookDate
            }
            if (isCheckOutDate(roomShoulderNight.bookDate)) {
                checkOut = roomShoulderNight.bookDate
            }
        })
    })
    return [checkIn, checkOut]
}

export const getOrderCheckInAndOut = (
    eventCheckIn: string | Date,
    eventCheckOut: string | Date,
    hotels: HotelJSON[],
    items: OrderItem[],
    timezone: string
): (string | Date)[] => {
    let checkInDate = eventCheckIn
    let checkOutDate = eventCheckOut
    hotels.forEach((hotel) => {
        const [_checkInDate, _checkOutDate] = getOrderCheckInAndOutByHotel(
            eventCheckIn,
            eventCheckOut,
            hotel,
            items,
            timezone
        )
        if (dateCompareMin(_checkInDate, checkInDate, timezone)) {
            checkInDate = _checkInDate
        }
        if (dateCompareMax(_checkOutDate, checkOutDate, timezone)) {
            checkOutDate = _checkOutDate
        }
    })
    return [checkInDate, checkOutDate]
}
