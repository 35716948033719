import { FULL_WIDTH, SIZE_8 } from 'styles/theme'
import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()({
    row: {
        display: 'flex',
        flexWrap: 'wrap',
        margin: `0 -${SIZE_8}`,
        '& > *': {
            boxSizing: 'border-box',
            flexShrink: 0,
            width: FULL_WIDTH,
            maxWidth: FULL_WIDTH,
            padding: `0 ${SIZE_8}`
        }
    }
})
