import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react'
import { BraintreeError, HostedFields } from 'braintree-web'
import { Address } from '../../../shared/ts/types'
import { HostedFieldsEvent, HostedFieldsHostedFieldsFieldName } from 'braintree-web/hosted-fields'
import { get } from 'store/actions/fetchCart'
import { useHome } from './useHome'

export type FormErrorsType = {
    cardNumber: string
    cvv: string
    expiration: string
}
export enum BraintreePaymentType {
    CreditCard = 'CreditCard'
}

export type BraintreeFormError = {
    error?: BraintreeError
    message: string
} | null

export type BraintreeNameType = {
    firstName: string
    lastName: string
} | null

export type BraintreeReturnType = {
    braintreeFormError: BraintreeFormError
    braintreeFormErrors: FormErrorsType
    cardType: string
    getBraintreeClientToken: (ordersUrl: string) => Promise<string>
    handleBraintreeHostedFieldsTokenize: () => Promise<string | null>
    handleBraintreePaymentRequest: (
        amount: number,
        billingAddress: Address,
        email: string,
        handleRequestError: () => void
    ) => Promise<PaymentMethodRequestReturnType>
    handleCloseSnackbar: () => void
    handleSetBraintreeDetails: (event: HostedFieldsEvent) => void
    handleValidateBraintreeForm: () => void
    handleValidateHostedFields: (event: {
        fields: { [x: string]: any }
        emittedBy: string | number
    }) => void
    hostedFieldsError: string
    hostedFieldsInstance: HostedFields | undefined
    isLoadingBraintreeForm: boolean
    openSnackbar: boolean
    setBraintreeFormError: Dispatch<SetStateAction<BraintreeFormError>>
    setBraintreeFormErrors: Dispatch<SetStateAction<FormErrorsType>>
    setHostedFieldsInstance: Dispatch<SetStateAction<HostedFields | undefined>>
    setIsLoadingBraintreeForm: Dispatch<SetStateAction<boolean>>
    setVaultPaymentMethodID: (pmID: string) => void
    vaultPaymentMethodID: string
}

export type BraintreePayloadType = {
    cardDetails: {
        brand: string | undefined
        expMonth: string | undefined
        expYear: string | undefined
        last4: string | undefined
    }
    paymentMethodId: string
    shouldVaultPayment: boolean
    paymentType: BraintreePaymentType
}

export type BraintreeTransactionType = {
    amount: string
    orderId: string
    paymentMethodToken: string
    shouldVaultPayment: boolean
    userId: string
}

export type CardDetailsType = {
    brand?: string
    expMonth?: string
    expYear?: string
    last4?: string
}

export type PaymentMethodTokenType = string
export type PaymentMethodRequestReturnType = {
    cardDetails: CardDetailsType | undefined
    paymentRequestToken: PaymentMethodTokenType | string
}
export type PaymentMethodResponseType = {
    details: {
        bin: string
        cardType: string
        expirationMonth: string
        expirationYear: string
        lastFour: string
    }
    nonce: string
    type: BraintreePaymentType
}

export const useBraintree = (): BraintreeReturnType => {
    const { setIsSnackbarOpen, setSnackbarError } = useHome()
    const [braintreeFormError, setBraintreeFormError] = useState<BraintreeFormError>(null)
    const [braintreeFormErrors, setBraintreeFormErrors] = useState<FormErrorsType>({
        cardNumber: '',
        cvv: '',
        expiration: ''
    })
    const [cardType, setCardType] = useState<string>('')
    const [hostedFieldsError, setHostedFieldsError] = useState<string>('')
    const [hostedFieldsInstance, setHostedFieldsInstance] = useState<HostedFields | undefined>()
    const [isLoadingBraintreeForm, setIsLoadingBraintreeForm] = useState<boolean>(true)
    // TODO: move to context
    const [openSnackbar, setOpenSnackbar] = useState<boolean>(false)
    const [vaultPaymentMethodID, setVaultPaymentMethodID] = useState<string>('')

    const getBraintreeClientToken = useCallback(async (ordersUrl: string): Promise<string> => {
        setIsLoadingBraintreeForm(true)
        try {
            const clientToken: string = await get(ordersUrl, `/braintree/client-tokens`)
            return clientToken
        } catch (error) {
            setBraintreeFormError({
                message: 'Error fetching Braintree client token: ' + error
            })
            return ''
        } finally {
            setIsLoadingBraintreeForm(false)
        }
    }, [])

    // TODO: Accept invite modal -> should always be true to vault payment.
    const handleBraintreePaymentRequest = async (
        amount: number,
        billingAddress: Address,
        email: string,
        handleRequestError: () => void
    ): Promise<PaymentMethodRequestReturnType> => {
        if (!hostedFieldsInstance) {
            setBraintreeFormError({
                message: 'No Braintree instance detected.'
            })
            return {
                cardDetails: undefined,
                paymentRequestToken: ''
            }
        }

        try {
            const response = await hostedFieldsInstance.tokenize({ vault: true })
            const hostedFields = hostedFieldsInstance.getState()
            const cardDetails: CardDetailsType = {
                brand: hostedFields.cards[0].niceType ?? '',
                expMonth: response?.details?.expirationMonth ?? '',
                expYear: response?.details?.expirationMonth ?? '',
                last4: response?.details?.lastFour ?? ''
            }

            return {
                cardDetails,
                paymentRequestToken: response.nonce
            }
        } catch (error) {
            handleRequestError()
            setBraintreeFormError({
                message: 'Error requesting payment method token from Braintree.',
                error: error as BraintreeError
            })
            return {
                cardDetails: undefined,
                paymentRequestToken: ''
            }
        }
    }

    const handleCloseSnackbar = () => {
        setBraintreeFormError(null)
        setOpenSnackbar(false)
    }

    const handleSetBraintreeDetails = useCallback((event) => {
        const cvvLabel = document.querySelector('[for="cc-cvv"]')
        if (event.cards.length === 1) {
            const card = event.cards[0]
            if (cvvLabel && card && card.code && card.code.name) {
                cvvLabel.textContent = card.code.name
                setCardType(card.type)
            }
        } else {
            if (cvvLabel) {
                cvvLabel.textContent = 'CVV'
            }
        }
    }, [])

    const handleBraintreeHostedFieldsTokenize = useCallback(async (): Promise<string | null> => {
        if (!hostedFieldsInstance) {
            return null
        }
        const state = hostedFieldsInstance.getState()
        const formValid = Object.keys(state.fields).every((key: string) => {
            return state.fields[key as HostedFieldsHostedFieldsFieldName].isValid
        })
        if (!formValid) {
            setHostedFieldsError('This field is required.')
            return null
        }
        if (!hostedFieldsError) {
            setHostedFieldsError('')
        }
        try {
            const cvvResponse = await hostedFieldsInstance.tokenize({
                cardholderName: 'cvv'
            })
            return cvvResponse.nonce
        } catch (error) {
            setIsSnackbarOpen(true)
            setSnackbarError({
                error: error as any,
                message: 'Error tokenizing hosted fields.'
            })
            return null
        }
    }, [hostedFieldsError, hostedFieldsInstance, setIsSnackbarOpen, setSnackbarError])

    const handleValidateHostedFields = useCallback(
        (event: { fields: { [x: string]: any }; emittedBy: string | number }) => {
            setBraintreeFormErrors({
                cardNumber: '',
                cvv: '',
                expiration: ''
            })
            var field = event.fields[event.emittedBy]
            field.container.classList.remove('is-valid')
            field.container.classList.remove('is-invalid')
            if (field.isValid) {
                field.container.classList.add('is-valid')
                field.container.classList.remove('is-invalid')
            } else if (field.isPotentiallyValid) {
            } else {
                field.container.classList.add('is-invalid')
            }
        },
        []
    )

    const handleValidateBraintreeForm = useCallback(() => {
        let formErrors: FormErrorsType = {
            cardNumber: '',
            cvv: '',
            expiration: ''
        }
        setBraintreeFormErrors({
            ...formErrors
        })
        const hostedFieldsState = hostedFieldsInstance?.getState()
        hostedFieldsState &&
            Object.entries(hostedFieldsState.fields).some(([_, value]) => {
                if (!value.isValid || value.isEmpty) {
                    switch (value.container.id) {
                        case 'cc-number':
                            formErrors.cardNumber = !value.isEmpty
                                ? 'The card number is not valid.'
                                : 'Credit card number is required.'
                            break
                        case 'cc-expiration':
                            formErrors.expiration = !value.isEmpty
                                ? 'The expiration date is not valid.'
                                : 'Expiration date is required.'
                            break
                        case 'cc-cvv':
                            formErrors.cvv = !value.isEmpty
                                ? 'The cvv is not valid.'
                                : 'CVV is required.'
                            break
                        default:
                            setBraintreeFormErrors({
                                ...formErrors
                            })
                            break
                    }
                    return
                }
            })
        setBraintreeFormErrors({
            ...formErrors
        })
        if (Object.values(formErrors).some((error) => error !== '')) {
            return
        }
    }, [hostedFieldsInstance])

    useEffect(() => {
        if (braintreeFormError) {
            setOpenSnackbar(true)
        }
    }, [braintreeFormError, openSnackbar])

    return {
        braintreeFormError,
        braintreeFormErrors,
        cardType,
        getBraintreeClientToken,
        handleBraintreeHostedFieldsTokenize,
        handleBraintreePaymentRequest,
        handleCloseSnackbar,
        handleSetBraintreeDetails,
        handleValidateBraintreeForm,
        handleValidateHostedFields,
        hostedFieldsError,
        hostedFieldsInstance,
        isLoadingBraintreeForm,
        openSnackbar,
        setBraintreeFormError,
        setBraintreeFormErrors,
        setHostedFieldsInstance,
        setIsLoadingBraintreeForm,
        setVaultPaymentMethodID,
        vaultPaymentMethodID
    }
}
