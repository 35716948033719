export enum InsureTarget {
    EVERYONE = 'everyone',
    MYSELF = 'myself'
}

export enum TravelGuardSelection {
    NO = 'no',
    YES = 'yes',
    YES_WITH_CANCEL = 'yes_with_cancel'
}
