import { ReactNode, createContext, useEffect, useState } from 'react'

export const AuthContext = createContext<any[]>(['{ "isLoggedIn": false }', () => {}])

type AddressType = {
    address: string
    address2: string
    city: string
    country: string
    firstName: string
    lastName: string
    phone: string
    state: string
    zip: string
}

type BillingInfoType = {
    billingAddress: AddressType
    brand: string
    expiration: string
    last4: string
    shippingAddress: AddressType
    stripeCustomerID: string
    stripePaymentMethodID: string
}

type UserType = {
    apple_id: string
    billingInfo: BillingInfoType
    created_at: Date | null
    email: string
    emailVerified: boolean
    exp: number
    facebook_id: string
    firstName: string
    google_id: string
    iat: number
    id: string
    is_active: boolean
    is_staff: boolean
    lastLogin: string
    lastName: string
    name: string
    passwordEmailSendTime: null | string
    phone: string
    profileImage: null | string
    roles: string[]
    source: string
    specificEvents: any[]
    stripeClientSecret: string
    version: number
}

type AuthState = {
    isLoggedIn?: boolean
    jwt: string
    refresh: string
    user: UserType | {}
}

export const AuthProvider = (props: { children: ReactNode }) => {
    const initialState: AuthState = {
        isLoggedIn: false,
        jwt: '',
        refresh: '',
        user: {}
    }

    const [authState, setAuthState] = useState<AuthState>(() => {
        if (typeof window !== 'undefined') {
            const storedState = localStorage.getItem('auth')
            return storedState ? JSON.parse(storedState) : initialState
        }
        return initialState
    })

    useEffect(() => {
        if (authState?.jwt && !authState.isLoggedIn) {
            setAuthState((prevState) => ({
                ...prevState,
                isLoggedIn: true
            }))
        }
        localStorage.setItem('auth', JSON.stringify(authState))
    }, [authState])

    return (
        <AuthContext.Provider value={[authState, setAuthState]}>
            {props.children}
        </AuthContext.Provider>
    )
}
