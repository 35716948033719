import { Country, ICountry, State } from 'country-state-city'
import { postcodeValidator, postcodeValidatorExistsForCountry } from 'postcode-validator'
import { isValidPhoneNumber } from 'react-phone-number-input'
import { Address } from '../store/actions/fetchCart'
import { TravelGuardInfoType } from 'types/travelGuard'
import dayjs from 'dayjs'
import {
    ENTER_VALID_EMAIL_ADDRESS,
    ENTER_VALID_PHONE,
    ENTER_VALID_POSTAL_CODE,
    FILL_FIELD_REQUIRED,
    PLEASE_ENTER_VALID_BIRTHDATE,
    PLEASE_SELECT_INSURANCE_OPTIONS,
    TRAVEL_GUARD_MAX_AGE
} from 'consts'

const countries: ICountry[] = Country.getAllCountries()

export const validateBirthDate = (date: string | Date, maxAge?: number) => {
    const _date = dayjs(date)
    if (!_date.isValid()) return false
    const today = dayjs()
    if (_date.isAfter(today, 'day')) return false
    if (maxAge) {
        return today.year() - _date.year() <= maxAge
    }
    return true
}

export const validateEmail = (value: string) => {
    const emailReg = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9_-]+)+$/
    let message = ''
    if (!value) {
        message = FILL_FIELD_REQUIRED
    } else if (!emailReg.test(value)) {
        message = ENTER_VALID_EMAIL_ADDRESS
    }
    return message
}
export const validateConfirmEmail = (value: string, email: string) => {
    const emailReg = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9_-]+)+$/
    let message = ''

    switch (true) {
        case !value:
            message = FILL_FIELD_REQUIRED
            break
        case !emailReg.test(value):
            message = ENTER_VALID_EMAIL_ADDRESS
            break
        case value.toLowerCase() !== email.toLowerCase():
            message = "The two email fields didn't match."
            break
    }
    return message
}
export const validateAddressInput = (address: Address, key: keyof Address) => {
    const value = address[key].trim()
    // const phoneReg = /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d+[\s.-]?\d+$/;
    const message: Record<string, string> = {}
    let isValid: boolean
    switch (key) {
        case 'phone':
            isValid = value ? isValidPhoneNumber(value) : false
            break
        case 'state':
            const countryStates = State.getStatesOfCountry(address.country)

            if (countryStates.length === 0) {
                isValid = true
            } else {
                isValid = countryStates.some((s) => s.isoCode == value)
            }
            break
        case 'country':
            isValid = countries.some((c) => c.isoCode == value)
            break
        case 'zip':
            const hasPostCode = postcodeValidatorExistsForCountry(address.country)
            if (hasPostCode) {
                isValid = postcodeValidator(value, address.country)
            } else {
                isValid = true
            }
            break
        case 'phoneAuthorization':
            isValid = true
            break
        default:
            isValid = value.length > 0 || key == 'address2'
    }

    if (isValid) {
        message[key] = ''
    } else {
        switch (key) {
            case 'phone':
                message[key] = ENTER_VALID_PHONE
                break
            case 'zip':
                message[key] = ENTER_VALID_POSTAL_CODE
                break
            default:
                message[key] = FILL_FIELD_REQUIRED
        }
    }

    return message as Record<keyof Address, string>
}

export const validateAddressForm = (formData: Address) => {
    let msg: any = {}
    let k: keyof Address
    for (k in formData) {
        msg = {
            ...msg,
            ...validateAddressInput(formData, k)
        }
    }

    return msg as Record<keyof Address, string>
}

const validateTravelGuardInput = (data: TravelGuardInfoType, key: keyof TravelGuardInfoType) => {
    const value = data[key]
    const message: Record<string, string> = {}
    switch (key) {
        case 'birthDate':
            if (!value) {
                message[key] = FILL_FIELD_REQUIRED
                break
            }
            if (!validateBirthDate(value, TRAVEL_GUARD_MAX_AGE)) {
                message[key] = PLEASE_ENTER_VALID_BIRTHDATE
                break
            }
            break
        case 'insureTarget':
            if (!value) {
                message[key] = FILL_FIELD_REQUIRED
            }
            break
        case 'selected':
            if (!value) {
                message[key] = PLEASE_SELECT_INSURANCE_OPTIONS
            }
            break
        default:
            const exhaustiveCheck: never = key
            throw new Error(`Unhandled case ${exhaustiveCheck}`)
    }
    return message as Record<keyof TravelGuardInfoType, string>
}

export const validateTravelGuardForm = (formData: TravelGuardInfoType, isSplitPay: boolean) => {
    let msg: any = {}
    let key: keyof TravelGuardInfoType
    for (key in formData) {
        if (!isSplitPay && key === 'insureTarget') {
            continue
        }
        msg = {
            ...msg,
            ...validateTravelGuardInput(formData, key)
        }
    }
    return msg as Record<keyof TravelGuardInfoType, string>
}
