import { useContext, useState } from 'react'
import { AuthContext } from '../auth/context'
import { isMobile, isTablet } from 'react-device-detect'
import { parseJwt } from '../util/parse'
import mParticle from '@mparticle/web-sdk'
import type { OrderState } from '../store'
import { usersClient } from 'apollo-client'
import { gql } from '@apollo/client'
import { useAppDispatch, useAppSelector } from 'hooks'
import { useRouter } from 'next/router'

const OAUTH_QUERY = gql`
    query Oauth(
        $token: String!
        $source: String!
        $name: String
        $firstName: String
        $lastName: String
    ) {
        oauth(
            token: $token
            source: $source
            name: $name
            firstName: $firstName
            lastName: $lastName
        ) {
            token
            refresh
        }
    }
`

export function useUser() {
    const dispatch = useAppDispatch()
    const [authState, setAuthState] = useContext(AuthContext)
    const [isSuccessful, setIsSuccessful] = useState<boolean>(false)
    const order: OrderState = useAppSelector((state) => state.orderReducer)
    const router = useRouter()
    const { eventSlug } = router.query
    const handleJWT = (response: { token: string; refresh: string }): void => {
        const jwt = response.token
        const refresh = response.refresh
        const user = parseJwt(jwt)
        const authContent = { isLoggedIn: true, jwt, refresh, user }
        setAuthState(authContent)
    }

    const signIn = () => {
        if (isMobile && !isTablet) {
            dispatch({ type: 'navbar/setState', payload: { expandedNav: true, signIn: true } })
        } else {
            dispatch({ type: 'navbar/setState', payload: { signIn: true } })
        }
    }

    const signUp = () => {
        if (isMobile && !isTablet) {
            dispatch({ type: 'navbar/setState', payload: { expandedNav: true, signUp: true } })
        } else {
            dispatch({ type: 'navbar/setState', payload: { signUp: true } })
        }
    }

    const logout = () => {
        window.mParticle.Identity.logout({})
        setAuthState({ isLoggedIn: false, jwt: '', refresh: '', user: {} })
        switch (router.pathname) {
            case `/events/${eventSlug}/checkout/submit`:
                break
            default:
                router.push('/logout')
        }
    }

    const setUser = (token: string, refresh: string) => {
        const user = parseJwt(token)
        setAuthState({ isLoggedIn: true, jwt: token, refresh, user })
    }

    const handleAppleLogin = async (id_token: string, firstName: string, lastName: string) => {
        let nameData = {}
        if (firstName || lastName) {
            nameData = {
                firstName: firstName,
                lastName: lastName,
                name: firstName + ' ' + lastName
            }
        }
        try {
            const { data } = await usersClient.query<{ oauth: { token: string; refresh: string } }>(
                {
                    fetchPolicy: 'no-cache',
                    query: OAUTH_QUERY,
                    variables: {
                        source: 'apple',
                        token: id_token,
                        ...nameData
                    }
                }
            )
            const jwt = data.oauth.token
            const refresh = data.oauth.refresh
            const user = parseJwt(jwt)
            if (router.pathname === '/invite/submit') {
                const { memberID } = router.query
                const member = order.members.find((m) => m.ID === memberID)
                if (member?.Email.toLowerCase() !== user.email.toLowerCase()) {
                    if (isMobile && !isTablet) {
                        dispatch({
                            type: 'navbar/setState',
                            payload: { expandedNav: true, signIn: true }
                        })
                    } else {
                        dispatch({ type: 'navbar/setState', payload: { signIn: true } })
                    }
                    document.querySelector('#js-apple-email')!.innerHTML =
                        'Please enter the correct email address.'
                    dispatch({ type: 'pageLoading/setState', payload: { loading: false } })
                    return
                }
            }
            setAuthState({ isLoggedIn: true, jwt, refresh, user })
            setIsSuccessful(true)
        } catch (error) {
            console.error('Error logging in with Apple: ', error)
        }
    }

    return {
        authState,
        handleAppleLogin,
        handleJWT,
        isSuccessful,
        logout,
        setIsSuccessful,
        setUser,
        signIn,
        signUp
    }
}
