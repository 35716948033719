import getConfig from 'next/config'
import { EventJSON } from '../../../../events-service/src/models/event'
import { OrderState } from 'store'

const { publicRuntimeConfig } = getConfig()

const CURRENCY = 'USD'
export const APP_NAME = 'Vibee'

type DataLayerObjectType = {
    [key: string]: any
}

declare global {
    interface Window {
        gtag: any
        dataLayer: DataLayerObjectType[]
    }
}

export type GAProductType = {
    affiliation: string
    coupon?: string
    discount?: number
    index: number
    item_brand: string
    item_category: string
    item_category2?: string
    item_category3?: string
    item_category4?: string
    item_category5?: string
    item_id: string
    item_list_id?: string
    item_list_name?: string
    item_name: string
    item_variant?: string
    location_id?: string
    price?: number
    quantity: number
}

export enum GoogleAnalyticsEventActionType {
    ADD_TO_CART = 'add_to_cart',
    CONVERSION = 'conversion',
    LOGIN = 'login',
    PAGE_VIEW = 'page_view',
    PURCHASE = 'purchase',
    REMOVE_FROM_CART = 'remove_from_cart',
    SELECT_ITEM = 'select_item',
    SIGN_UP = 'sign_up',
    VIEW_CART = 'view_cart',
    VIEW_ITEM = 'view_item',
    VIEW_ITEM_LIST = 'view_item_list'
}

export type GoogleAnalyticsEventPropType = {
    action: GoogleAnalyticsEventActionType
    appName?: typeof APP_NAME
    category?: string
    currency?: typeof CURRENCY
    label?: string
    loginMethod?: string
    items?: GAProductType[]
    itemListId?: string
    itemListName?: string
    pageTitle?: string
    tax?: number
    transactionId?: string
    value?: number
}

type GATransaction = {
    currency: typeof CURRENCY
    items: GAProductType[]
    paid_in_full: boolean
    paid_in_installments: boolean
    revenue: number
    split_payment_group_size?: number
    split_payment?: boolean
    tax?: number
    transaction_id?: string
    value?: number
}

export const handleOrder = (
    event: EventJSON,
    eventUrl: string,
    order: OrderState,
    isSplitPay?: boolean,
    isInstallment?: boolean
): GATransaction => {
    const hotel = event!.hotels.find((h) => {
        return h.rooms.find((r) => {
            return r.tiers.find((t) => {
                return order.items.find((i) => i.itemID === t.id)
            })
        })
    })!

    let gaProducts: GAProductType[] = []
    order.items.forEach((item, index) => {
        let gaProduct
        if (item.type === 'room.tier') {
            const room = hotel.rooms.find((room) => room.id === item.parent)!
            const tier = room.tiers.find((tier) => tier.id === item.itemID)!
            const customAttributes = { tier: tier.name }
            gaProduct = {
                affiliation: APP_NAME,
                coupon: undefined,
                discount: undefined,
                index: index,
                item_brand: hotel.name,
                item_category: `${event.name} accommodation`,
                item_category2: eventUrl,
                item_id: room.id,
                item_list_id: undefined,
                item_list_name: undefined,
                item_name: `${event.name} ${room.name}`,
                item_variant: undefined,
                location_id: undefined,
                price: item.price / 100,
                quantity: item.quantity
            }
            gaProducts.push(gaProduct)
        } else if (item.type === 'ticket.tier' || item.type === 'tier') {
            const ticket = event.tickets.find((ticket) => ticket.id === item.parent)!
            const tier = ticket.tiers.find((tier) => tier.id === item.itemID)!
            const customAttributes = { tier: tier.name }
            gaProduct = {
                affiliation: APP_NAME,
                coupon: undefined,
                discount: undefined,
                index: index,
                item_brand: hotel.name,
                item_category: `${event.name} ticket`,
                item_category2: eventUrl,
                item_id: ticket.id,
                item_list_id: undefined,
                item_list_name: undefined,
                item_name: `${event.name} ${ticket.name}`,
                item_variant: undefined,
                location_id: undefined,
                price: item.price / 100,
                quantity: item.quantity
            }
            gaProducts.push(gaProduct)
        } else if (item.type === 'item') {
            const addOn = event.addOnItems.find((addOn) => addOn.id === item.itemID)!
            gaProduct = {
                affiliation: APP_NAME,
                coupon: undefined,
                discount: undefined,
                index: index,
                item_brand: hotel?.name || event.name,
                item_category: `${event.name} addOn`,
                item_category2: eventUrl,
                item_id: addOn.id,
                item_list_id: undefined,
                item_list_name: undefined,
                item_name: `${event.name} ${addOn.name}`,
                item_variant: undefined,
                location_id: undefined,
                price: item.price / 100,
                quantity: item.quantity
            }
            gaProducts.push(gaProduct)
        }
    })

    return {
        currency: CURRENCY,
        items: gaProducts,
        paid_in_full: !isInstallment,
        paid_in_installments: isInstallment ? true : false,
        revenue: order.total / 100,
        split_payment_group_size: isSplitPay ? order.members.length : undefined,
        split_payment: isSplitPay,
        tax: order.taxesAndFees / 100,
        transaction_id: order.id,
        value: order.total / 100
    }
}

export const googleAnalyticsPageview = (url: string): void => {
    if (!window.gtag) {
        return
    }
    window.gtag('config', publicRuntimeConfig.NEXT_PUBLIC_GA_MEASUREMENT_ID, {
        page_path: url
    })
}

export const googleAnalyticsEvent = ({
    action,
    appName = APP_NAME,
    category,
    currency = CURRENCY,
    label,
    items = [],
    itemListId,
    itemListName,
    loginMethod,
    pageTitle,
    tax,
    transactionId,
    value
}: GoogleAnalyticsEventPropType): void => {
    if (!window.dataLayer) {
        return
    }
    if (!window.gtag) {
        return
    }
    window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push({
        appName: appName,
        event: action,
        ecommerce: {
            currency: currency,
            event_category: category,
            event_label: label,
            items: items,
            item_list_id: itemListId,
            item_list_name: itemListName,
            method: loginMethod,
            page_location: window.location.toString(),
            page_title: pageTitle,
            tax: tax,
            transaction_id: transactionId,
            value: value
        }
    })
    window.gtag('event', action, {
        app_name: appName,
        currency: currency,
        event_category: category,
        event_label: label,
        items: items,
        item_list_id: itemListId,
        item_list_name: itemListName,
        method: loginMethod,
        page_location: window.location.toString(),
        page_title: pageTitle,
        tax: tax,
        transaction_id: transactionId,
        value: value
    })
}
