import { useContext, useEffect } from 'react'
import type { AppContext, AppProps } from 'next/app'
import App from 'next/app'
import dynamic from 'next/dynamic'
import Script from 'next/script'
import getConfig from 'next/config'
import Head from 'next/head'
import { useRouter } from 'next/router'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import { ApolloProvider } from '@apollo/client'
import { Provider as ReduxProvider } from 'react-redux'
import { CacheProvider, EmotionCache } from '@emotion/react'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LicenseInfo } from '@mui/x-license-pro'
import { AuthContext, AuthProvider } from '../auth/context'
import { useHome } from 'hooks'
import { useNav } from 'hooks/useNav'
import { useWindowWidth } from 'hooks/useWidth'
import ErrorBoundary from '../components/errorBoundary'
import store from '../store/index'
import 'styles/globals.css'
import { NavbarComponent } from 'components/Navbar/NavbarComponent'
import { FooterComponent } from 'components/Footer/FooterComponent'
import { theme } from 'styles/theme'
import { eventsWithoutJwtClient } from 'apollo-client'
import { errorSignatures } from 'util/rollbarErrorConfig'
import createEmotionCache from 'util/createEmotionCache'
import { APP_NAME, googleAnalyticsPageview } from 'components/Google/GoogleAnalytics'

const Snackbar = dynamic(() =>
    import('components/Snackbar/SnackbarComponent').then((mod) => mod.SnackbarComponent)
)
const OrderCancelledCountDown = dynamic(() =>
    import('components/orderCancelledCountDown').then((mod) => mod.OrderCancelledCountDown)
)
const UserAccountModal = dynamic(() =>
    import('components/UserAccount/UserAccountModal').then((mod) => mod.UserAccountModal)
)

import { config, library } from '@fortawesome/fontawesome-svg-core'
import {
    faCalendar,
    faTicket,
    faTimes,
    faSquareH,
    faFaceSmilePlus
} from '@fortawesome/pro-regular-svg-icons'
import {
    faWifi,
    faCircleParking,
    faUtensils,
    faHandHeart,
    faBellConcierge,
    faSpa,
    faWheelchair,
    faBedFront,
    faShower,
    faPopcorn
} from '@fortawesome/pro-regular-svg-icons'
import '@fortawesome/fontawesome-svg-core/styles.css'
import '../public/static/css/plugins/bootstrap.css'
import '../public/static/css/plugins/bootstrap-icons.css'
import '../public/static/css/plugins/bootstrap-select.css'
import '../public/static/css/plugins/image-gallery.css'
import '../public/static/css/main.css'
import '../public/static/css/plugins/slick.css'
import '../public/static/css/overwrite.css'
import '../public/static/css/modal.css'

config.autoAddCss = false

library.add(
    faCalendar,
    faTicket,
    faTimes,
    faSquareH,
    faFaceSmilePlus,
    faWifi,
    faCircleParking,
    faUtensils,
    faHandHeart,
    faBellConcierge,
    faSpa,
    faWheelchair,
    faBedFront,
    faShower,
    faPopcorn
)

const { publicRuntimeConfig } = getConfig()
const clientSideEmotionCache = createEmotionCache()

LicenseInfo.setLicenseKey(
    publicRuntimeConfig?.NEXT_PUBLIC_MUI_LICENSE_KEY ??
        '7762be47fb2cc0c8529b35587387da25Tz04ODA0MixFPTE3NDQyMzAyOTYwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
)

export interface MyAppProps extends AppProps {
    emotionCache?: EmotionCache
}

interface Meta {
    keywords?: string
    description: string
    url: string
    shareImg: string
    title: string
    canonical?: string
}

function MyApp({ Component, pageProps, emotionCache = clientSideEmotionCache }: MyAppProps) {
    const [authState, _] = useContext(AuthContext)
    const router = useRouter()
    const { currentPathName, isNavbarHidden, isNotHomePage, shouldShowOrderCancelledCountdown } =
        useNav()
    const { isSnackbarOpen, setIsSnackbarOpen, snackbarError } = useHome()
    const { isMobileWidth } = useWindowWidth()
    const shouldShowFooter =
        currentPathName === '/' ||
        currentPathName.startsWith('/tours') ||
        currentPathName === '/faq' ||
        currentPathName === '/contact-us' ||
        currentPathName === '/[section]' ||
        currentPathName === '/careers'
    const isEvent = currentPathName === '/events/[slug]'
    const routerQuerySlug = router.query.slug as String
    const isF1 = isEvent
        ? routerQuerySlug.slice(-3) === '-f1' || routerQuerySlug.slice(-3) === '-F1'
        : false
    // Only for events: stripe-qpsr-hedc24 and braintree-qpsr-hedc24
    const isStripeQpsrHedc24Event = isEvent && routerQuerySlug === 'stripe-qpsr-hedc24'
    const isBraintreeQpsrHedc24Event = isEvent && routerQuerySlug === 'braintree-qpsr-hedc24'
    const meta: Meta = (pageProps as any).meta
    const isNodeENVProd = process.env.NODE_ENV === 'production'
    const isProdUrl = publicRuntimeConfig.NEXT_PUBLIC_ORDERS_URL === 'https://orders.vibee.com'
    const subDomain = publicRuntimeConfig.NEXT_PUBLIC_SUBDOMAIN
    const gaMeasurementId = publicRuntimeConfig.NEXT_PUBLIC_GA_MEASUREMENT_ID
    const gaID = subDomain === 'preview' ? 'G-HFSPVV3NDC' : gaMeasurementId
    const gaConfig = gaID ? "gtag('config', '" + gaID + "');" : ''
    //use in test branch(GA)
    // const gaConfig = "gtag('config', 'G-TW70X8LZGX',{ 'debug_mode':true });"
    const mParticleAPIKey = 'us2-e8411bf9c82c334b85b7d81a709b193b'

    useEffect(() => {
        const handleRouteChange = (url: string) => {
            googleAnalyticsPageview(url)
        }
        router.events.on('routeChangeComplete', handleRouteChange)
        return () => {
            router.events.off('routeChangeComplete', handleRouteChange)
        }
    }, [router.events])

    useEffect(() => {
        const auth = authState
        const mParticleConfig: mParticle.MPConfiguration = {
            appName: APP_NAME,
            // TODO: should be a better check, can change later when we inject ENV vars for distinguishing between prod & staging
            isDevelopmentMode: isProdUrl ? false : true,
            logLevel: isProdUrl ? 'none' : 'warning'
        }
        if (window !== undefined) {
            if (mParticleAPIKey) {
                if (auth?.user != null) {
                    mParticleConfig.identifyRequest = {
                        userIdentities: {
                            customerid: auth.user.id,
                            email: auth.user.email.toLowerCase()
                        }
                    }

                    mParticleConfig.identifyRequest.userIdentities.mobile_number =
                        auth.user.phone ?? undefined
                    mParticleConfig.identityCallback = (result) => {
                        if (result.getUser()) {
                            const user = result.getUser()
                            user.setUserAttribute('firstName', auth.user.firstName)
                            user.setUserAttribute('lastName', auth.user.lastName)
                            user.setUserAttribute(
                                'mobile',
                                auth.user.billingInfo?.billingAddress?.phone ?? ''
                            )
                            user.setUserAttribute(
                                'address',
                                auth.user.billingInfo?.billingAddress?.address ?? ''
                            )
                            user.setUserAttribute(
                                'city',
                                auth.user.billingInfo?.billingAddress?.city ?? ''
                            )
                            user.setUserAttribute(
                                'state',
                                auth.user.billingInfo?.billingAddress?.state ?? ''
                            )
                            user.setUserAttribute(
                                'zip',
                                auth.user.billingInfo?.billingAddress?.zip ?? ''
                            )
                            user.setUserAttribute(
                                'country',
                                auth.user.billingInfo?.billingAddress?.country ?? ''
                            )

                            const identities = user.getUserIdentities()
                        } else {
                            // the IDSync call failed
                        }
                    }
                }

                // @ts-ignore
                if (gaMeasurementId && window != undefined && window.gaGlobal?.vid) {
                    const integrationAttributes = {}
                    const googleMparticlePartnerId = 160
                    const googleClientIdAttributeKey = 'client_id'

                    // @ts-ignore
                    integrationAttributes[googleClientIdAttributeKey] = window.gaGlobal.vid
                    window.mParticle.setIntegrationAttribute(
                        googleMparticlePartnerId,
                        integrationAttributes
                    )
                }

                window.mParticle.eCommerce.setCurrencyCode('USD')
                window.mParticle.ready(() => {
                    window.mParticle.init(mParticleAPIKey, mParticleConfig)
                })
            } else {
                console.error('Please add your mParticle API Key')
            }
        }

        if (isNodeENVProd) {
            if (auth?.user) {
                const authUser = auth.user

                // some configuration is also in document.tsx
                window.Rollbar.configure({
                    itemsPerMinute: 5,
                    maxItems: 20,
                    checkIgnore: (
                        isUncaught: any,
                        args: any,
                        payload: {
                            body: { trace: { exception: { description: string | string[] } } }
                        }
                    ) => {
                        const errorValueToCheck = payload.body?.trace?.exception?.description || ''
                        const hasRollbarError = errorSignatures.some((signature) =>
                            errorValueToCheck.includes(signature)
                        )
                        return hasRollbarError
                    },
                    ignoredMessages: errorSignatures,
                    payload: {
                        person: {
                            id: authUser.id,
                            email: authUser.email
                        }
                    },
                    output: {
                        sourcemap: true
                    }
                })
            }
        }
    }, [isNodeENVProd, isProdUrl])

    return (
        <AuthProvider>
            <CacheProvider value={emotionCache}>
                <Head>
                    <meta
                        name="viewport"
                        content="width=device-width, initial-scale=1"
                    />
                    <meta charSet="UTF-8" />
                    {meta && (
                        <>
                            {(isStripeQpsrHedc24Event || isBraintreeQpsrHedc24Event) && (
                                <meta
                                    content="noindex,nofollow"
                                    name="robots"
                                />
                            )}
                            <meta
                                name="keywords"
                                content={meta.keywords}
                            />
                            <meta
                                name="description"
                                content={meta.description}
                            />
                            <meta
                                property="og:description"
                                content={meta.description}
                            />
                            <meta
                                property="og:url"
                                content={meta.url}
                            />
                            <meta
                                property="fb:app_id"
                                content="4826926720709866"
                            />
                            <meta
                                property="og:image"
                                content={meta.shareImg}
                            />
                            <meta
                                property="og:title"
                                content={meta.title}
                            />
                            <meta
                                property="og:type"
                                content="website"
                            />
                            <meta
                                name="twitter:card"
                                content="summary_large_image"
                            />
                            <meta
                                name="twitter:title"
                                content={meta.title}
                            />
                            <meta
                                name="twitter:description"
                                content={meta.description}
                            />
                            <meta
                                name="twitter:image"
                                content={meta.shareImg}
                            />
                            {meta.canonical && (
                                <link
                                    rel="canonical"
                                    href={meta.canonical}
                                />
                            )}
                            <title>{meta.title}</title>
                        </>
                    )}
                </Head>
                <ApolloProvider client={eventsWithoutJwtClient}>
                    <ReduxProvider store={store}>
                        <ErrorBoundary>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <ThemeProvider theme={theme}>
                                    <CssBaseline />
                                    {!isNavbarHidden && (
                                        <NavbarComponent isMobileWidth={isMobileWidth} />
                                    )}
                                    {shouldShowOrderCancelledCountdown && (
                                        <OrderCancelledCountDown isNotHomePage={isNotHomePage} />
                                    )}
                                    <Component {...pageProps} />
                                    <UserAccountModal />
                                    {shouldShowFooter ? (
                                        <FooterComponent
                                            isMobileWidth={isMobileWidth}
                                            isNotHomePage={isNotHomePage}
                                            routerPathName={currentPathName}
                                            isF1={isF1}
                                        />
                                    ) : null}
                                    {isSnackbarOpen && (
                                        <Snackbar
                                            isSnackbarOpen={isSnackbarOpen}
                                            setIsSnackbarOpen={setIsSnackbarOpen}
                                            snackbarError={snackbarError}
                                        />
                                    )}
                                    <Script
                                        id="mparticle"
                                        type="text/javascript"
                                    >
                                        {`(function(t){window.mParticle=window.mParticle||{};window.mParticle.EventType={Unknown:0,Navigation:1,Location:2,Search:3,Transaction:4,UserContent:5,UserPreference:6,Social:7,Other:8};window.mParticle.eCommerce={Cart:{}};window.mParticle.Identity={};window.mParticle.config=window.mParticle.config||{};window.mParticle.config.rq=[];window.mParticle.config.snippetVersion=2.3;window.mParticle.ready=function(t){window.mParticle.config.rq.push(t)};var e=["endSession","logError","logBaseEvent","logEvent","logForm","logLink","logPageView","setSessionAttribute","setAppName","setAppVersion","setOptOut","setPosition","startNewSession","startTrackingLocation","stopTrackingLocation"];var o=["setCurrencyCode","logCheckout"];var i=["identify","login","logout","modify"];e.forEach(function(t){window.mParticle[t]=n(t)});o.forEach(function(t){window.mParticle.eCommerce[t]=n(t,"eCommerce")});i.forEach(function(t){window.mParticle.Identity[t]=n(t,"Identity")});function n(e,o){return function(){if(o){e=o+"."+e}var t=Array.prototype.slice.call(arguments);t.unshift(e);window.mParticle.config.rq.push(t)}}var dpId,dpV,config=window.mParticle.config,env=config.isDevelopmentMode?1:0,dbUrl="?env="+env,dataPlan=window.mParticle.config.dataPlan;dataPlan&&(dpId=dataPlan.planId,dpV=dataPlan.planVersion,dpId&&(dpV&&(dpV<1||dpV>1e3)&&(dpV=null),dbUrl+="&plan_id="+dpId+(dpV?"&plan_version="+dpV:"")));var mp=document.createElement("script");mp.type="text/javascript";mp.async=true;mp.src=("https:"==document.location.protocol?"https://jssdkcdns":"http://jssdkcdn")+".mparticle.com/js/v2/"+t+"/mparticle.js" + dbUrl;var c=document.getElementsByTagName("script")[0];c.parentNode.insertBefore(mp,c)}
                                        )('us2-e8411bf9c82c334b85b7d81a709b193b')`}
                                    </Script>
                                    <Script
                                        id="google-tag-manager"
                                        strategy="afterInteractive"
                                        className="optanon-category-C0002"
                                    >
                                        {`
                                            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                                            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                                            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                                            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                                            })(window,document,'script','dataLayer','GTM-NWDRRQP');
                                        `}
                                    </Script>
                                    <Script
                                        strategy="afterInteractive"
                                        src={`https://www.googletagmanager.com/gtag/js?id=${gaID}`}
                                    />
                                    <Script
                                        id="gtag-init"
                                        strategy="afterInteractive"
                                        dangerouslySetInnerHTML={{
                                            __html: `
                                                window.dataLayer = window.dataLayer || [];
                                                function gtag(){dataLayer.push(arguments);}
                                                gtag('js', new Date());
                                                gtag('config', '${gaID}', {
                                                        debug_mode:${!isNodeENVProd},
                                                        page_path: window.location.pathname,
                                                    }
                                                );
                                            `
                                        }}
                                    />
                                    {isNodeENVProd ? (
                                        <Script
                                            id="cookielaw"
                                            src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
                                            charSet="UTF-8"
                                            data-domain-script="d38aed03-4d16-4370-ac54-f010bb70efad"
                                        />
                                    ) : (
                                        <Script
                                            id="cookielaw-not-prod"
                                            src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
                                            charSet="UTF-8"
                                            data-domain-script="d38aed03-4d16-4370-ac54-f010bb70efad-test"
                                        />
                                    )}
                                    <Script id="optanonwrapper">
                                        {`
                                            function OptanonWrapper() { }
                                            window.dataLayer = window.dataLayer || [];
                                            function gtag(){window.dataLayer.push(arguments);}
                                            gtag('js', new Date());
                                            ${gaConfig}
                                            `}
                                    </Script>
                                    {isNodeENVProd && (
                                        <Script id="hive">
                                            {`
                                            (function(h,i,v,e,s,d,k){h.HiveSDKObject=s;h[s]=h[s]||function(){(h[s].q=h[s].q||[]).push(arguments)},d=i.createElement(v),k=i.getElementsByTagName(v)[0];d.async=1;d.id=s;d.src=e+'?r='+parseInt(new Date()/60000);k.parentNode.insertBefore(d,k)})(window,document,'script','https://cdn-prod.hive.co/static/js/sdk-loader.js','HIVE_SDK')

                                            HIVE_SDK('init', 130834, function(data) {});
                                        `}
                                        </Script>
                                    )}
                                    <Script
                                        src="https://cdn.attn.tv/vibee/dtag.js"
                                        strategy="lazyOnload"
                                    />
                                </ThemeProvider>
                            </LocalizationProvider>
                        </ErrorBoundary>
                    </ReduxProvider>
                </ApolloProvider>
            </CacheProvider>
        </AuthProvider>
    )
}

MyApp.getInitialProps = async (appContext: AppContext) => {
    const appProps = await App.getInitialProps(appContext)
    return { ...appProps }
}

export default MyApp
