import { useState } from "react";


declare var HIVE_SDK: any;

export default function useHive(successCallback:any, errorCallback:any) {
  const emailReg = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9_-]+)+$/;
  const [notifying, setNotifying] = useState<boolean>(false);
  const [notifyResult, setNotifyResult] = useState<string>("");
  const [emailValid, setEmailValid] = useState<boolean|undefined>(undefined);
  const [emailError, setEmailError] = useState<string>('');

  const hiveSubscribe = (email:string, eventName:string) => {
    setNotifying(true);
    setEmailError('');
    setNotifyResult('');
    const valid = emailReg.test(email);
    if (!valid) {
      setEmailValid(false);
      setNotifying(false);
      setEmailError('Please enter a valid email address.');
    } else {
      setEmailValid(undefined);
      setEmailError('');
      HIVE_SDK(
        'emailSignup',
        {
          email: email,
        },
        function(data:any) {
          setEmailValid(true);
          setNotifying(false);
          setNotifyResult('Thanks for subscribing.');
          HIVE_SDK(
            'addToSegment',
            eventName,
            function() {
              successCallback();
            },
            function(data:any) {
              errorCallback(data);
            }
          );
        },
        function(data:any) {
          setEmailValid(false);
          setNotifying(false);
          setEmailError('Please enter a valid email address.');
        }
      );
    }
  }

  let hiveState = {
    notifying: notifying,
    notifyResult: notifyResult,
    emailValid: emailValid,
    emailError: emailError
  }

  return [hiveState, hiveSubscribe] as const;
}