import { isMobile, isTablet } from 'react-device-detect'

export function getImage(headerImageArray: any, size: string = 'default') {
    let images: { [index: string]: { url: string; height: number; width: number } } = {}
    for (let i = 0; i < headerImageArray.length; i++) {
        let image = headerImageArray[i]
        images[image.size] = image
    }

    if (size == 'default') {
        if (isMobile) {
            size = 'mobile'
        } else if (isTablet) {
            size = 'tablet'
        } else {
            size = 'desktop'
        }
    }

    let try_sizes: string[] = []
    if (size == 'mobile') {
        try_sizes = ['mobile', 'tablet', 'desktop', 'square']
    } else if (size == 'tablet') {
        try_sizes = ['tablet', 'desktop', 'mobile', 'square']
    } else if (size == 'square') {
        try_sizes = ['square', 'tablet', 'desktop', 'mobile']
    } else if (size == 'card') {
        try_sizes = ['card', 'mobile', 'desktop', 'tablet']
    } else {
        try_sizes = ['desktop', 'tablet', 'mobile', 'square']
    }

    for (let i = 0; i < try_sizes.length; i++) {
        if (images[try_sizes[i]]) {
            // use Cloudflare for caching & image optimization
            const updatedImage = {
                ...images[try_sizes[i]],
                url: images[try_sizes[i]].url.replace('s3.us-west-2.amazonaws.com/vibee.com/', 's3west.vibee.com/')
            }

            return updatedImage
        }
    }

    return { url: '', height: 0, width: 0 }
}
