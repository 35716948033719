import { ItineraryItemJSON } from '../../../../events-service/src/models/itineraryItem'
import { getTimeZone } from '../../util/dates'
import { getDayOfWeek } from '../../util/parse'
import { useStyles } from 'components/Slug/styles/eventTimeLine.styles'
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined'
import { FONT_SIZE_8, SIZE_12 } from 'styles/theme'
import dayjs from 'dayjs'

export const EventTimeLine = ({
    itinerary,
    timezone
}: {
    itinerary: ItineraryItemJSON[]
    timezone: string
}) => {
    const { classes } = useStyles()
    let rightOrLeft: string = 'right'
    let itineraries: any = []

    itinerary.forEach((itinerary: any) => {
        const day = getTimeZone(itinerary.date, timezone)
        let sameDayIndex = 0
        const hasDay = itineraries.find((item: any, index: number) => {
            sameDayIndex = index
            return item.day.format('YYYY-MM-DD') === day.format('YYYY-MM-DD')
        })
        if (itineraries.length === 0 || !hasDay) {
            itineraries.push({
                day: day,
                id: itinerary.id,
                items: [
                    {
                        id: itinerary.id,
                        label: itinerary.label,
                        date: itinerary.date,
                        position: itinerary.position
                    }
                ],
                position: itinerary.position
            })
        } else {
            itineraries[sameDayIndex].items.push({
                date: itinerary.date,
                id: itinerary.id,
                label: itinerary.label,
                position: itinerary.position
            })
        }
    })
    const sortedItineraries = itineraries
        .sort((a: { day: dayjs.Dayjs }, b: { day: dayjs.Dayjs }) => (a.day.isAfter(b.day) ? 1 : -1))
        .map((item: { items: any[] }) => {
            return {
                ...item,
                items: item.items?.sort((a, b) => {
                    return a.position - b.position
                })
            }
        })

    return (
        <div className={classes.wrapper}>
            <div
                style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    margin: `0 -${SIZE_12}`
                }}
            >
                <div className={classes.container}>
                    <h2 className={classes.header}>Itinerary</h2>
                    <div id="content">
                        <ul className={`${classes.timeLine}`}>
                            {sortedItineraries.map((itineraryItem: any) => {
                                let markerStyle: any = {
                                    left: 0,
                                    marginRight: FONT_SIZE_8
                                }
                                let eventClasses = classes.event + ' '
                                if (rightOrLeft === 'right') {
                                    rightOrLeft = 'left'
                                    eventClasses += classes.right
                                } else {
                                    rightOrLeft = 'right'
                                    eventClasses += classes.left
                                    markerStyle = {
                                        marginLeft: FONT_SIZE_8,
                                        right: 0
                                    }
                                }
                                return (
                                    <li
                                        className={`${classes.event} ${eventClasses}`}
                                        data-date={getDayOfWeek(itineraryItem.day, timezone)}
                                        key={itineraryItem.id}
                                    >
                                        <ul>
                                            {itineraryItem.items.map((item: any) => {
                                                const currentTime = dayjs()
                                                const itemTime = dayjs(item.date)
                                                const isItemExpires = currentTime.isAfter(itemTime)
                                                return (
                                                    <li
                                                        className={classes.text}
                                                        key={item.id}
                                                    >
                                                        <span
                                                            className={classes.marker}
                                                            style={markerStyle}
                                                        >
                                                            {isItemExpires && <CheckOutlinedIcon />}
                                                        </span>
                                                        <div
                                                            dangerouslySetInnerHTML={{
                                                                __html: item.label
                                                            }}
                                                        />
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}
