import * as React from 'react';

import ImageGallery, { ReactImageGalleryItem, ReactImageGalleryProps } from 'react-image-gallery';


export default class hotelCardImagesGallery extends ImageGallery {
  // originRenderItem?: ((item: ReactImageGalleryItem) => React.ReactNode) | undefined;
  // renderItem?: ((item: ReactImageGalleryItem) => React.ReactNode) | undefined;

  constructor(props: ReactImageGalleryProps) {
    super(props);
    let _this: any = this;
    _this.originRenderItem = _this.renderItem;
    _this.renderItem = (item: ReactImageGalleryItem) => {
      let i = _this.originRenderItem!(item);
      return (
        <>
          {i}
          <div className="image-gallery-index">
            <span className="image-gallery-index-current">
              {_this.getCurrentIndex() + 1}
            </span>
            <span className="image-gallery-index-separator">
              {_this.props.indexSeparator}
            </span>
            <span className="image-gallery-index-total">
              {_this.props.items.length}
            </span>
          </div>
        </>
      );
    }
  }
}