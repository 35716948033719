import { gql } from '@apollo/client'
export const CONTACT_US = gql`
    mutation ContactUs(
        $topical: String!
        $email: String!
        $name: String!
        $subject: String!
        $orderNumber: String
        $phone: String
        $relatedTo: String!
        $content: String!
    ) {
        contactUs(
            topical: $topical
            email: $email
            name: $name
            subject: $subject
            orderNumber: $orderNumber
            phone: $phone
            relatedTo: $relatedTo
            content: $content
        ) {
            ok
        }
    }
`
