import { Skeleton } from '@mui/material'
import { useStyles } from 'components/Slug/styles/eventTop.styles'
import Image from 'components/image'
import { FULL_WIDTH } from 'styles/theme'
import { getImage } from 'util/getEventHeaderImageByDevice'

export type EventTopProps = {
    hasAddToCart: boolean
    headerImage: any
    headerVideo: any
    isLoading: boolean
    isMobileWidth: boolean
}

export const EventTop = ({
    hasAddToCart,
    headerImage,
    headerVideo,
    isLoading,
    isMobileWidth
}: EventTopProps) => {
    const { classes } = useStyles()
    const desktopBackgroundImageUrl = getImage(headerImage, 'desktop').url
    const homepageBackgroundImageUrl = getImage(headerImage, 'square').url
    let desktopVideo = ''
    let mobileVideo = ''

    headerVideo?.forEach((video: { size: string; url: string }) => {
        if (video.size === 'desktop') {
            desktopVideo = video.url
        }
        if (video.size === 'mobile') {
            mobileVideo = video.url
        }
    })

    const video = isMobileWidth ? mobileVideo : desktopVideo
    const renderEventTop = () => {
        if (isLoading) {
            return (
                <Skeleton
                    animation="wave"
                    className={classes.skeleton}
                    sx={{
                        height: FULL_WIDTH,
                        width: FULL_WIDTH
                    }}
                    variant="rectangular"
                />
            )
        }
        if (headerVideo.length > 0) {
            const poster = isMobileWidth ? homepageBackgroundImageUrl : desktopBackgroundImageUrl
            return (
                <video
                    autoPlay={true}
                    height={FULL_WIDTH}
                    loop={true}
                    muted={true}
                    playsInline={true}
                    poster={poster}
                    preload="metadata"
                    style={{
                        display: isLoading ? 'none' : 'block'
                    }}
                    width={FULL_WIDTH}
                >
                    <source src={video} />
                </video>
            )
        }
        return (
            <>
                <Image
                    alt=""
                    className={classes.desktopImage}
                    layout="fill"
                    src={desktopBackgroundImageUrl}
                />
                <Image
                    alt=""
                    className={classes.homepageImage}
                    layout="fill"
                    src={homepageBackgroundImageUrl}
                />
            </>
        )
    }
    return (
        <>
            <div
                className={classes.container}
                style={hasAddToCart ? { marginTop: 0 } : {}}
            >
                {headerVideo.length > 0 ? (
                    <div className={classes.videoWrapper}>{renderEventTop()}</div>
                ) : (
                    <div className={classes.imageContainer}>
                        <div className={classes.imageWrapper}>{renderEventTop()}</div>
                    </div>
                )}
            </div>
        </>
    )
}
