import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { TicketTipJSON } from '../../../events-service/src/models/ticketTip'
import { Typography } from '@mui/material'
import {
    FONT_SIZE_8,
    FONT_SIZE_12,
    FONT_SIZE_14,
    FONT_WEIGHT_500,
    SIZE_2,
    SIZE_10,
    SIZE_20,
    SIZE_24,
    SIZE_36,
    theme,
} from 'styles/theme'
import clsx from 'clsx'

import { useStyles } from './Slug/styles/tipsSection.styles'
import { useWindowWidth } from 'hooks'

export type TipsSectionPropType = {
    handleViewMore: (b: boolean) => void
    needViewMore: boolean
    ticketName: string
    ticketTip: TicketTipJSON | undefined
    viewMore: boolean
}

export const TipsSection = ({
    handleViewMore,
    needViewMore,
    ticketName,
    ticketTip,
    viewMore
}: TipsSectionPropType) => {
    const { classes } = useStyles()
    const { isMobileWidth } = useWindowWidth()
    return (
        <>
            {ticketTip && (
                <div
                    className={classes.cardBody}
                    style={{
                        paddingTop: FONT_SIZE_8,
                        paddingBottom: isMobileWidth ? SIZE_24 : SIZE_36
                    }}
                >
                    {ticketTip && (
                        <div
                            className={classes.listBox}
                            style={{
                                margin: '0 auto',
                                width: '250px'
                            }}
                        >
                            <div
                                className={clsx({
                                    [classes.tipHide]: needViewMore && !viewMore
                                })}
                                id={ticketTip.id}
                            >
                                <div
                                    style={{
                                        margin: 0,
                                        padding: 0,
                                        textAlign: 'left'
                                    }}
                                >
                                    {ticketTip.tips.map((tip, index) => {
                                        return (
                                            <div
                                                key={`${ticketName}_tip${index}`}
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    marginBottom: SIZE_20
                                                }}
                                            >
                                                <CheckCircleIcon
                                                    style={{
                                                        color: theme.palette.secondary.light,
                                                        fontSize: FONT_SIZE_14,
                                                        marginRight: SIZE_10,
                                                        marginTop: SIZE_2
                                                    }}
                                                />{' '}
                                                <Typography
                                                    color={theme.palette.secondary.contrastText}
                                                    style={{
                                                        fontSize: FONT_SIZE_12,
                                                        fontWeight: FONT_WEIGHT_500
                                                    }}
                                                    variant="body2"
                                                >
                                                    {tip}
                                                </Typography>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                            {needViewMore ? (
                                <a
                                    className={classes.viewMore}
                                    onClick={() => handleViewMore(!viewMore)}
                                    style={{
                                        marginTop: viewMore ? FONT_SIZE_8 : FONT_SIZE_12
                                    }}
                                >
                                    {viewMore ? 'View Less' : 'View More'}
                                </a>
                            ) : (
                                ''
                            )}
                        </div>
                    )}
                </div>
            )}
        </>
    )
}
