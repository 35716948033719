import { gql, DocumentNode } from '@apollo/client'

export const ACTIVE_EVENTS_AND_TOURS: DocumentNode = gql`
    query ListActiveToursAndEvents($limit: Int!, $offset: Int!, $orderBy: String, $desc: String) {
        listActiveToursAndEvents(limit: $limit, offset: $offset, orderBy: $orderBy, desc: $desc) {
            count
            total
            data {
                events {
                    id
                    published
                    slug
                    timezone
                    name
                    location
                    locationDisplay
                    packagesFrom
                    startDate
                    endDate
                    dateDisplay
                    headerImage {
                        format
                        height
                        id
                        position
                        size
                        url
                        width
                    }
                    realEventEndDate
                }
                tours {
                    id
                    name
                    locationDisplay
                    slug
                    events {
                        ... on Event {
                            id
                            published
                            randomPass {
                                password
                                startTime
                            }
                            slug
                            name
                            description
                            location
                            locationDisplay
                            realEventStartDate
                            realEventEndDate
                            timezone
                        }
                        ... on Dropdown {
                            id
                            name
                        }
                    }
                    homepageSubtext
                    headerImage {
                        format
                        height
                        id
                        position
                        size
                        url
                        width
                    }
                }
            }
        }
    }
`
