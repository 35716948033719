import Box from '@mui/material/Box'
import Close from '@mui/icons-material/Close'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import ForgetPwd from 'components/forgetPwd'
import ResetPwd from 'components/resetPwd'
import SignIn from 'components/signIn'
import SignUp from 'components/signUp'
import { ModalComponent } from 'components/Modal'
import { useAppSelector } from 'hooks'
import { useDispatch } from 'react-redux'
import { NavbarState } from 'store'
import { FONT_SIZE_20, SIZE_18, SIZE_20, SIZE_28 } from 'styles/theme'
import { ReactNode } from 'react'

export type ModalComponentType = {
    children: ReactNode
    isShown: boolean
    onClose: () => void
    title: string
}

const Modal = ({ children, isShown, onClose, title }: ModalComponentType) => {
    return (
        <ModalComponent
            open={isShown}
            sx={{
                position: 'absolute',
                top: 'calc(100% - 95%)'
            }}
        >
            <Box
                style={{
                    maxWidth: '407px',
                    minWidth: '407px'
                }}
            >
                <DialogTitle>
                    <Box
                        flexDirection="column"
                        display="flex"
                        paddingLeft={SIZE_18}
                        paddingRight={SIZE_18}
                        paddingTop={SIZE_20}
                    >
                        <Typography
                            sx={{
                                fontSize: FONT_SIZE_20,
                                fontWeight: 700,
                                lineHeight: SIZE_28
                            }}
                        >
                            {title}
                        </Typography>
                        {onClose ? (
                            <IconButton
                                aria-label="close"
                                onClick={onClose}
                                sx={{
                                    position: 'absolute',
                                    right: 28,
                                    top: 30,
                                    color: (theme) => theme.palette.grey[500]
                                }}
                            >
                                <Close />
                            </IconButton>
                        ) : null}
                    </Box>
                </DialogTitle>
                <DialogContent>{children}</DialogContent>
            </Box>
        </ModalComponent>
    )
}

export const UserAccountModal = () => {
    const dispatch = useDispatch()
    const navState: NavbarState = useAppSelector((state) => state.navbarReducer)
    const modalData = [
        {
            key: 'signUp',
            title: 'Sign Up',
            content: <SignUp />
        },
        {
            key: 'signIn',
            title: 'Sign In',
            content: <SignIn />
        },
        {
            key: 'forgotPassword',
            title: 'Forgot Password',
            content: <ForgetPwd />
        },
        {
            key: 'resetPassword',
            title: 'Reset Password',
            content: <ResetPwd />
        }
    ]

    return (
        <>
            {modalData.map(
                ({ key, title, content }) =>
                    (navState as any)[key] &&
                    !navState.expandedNav && (
                        <Modal
                            key={key}
                            isShown={(navState as any)[key] && !navState.expandedNav}
                            onClose={() =>
                                dispatch({ type: 'navbar/setState', payload: { [key]: false } })
                            }
                            title={title}
                        >
                            {content}
                        </Modal>
                    )
            )}
        </>
    )
}
