import dayjs, { Dayjs } from 'dayjs'
import { DateRange } from '@mui/x-date-pickers-pro'
import { Dispatch, useCallback, useEffect, useState } from 'react'
import { EventJSON } from '../../../events-service/src/models/event'
import { getShoulderNightsByHotelId, getLocalTimeZone } from 'util/dates'
import { ShoulderInventoryErrorArrayType } from 'util/customizeJsonType'

export type SelectableDateType = {
    max: Dayjs | null
    min: Dayjs | null
}

export type HotelOnlyReturnType = {
    addToCartDefaultValueRange: DateRange<dayjs.Dayjs> | [null, null]
    addToCartValue: DateRange<dayjs.Dayjs> | [null, null]
    cartFirstCheckDateOfLocationTimeRange: DateRange<dayjs.Dayjs> | [null, null]
    defaultValueRange: DateRange<Dayjs>
    getCartFirstCheckDateRange: () => DateRange<dayjs.Dayjs>
    hasShoulderNights: boolean
    maxSelectDate: any
    minSelectDate: any
    resetDatesToInitialEventDates: () => void
    selectedDates: DateRange<Dayjs> | [null, null]
    setMaxSelectDate: Dispatch<any>
    setMinSelectDate: Dispatch<any>
    shoulderInventoryErrorArray: ShoulderInventoryErrorArrayType | undefined
    updateDates: (value: DateRange<Dayjs>) => void
    updateEventProp: (prop: any) => void
    textContent: string
}

export const useHotelOnly = ({
    event,
    groupSize,
    hasAddToCart,
    inventory,
    selectedHotelId
}: {
    event: EventJSON
    groupSize: number
    hasAddToCart: boolean
    inventory: Record<string, number>
    selectedHotelId: string
}): HotelOnlyReturnType => {
    const getShoppingCart = () => {
        if (typeof window !== 'undefined') {
            const addToCartInfo = window.localStorage.getItem('addToCartInfo')
            if (addToCartInfo) {
                const cartInfo = JSON.parse(addToCartInfo)
                return cartInfo
            }
        }
        return {}
    }
    const currentShoppingCart = getShoppingCart()
    // TODO remember time
    // const isCurrentEvent = currentShoppingCart.eventID === event.id
    // let checkInDateSelect =
    //     currentShoppingCart?.checkInDate !== undefined
    //         ? currentShoppingCart.checkInDate
    //         : event.checkInDate
    // let checkOutDateSelect =
    //     currentShoppingCart?.checkOutDate !== undefined
    //         ? currentShoppingCart.checkOutDate
    //         : event.checkOutDate
    // let startDayDistance: number = getTimeZone(event.checkInDate, event.timezone).diff(
    //     getTimeZone(checkInDateSelect, event.timezone),
    //     'day'
    // )
    // let endDateDistance: number = getTimeZone(checkOutDateSelect, event.timezone).diff(
    //     getTimeZone(event.checkOutDate, event.timezone),
    //     'day'
    // )
    // let isShoulderNights: boolean = startDayDistance !== 0 || endDateDistance !== 0
    const textContent =
        'Please select your check-in and check-out dates. ' +
        'Extend your stay by booking extra nights!'
    const [hasShoulderNights, setHasShoulderNights] = useState<boolean>(false)
    const defaultValueRange: DateRange<Dayjs> = [
        getLocalTimeZone(event.checkInDate, event.timezone),
        getLocalTimeZone(event.checkOutDate, event.timezone)
    ]
    const [minSelectDate, setMinSelectDate] = useState<any>(null)
    const [maxSelectDate, setMaxSelectDate] = useState<any>(null)
    const [shoulderInventoryErrorArray, setsShoulderInventoryErrorArray] = useState<{
        errorDate: string[]
        hotelName: string
        onCloseClick: () => void
        show: boolean
    }>()
    const currentCheckInCheckOutDates: DateRange<Dayjs> = [
        getLocalTimeZone(currentShoppingCart?.checkInDate, event.timezone),
        getLocalTimeZone(currentShoppingCart?.checkOutDate, event.timezone)
    ]
    const [selectedDates, setSelectedDates] = useState<DateRange<Dayjs> | [null, null]>(
        currentCheckInCheckOutDates
    )
    const addToCartValue: DateRange<Dayjs> | [null, null] = [selectedDates[0], selectedDates[1]]
    const addToCartDefaultValueRange: DateRange<Dayjs> | [null, null] = [
        defaultValueRange[0],
        defaultValueRange[1]
    ]
    const cartFirstCheckInDate =
        currentShoppingCart.firstCheckInDate != undefined
            ? currentShoppingCart.firstCheckInDate
            : ''
    const cartFirstCheckOutDate =
        currentShoppingCart.firstCheckOutDate != undefined
            ? currentShoppingCart.firstCheckOutDate
            : ''
    const cartFirstCheckDateOfLocationTimeRange: DateRange<Dayjs> | [null, null] = [
        getLocalTimeZone(event.checkInDate, event.timezone),
        getLocalTimeZone(event.checkOutDate, event.timezone)
    ]
    const cartFirstCheckDateRange: DateRange<Dayjs> | [null, null] = [
        getLocalTimeZone(event.checkInDate, event.timezone),
        getLocalTimeZone(event.checkOutDate, event.timezone)
    ]

    const getAndSetShoulderNights = useCallback(
        (inventory: Record<string, number>): void => {
            const hotel = event.hotels.find((h) => h.id === selectedHotelId)
            const hotelShoulderNights = getShoulderNightsByHotelId(
                event,
                hotel,
                inventory,
                '',
                groupSize
            ) as DateRange<Dayjs>
            const hotelHasShoulderNights =
                hotelShoulderNights &&
                hotelShoulderNights.length > 0 &&
                hotelShoulderNights.every((night) => night !== null)
            setHasShoulderNights(hotelHasShoulderNights)
        },
        [event, groupSize, selectedHotelId]
    )

    const getCartFirstCheckDateRange = () => {
        let checkDateRange: DateRange<Dayjs> = [null, null]
        if (hasAddToCart) {
            checkDateRange = cartFirstCheckDateRange
        }
        return checkDateRange
    }

    const resetDatesToInitialEventDates = () => {
        updateEventProp({
            ...currentShoppingCart,
            checkInDate: event.checkInDate,
            checkOutDate: event.checkOutDate,
            comparedEventsID: event.id,
            firstSelectedCheckInDate: [event.checkInDate, event.checkOutDate]
        })
        setSelectedDates([defaultValueRange[0], defaultValueRange[1]])
    }

    const updateEventProp = useCallback(
        (eventProp) => {
            window.localStorage.setItem(
                'addToCartInfo',
                JSON.stringify({
                    ...currentShoppingCart,
                    ...eventProp
                })
            )
        },
        [currentShoppingCart]
    )

    const updateDates = useCallback(
        (newDates: DateRange<Dayjs>) => {
            const newCheckIn = dayjs
                .tz(newDates[0]?.format('YYYY-MM-DD'), event.timezone)
                .toISOString()
            const newCheckOut = dayjs
                .tz(newDates[1]?.format('YYYY-MM-DD'), event.timezone)
                .toISOString()
            updateEventProp({
                checkInDate: newCheckIn,
                checkOutDate: newCheckOut,
                comparedEventsID: event.id,
                hasSelectedHotelID: selectedHotelId
            })
            setSelectedDates([newDates[0], newDates[1]])
        },
        [currentShoppingCart]
    )

    useEffect(() => {
        if (Object.entries(inventory).length > 0 && groupSize > 0 && selectedHotelId) {
            getAndSetShoulderNights(inventory)
        }
    }, [getAndSetShoulderNights, groupSize, inventory, selectedHotelId])

    // TODO remember time
    useEffect(() => {
        if (event.tickets.length === 0) {
            updateEventProp({
                checkInDate: event.checkInDate,
                checkOutDate: event.checkOutDate,
                firstCheckInDate: event.checkInDate,
                firstCheckOutDate: event.checkOutDate
            })
            setSelectedDates([defaultValueRange[0], defaultValueRange[1]])
        }
    }, [])
    // useEffect(() => {
    //     if (Object.entries(inventory).length > 0 && isShoulderNights && isCurrentEvent) {
    //         const hasSelectedHotelID = currentShoppingCart.hotelID
    //         const hasSelectGroupSize = currentShoppingCart.groupSize
    //         const { shoulderInventoryNotEnough, inventoryErrorDate } =
    //             getShoulderInventoryErrorDate(
    //                 hasSelectedHotelID,
    //                 event,
    //                 '',
    //                 hasSelectGroupSize,
    //                 inventory,
    //                 startDayDistance,
    //                 endDateDistance
    //             )
    //         if (shoulderInventoryNotEnough) {
    //             const hotelName =
    //                 event.hotels.find((hotel) => hotel.id === hasSelectedHotelID)?.name ?? ''
    //             checkInDateSelect = event.checkInDate
    //             checkOutDateSelect = event.checkOutDate
    //             startDayDistance = 0
    //             endDateDistance = 0
    //             isShoulderNights = false
    //             resetDatesToInitialEventDates()
    //             setsShoulderInventoryErrorArray({
    //                 hotelName: hotelName,
    //                 errorDate: inventoryErrorDate,
    //                 show: true,
    //                 onCloseClick: () => {
    //                     setsShoulderInventoryErrorArray(undefined)
    //                 }
    //             })
    //         }
    //     }
    // }, [inventory])

    return {
        addToCartDefaultValueRange,
        addToCartValue,
        cartFirstCheckDateOfLocationTimeRange,
        defaultValueRange,
        getCartFirstCheckDateRange,
        hasShoulderNights,
        maxSelectDate,
        minSelectDate,
        resetDatesToInitialEventDates,
        selectedDates,
        setMaxSelectDate,
        setMinSelectDate,
        shoulderInventoryErrorArray,
        updateDates,
        updateEventProp,
        textContent
    }
}
