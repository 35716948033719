import { faSpinner } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal } from 'react-bootstrap'

export type PreSaleModalProp = {
    code: string
    codeError: string
    handleValidCode: () => void
    hidePreSalePopUp: () => void
    onChangeCode: (e: any) => void
    showPreSalePopUp: boolean
    submitting: boolean
}

export default function PreSaleModal({
    code,
    codeError,
    handleValidCode,
    hidePreSalePopUp,
    onChangeCode,
    showPreSalePopUp,
    submitting
}: PreSaleModalProp) {
    return (
        <Modal
            aria-label="Presale Modal"
            className="modal reseat-modal"
            show={showPreSalePopUp}
            contentClassName="border-0 rounded-normal"
            dialogClassName="mx-sm-auto"
        >
            <Modal.Header className="modal-header ms-3 mt-3 pb-0 border-0 mb-0">
                <Modal.Title
                    as={() => {
                        return (
                            <h2 className="modal-title sub-headline">
                                Presale Access
                                <span
                                    className="btn-close end-0 me-3 p-2 position-absolute"
                                    onClick={hidePreSalePopUp}
                                />
                            </h2>
                        )
                    }}
                ></Modal.Title>
            </Modal.Header>

            <Modal.Body className="modal-body text-center mx-auto pt-2">
                <div className="p-3 pt-0">
                    <p className="paragrah text-start">
                        Get early access to event packages by entering your secret presale code.
                    </p>
                    <form
                        className="LoginForm text-start"
                        noValidate={false}
                        autoComplete="off"
                        autoCapitalize="off"
                        id=""
                    >
                        <div className="mb-3 form-static">
                            <input
                                aria-label="Presale Code Input: Please enter your presale code"
                                className={`form-control  ${codeError !== '' && 'is-invalid'}`}
                                id="presaleCode"
                                onChange={onChangeCode}
                                placeholder="Enter Code"
                                type="text"
                                value={code}  
                            />
                            <div
                                className="fs-14 mt-0"
                                style={{ color: '#F04438' }}
                            >
                                {codeError}
                            </div>
                        </div>
                        <button
                            aria-label='Unlock Button'
                            className="btn btn-block btn-purple modal-save-btn"
                            onClick={async (e) => {
                                if (submitting) {
                                    return
                                }
                                handleValidCode()
                                e.preventDefault()
                            }}
                        >
                            {submitting ? (
                                <FontAwesomeIcon
                                    icon={faSpinner}
                                    className="fa-spin"
                                />
                            ) : (
                                'Unlock'
                            )}
                        </button>
                    </form>
                </div>
            </Modal.Body>
        </Modal>
    )
}
