// Thunk function
import { Dispatch } from 'redux'
import {
    OrderState,
    ShoppingCartState,
    initialCartState,
    CartParams,
    CheckoutParams
} from '../index'

const post = async <T>(
    ordersUrl: string,
    url: string,
    payload: Record<string, any> | Record<string, any>[],
    signal?: AbortSignal
): Promise<T> => {
    url = `${ordersUrl.endsWith('/') ? ordersUrl.substr(0, ordersUrl.length - 1) : ordersUrl}/${
        url.startsWith('/') ? url.substr(1) : url
    }`
    //console.log('post url:', url)
    const options = {
        method: 'POST',
        mode: 'cors' as RequestMode,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
        },
        body: JSON.stringify(payload),
        signal
    }

    const response = await fetch(url, options)
    return await response.json()
}

export const get = async <T>(ordersUrl: string, url: string): Promise<T> => {
    url = `${
        ordersUrl?.endsWith('/') ? ordersUrl?.substring(0, ordersUrl.length - 1) : ordersUrl
    }/${url.startsWith('/') ? url.substring(1) : url}`
    const options = {
        method: 'GET',
        mode: 'cors' as RequestMode,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
        }
    }
    try {
        const response = await fetch(url, options)
        return await response.json()
    } catch (error) {
        console.error('Failed to fetch cart:', error)
        return {} as T
    }
}

export const fetchCart = (ordersUrl: string, id: string, shouldSetCart: boolean = true) => {
    return async function (dispatch: Dispatch) {
        let response = initialCartState
        if (id !== undefined && id) {
            response = await get<ShoppingCartState>(ordersUrl, `/v2/carts/${id}`)
        }
        // check if cart/response no items, which it will when initially created as to not hold inventory prematurely
        if ((response.items && response.items.length == 0) || !response.items) {
            response.expires = ''
        }
        if (shouldSetCart) {
            dispatch({ type: 'shoppingCart/setCart', payload: response })
        }
        return JSON.parse(JSON.stringify(response)) as ShoppingCartState
    }
}

export function upsertCart(ordersUrl: string, cart: CartParams) {
    return async function (dispatch: Dispatch) {
        try {
            const response = await post<ShoppingCartState>(ordersUrl, `/v2/carts`, cart)
            if (!(response as any).message) {
                dispatch({ type: 'shoppingCart/setCart', payload: response })
            }
            return response
        } catch (e: any) {
            throw new Error(e)
        }
    }
}

export const CheckAnyMembersPurchasing = async (ordersUrl: string, memberIds: string[]) => {
    let query = ''
    memberIds.forEach((id, index) => {
        if (index === 0) {
            query += 'memberID=' + id
        } else {
            query += ',' + id
        }
    })
    let isPurchasing = false
    try {
        const response = await get<{ message: string }>(
            ordersUrl,
            `/v1/carts/check-any-members-purchasing?${query}`
        )
        if (response.message === 'some members are currently purchasing') {
            isPurchasing = true
        }
    } catch (error: any) {
        console.error('Check any members purchasing error', error)
        return isPurchasing
    }
    return isPurchasing
}

export async function checkCode(ordersUrl: string, eventID: string, code: string) {
    const url = ordersUrl + `/presale/${eventID}/${code}`
    const options = {
        method: 'GET',
        mode: 'cors' as RequestMode,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
        }
    }
    //console.log('fetch-get-url', url)
    try {
        const response = await fetch(url, options)
        return await response.json()
    } catch (e: any) {}
}

export const getTravelGuardQuote = async (
    cartOrOrder: 'cart' | 'order',
    id: string,
    ordersUrl: string,
    params: any,
    signal: AbortSignal
) => {
    const query = cartOrOrder === 'cart' ? 'cartID=' : 'orderID='
    const url = `/insurance/travelguard/quote-request?` + query + id
    try {
        const response = await post(ordersUrl, url, params, signal)
        return response
    } catch (error: any) {
        throw new Error(error)
    }
}

/*
  CartID          uuid.UUID  `json:"cartID"`
  UserID          *uuid.UUID `json:"userID"`
  Email           string     `json:"email"`
  PaymentMethodID string     `json:"paymentMethodID"`
  Layaway         bool       `json:"layaway"`
 */
export interface Address {
    firstName: string
    lastName: string
    address: string
    address2: string
    city: string
    state: string
    zip: string
    country: string
    phone: string
    // should be boolean, but hacking around this because of the validateInput and validateForm that we run on this type of obj
    phoneAuthorization: string
}

export const checkout = (ordersUrl: string, params: CheckoutParams) => {
    // @ts-ignore
    if (params.shippingInfo.phoneAuthorization == 'true') {
        // @ts-ignore
        params.shippingInfo.phoneAuthorization = true
        // @ts-ignore
        params.billingInfo.phoneAuthorization = true
    } else {
        // @ts-ignore
        params.shippingInfo.phoneAuthorization = false
        // @ts-ignore
        params.billingInfo.phoneAuthorization = false
    }
    return async function (
        dispatch: Dispatch
    ): Promise<OrderState | { stripe_client_secret: string; details: string }> {
        const response = await post<OrderState>(ordersUrl, '/v2/checkout', params)
        if ((response as any).message) {
            throw response
        }
        if ((response as any).error === 'discount error') {
            return response
        }
        dispatch({ type: 'order/load/success', payload: response })
        return response
    }
}

export async function checkPaymentStatus(paymentsUrl: string, order: { id: string }) {
    const options = {
        method: 'GET',
        mode: 'cors' as RequestMode,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json; charset=UTF-8'
        }
    }
    const response = await fetch(`${paymentsUrl}/payment-plan/${order.id}`, options)
    const paymentPlan = await response.json()
    return [paymentPlan.status, (paymentPlan.stripeCharges || []).length]
}

export async function confirmPayment(paymentsUrl: string, order: { id: string }) {
    const options: RequestInit = {
        method: 'POST',
        mode: 'cors',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
        }
    }
    const response = await fetch(`${paymentsUrl}/payment-plan/${order.id}/confirm`, options)
    const paymentPlan = await response.json()
    return paymentPlan.status
}

export async function getPaymentPlan(
    paymentsUrl: string,
    params: { eventID: string; deposit: number; total: number }
): Promise<{ id: string; date: Date; charged: number; isFirstPayment: boolean }[]> {
    const options = {
        method: 'GET',
        mode: 'cors' as RequestMode,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
        }
    }
    try {
        const response = await fetch(
            `${paymentsUrl}/payment-plan?eventID=${params.eventID}&total=${params.total}&deposit=${params.deposit}`,
            options
        )
        const paymentPlan = await response.json()
        const values = paymentPlan.desiredStatuses.map(
            (ds: { id: string; date: string; charged: number; isFirstPayment: boolean }) => {
                return {
                    id: ds.id,
                    date: new Date(ds.date),
                    charged: ds.charged,
                    isFirstPayment: ds.isFirstPayment
                }
            }
        ) as {
            id: string
            date: Date
            charged: number
            isFirstPayment: boolean
        }[]
        values.sort((a, b) => {
            return a.date.valueOf() - b.date.valueOf()
        })
        let total = 0
        values.forEach((value) => {
            value.charged -= total
            total += value.charged
        })
        return values
    } catch (error) {
        console.log('error:', error)
        window.Rollbar.error(error)
    }
    return []
}

export async function getDeposit(
    ordersUrl: string,
    params: { eventID: string; memberTotal: number; groupSize: number }
): Promise<{ deposit: number }> {
    const options = {
        method: 'GET',
        mode: 'cors' as RequestMode,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
        }
    }
    try {
        const response = await fetch(
            `${ordersUrl}/deposit/${params.eventID}?memberTotal=${params.memberTotal}&groupSize=${params.groupSize}`,
            options
        )
        const deposit: { deposit: number } = await response.json()
        return deposit
    } catch (error) {
        console.log('error:', error)
        window.Rollbar.error(error)
    }
    return { deposit: 0 }
}

export async function getOrderPaymentPlan(paymentsUrl: string, orderID: string) {
    const options = {
        method: 'GET',
        mode: 'cors' as RequestMode,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
        }
    }
    const response = await fetch(`${paymentsUrl}/payment-plan/${orderID}`, options)
    const paymentPlan = await response.json()
    return paymentPlan
}

export async function getMemberPaymentPlan(
    paymentsUrl: string,
    params: { orderID: string; memberID: string }
): Promise<
    | {
          id: string
          date: Date
          charged: number
          isFirstPayment: boolean
          transactionStatus: string
      }[]
    | boolean
    | undefined
> {
    const options = {
        method: 'GET',
        mode: 'cors' as RequestMode,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
        }
    }
    try {
        const response = await fetch(
            `${paymentsUrl}/payment-plan/${params.orderID}/${params.memberID}`,
            options
        )
        const paymentPlan = await response.json()
        if (paymentPlan.message === 'could not query payment plan: no rows in result set') {
            return false
        } else {
            let values = paymentPlan.desiredStatuses.map(
                (ds: {
                    id: string
                    date: Date
                    charged: number
                    isFirstPayment: boolean
                    shouldChargeNow: boolean
                    transactionStatus: string
                    position: number
                    paidOn: Date
                }) => {
                    return {
                        id: ds.id,
                        date: ds.date,
                        charged: ds.charged,
                        isFirstPayment: ds.isFirstPayment,
                        shouldChargeNow: ds.shouldChargeNow,
                        transactionStatus: ds.transactionStatus,
                        position: ds.position,
                        paidOn: ds.paidOn
                    }
                }
            ) as {
                id: string
                date: Date
                charged: number
                isFirstPayment: boolean
                shouldChargeNow: boolean
                transactionStatus: string
                position: number
                paidOn: Date
            }[]

            values.sort((a, b) => {
                if (a.paidOn && b.paidOn) {
                    if (new Date(a.paidOn).valueOf() === new Date(b.paidOn).valueOf()) {
                        if (new Date(a.date).valueOf() === new Date(b.date).valueOf()) {
                            return a.position - b.position
                        }
                        return new Date(a.date).valueOf() - new Date(b.date).valueOf()
                    }
                    return new Date(a.paidOn).valueOf() - new Date(b.paidOn).valueOf()
                }

                const d1 = new Date(a.paidOn || a.date).toISOString().slice(0, 10)
                const d2 = new Date(b.paidOn || b.date).toISOString().slice(0, 10)
                if (d1 === d2) {
                    if (a.date === b.date) {
                        return a.position - b.position
                    }
                    if (a.paidOn && !b.paidOn) {
                        return -1
                    }
                    if (!a.paidOn && b.paidOn) {
                        return 1
                    }
                }

                if (new Date(a.date).valueOf() === new Date(b.date).valueOf()) {
                    return a.position - b.position
                }
                return new Date(a.date).valueOf() - new Date(b.date).valueOf()
            })

            // Deep copy
            let chargePlans = values.map((v) => {
                return { ...v }
            })
            chargePlans.forEach((chargePlan, chargePlanIndex) => {
                if (chargePlan.transactionStatus == 'canceled') {
                    let perv = chargePlanIndex - 1
                    for (let i = perv; i > 0; i--) {
                        if (values[perv].transactionStatus === 'error') {
                            perv--
                        }
                    }
                    const prevCharged = perv >= 0 ? values[perv].charged : 0
                    chargePlan.charged -= prevCharged
                } else {
                    let mostRecentNonCanceled:
                        | {
                              id: string
                              date: Date
                              charged: number
                          }
                        | undefined
                    values.forEach((value, index) => {
                        if (
                            index < chargePlanIndex &&
                            value.transactionStatus !== 'canceled' &&
                            value.transactionStatus !== 'error'
                        ) {
                            mostRecentNonCanceled = value
                        }
                    })
                    chargePlan.charged -= mostRecentNonCanceled?.charged
                        ? mostRecentNonCanceled.charged
                        : 0
                }
            })
            return chargePlans
        }
    } catch (error) {
        console.log('error:', error)
        window.Rollbar.error(error)
    }
}

export async function getPaymentData(paymentsUrl: string, orderID: string, memberID: string) {
    let paymentProcessorCharges: {
        Amount: number
        AmountRefunded: number
        Created: Date
        Status: string
        Brand: string
        last4: string
        Type: string
    }[] = []

    const options = {
        method: 'GET',
        mode: 'cors' as RequestMode,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
        }
    }
    try {
        const response = await fetch(`${paymentsUrl}/payment-plan/${orderID}/${memberID}`, options)
        const paymentPlan = await response.json()
        if (paymentPlan.paymentProcessorCharges) {
            paymentProcessorCharges = paymentPlan.paymentProcessorCharges.map((i: any) => {
                const card = i.CardDetails
                return {
                    Amount: i.Amount,
                    Created: new Date(i.Created),
                    Status: i.Status,
                    Brand: card?.brand || '',
                    last4: card?.last4 || '',
                    Type: i.Type
                }
            })
            paymentProcessorCharges.sort((a, b) => {
                return a.Created.valueOf() - b.Created.valueOf()
            })
        }
    } catch (error) {
        console.log('error:', error)
        window.Rollbar.error(error)
    }
    return paymentProcessorCharges
}

export async function expiresResetsLeft(
    ordersUrl: string,
    cartID: string,
    declinedPayment?: boolean
) {
    let url = `/v2/cart/${cartID}/resetCartExpiration`
    if (declinedPayment) {
        url = `/v2/cart/${cartID}/resetCartExpiration?declinedPayment=true`
    }
    url = `${ordersUrl.endsWith('/') ? ordersUrl.substr(0, ordersUrl.length - 1) : ordersUrl}/${
        url.startsWith('/') ? url.substr(1) : url
    }`
    const options = {
        method: 'POST',
        mode: 'cors' as RequestMode
    }
    //console.log({url})
    const response = await fetch(url, options)
    const data = (await response.json()) as ShoppingCartState
    return data
}

export const checkDiscountCode = async (
    ordersUrl: string,
    eventID: string,
    cartID: string,
    code: string
) => {
    const url = ordersUrl + `/v2/discount/${eventID}/${cartID}/${code}`
    const options = {
        method: 'POST',
        mode: 'cors' as RequestMode,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
        }
    }
    try {
        const response = await fetch(url, options)
        return response.json()
    } catch (error) {
        console.error('Failed to fetch discount code:', error)
        return { message: 'This code does not exist' }
    }
}

export const removeDiscountCode = async (ordersUrl: string, cartID: string) => {
    const url = ordersUrl + `/discount/${cartID}/remove-discount`
    const options = {
        method: 'POST',
        mode: 'cors' as RequestMode,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
        }
    }
    try {
        const response = await fetch(url, options)
        if (response.ok === true) {
            return { message: 'Successfully removed discount code' }
        }
        return { message: 'Error requesting to remove discount code' }
    } catch (error) {
        console.error('Failed to remove discount code', error)
        return { message: 'Failed to remove discount code' }
    }
}

export const purchaseAddOns = (ordersUrl: string, params: CheckoutParams) => {
    // @ts-ignore
    if (params.shippingInfo.phoneAuthorization == 'true') {
        // @ts-ignore
        params.shippingInfo.phoneAuthorization = true
        // @ts-ignore
        params.billingInfo.phoneAuthorization = true
    } else {
        // @ts-ignore
        params.shippingInfo.phoneAuthorization = false
        // @ts-ignore
        params.billingInfo.phoneAuthorization = false
    }
    return async function (
        dispatch: Dispatch
    ): Promise<OrderState | { stripe_client_secret: string; details: string }> {
        const response = await post<OrderState>(ordersUrl, '/v1/purchase-addons', params)
        if ((response as any).message) {
            throw response
        }
        dispatch({ type: 'order/load/success', payload: response })
        return response
    }
}
