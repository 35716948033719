import { useAppDispatch, useAppSelector } from 'hooks'
import { useRouter } from 'next/router'
import { Dispatch, RefObject, SetStateAction, useEffect, useRef, useState } from 'react'
import { AddToCartInfo, NavbarState, ShoppingCartState } from 'store'
import { v4 as uuid } from 'uuid'
import { expiresResetsLeft, fetchCart } from 'store/actions/fetchCart'
import getConfig from 'next/config'
import { toaster } from 'evergreen-ui'
import { isEqual } from 'lodash'
import { now } from 'util/dates'
import dayjs from 'dayjs'
import { useLocalStorage } from './useLocalStorage'
import { INIT_UUID } from 'consts'

const { publicRuntimeConfig } = getConfig()

export type CartProps = {
    navState: NavbarState
    isLoggedIn: boolean
    isNavExpanded: boolean
}

type CartReturnType = {
    addToCartCount: number
    cartInfo?: AddToCartInfo
    countDownFinishedCallback: () => void
    handleLocalCartSubmitted: (handleSuccessCallback: () => void) => void
    isShowShoppingBag: boolean
    queryParams: string
    refuseResetExpirationTime: () => void
    resetExpirationTime: () => void
    saveCart: RefObject<ShoppingCartState>
    setShowExpiresModal: Dispatch<SetStateAction<boolean>>
    shoppingBagClickCallback: () => {}
    shoppingCart: ShoppingCartState
    shouldShowCountdown: boolean
    shouldShowOrderCancelledCountdown: boolean
    shouldShowShoppingBag: boolean
    shouldShowTimer: boolean
    shouldStop: boolean
    showExpiresModal: boolean
    showExpiresResetsModal: boolean
}

export const useCart = ({ navState, isLoggedIn, isNavExpanded }: CartProps): CartReturnType => {
    const { getLocalStorage } = useLocalStorage()
    const router = useRouter()
    const eventSlug = getLocalStorage('eventSlug')
    const dispatch = useAppDispatch()
    const shoppingCart: ShoppingCartState = useAppSelector((state) => state.shoppingCartReducer)
    const cartFinishedLock = useRef(false)
    const { isStop: shouldStop } = useAppSelector((state) => state.CountDownReducer)
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [shouldShowShoppingBag, setShouldShowShoppingBag] = useState<boolean>(false)
    const [showExpiresModal, setShowExpiresModal] = useState<boolean>(false)
    const [showExpiresResetsModal, setShowExpiresResetsModal] = useState<boolean>(false)
    const queryParams =
        typeof window !== 'undefined' && window.localStorage.getItem('password')
            ? `?password=${window.localStorage.getItem('password')}`
            : ''
    const localCartFinishTimer = useRef<NodeJS.Timeout>()
    const saveCart = useRef<ShoppingCartState | null>(null)
    const cartInfo: AddToCartInfo | undefined =
        typeof window !== 'undefined' &&
        window.localStorage.getItem('addToCartInfo') !== 'undefined'
            ? (JSON.parse(localStorage.getItem('addToCartInfo') || '{}') as AddToCartInfo)
            : undefined
    const shouldShowCountdown: boolean = cartInfo?.shouldShowCountdown ?? false
    const addToCartCount: number = cartInfo?.addToCartCount ?? 0
    const isAcceptPreviewOrSubmitPage: boolean =
        router.pathname === '/invite/accept' || router.pathname === '/invite/submit'
    const isCheckoutPreviewOrSubmitPage =
        router.pathname === '/events/[slug]/checkout/preview' ||
        router.pathname === '/events/[slug]/checkout/submit' ||
        router.pathname === '/orders/[orderID]/checkout/submit'
    const isOrderDetailsPage = router.pathname === '/orders/[orderID]'
    const isShowShoppingBag: boolean = cartInfo?.isShowShoppingBag ?? false
    const shouldShowOrderCancelledCountdown =
        (!isOrderDetailsPage && !isAcceptPreviewOrSubmitPage) ||
        (isLoggedIn && !isOrderDetailsPage && !isAcceptPreviewOrSubmitPage)

    const checkStorageChange = () => {
        let localCart: ShoppingCartState | undefined
        const cartData = window.localStorage.getItem('cart')
        try {
            localCart = cartData ? JSON.parse(cartData) : undefined
        } catch (error) {}
        const currentPageCart = saveCart.current
        if (!localCart || !currentPageCart) {
            return
        }

        const orderId = getLocalStorage('orderId')
        if (currentPageCart.id === orderId) {
            return {
                handleFunc: handleLocalCartSubmitted,
                handleType: 'cartSubmitted'
            }
        }

        const finishedCartID = window.localStorage.getItem('finishedCartID')
        if (
            finishedCartID &&
            finishedCartID === currentPageCart.id &&
            localCart.items.length === 0
        ) {
            return {
                handleFunc: handleCurrentCartFinished,
                handleType: 'cartFinished'
            }
        }

        if (localCart.id === currentPageCart.id && localCart.expires && currentPageCart.expires) {
            const localCountDownEndTime = new Date(localCart.expires).getTime()
            const currentCountDownEndTime = new Date(currentPageCart.expires).getTime()
            if (localCountDownEndTime > currentCountDownEndTime) {
                if (localCart.expiresResetsLeft < currentPageCart.expiresResetsLeft) {
                    return {
                        handleFunc: handleLocalCartResetsLeftChange,
                        handleType: 'cartResetsLeftChange'
                    }
                }
                if (
                    localCart.expiresResetsLeftForDeclinedPayment <
                    currentPageCart.expiresResetsLeftForDeclinedPayment
                ) {
                    return {
                        handleFunc: handleExpiresResetsLeftForDeclinedPaymentChange,
                        handleType: 'expiresResetsLeftForDeclinedPaymentChange'
                    }
                }
            }
            const itemsHasChange = !isEqual(currentPageCart.items || [], localCart.items || [])
            const groupSizeHasChange =
                (currentPageCart.groupSize || 0) !== (localCart.groupSize || 0)
            if (
                currentPageCart.items.length > 0 &&
                localCart.items.length > 0 &&
                (itemsHasChange || groupSizeHasChange)
            ) {
                return {
                    handleFunc: handleLocalCartItemsChange,
                    handleType: 'cartItemsChange'
                }
            }
        }
        if (localCart.id !== currentPageCart.id && localCart.expires && currentPageCart.expires) {
            return {
                handleFunc: handleLocalCartItemsChange,
                handleType: 'cartItemsChange'
            }
        }
    }

    const handleCartChange = () => {
        let handler = checkStorageChange()
        if (handler) {
            clearCartTimer()
            dispatch({ type: 'countDown/stop' }) // Wait for re-get cart.
            if (handler.handleType === 'cartFinished') {
                setShowExpiresModal(true)
            } else {
                setShowExpiresModal(false)
            }
            setShowExpiresResetsModal(false)
        }
        if (document.visibilityState === 'visible' && handler && !cartFinishedLock.current) {
            cartFinishedLock.current = true
            handler.handleFunc(() => {
                cartFinishedLock.current = false
            })
        }
    }

    // If the user change tab, they may change cart. So we will get the local cart, and then compare with current cart, if local cart has change, do something.
    const handleChangeTab = async () => handleCartChange()

    const handleCartSubmitting = () => {
        let cartSubmitting: boolean | undefined
        const submitting = window.localStorage.getItem('submitting')
        try {
            cartSubmitting = submitting ? JSON.parse(submitting) : false
        } catch (error) {}

        if (cartSubmitting) {
            dispatch({ type: 'countDown/stop' })
        } else {
            dispatch({ type: 'countDown/start' })
        }
    }

    const handleStorageChange = (e: any) => {
        if (e.key && e.key === 'submitting' && e.newValue) {
            handleCartSubmitting()
            return
        }
        // If the storage cart change, we will get the local cart, and then compare with current cart, if local cart has change do something.
        if (e.key && e.key === 'cart' && e.newValue) {
            handleCartChange()
        }
    }

    const handleLocalCartItemsChange = (handleSuccessCallback: () => void) => {
        toaster.danger('Your cart has been changed, refreshing……')
        const localCart: ShoppingCartState | undefined = getLocalStorage('cart')
        const localAddToCartInfo: AddToCartInfo | undefined = getLocalStorage('addToCartInfo')

        const shouldShowCountdown = localAddToCartInfo?.shouldShowCountdown || false
        const eventSlug = localAddToCartInfo?.eventSlug || ''
        setTimeout(() => {
            toaster.closeAll()

            const isAdditionalSubmitPage = router.pathname === '/orders/[orderID]/checkout/submit'
            const isPreviewPage = router.pathname === '/events/[slug]/checkout/preview'
            const isSubmitPage = router.pathname === '/events/[slug]/checkout/submit'
            let isAdditionalOrder = false
            if (localCart?.purchaseAddOnsMemberId) {
                isAdditionalOrder = localCart.purchaseAddOnsMemberId !== INIT_UUID
            }
            switch (true) {
                case !shouldShowCountdown:
                    router.push(`/events/${eventSlug}/choose-your-accommodation`).finally(() => {
                        handleSuccessCallback()
                    })
                    break
                case isAdditionalOrder && !isAdditionalSubmitPage:
                    const orderId = getLocalStorage('orderId')
                    router.push(`/orders/${orderId}/checkout/submit`).finally(() => {
                        handleSuccessCallback()
                    })
                    break
                case (isAdditionalOrder && isAdditionalSubmitPage) ||
                    (!isAdditionalOrder && (isPreviewPage || isSubmitPage)):
                    window.location.reload()
                    break
                default:
                    router
                        .push(`/events/${eventSlug}/checkout/preview${queryParams}`)
                        .finally(() => {
                            handleSuccessCallback()
                        })
                    break
            }
        }, 3000)
    }

    const handleLocalCartSubmitted = (handleSuccessCallback: () => void) => {
        setShouldShowShoppingBag(false)
        const eventSlug = getLocalStorage('eventSlug')
        let url = '/'
        if (eventSlug) {
            url = `/events/${eventSlug}${queryParams}`
        }
        toaster.danger('Your cart has been submitted, refreshing……')

        setTimeout(() => {
            let isEventDetailsPage = false
            const pathName = window.location.href
            const eventEndPaths: string | undefined = pathName.split('/events/')[1]
            if (eventEndPaths?.split('/').length === 1) {
                isEventDetailsPage = true
            }
            if (isEventDetailsPage) {
                window.location.reload()
            } else {
                toaster.closeAll()
                router.push(url).finally(() => {
                    handleSuccessCallback()
                })
            }
        }, 3000)
    }

    const handleCurrentCartFinished = (handleSuccessCallback?: () => void) => {
        setShowExpiresModal(true)
        setShowExpiresResetsModal(false)
        setShouldShowShoppingBag(false)
        dispatch({ type: 'countDown/expired' })
        let localCart: ShoppingCartState | undefined
        const cartData = window.localStorage.getItem('cart')
        try {
            localCart = cartData ? JSON.parse(cartData) : undefined
        } catch (error) {}

        const currentPageCart = saveCart.current
        if (!localCart || !currentPageCart) {
            return
        }
        if (currentPageCart.id === localCart.id) {
            const newCartID = uuid()
            dispatch({ type: 'shoppingCart/refreshCartID', payload: newCartID })
        }
        setTimeout(() => {
            handleSuccessCallback?.()
        }, 3000)
    }

    const handleLocalCartResetsLeftChange = (handleSuccessCallback: () => void) => {
        toaster.danger('Your cart has been changed, refreshing……')
        setTimeout(() => {
            const isPreviewPage =
                window.location.href.indexOf(`/events/${eventSlug}/checkout/preview`) !== -1
            if (isPreviewPage) {
                window.location.reload()
            } else {
                router.push(`/events/${eventSlug}/checkout/preview${queryParams}`).finally(() => {
                    toaster.closeAll()
                    handleSuccessCallback()
                })
            }
        }, 3000)
    }

    const handleExpiresResetsLeftForDeclinedPaymentChange = (handleSuccessCallback: () => void) => {
        toaster.danger('Your cart has been changed, refreshing……')
        setTimeout(() => {
            toaster.closeAll()
            const isPreviewPage =
                window.location.href.indexOf(`/events/${eventSlug}/checkout/preview`) !== -1
            const isSubmitPage =
                window.location.href.indexOf(`/events/${eventSlug}/checkout/submit`) !== -1
            if (isPreviewPage || isSubmitPage) {
                window.location.reload()
            } else {
                router.push(`/events/${eventSlug}/checkout/preview${queryParams}`).finally(() => {
                    handleSuccessCallback()
                })
            }
        }, 3000)
    }

    const shoppingBagClickCallback = async () => {
        if (isNavExpanded) {
            dispatch({ type: 'navbar/setState', payload: { expandedNav: false } })
        }
        let url = `/events/${eventSlug}/checkout/preview${queryParams}`
        if (shoppingCart.purchaseAddOnsMemberId !== INIT_UUID) {
            const orderId = getLocalStorage('orderId')
            if (!isLoggedIn) {
                router.push(`/?login_redirect=/orders/${orderId}`)
                dispatch({
                    type: 'navbar/setState',
                    payload: { signIn: true }
                })
                return
            }
            url = `/orders/${orderId}/checkout/submit`
        }
        await router.push(url)
    }

    const shouldShowTimer =
        (((shoppingCart?.expires || saveCart.current?.expires) &&
            !isAcceptPreviewOrSubmitPage) as boolean) ?? false

    const clearCartTimer = () => {
        localCartFinishTimer.current && clearTimeout(localCartFinishTimer.current)
    }

    const countDownFinishedCallback = () => {
        const cartData = localStorage.getItem('cart')
        let localCart: ShoppingCartState | undefined
        try {
            localCart = cartData ? JSON.parse(cartData) : undefined
        } catch (error) {}
        const currentPageCart = saveCart.current
        if (!localCart || !currentPageCart) {
            return
        }

        const finishedCartID = window.localStorage.getItem('finishedCartID')
        if (finishedCartID === currentPageCart.id && localCart.items.length === 0) {
            return
        }
        const localExpiresResetting = window.localStorage.getItem('expiresResetting')
        const expiresResetting = localExpiresResetting ? JSON.parse(localExpiresResetting) : false
        if (expiresResetting) {
            return
        }

        const countDownEndTime = new Date(localCart.expires || new Date()).getTime()
        const expirationTime = new Date(localCart.expires || new Date()).getTime() + 15000
        const currentTime = new Date().getTime()
        const t1 = localCart.expiresResetsLeft === 0 && currentTime >= countDownEndTime
        const t2 = localCart.expiresResetsLeft > 0 && currentTime >= expirationTime
        const localCartFinished = t1 || t2
        if (localCartFinished) {
            window.localStorage.setItem('finishedCartID', currentPageCart.id)
            handleCurrentCartFinished()
        } else {
            if (localCart.expiresResetsLeft > 0) {
                setShowExpiresResetsModal(true)
            }
            localCartFinishTimer.current = setTimeout(countDownFinishedCallback, 500)
        }
    }

    const refuseResetExpirationTime = () => {
        setShowExpiresResetsModal(false)
        clearCartTimer()
        window.localStorage.setItem('finishedCartID', saveCart.current!.id)
        handleCurrentCartFinished()
    }

    const resetExpirationTime = async () => {
        setShowExpiresResetsModal(false)
        const localExpiresResetting = window.localStorage.getItem('expiresResetting')
        const expiresResetting = localExpiresResetting ? JSON.parse(localExpiresResetting) : false
        if (expiresResetting) {
            console.log('reset...')
            return
        }
        clearCartTimer()
        window.localStorage.setItem('expiresResetting', 'true')
        let resetSuccess = false
        try {
            const newCart = await expiresResetsLeft(
                publicRuntimeConfig.NEXT_PUBLIC_ORDERS_URL!,
                shoppingCart.id
            )
            if ((newCart.items || []).length > 0) {
                dispatch({ type: 'shoppingCart/setCart', payload: newCart })
                saveCart.current = newCart
                resetSuccess = true
            }
        } catch (error) {}
        window.localStorage.setItem('expiresResetting', 'false')
        if (!resetSuccess) {
            window.localStorage.setItem('finishedCartID', shoppingCart.id)
            handleCurrentCartFinished()
        }
    }

    const refModelState = useRef(navState)
    useEffect(() => {
        refModelState.current = navState
    }, [navState])

    useEffect(() => {
        const shoppingCartID = localStorage.getItem('shoppingCartID')!
        const getData = async () => {
            try {
                const cartData = await fetchCart(
                    publicRuntimeConfig.NEXT_PUBLIC_ORDERS_URL!,
                    shoppingCartID
                )(dispatch)
                setIsLoading(false)
                saveCart.current = cartData
                dispatch({ type: 'countDown/start' })
                dispatch({ type: 'countDown/cancelExpired' })
            } catch (err) {
                console.error(err)
                setIsLoading(false)
            }
        }
        getData()
    }, [dispatch, router.asPath])

    useEffect(() => {
        const addHTMLClass = () => {
            let htmlElement = document.getElementsByTagName('html')[0]
            const isTouchable = Boolean('ontouchstart' in window)
            const ua = navigator.userAgent.toLowerCase()
            const isIpad = ua.match(/ipad/i) !== null
            const isAndroid = ua.match(/android/i) !== null
            const isIphone = ua.match(/iphone/i) !== null
            const isMac = ua.match(/macintosh|mac os x/i) !== null
            const isWindows = ua.match(/windows|win32/i) !== null
            const isLinux = ua.match(/linux/i) !== null

            if (isIphone) {
                htmlElement.className += ' iphone'
            } else if (isWindows) {
                htmlElement.className += ' win'
            } else if (isAndroid) {
                htmlElement.className += ' android'
            } else if (isIpad) {
                htmlElement.className += ' ipad'
            } else if (isMac) {
                htmlElement.className += ' mac'
            } else if (isLinux) {
                htmlElement.className += ' linux'
            }

            if (isTouchable) {
                htmlElement.className += ' touch'
            } else {
                htmlElement.className += ' no-touch'
            }
        }
        addHTMLClass()
    }, [])

    useEffect(() => {
        if (isLoading) return
        if (!shoppingCart.expires) {
            localStorage.setItem(
                'addToCartInfo',
                JSON.stringify({
                    ...cartInfo,
                    addToCartCount: 0,
                    isShowShoppingBag: false,
                    previousSelectedTicketNumber: '',
                    previousTicketItems: [],
                    shouldShowCountdown: false
                })
            )
        }
        if ((shoppingCart.items || []).length > 0 && shoppingCart.expires && shouldShowCountdown) {
            const secondsLeft = dayjs(shoppingCart.expires).valueOf() - now().valueOf()
            if (addToCartCount > 1 || router.asPath === '/') {
                const shouldShowShoppingBag = secondsLeft > 0 && !isAcceptPreviewOrSubmitPage
                setShouldShowShoppingBag(shouldShowShoppingBag)
            }
        } else {
            setShouldShowShoppingBag(false)
        }
        if (isCheckoutPreviewOrSubmitPage) {
            setShouldShowShoppingBag(false)
        }

        return () => {
            clearCartTimer()
        }
    }, [shoppingCart, isLoading, shouldShowCountdown, shouldShowShoppingBag])

    useEffect(() => {
        if (
            (shoppingCart.items || []).length > 0 &&
            shoppingCart.expires &&
            router.pathname.indexOf('/events/[slug]/add-ons') === -1 &&
            shouldShowCountdown
        ) {
            localStorage.setItem(
                'addToCartInfo',
                JSON.stringify({ ...cartInfo, addToCartCount: addToCartCount + 1 })
            )
        }
    }, [shoppingCart])

    useEffect(() => {
        window.addEventListener('storage', handleStorageChange)
        document.addEventListener('visibilitychange', handleChangeTab)

        return () => {
            window.removeEventListener('storage', handleStorageChange)
            document.removeEventListener('visibilitychange', handleChangeTab)
        }
    }, [])

    useEffect(() => {
        if (saveCart.current) {
            saveCart.current.expiresResetsLeftForDeclinedPayment =
                shoppingCart.expiresResetsLeftForDeclinedPayment
        }
    }, [shoppingCart.expiresResetsLeftForDeclinedPayment])

    return {
        addToCartCount,
        cartInfo,
        countDownFinishedCallback,
        handleLocalCartSubmitted,
        isShowShoppingBag,
        queryParams,
        refuseResetExpirationTime,
        resetExpirationTime,
        saveCart,
        setShowExpiresModal,
        shoppingBagClickCallback,
        shoppingCart,
        shouldShowCountdown,
        shouldShowOrderCancelledCountdown,
        shouldShowShoppingBag,
        shouldShowTimer,
        shouldStop,
        showExpiresModal,
        showExpiresResetsModal
    }
}
