import { COLOR_WHITE, theme } from '../../styles/theme'
import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()({
    button: {
        verticalAlign: 'center'
    },
    disabledStyle: {
        '&.Mui-disabled': {
            backgroundColor: theme.palette.primary.light,
            color: COLOR_WHITE
        }
    }
})
