import { gql } from '@apollo/client'
export const GET_TOPICAL_QUERY = gql`
    query GetEventQuery {
        getSettings {
            relatedTos {
                id
                name
                position
            }
        }
    }
`
