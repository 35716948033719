import { useEffect, useRef, useState } from 'react'
import { SIZE_80 } from 'styles/theme'

type useTripSummaryReturnType = {
    handleMobileTripSummaryShow: () => void
    handlePcTripSummaryShow: () => void
    showMobileTripSummary: boolean
    showPcTripSummary: boolean
    tripSummaryStyle: string
}

export const useTripSummary = (): useTripSummaryReturnType => {
    const [showMobileTripSummary, setShowMobileTripSummary] = useState<boolean>(true)
    const [showPcTripSummary, setShowPcTripSummary] = useState<boolean>(true)
    const [tripSummaryStyle, setTripSummaryStyle] = useState<string>('')
    const prevScrollY = useRef(0)

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true })
        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    const handleScroll = () => {
        let tripSummaryStyle = '160px'
        const position = window.scrollY
        if (position > 0 && position > prevScrollY.current) {
            tripSummaryStyle = SIZE_80
        }
        setTripSummaryStyle(tripSummaryStyle)
        prevScrollY.current = position
    }

    const handleMobileTripSummaryShow = () => {
        setShowMobileTripSummary(!showMobileTripSummary)
    }

    const handlePcTripSummaryShow = () => {
        setShowPcTripSummary(!showPcTripSummary)
    }

    return {
        handleMobileTripSummaryShow,
        handlePcTripSummaryShow,
        showMobileTripSummary,
        showPcTripSummary,
        tripSummaryStyle
    }
}
