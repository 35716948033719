import { usersClient } from '../apollo-client'
import { gql } from '@apollo/client'
import { parseJwt } from './parse'

export const refresh = async (): Promise<{ jwt: string }> => {
    // get the authentication token from local storage if it exists
    let authStr = '{}'
    if (typeof localStorage !== 'undefined') {
        authStr = localStorage.getItem('auth') || authStr
    }
    const auth = JSON.parse(authStr || '{}')
    const t = auth.refresh
    const {
        data: {
            refresh: { token, refresh }
        }
    } = await usersClient.mutate({
        mutation: gql`
            query refresh($token: String!) {
                refresh(token: $token) {
                    token
                    refresh
                }
            }
        `,
        variables: { token: t }
    })!
    auth.jwt = token
    auth.refresh = refresh

    const jwt = token
    const user = parseJwt(jwt)
    localStorage.setItem('auth', JSON.stringify({ isLoggedIn: true, jwt, refresh, user }))
    return auth
}
