import { Accordion } from 'react-bootstrap'
import { sendMultiStateCustomEventToMparticle } from '../util/handlemParticle'
import { useStyles } from './eventFAQ.styles'
import { FaqsCategoriesType, FaqsType } from 'types/event'
import { isMobileOnly } from 'react-device-detect'

export type EventFAQProps = {
    faqs: FaqsType[]
    faqsCategories: FaqsCategoriesType[]
    hasFaqsCategories: boolean
}

export const EventFAQ = ({ faqs, faqsCategories, hasFaqsCategories }: EventFAQProps) => {
    const { classes } = useStyles()
    return (
        <div className="container container-fluid mb-5 pt-0 pt-md-3 faq-container">
            <div className="row">
                <div className="col-12 gx-4-2">
                    <h2
                        className="text-start text-md-center pe-3 pe-md-0 event-section-headline"
                        style={{
                            marginBottom: hasFaqsCategories && !isMobileOnly ? '54px' : ''
                        }}
                    >
                        FAQs
                    </h2>
                    <div
                        className="accordion accordion-flush"
                        id="accordionExample"
                    >
                        {!hasFaqsCategories && (
                            <Accordion>
                                {faqs.map(
                                    (faq: { question: string; answer: string }, index: number) => {
                                        return (
                                            <Accordion.Item
                                                onClick={(_) => {
                                                    sendMultiStateCustomEventToMparticle(
                                                        {
                                                            faq_question: faq.question,
                                                            faq_answer: faq.answer,
                                                            page: window.location.href.split('?')[0]
                                                        },
                                                        'Toggled an FAQ on Event page'
                                                    )
                                                }}
                                                key={'faq-' + index.toString()}
                                                eventKey={'faq-' + index.toString()}
                                            >
                                                <Accordion.Header>{faq.question}</Accordion.Header>
                                                <Accordion.Body
                                                    dangerouslySetInnerHTML={{ __html: faq.answer }}
                                                ></Accordion.Body>
                                            </Accordion.Item>
                                        )
                                    }
                                )}
                            </Accordion>
                        )}
                        {hasFaqsCategories && (
                            <div className="category-">
                                {faqsCategories.map((category, index) => {
                                    return (
                                        <div
                                            className={
                                                index !== faqsCategories.length - 1
                                                    ? classes.categoryBox
                                                    : ''
                                            }
                                            key={category.id}
                                        >
                                            <div
                                                className={classes.categoryTitle}
                                                key={category.id + category.categoryName}
                                            >
                                                {category.categoryName}
                                            </div>
                                            <Accordion>
                                                {faqs.map(
                                                    (
                                                        faq: {
                                                            question: string
                                                            answer: string
                                                            categoryID: string
                                                        },
                                                        index: number
                                                    ) => {
                                                        if (faq.categoryID === category.id) {
                                                            return (
                                                                <Accordion.Item
                                                                    onClick={(_) => {
                                                                        sendMultiStateCustomEventToMparticle(
                                                                            {
                                                                                faq_question:
                                                                                    faq.question,
                                                                                faq_answer:
                                                                                    faq.answer,
                                                                                page: window.location.href.split(
                                                                                    '?'
                                                                                )[0]
                                                                            },
                                                                            'Toggled an FAQ on Event page'
                                                                        )
                                                                    }}
                                                                    key={'faq-' + index.toString()}
                                                                    eventKey={
                                                                        'faq-' + index.toString()
                                                                    }
                                                                >
                                                                    <Accordion.Header>
                                                                        {faq.question}
                                                                    </Accordion.Header>
                                                                    <Accordion.Body
                                                                        dangerouslySetInnerHTML={{
                                                                            __html: faq.answer
                                                                        }}
                                                                    ></Accordion.Body>
                                                                </Accordion.Item>
                                                            )
                                                        }
                                                    }
                                                )}
                                            </Accordion>
                                        </div>
                                    )
                                })}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}
