import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import Facebook from '@mui/icons-material/Facebook'
import Instagram from '@mui/icons-material/Instagram'
import Twitter from '@mui/icons-material/Twitter'
import mParticle from '@mparticle/web-sdk'
import { Newsletter } from 'components/Footer/Newsletter'
import { sendMultiStateCustomEventToMparticle } from 'util/handlemParticle'
import { useStyles } from './footer.styles'
import {
    COLOR_GRAY_50,
    COLOR_GRAY_200,
    COLOR_GRAY_400,
    COLOR_WHITE,
    FONT_SIZE_14,
    FONT_SIZE_24,
    SIZE_24,
    SIZE_8,
    theme
} from 'styles/theme'
import { LogoColorType, LogoComponent } from 'components/Logo'
import { TikTokIcon } from 'components/Icons'
import clsx from 'clsx'

const socialIcons = [
    {
        name: 'twitter',
        url: 'https://twitter.com/vibeepresents',
        icon: (
            <Twitter
                sx={{
                    fontSize: FONT_SIZE_24
                }}
            />
        )
    },
    {
        name: 'instagram',
        url: 'https://www.instagram.com/vibeepresents/',
        icon: (
            <Instagram
                sx={{
                    fontSize: FONT_SIZE_24
                }}
            />
        )
    },
    {
        name: 'facebook',
        url: 'https://www.facebook.com/VibeePresents/',
        icon: (
            <Facebook
                sx={{
                    fontSize: FONT_SIZE_24
                }}
            />
        )
    },
    {
        name: 'tiktok',
        url: 'https://www.tiktok.com/@vibeepresents',
        icon: (
            <Box
                sx={{
                    display: 'flex',
                    height: SIZE_24,
                    justifyContent: 'center',
                    padding: '2px',
                    width: SIZE_24
                }}
            >
                <TikTokIcon fill={COLOR_GRAY_200} />
            </Box>
        )
    }
]

export type FooterComponentProps = {
    isF1: boolean
    isMobileWidth: boolean | null
    isNotHomePage: boolean
    routerPathName: any
}

export const FooterComponent = ({
    isF1,
    isMobileWidth,
    isNotHomePage,
    routerPathName
}: FooterComponentProps) => {
    const { classes } = useStyles()

    return (
        <div className={classes.footer}>
            <Container
                className={classes.footerWrapper}
                style={{
                    height: isMobileWidth ? 'unset' : '380px',
                    maxWidth: isNotHomePage ? '1330px' : '1330px'
                }}
            >
                <Box mb={!isMobileWidth ? 2 : undefined}>
                    <Grid
                        container
                        spacing={2}
                    >
                        <Grid
                            item
                            xs={12}
                        >
                            <Box
                                display="flex"
                                flexDirection="row"
                                justifyContent="space-between"
                            >
                                <Grid
                                    container
                                    spacing={2}
                                >
                                    <Grid
                                        item
                                        md={8}
                                        sm={isMobileWidth ? 12 : 6}
                                        xs={12}
                                    >
                                        <Box
                                            display="flex"
                                            flexDirection="column"
                                        >
                                            <div
                                                className={clsx({
                                                    [classes.mobileFooterLogo]: isMobileWidth
                                                })}
                                                style={{
                                                    marginBottom: !isMobileWidth
                                                        ? '62px'
                                                        : undefined
                                                }}
                                            >
                                                <Link
                                                    className={classes.footerLogo}
                                                    href="/"
                                                    aria-label="Link to home page"
                                                >
                                                    <LogoComponent
                                                        color={LogoColorType.WHITE}
                                                        height="44px"
                                                        width={'94px'}
                                                    />
                                                </Link>
                                            </div>
                                            {isMobileWidth ? (
                                                <Grid
                                                    item
                                                    md={4}
                                                    xs={12}
                                                >
                                                    <Box
                                                        sx={{
                                                            marginBottom: SIZE_24
                                                        }}
                                                    >
                                                        <Newsletter
                                                            isMobile={isMobileWidth}
                                                            routerPathName={routerPathName}
                                                            isF1={isF1}
                                                        />
                                                    </Box>
                                                </Grid>
                                            ) : null}
                                            {isMobileWidth && (
                                                <Box
                                                    sx={{
                                                        margin: 'auto',
                                                        marginBottom: SIZE_24
                                                    }}
                                                >
                                                    <Grid
                                                        container
                                                        spacing={3}
                                                    >
                                                        {socialIcons.map((socialIcon, index) => (
                                                            <Grid
                                                                key={index}
                                                                item
                                                                xs={3}
                                                                display="flex"
                                                                justifyContent="center"
                                                            >
                                                                <Link
                                                                    aria-label={`${socialIcon.name} button icon`}
                                                                    href={socialIcon.url}
                                                                    className={
                                                                        classes.socialIconLink
                                                                    }
                                                                    target="_blank"
                                                                    rel="noreferrer"
                                                                >
                                                                    {socialIcon.icon}
                                                                </Link>
                                                            </Grid>
                                                        ))}
                                                    </Grid>
                                                </Box>
                                            )}
                                            <div>
                                                <Box
                                                    alignItems={
                                                        isMobileWidth ? 'center' : 'flex-start'
                                                    }
                                                    display="flex"
                                                    flexDirection={'row'}
                                                    flexWrap="wrap"
                                                    justifyContent={
                                                        isMobileWidth ? 'center' : 'flex-start'
                                                    }
                                                    mb={2}
                                                    textAlign={isMobileWidth ? 'center' : 'left'}
                                                    sx={{ width: '100%' }}
                                                >
                                                    <div className={classes.linkContainer}>
                                                        <Link
                                                            href="/faq"
                                                            className={classes.link}
                                                            onClick={() =>
                                                                sendMultiStateCustomEventToMparticle(
                                                                    {},
                                                                    'Clicked FAQ link in Footer',
                                                                    '',
                                                                    window.mParticle.EventType
                                                                        .Navigation
                                                                )
                                                            }
                                                        >
                                                            FAQ
                                                        </Link>
                                                    </div>
                                                    <div className={classes.linkContainer}>
                                                        <Link
                                                            href="/contact-us"
                                                            className={classes.link}
                                                        >
                                                            Contact Us
                                                        </Link>
                                                    </div>
                                                    <div className={classes.linkContainer}>
                                                        <Link
                                                            href="/web-accessibility"
                                                            className={classes.link}
                                                        >
                                                            Web Accessibility
                                                        </Link>
                                                    </div>
                                                    <div className={classes.linkContainer}>
                                                        <Link
                                                            href="/careers"
                                                            className={classes.link}
                                                        >
                                                            Careers
                                                        </Link>
                                                    </div>
                                                    <div className={classes.linkContainer}>
                                                        <Link
                                                            href="/privacy-policy"
                                                            className={classes.link}
                                                        >
                                                            Privacy
                                                        </Link>
                                                    </div>
                                                    <div className={classes.linkContainer}>
                                                        <Link
                                                            href="/terms-of-purchase"
                                                            className={classes.link}
                                                        >
                                                            Terms of Purchase
                                                        </Link>
                                                    </div>
                                                    <div className={classes.linkContainer}>
                                                        <Link
                                                            href="/privacy-policy/#your"
                                                            className={classes.link}
                                                        >
                                                            Do Not Sell or Share My Personal
                                                            Information
                                                        </Link>
                                                    </div>
                                                    <div className={classes.linkContainer}>
                                                        <a
                                                            href="javascript:Optanon.ToggleInfoDisplay()"
                                                            className={classes.link}
                                                        >
                                                            Cookie Settings
                                                        </a>
                                                    </div>
                                                </Box>
                                            </div>
                                        </Box>
                                    </Grid>
                                    <Grid
                                        item
                                        md={4}
                                        sm={isMobileWidth ? 12 : 6}
                                        xs={12}
                                    >
                                        {!isMobileWidth ? (
                                            <Box
                                                display="flex"
                                                justifyContent="flex-end"
                                                sx={{
                                                    marginBottom: SIZE_24
                                                }}
                                            >
                                                <Newsletter
                                                    isMobile={isMobileWidth}
                                                    routerPathName={routerPathName}
                                                    isF1={isF1}
                                                />
                                            </Box>
                                        ) : null}
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                <Divider
                    light
                    sx={{
                        backgroundColor: COLOR_GRAY_50
                    }}
                />
                <Box mt={!isMobileWidth ? 2 : undefined}>
                    <Grid
                        container
                        spacing={2}
                    >
                        <Grid
                            item
                            md={8}
                            sm={isMobileWidth ? 12 : 6}
                            xs={12}
                        >
                            <Box
                                display="flex"
                                alignItems={isMobileWidth ? 'center' : 'center'}
                                justifyContent={isMobileWidth ? 'center' : 'flex-start'}
                                height={!isMobileWidth ? '100%' : undefined}
                            >
                                <Typography
                                    variant="body2"
                                    color="inherit"
                                    component="p"
                                    style={{
                                        color: COLOR_GRAY_400,
                                        fontSize: isMobileWidth ? FONT_SIZE_14 : undefined,
                                        marginTop: isMobileWidth ? SIZE_24 : undefined,
                                        textAlign: isMobileWidth ? 'center' : undefined
                                    }}
                                >
                                    By continuing past this page, you agree to our{' '}
                                    <Link
                                        href="/terms-of-use"
                                        className={classes.link}
                                        style={{
                                            lineHeight: SIZE_8
                                        }}
                                    >
                                        terms of use
                                    </Link>
                                    . {`@${new Date().getFullYear()}`} Vibee. All rights reserved.
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid
                            item
                            md={4}
                            sm={6}
                            xs={12}
                        >
                            {!isMobileWidth && (
                                <Box
                                    alignItems={isMobileWidth ? 'center' : 'flex-end'}
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent={isMobileWidth ? 'center' : 'space-between'}
                                    height={isMobileWidth ? undefined : '100%'}
                                >
                                    <Box
                                        alignItems="center"
                                        display="flex"
                                        flexDirection="row"
                                    >
                                        {socialIcons.map((socialIcon, index) => (
                                            <Link
                                                aria-label={`${socialIcon.name} button icon`}
                                                href={socialIcon.url}
                                                className={classes.socialIconLink}
                                                key={index}
                                                target="_blank"
                                                rel="noreferrer"
                                                sx={{
                                                    height: SIZE_24,
                                                    marginRight:
                                                        index !== socialIcons.length - 1
                                                            ? SIZE_24
                                                            : undefined,
                                                    width: SIZE_24,
                                                    '&:hover': {
                                                        color: COLOR_WHITE
                                                    }
                                                }}
                                            >
                                                {socialIcon.icon}
                                            </Link>
                                        ))}
                                    </Box>
                                </Box>
                            )}
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </div>
    )
}
