import { ReactNode } from 'react'
import Dialog from '@mui/material/Dialog'
import { useStyles } from './ModalComponent.styles'
import { SIZE_16, theme } from 'styles/theme'

export type ModalComponentProps = {
    children: ReactNode
    fullScreen?: boolean
    open: boolean
    style?: {}
    sx?: {}
    title?: string
}

export const ModalComponent = ({
    children,
    fullScreen = false,
    open = false,
    style,
    sx,
    title = 'Dialog'
}: ModalComponentProps) => {
    const { classes } = useStyles()
    return (
        <Dialog
            disableScrollLock
            fullScreen={fullScreen}
            open={open}
            aria-labelledby="modal"
            PaperProps={{
                sx: {
                    borderRadius: SIZE_16,
                    margin: 'auto',
                    '&.MuiDialog-paper': {
                        [theme.breakpoints.down('sm')]: {
                            marginTop: '10%'
                        }
                    },
                    ...sx
                }
            }}
            slotProps={{
                backdrop: {
                    classes: {
                        root: classes.backDrop
                    }
                }
            }}
            sx={style}
        >
            <label id="modal" className={classes.dialogLabel}>{title}</label>
            {children}
        </Dialog>
    )
}
