import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline'
import Box from '@mui/material/Box'
import Fab from '@mui/material/Fab'
import Zoom from '@mui/material/Zoom'
import { COLOR_GRAY_500, FONT_SIZE_14, SIZE_20, SIZE_24 } from 'styles/theme'

type Props = {
    isLoading: boolean
    isSuccessful: boolean
}

export const SignInConfirmedComponent = ({ isSuccessful }: Props) => {
    return (
        <Box style={{ fontWeight: 'bold', marginBottom: SIZE_24, textAlign: 'center' }}>
            <Box
                style={{
                    color: COLOR_GRAY_500,
                    fontSize: FONT_SIZE_14,
                    marginBottom: SIZE_20
                }}
            >
                <Box style={{ fontWeight: 'bold', textAlign: 'center' }}>Success!</Box>
            </Box>
            <Zoom in={isSuccessful}>
                <Fab
                    aria-label="confirmed"
                    color="success"
                    disableRipple
                    disableFocusRipple
                    size="medium"
                    sx={{
                        boxShadow: 'none',
                        ...(isSuccessful && {
                            bgcolor: 'green[500]',
                            '&:hover': {
                                bgcolor: 'secondary'
                            }
                        })
                    }}
                    variant="circular"
                >
                    {isSuccessful ? <CheckCircleOutline /> : null}
                </Fab>
            </Zoom>
        </Box>
    )
}
