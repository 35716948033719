import { ApolloClient, from, fromPromise, gql, HttpLink, InMemoryCache } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { setContext } from '@apollo/client/link/context'
import getConfig from 'next/config'

const { serverRuntimeConfig, publicRuntimeConfig } = getConfig()

const eventsHttpLink = new HttpLink({
    uri:
        typeof window === 'undefined'
            ? serverRuntimeConfig.NEXT_PUBLIC_BACKEND_EVENTS_GRAPHQL_URL
            : publicRuntimeConfig.NEXT_PUBLIC_EVENTS_GRAPHQL_URL
})

const usersHttpLink = new HttpLink({
    uri:
        typeof window === 'undefined'
            ? serverRuntimeConfig.NEXT_PUBLIC_BACKEND_USERS_GRAPHQL_URL
            : publicRuntimeConfig.NEXT_PUBLIC_USERS_GRAPHQL_URL
})

const getNewToken = async () => {
    // get the authentication token from local storage if it exists
    let authStr = '{}'
    if (typeof localStorage !== 'undefined') {
        authStr = localStorage.getItem('auth') || authStr
    }
    const auth = JSON.parse(authStr || '{}')
    const refreshToken = auth.refresh
    const {
        data: {
            refresh: { token, refresh }
        }
    } = await usersClient.mutate({
        mutation: gql`
            query refresh($token: String!) {
                refresh(token: $token) {
                    token
                    refresh
                }
            }
        `,
        variables: { token: refreshToken }
    })!
    auth.jwt = token
    auth.refresh = refresh
    localStorage.setItem('auth', JSON.stringify(auth))
}

const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    let auth = '{}'
    if (typeof localStorage !== 'undefined') {
        auth = localStorage.getItem('auth') || auth
    }
    const jwt = JSON.parse(auth || '{}').jwt
    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            authorization: jwt ? `Bearer ${jwt}` : ''
        }
    }
})

const touristLink = setContext((_, { headers }) => {
    return {
        headers: {
            ...headers
        }
    }
})

let isRefreshing = false
let pendingRequests: Function[] = []
const setIsRefreshing = (value: boolean) => {
    isRefreshing = value
}
const addPendingRequest = (pendingRequest: Function) => {
    pendingRequests.push(pendingRequest)
}
const resolvePendingRequests = () => {
    pendingRequests.map((callback) => callback())
    pendingRequests = []
}

const touristErrorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
            console.log(
                `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
            )
        })
    }
    if (networkError) console.log(`[Network error]: ${networkError}`)
})

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) => {
            if (message.indexOf('jwt expired') !== -1) {
                if (!isRefreshing) {
                    setIsRefreshing(true)
                    return fromPromise(
                        getNewToken().catch((_) => {
                            resolvePendingRequests()
                            setIsRefreshing(false)

                            // TODO: add mParticle user logout here

                            localStorage.clear()
                            window.location.href = '/logout'
                            return forward(operation)
                        })
                    ).flatMap(() => {
                        resolvePendingRequests()
                        setIsRefreshing(false)
                        return forward(operation)
                    })
                } else {
                    return fromPromise(
                        new Promise((resolve) => {
                            addPendingRequest(() => resolve(null))
                        })
                    ).flatMap(() => {
                        return forward(operation)
                    })
                }
            } else {
                console.log(
                    `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
                )
            }
        })

    if (networkError) console.log(`[Network error]: ${networkError}`)
})

export const eventsClient = new ApolloClient({
    link: authLink.concat(from([errorLink, eventsHttpLink])),
    cache: new InMemoryCache()
})

export const eventsWithoutJwtClient = new ApolloClient({
    link: touristLink.concat(from([touristErrorLink, eventsHttpLink])),
    cache: new InMemoryCache()
})

export const usersClient = new ApolloClient({
    link: authLink.concat(from([errorLink, usersHttpLink])),
    cache: new InMemoryCache()
})
