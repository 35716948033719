import { useCallback, useEffect, useRef, useState } from 'react'
import { gql } from '@apollo/client'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faApple, faFacebookSquare } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { toaster } from 'evergreen-ui'
import Script from 'next/script'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { usersClient } from '../apollo-client'
import { NavbarState, OrderState } from '../store/index'
import { useRouter } from 'next/router'
import { sendMultiStateCustomEventToMparticle } from '../util/handlemParticle'
import getConfig from 'next/config'
import { GoogleOAuthProvider, GoogleLogin, CredentialResponse } from '@react-oauth/google'
import { useUser, useWindowWidth } from 'hooks'
import { SignInConfirmedComponent } from './SignInConfirmed'
import { useStyles } from './SignInConfirmed/SignIn.styles'
import { googleAnalyticsEvent, GoogleAnalyticsEventActionType } from './Google/GoogleAnalytics'
import { parseJwt } from 'util/parse'
import { validateConfirmEmail } from 'util/validate'
import { ButtonComponent } from './Button'
import TextFieldComponent from './TextField/TextFieldComponent'
import { defaultRegisterInfoObject, FILL_FIELD_REQUIRED } from 'consts'
import { REGISTER_QUERY } from 'graphQL/user/userRegisterQuery.graphql'
import { LogoColorType, LogoComponent } from 'components/Logo/LogoComponent'

const { publicRuntimeConfig } = getConfig()
const faFacebookSquareSvg = faFacebookSquare as IconProp
const faAppleSvg = faApple as IconProp

const OAUTH = gql`
    query Oauth(
        $token: String!
        $source: String!
        $profileImage: String
        $lastName: String
        $firstName: String
        $name: String
    ) {
        oauth(
            token: $token
            source: $source
            profileImage: $profileImage
            lastName: $lastName
            firstName: $firstName
            name: $name
        ) {
            token
            refresh
        }
    }
`

interface PasswordJSON {
    minLength: {
        reg: RegExp
        isValid: boolean
    }
    lowercase: {
        reg: RegExp
        isValid: boolean
    }
    uppercase: {
        reg: RegExp
        isValid: boolean
    }
    special: {
        reg: RegExp
        isValid: boolean
    }
}

function SignUp() {
    const router = useRouter()
    const { handleAppleLogin, handleJWT, isSuccessful, setIsSuccessful } = useUser()
    const [registerInfo, setRegisterInfo] = useState(defaultRegisterInfoObject)
    const [shouldShowPasswordReg, setShouldShowPasswordReg] = useState<boolean>(false)
    const dispatch = useDispatch()
    const navState: NavbarState = useSelector((state: RootStateOrAny) => state.navbarReducer)
    const isSignUpLoading: boolean = useSelector(
        (state: RootStateOrAny) => state.pageLoadingReducer.loading
    )
    const [facebookLoginError, setFacebookLoginError] = useState<string>('')
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const { isMobileWidth, windowWidth } = useWindowWidth()
    const [passwordReg, setPasswordReg] = useState({
        minLength: {
            reg: /.{8,}/,
            isValid: false
        },
        lowercase: {
            reg: /[a-z]/,
            isValid: false
        },
        uppercase: {
            reg: /[A-Z]/,
            isValid: false
        },
        special: {
            reg: /\W/,
            isValid: false
        }
    })

    useEffect(() => {
        if (windowWidth) {
            if (windowWidth >= 768 && navState.expandedNav) {
                dispatch({ type: 'navbar/updateState', payload: { expandedNav: false } })
            } else if (windowWidth < 768 && !navState.expandedNav) {
                dispatch({ type: 'navbar/updateState', payload: { expandedNav: true } })
            }
        }
    }, [windowWidth])

    useEffect(() => {
        let k: keyof PasswordJSON
        for (k in passwordReg) {
            const isValid = passwordReg[k].reg.test(registerInfo.password)
            passwordReg[k]['isValid'] = isValid
        }
        setPasswordReg({ ...passwordReg })
    }, [registerInfo.password])

    const emailReg = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9_-]+)+$/
    const [emailError, setEmailError] = useState<string>('')
    const [confirmEmailError, setConfirmEmailError] = useState<string>('')
    const [firstNameError, setFirstNameError] = useState<string>('')
    const [lastNameError, setLastNameError] = useState<string>('')
    const [passwordError, setPasswordError] = useState<string>('')
    const [serverError, setServerError] = useState<string>('')
    const order: OrderState = useSelector((state: RootStateOrAny) => state.orderReducer)
    const fbRef = useRef(null)
    const [googleWidth, setGoogleWidth] = useState<number>(0)
    const isNotLoadingOrSuccessful = !isLoading && !isSuccessful
    const { classes } = useStyles()
    const signUpRef = useRef(null)

    useEffect(() => {
        if (signUpRef.current) {
            (signUpRef.current as HTMLElement).style.pointerEvents = 'none';
            const timer = setTimeout(() => {
                if (signUpRef.current) {
                    (signUpRef.current as HTMLElement).style.pointerEvents = 'auto';
                }
            }, 300);
            return () => clearTimeout(timer);
        }
    }, []);

    useEffect(() => {
        const handleResize = () => {
            if (fbRef.current) {
                const fbRefWidth = (fbRef.current as HTMLElement).offsetWidth
                setGoogleWidth(fbRefWidth)
            }
        };
        const resizeObserver = new ResizeObserver(handleResize);
        if (fbRef.current) {
            resizeObserver.observe(fbRef.current)
            handleResize()
        }
        return () => {
            if (fbRef.current) {
                resizeObserver.unobserve(fbRef.current);
            }
        };
    }, [fbRef])

    const closeSignUpModal = useCallback(() => {
        if (navState.expandedNav) {
            dispatch({ type: 'navbar/setState', payload: { expandedNav: false, signIn: false } })
        } else {
            dispatch({ type: 'navbar/setState', payload: { signIn: false } })
        }
    }, [dispatch, navState.expandedNav])

    const handleGoogleLogin = async (googleData: CredentialResponse | any) => {
        sendMultiStateCustomEventToMparticle(googleData, 'Sign Up', 'Attempted')
        dispatch({ type: 'pageLoading/setState', payload: { loading: true } })
        try {
            if (!googleData.credential) {
                console.error(googleData)
                dispatch({ type: 'pageLoading/setState', payload: { loading: false } })
            } else {
                const { memberID } = router.query
                if (router.pathname === '/invite/submit') {
                    const googleUserEmail = parseJwt(googleData.credential).email
                    const member = order.members.find((m) => m.ID === memberID)
                    if (member?.Email.toLowerCase() !== googleUserEmail.toLowerCase()) {
                        setEmailError('Please enter the correct email address.')
                        sendMultiStateCustomEventToMparticle(
                            googleData +
                                {
                                    error_message:
                                        "Invited member email doesn't match Google User Email"
                                },
                            'Sign Up',
                            'Failed'
                        )
                        dispatch({ type: 'pageLoading/setState', payload: { loading: false } })
                        return
                    }
                }
                const r = await usersClient.query<{ oauth: { token: string; refresh: string } }>({
                    fetchPolicy: 'no-cache',
                    query: OAUTH,
                    variables: {
                        token: googleData.credential,
                        source: 'google'
                    }
                })
                handleJWT(r.data.oauth)
                sendMultiStateCustomEventToMparticle(googleData, 'Sign Up', 'Succeeded')
                setIsSuccessful(true)
            }
        } catch (error) {
            console.error(error)
            sendMultiStateCustomEventToMparticle(
                googleData + { error_message: error },
                'Sign Up',
                'Failed'
            )
            dispatch({ type: 'pageLoading/setState', payload: { loading: false } })
        }
    }

    const handleFacebookLogin = async (FB: any) => {
        let data = {}
        const facebookAuthType = window.localStorage.getItem('facebookAuthType') || undefined
        if (facebookAuthType) {
            data = { scope: 'email,', auth_type: facebookAuthType }
        } else {
            data = { scope: 'email,' }
        }
        FB.login(function (res: any) {
            sendMultiStateCustomEventToMparticle({ source: 'Facebook' }, 'Sign Up', 'Attempted')
            if (res.authResponse) {
                FB.api(
                    '/me',
                    'GET',
                    { fields: 'email,picture' },
                    async (data: { accessToken: string; email: string; picture: any }) => {
                        if (data.email) {
                            window.localStorage.removeItem('facebookAuthType')
                            const { memberID } = router.query
                            if (router.pathname === '/invite/submit') {
                                const member = order.members.find((m) => m.ID === memberID)
                                if (member?.Email.toLowerCase() !== data.email.toLowerCase()) {
                                    setEmailError('Please enter the correct email address.')
                                    sendMultiStateCustomEventToMparticle(
                                        {
                                            source: 'Facebook',
                                            error_message:
                                                "Invited member email doesn't match Facebook User Email"
                                        },
                                        'Sign Up',
                                        'Failed'
                                    )
                                    dispatch({
                                        type: 'pageLoading/setState',
                                        payload: { loading: false }
                                    })
                                    return
                                }
                            }
                            try {
                                const response = await fetch(data.picture?.data?.url || '')
                                const blobResponse = await response.blob()
                                const reader = new FileReader()
                                reader.readAsDataURL(blobResponse)
                                reader.onload = async function (e) {
                                    const base64Img = e!.target!.result
                                    let isBase64 = false
                                    if (
                                        typeof base64Img == 'string' &&
                                        base64Img.indexOf('base64,') !== -1
                                    ) {
                                        isBase64 = true
                                    }
                                    const r = await usersClient.query<{
                                        oauth: { token: string; refresh: string }
                                    }>({
                                        fetchPolicy: 'no-cache',
                                        query: OAUTH,
                                        variables: {
                                            token: res.authResponse.accessToken,
                                            source: 'facebook',
                                            profileImage: isBase64 ? base64Img : ''
                                        }
                                    })
                                    handleJWT(r.data.oauth)
                                    sendMultiStateCustomEventToMparticle(
                                        r.data.oauth,
                                        'Sign Up',
                                        'Succeeded'
                                    )
                                    setIsSuccessful(true)
                                }
                            } catch (error) {
                                sendMultiStateCustomEventToMparticle(
                                    { source: 'Facebook', error_message: error },
                                    'Sign Up',
                                    'Failed'
                                )
                                console.log(error)
                                dispatch({
                                    type: 'pageLoading/setState',
                                    payload: { loading: false }
                                })
                            }
                        } else {
                            console.log('setItem rerequest...')
                            window.localStorage.setItem('facebookAuthType', 'rerequest')
                            dispatch({ type: 'pageLoading/setState', payload: { loading: false } })
                            if (res?.status === 'connected') {
                                sendMultiStateCustomEventToMparticle(
                                    {
                                        source: 'Facebook',
                                        error_message:
                                            'You can not login with Facebook without sharing your email information.'
                                    },
                                    'Sign Up',
                                    'Failed'
                                )
                                setFacebookLoginError(
                                    'You can not login with Facebook without sharing your email information.'
                                )
                            }
                        }
                    }
                )
            } else {
                // Close the authorization window
                dispatch({ type: 'pageLoading/setState', payload: { loading: false } })
            }
        }, data)
    }

    const handleEmailRegister = async () => {
        const { confirmEmail, email, firstName, lastName, name, password, phone } = registerInfo
        const emailIsNotValid = !emailReg.test(email)
        const passwordIsNotValid = Object.entries(passwordReg).find(([_, b]) => !b.isValid)
        sendMultiStateCustomEventToMparticle({ source: 'Email' }, 'Sign Up', 'Attempted')
        setIsLoading(true)
        if (emailIsNotValid) {
            sendMultiStateCustomEventToMparticle(
                { source: 'Email', error_message: 'Please enter a valid email address.' },
                'Sign Up',
                'Failed'
            )
            setEmailError('Please enter a valid email address.')
            setIsLoading(false)
        } else {
            setEmailError('')
        }
        const message = validateConfirmEmail(confirmEmail, email)

        const isSameEmail = message === ''
        if (isSameEmail) {
            setConfirmEmailError('')
        } else {
            setIsLoading(false)
            setConfirmEmailError(message)
        }

        const nameError = FILL_FIELD_REQUIRED
        if (firstName) {
            setFirstNameError('')
        } else {
            setFirstNameError(nameError)
        }

        if (lastName) {
            setLastNameError('')
        } else {
            setLastNameError(nameError)
        }

        if (!firstName || !lastName) {
            setIsLoading(false)
            return
        }
        if (passwordIsNotValid) {
            sendMultiStateCustomEventToMparticle(
                { source: 'Email', error_message: 'Password does not meet requirements.' },
                'Sign Up',
                'Failed'
            )
            setPasswordError('Password does not meet requirements.')
            setIsLoading(false)
        } else {
            setPasswordError('')
        }
        if (emailIsNotValid || passwordIsNotValid || !isSameEmail) {
            return
        }

        try {
            await usersClient.query<{ register: { token: string; refresh: string } }>({
                fetchPolicy: 'no-cache',
                query: REGISTER_QUERY,
                variables: {
                    email,
                    firstName,
                    isActive: true,
                    isManualRegistration: true,
                    lastName,
                    name,
                    password,
                    phone
                }
            })

            // handleJWT(response.data.register)
            sendMultiStateCustomEventToMparticle({ source: 'Email' }, 'Sign Up', 'Succeeded')
            setIsSuccessful(true)
        } catch (e: any) {
            if (e.graphQLErrors.length) {
                setServerError(e.graphQLErrors[0].message)
                sendMultiStateCustomEventToMparticle(
                    { source: 'Email', error_message: e.graphQLErrors[0].message },
                    'Sign In',
                    'Failed'
                )
                setIsLoading(false)
            } else {
                sendMultiStateCustomEventToMparticle(
                    { source: 'Email', error_message: 'Internal Server Error' },
                    'Sign In',
                    'Failed'
                )
                setServerError('Internal Server Error')
                setIsLoading(false)
            }
        }
    }

    useEffect(() => {
        if (isSuccessful) {
            if (navState.expandedNav) {
                dispatch({
                    type: 'navbar/setState',
                    payload: { expandedNav: true, signIn: true, signUpSuccessEmail: registerInfo.email }
                })
            } else {
                dispatch({
                    type: 'navbar/setState',
                    payload: { expandedNav: false, signIn: true, signUpSuccessEmail: registerInfo.email }
                })
            }
        }
    }, [isSuccessful])

    useEffect(() => {
        googleAnalyticsEvent({
            action: GoogleAnalyticsEventActionType.PAGE_VIEW,
            category: 'Sign Up',
            label: window.location.href.split('?')[0],
            pageTitle: 'Sign Up'
        })
    }, [])

    return (
        <>
            {!isSuccessful && (
                <div className="login-wrapper p-3" ref={signUpRef}>
                    <div className={classes.googleLoginButton}>
                        <GoogleOAuthProvider
                            clientId={publicRuntimeConfig.NEXT_PUBLIC_GOOGLE_OAUTH_CLIENT_ID!}
                        >
                            <GoogleLogin
                                locale="en-US"
                                width={googleWidth}
                                text="continue_with"
                                shape="square"
                                size="large"
                                theme="outline"
                                onSuccess={handleGoogleLogin}
                                onError={() => {
                                    console.error('Login Failed')
                                    dispatch({
                                        type: 'pageLoading/setState',
                                        payload: { loading: false }
                                    })
                                }}
                                auto_select={false}
                            />
                        </GoogleOAuthProvider>
                    </div>
                    <Script
                        crossOrigin="anonymous"
                        src="https://connect.facebook.net/en_US/sdk.js"
                        onLoad={() => {
                            ;(window as any).fbAsyncInit = function () {
                                const FB = (window as any).FB
                                FB.init({
                                    appId: 4826926720709866,
                                    cookie: false,
                                    version: 'v14.0'
                                })
                            }
                        }}
                    ></Script>
                    <div
                        className={classes.facebookLoginButton}
                        ref={fbRef}
                        onClick={async () => {
                            const FB = (window as any).FB
                            if (isSignUpLoading || !FB) return
                            setFacebookLoginError('')
                            dispatch({ type: 'pageLoading/setState', payload: { loading: true } })
                            handleFacebookLogin(FB)
                        }}
                    >
                        <div className="align-items-center d-flex">
                            <FontAwesomeIcon
                                icon={faFacebookSquareSvg}
                                size="xl"
                                className="text-facebook-blue"
                                width={18}
                                height={18}
                                style={{ marginLeft: '-2px' }}
                            />
                            <span className={classes.thirdPartyLoginText}>Continue with Facebook</span>
                        </div>
                    </div>
                    <Script
                        src="https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js"
                        onLoad={() => {
                            const params = {
                                clientId: 'com.vibee.login',
                                usePopup: true,
                                scope: 'name email',
                                state: window.location.href,
                                redirectURI:
                                    (
                                        window.location.protocol +
                                        '//' +
                                        window.location.hostname
                                    ).replace('http://localhost:3000', 'https://vibee.com') +
                                    '/api/login'
                            }
                            ;(window as any).AppleID.auth.init(params)
                        }}
                    />
                    <div
                        className={classes.appleLoginButton}
                        onClick={async () => {
                            const AppleID = (window as any).AppleID
                            if (isSignUpLoading || !AppleID) return
                            dispatch({ type: 'pageLoading/setState', payload: { loading: true } })
                            try {
                                sendMultiStateCustomEventToMparticle(
                                    { source: 'Apple' },
                                    'Sign Up',
                                    'Attempted'
                                )
                                const data = await AppleID.auth.signIn()
                                await handleAppleLogin(
                                    data.authorization.id_token,
                                    data?.user?.firstName ?? '',
                                    data?.user?.lastName ?? ''
                                )
                                sendMultiStateCustomEventToMparticle(
                                    { source: 'Apple' },
                                    'Sign Up',
                                    'Succeeded'
                                )
                            } catch (e) {
                                sendMultiStateCustomEventToMparticle(
                                    { source: 'Apple', error_message: e },
                                    'Sign Up',
                                    'Failed'
                                )
                                dispatch({
                                    type: 'pageLoading/setState',
                                    payload: { loading: false }
                                })
                            }
                        }}
                    >
                        <div className="align-items-center d-flex">
                            <FontAwesomeIcon
                                icon={faAppleSvg}
                                size="xl"
                                className="text-dark"
                                width={18}
                                height={18}
                                style={{ marginLeft: '-2px' }}
                            />
                            <span className={classes.thirdPartyLoginText}>Continue with Apple</span>
                        </div>
                    </div>
                    <span className={`divider-or ${classes.signInDividerOr}`}></span>
                    <form
                        className="LoginForm"
                        id="registrationForm"
                        noValidate
                        autoComplete="off"
                        autoCapitalize="off"
                        onSubmit={(event) => {
                            event.preventDefault()
                        }}
                    >
                        <div className={classes.nameBox}>
                            <div className={classes.firstNameInput}>
                                <TextFieldComponent
                                    errorMsg={firstNameError}
                                    label="First Name"
                                    onChange={(event) => {
                                        setRegisterInfo({
                                            ...registerInfo,
                                            firstName: event.target.value.trim()
                                        })
                                    }}
                                    value={registerInfo.firstName}
                                />
                            </div>
                            <div className={classes.lastNameInput}>
                                <TextFieldComponent
                                    errorMsg={lastNameError}
                                    label="Last Name"
                                    onChange={(event) => {
                                        setRegisterInfo({
                                            ...registerInfo,
                                            lastName: event.target.value.trim()
                                        })
                                    }}
                                    value={registerInfo.lastName}
                                />
                            </div>
                        </div>
                        <div className={classes.inputWrapper}>
                            <TextFieldComponent
                                errorMsg={emailError}
                                label="Email"
                                onChange={(event) => {
                                    setRegisterInfo({
                                        ...registerInfo,
                                        email: event.target.value.trim()
                                    })
                                }}
                                value={registerInfo.email}
                            />
                        </div>
                        <div className={classes.inputWrapper}>
                            <TextFieldComponent
                                errorMsg={confirmEmailError}
                                label="Re-enter Email"
                                onChange={(event) => {
                                    setRegisterInfo({
                                        ...registerInfo,
                                        confirmEmail: event.target.value.trim()
                                    })
                                }}
                                value={registerInfo.confirmEmail}
                            />
                        </div>
                        <div className={classes.inputWrapper}>
                            <TextFieldComponent
                                errorMsg={
                                    passwordError || serverError || facebookLoginError
                                }
                                label="Password"
                                onChange={(e) => {
                                    setRegisterInfo({
                                        ...registerInfo,
                                        password: e.target.value.trim()
                                    })
                                }}
                                onFocus={() => {
                                    setShouldShowPasswordReg(true)
                                }}
                                type="password"
                                value={registerInfo.password}
                            />
                            {shouldShowPasswordReg && (
                                <ul className="mt-2 list-unstyled list-control password-valid">
                                    <li
                                        className={`min-length-valid ${passwordReg.minLength.isValid ? 'active' : ''
                                            }`}
                                    >
                                        8 characters minimum
                                    </li>
                                    <li
                                        className={`lower-valid ${passwordReg.lowercase.isValid ? 'active' : ''
                                            }`}
                                    >
                                        1 lowercase character
                                    </li>
                                    <li
                                        className={`upper-valid ${passwordReg.uppercase.isValid ? 'active' : ''
                                            }`}
                                    >
                                        1 uppercase character
                                    </li>
                                    <li
                                        className={`special-valid ${passwordReg.special.isValid ? 'active' : ''
                                            }`}
                                    >
                                        1 special character
                                    </li>
                                </ul>
                            )}
                        </div>
                        <ButtonComponent
                            circularProgressSize={24}
                            className={classes.createAccountButton}
                            isLoading={isLoading}
                            onClick={() => {
                                if (isLoading) {
                                    return
                                }
                                handleEmailRegister()
                            }}
                            title="Create Account"
                            type="submit"
                        />
                    </form>
                    <p className={classes.signUpTipButton}>
                        Already have an account?{' '}
                        <span
                            className={classes.tipLink}
                            onClick={() => {
                                if (isLoading) {
                                    return
                                }
                                if (navState.expandedNav) {
                                    dispatch({
                                        type: 'navbar/setState',
                                        payload: { expandedNav: true, signIn: true }
                                    })
                                } else {
                                    dispatch({
                                        type: 'navbar/setState',
                                        payload: { signIn: true }
                                    })
                                }
                            }}
                        >
                            Sign In
                        </span>
                    </p>
                    {isMobileWidth && (
                        <div className={classes.logo}>
                            <LogoComponent
                                color={LogoColorType.BLACK}
                                height="31px"
                                width='80px'
                            />
                        </div>
                    )}
                </div>
            )}
            {isSuccessful ? (
                <SignInConfirmedComponent
                    isLoading={isLoading}
                    isSuccessful={isSuccessful}
                />
            ) : null}
        </>
    )
}

export default SignUp
