import {
    BORDER_TRANSPARENT,
    COLOR_BLACK,
    COLOR_GRAY_100,
    COLOR_GRAY_400,
    COLOR_GRAY_800,
    COLOR_WHITE,
    FONT_SIZE_12,
    FONT_SIZE_18,
    FONT_WEIGHT_500,
    FONT_WEIGHT_700,
    FULL_WIDTH,
    HALF_WIDTH,
    SIZE_2,
    SIZE_4,
    SIZE_8,
    SIZE_12,
    SIZE_14,
    SIZE_16,
    SIZE_18,
    SIZE_20,
    SIZE_28,
    SIZE_54,
    SIZE_56,
    SIZE_70,
    SIZE_80,
    SIZE_100
} from 'styles/theme'
import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()({
    button: {
        border: BORDER_TRANSPARENT,
        borderRadius: SIZE_8,
        display: 'block',
        marginBottom: SIZE_20,
        padding: `${SIZE_12} ${SIZE_14}`,
        transition: 'none',
        width: FULL_WIDTH,
        '&.Mui-disabled': {
            backgroundColor: COLOR_GRAY_100,
            borderColor: COLOR_GRAY_100,
            boxShadow: `0px 1px 2px ${COLOR_GRAY_800}0d`,
            color: COLOR_GRAY_400,
            opacity: 0.65,
            pointerEvents: 'none'
        },
        '& .MuiCircularProgress-root': {
            position: 'relative',
            top: SIZE_4
        }
    },
    container: {
        backgroundClip: 'border-box',
        backgroundColor: COLOR_WHITE,
        border: 0,
        borderRadius: SIZE_8,
        boxShadow: `0px ${SIZE_2} ${SIZE_4} ${COLOR_GRAY_800}1A, 0px ${SIZE_2} ${SIZE_2} ${COLOR_GRAY_800}0F`,
        display: 'flex',
        flexDirection: 'column',
        height: FULL_WIDTH,
        minWidth: 0,
        overflow: 'hidden',
        position: 'relative',
        textAlign: 'center',
        wordWrap: 'break-word'
    },
    description: {
        fontSize: FONT_SIZE_12,
        fontWeight: FONT_WEIGHT_500,
        lineHeight: SIZE_18,
        marginTop: SIZE_8,
        marginBottom: SIZE_8,
        height: SIZE_54,
        padding: 0,
        '@media (min-width: 768px)': {
            padding: `0 ${SIZE_14}`
        }
    },
    futureButton: {
        padding: `${SIZE_12} 0`
    },
    image: {
        objectFit: 'cover'
    },
    imageContainer: {
        backgroundSize: 'cover',
        height: FULL_WIDTH,
        left: 0,
        overflow: 'hidden',
        position: 'absolute',
        top: 0,
        width: FULL_WIDTH
    },
    imageWrapper: {
        height: 0,
        paddingBottom: '44%',
        position: 'relative',
        '@media (min-width: 768px)': {
            paddingBottom: '43.75%'
        }
    },
    lockButton: {
        '& svg': {
            fontSize: FONT_SIZE_18,
            transform: `translate(0px, -${SIZE_2})`
        }
    },
    name: {
        color: COLOR_GRAY_800,
        fontSize: FONT_SIZE_18,
        fontWeight: FONT_WEIGHT_700,
        height: SIZE_28,
        lineHeight: SIZE_28,
        marginBottom: 0
    },
    nameContainer: {
        backgroundColor: COLOR_WHITE,
        padding: `${SIZE_20} ${SIZE_16} ${SIZE_12}`,
        textDecoration: 'none'
    },
    priceContainer: {
        color: COLOR_GRAY_800,
        fontSize: SIZE_14,
        fontWeight: FONT_WEIGHT_500,
        lineHeight: SIZE_20
    },
    emptyPriceContainer: {
        marginBottom: SIZE_4,
        '@media (min-width: 768px)': {
            marginBottom: SIZE_12
        }
    },
    priceText: {
        color: COLOR_BLACK,
        fontSize: FONT_SIZE_18,
        fontWeight: FONT_WEIGHT_700,
        lineHeight: SIZE_28
    },
    priceWrapper: {
        padding: `0 ${SIZE_16} 0`,
        '& .btn': {
            marginBottom: SIZE_20
        }
    },
    thumbnail: {
        border: `1px solid ${COLOR_WHITE}`,
        borderRadius: SIZE_2,
        bottom: SIZE_8,
        cursor: 'pointer',
        height: SIZE_70,
        overflow: 'hidden',
        position: 'absolute',
        right: SIZE_8,
        width: SIZE_100,
        '@media (max-width: 767px)': {
            height: SIZE_56,
            width: SIZE_80
        },
        '& img': {
            height: FULL_WIDTH,
            left: 0,
            position: 'absolute',
            top: 0,
            width: FULL_WIDTH
        }
    },
    wrapper: {
        flex: '0 0 auto',
        padding: `0 ${SIZE_8}`,
        marginBottom: SIZE_16,
        maxWidth: FULL_WIDTH,
        width: `${FULL_WIDTH} !important`,
        '@media (min-width: 768px)': {
            width: `${HALF_WIDTH} !important`
        },
        '@media (min-width: 1200px)': {
            width: '33.33333333% !important'
        }
    }
})
