import { theme } from 'styles/theme'
import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()({
    backDrop: {
        backdropFilter: 'blur(3px)',
        backgroundColor: 'rgba(0,0,30,0.4)'
    },
    dialogLabel: {
        display: 'none'
    }
})
