import {
    BORDER_RADIUS,
    COLOR_GRAY_800,
    COLOR_WHITE,
    FONT_SIZE_14,
    FONT_WEIGHT_700,
    SIZE_6,
    SIZE_8,
    SIZE_16,
    SIZE_20,
    SIZE_24,
    SIZE_28,
    SIZE_30,
    SIZE_120,
    theme
} from 'styles/theme'
import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()({
    appBar: {
        backgroundColor: 'transparent',
        borderBottom: 'none',
        boxShadow: 'none',
        height: '80px',
        transition: 'background-color 0.2s ease-in-out',
        zIndex: 1000
    },
    appBarScrolled: {
        backgroundColor: '#ffffff',
        boxShadow: '0 1px 0 #f2f4f7'
    },
    navbarLogo: {
        display: 'flex',
        marginRight: theme.spacing(3),
        marginLeft: 0,
        height: 80,
        position: 'relative',
        width: '100px'
    },
    navItemsContainer: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        maxWidth: '1800px',
        paddingLeft: SIZE_8,
        paddingRight: SIZE_6,
        width: '100%'
    },
    signInButton: {
        borderRadius: BORDER_RADIUS,
        boxShadow: '0 1px 2px rgba(16,24,40,.05)',
        fontSize: FONT_SIZE_14,
        fontWeight: 500,
        height: 'fit-content',
        lineHeight: SIZE_20,
        marginLeft: SIZE_16,
        padding: `${SIZE_8} ${SIZE_16}`,
        textTransform: 'capitalize'
    },
    toolbarWrapper: {
        margin: 'auto',
        width: '100%'
    },
    navbarText:{
        color: COLOR_GRAY_800,
        fontSize: SIZE_20,
        fontWeight: FONT_WEIGHT_700,
        lineHeight: SIZE_30,
        marginBottom: 0,
        marginLeft: SIZE_8
    }
})
