import { AuthContext } from 'auth/context'
import { useRouter } from 'next/router'
import {
    Dispatch,
    RefObject,
    SetStateAction,
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState
} from 'react'

type NavReturnType = {
    currentPathName: string
    handleSignUpButtonClick: Dispatch<SetStateAction<boolean>>
    heroRef?: RefObject<HTMLDivElement>
    isAccountModalOpen: boolean
    isNotCheckoutOrSubmitPage: boolean
    isNavbarHidden: boolean
    isNotHomePage: boolean
    isScrolled: boolean
    isLoggedIn: boolean
    setIsScrolled: Dispatch<SetStateAction<boolean>>
    shouldShowOrderCancelledCountdown: boolean
    setShouldShowOrderCancelledCountdown: Dispatch<SetStateAction<boolean>>
}

export const useNav = (): NavReturnType => {
    const [authState] = useContext(AuthContext)
    const { isLoggedIn } = authState
    const router = useRouter()
    const currentPathName: string = router.pathname
    const isNotCheckoutOrSubmitPage: boolean = !currentPathName.includes('/checkout/')
    const isNotHomePage: boolean = currentPathName !== '/'
    const [isScrolled, setIsScrolled] = useState<boolean>(false)
    const [isNavbarHidden, setIsNavbarHidden] = useState<boolean>(false)
    const [isAccountModalOpen, setIsAccountModalOpen] = useState<boolean>(false)
    const [shouldShowOrderCancelledCountdown, setShouldShowOrderCancelledCountdown] =
        useState<boolean>(false)
    const heroRef = useRef<HTMLDivElement | null>(null)

    const handleSignUpButtonClick = (): void => setIsAccountModalOpen(!isAccountModalOpen)

    const handleScroll = useCallback(() => {
        const isMobileHeight = window.innerHeight < 768
        const viewportHeight = isMobileHeight ? window.innerHeight * 0.725 : 400
        const shouldScrollHome: boolean = window.scrollY > viewportHeight
        if (currentPathName !== String('/')) {
            setIsScrolled(false)
            setIsNavbarHidden(false)
        } else {
            setIsNavbarHidden(false)
            if (shouldScrollHome !== isScrolled) {
                setIsScrolled(shouldScrollHome)
            }
        }
    }, [currentPathName, isScrolled])

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true })
        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [currentPathName, handleScroll, isScrolled])

    useEffect(() => {
        const pathsNotToShowBanner = ['/invite/accept', '/invite/submit', '/orders/[orderID]']
        const shouldShowCountdownBanner =
            !pathsNotToShowBanner.includes(router.pathname) && !isLoggedIn
        setShouldShowOrderCancelledCountdown((prevState) => (prevState = shouldShowCountdownBanner))
    }, [isLoggedIn, router.pathname])

    return {
        currentPathName,
        handleSignUpButtonClick,
        heroRef,
        isAccountModalOpen,
        isNavbarHidden,
        isNotCheckoutOrSubmitPage,
        isNotHomePage,
        isScrolled,
        isLoggedIn,
        setIsScrolled,
        setShouldShowOrderCancelledCountdown,
        shouldShowOrderCancelledCountdown
    }
}
