import React, { FC } from 'react'
import { COLOR_BLACK } from 'styles/theme'

type Props = {
    fill?: string
    height?: string
    width?: string
}

export const LogoIcon: FC<Props> = ({ fill = COLOR_BLACK, height = '83', width = '201' }: Props): JSX.Element => (
    <svg
        fill={fill}
        height={height}
        width={width}
        viewBox="0 0 201 83"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M17.244 82.2256L0 41.9658H9.02633L19.4005 66.151L29.7389 41.9658H38.7652L21.557 82.2256H17.2479H17.244Z"
            fill={fill}
        />
        <path
            d="M47.9579 29.8554C48.834 30.7434 49.274 31.8216 49.274 33.0901C49.274 34.3586 48.834 35.4369 47.9579 36.3249C47.0818 37.2128 45.9957 37.6608 44.7073 37.6608C43.419 37.6608 42.3605 37.2168 41.4726 36.3249C40.5846 35.4369 40.1406 34.3586 40.1406 33.0901C40.1406 31.8216 40.5846 30.7473 41.4726 29.8554C42.3605 28.9674 43.4388 28.5234 44.7073 28.5234C45.9758 28.5234 47.0818 28.9674 47.9579 29.8554ZM40.541 82.2295H48.834V41.9698H40.541V82.2295Z"
            fill={fill}
        />
        <path
            d="M72.9987 41.7126C78.5524 41.7126 83.3133 43.7224 87.2814 47.742C91.2297 51.7339 93.1999 56.5701 93.1999 62.2468C93.1999 67.9234 91.2258 72.72 87.2814 76.7159C83.3133 80.7593 78.5485 82.781 72.9987 82.781C68.6619 82.781 64.7414 81.4768 61.2331 78.8723V82.2696H52.7578V28.8926H61.2331V45.5499C64.7414 42.993 68.6619 41.7165 72.9987 41.7165V41.7126ZM72.9987 74.3017C76.2136 74.3017 78.9647 73.1204 81.256 70.7578C83.571 68.3951 84.7285 65.5568 84.7285 62.2468C84.7285 58.9367 83.571 56.0588 81.256 53.6961C78.9647 51.3335 76.2136 50.1522 72.9987 50.1522C69.7838 50.1522 66.9573 51.3335 64.67 53.6961C62.3788 56.0588 61.2371 58.909 61.2371 62.2468C61.2371 65.5846 62.3827 68.3991 64.67 70.7578C66.9573 73.1204 69.7362 74.3017 72.9987 74.3017Z"
            fill={fill}
        />
        <path
            d="M132.136 73.5323C130.455 76.1169 128.263 78.198 125.559 79.7797C122.416 81.6785 119.018 82.6299 115.363 82.6299C109.786 82.6299 105.025 80.6201 101.081 76.6005C97.1323 72.6086 95.1621 67.7724 95.1621 62.0957C95.1621 56.4191 97.1362 51.5868 101.081 47.591C105.025 43.5713 109.786 41.5615 115.363 41.5615C120.941 41.5615 125.678 43.5713 129.65 47.591C133.594 51.5868 135.568 56.4191 135.568 62.0957V65.5683H104.367C105.049 68.2243 106.46 70.353 108.604 71.9625C110.626 73.4252 112.878 74.1546 115.363 74.1546C119.431 74.1546 122.574 72.5373 124.79 69.2946L132.132 73.5323H132.136ZM126.364 57.8581C125.512 55.543 124.096 53.668 122.126 52.233C120.104 50.7702 117.853 50.0408 115.367 50.0408C112.882 50.0408 110.63 50.7702 108.608 52.233C106.634 53.668 105.223 55.547 104.371 57.8581H126.368H126.364Z"
            fill={fill}
        />
        <path
            d="M174.509 73.5323C172.828 76.1169 170.636 78.198 167.932 79.7797C164.789 81.6785 161.391 82.6299 157.736 82.6299C152.159 82.6299 147.398 80.6201 143.454 76.6005C139.505 72.6086 137.535 67.7724 137.535 62.0957C137.535 56.4191 139.509 51.5868 143.454 47.591C147.398 43.5713 152.159 41.5615 157.736 41.5615C163.314 41.5615 168.051 43.5713 172.023 47.591C175.967 51.5868 177.942 56.4191 177.942 62.0957V65.5683H146.74C147.422 68.2243 148.833 70.353 150.978 71.9625C152.999 73.4252 155.251 74.1546 157.736 74.1546C161.804 74.1546 164.947 72.5373 167.163 69.2946L174.505 73.5323H174.509ZM168.737 57.8581C167.885 55.543 166.469 53.668 164.499 52.233C162.477 50.7702 160.226 50.0408 157.74 50.0408C155.255 50.0408 153.003 50.7702 150.981 52.233C149.007 53.668 147.596 55.547 146.744 57.8581H168.741H168.737Z"
            fill={fill}
        />
        <path
            d="M195.661 4.81034C192.577 1.86499 188.577 -0.0298697 184.585 0.0216641C184.438 0.0137358 184.292 0.00976828 184.149 0.00580414C183.4 -0.0140165 182.663 0.0177053 181.941 0.0890596C182.575 1.02856 183.214 1.97202 183.844 2.91549C184.656 4.12851 185.441 5.36135 186.254 6.57437C187.459 8.37013 188.712 10.1342 189.877 11.9537C190.686 13.2143 191.459 14.5106 192.113 15.8584C192.93 17.5431 192.593 19.2873 191.994 20.9562C191.082 23.4893 189.62 25.6855 187.59 27.4733C185.544 29.277 183.325 30.8666 180.554 31.1797C178.468 31.4136 176.506 31.0014 175.103 29.0708C173.601 26.9976 173.799 24.8451 174.496 22.6331C175.392 19.7987 177.168 17.5352 179.388 15.6284C181.128 14.134 183.071 12.9527 185.453 12.477C185.283 12.0687 185.192 11.7 184.993 11.3948C184.01 9.87253 183.003 8.36617 182.004 6.85187C181.204 5.63488 180.403 4.41789 179.598 3.20486C179.099 2.45168 178.595 1.6985 178.092 0.945314C169.438 3.93031 164.186 13.5869 167.305 22.978C170.421 32.369 177.343 41.5975 182.674 49.4782C183.221 50.2353 184.137 50.2353 184.684 49.4821C190.024 41.6173 197.112 32.1589 200.156 22.7044C202.194 16.3737 200.477 9.40873 195.665 4.8143L195.661 4.81034Z"
            fill={fill}
        />
    </svg>
)
