import { FormHelperText, TextField } from '@mui/material'
import { useStyles } from 'components/SignInConfirmed/SignIn.styles'
import { ChangeEvent, FocusEventHandler } from 'react'

export type TextFieldComponentProps = {
    errorMsg: string
    label: string
    onChange: (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void
    onFocus?: FocusEventHandler<HTMLInputElement> | undefined
    type?: string
    value: string
}

export default function TextFieldComponent({
    errorMsg,
    label,
    onChange,
    onFocus,
    type,
    value
}: TextFieldComponentProps) {
    const { classes } = useStyles()
    return (
        <>
            <TextField
                className={classes.textFieldBox}
                fullWidth
                label={label}
                maxRows={10}
                onChange={onChange}
                onFocus={onFocus}
                type={type}
                value={value}
                variant="filled"
            />
            <FormHelperText
                className={classes.errorMessage}
                id="component-error-text"
            >
                {errorMsg}
            </FormHelperText>
        </>
    )
}
