import Image from './image'
import { useState } from 'react'
import HotelCardImagesGallery from './hotelCardImagesGallery'

export type HotelCardImagesProp = {
    handleShowSingleImage: () => void
    headerImage: any[]
    hideAllImageCardGallery: () => void
    hideSingleImage: () => void
    showSingleImage: boolean
}

export default function HotelCardImages({
    handleShowSingleImage,
    headerImage,
    hideAllImageCardGallery,
    hideSingleImage,
    showSingleImage
}: HotelCardImagesProp) {
    const [startIndex, setStartIndex] = useState<number>(0)
    let images = headerImage
        .sort((a, b) => a.position - b.position)
        .map((i: any) => ({ original: i.url, thumbnail: i.url }))

    return (
        <>
            {headerImage.length ? (
                <div
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        zIndex: 2000,
                        backgroundColor: 'white'
                    }}
                >
                    <div
                        className="btn-close close-image-gallery-btn fs-5 opacity-100 position-absolute"
                        style={{ zIndex: 2010, color: '#667085' }}
                        onClick={() => {
                            hideAllImageCardGallery()
                        }}
                    ></div>
                    <div
                        className="image-gallery image-gallery-container"
                        aria-live="polite"
                    >
                        {headerImage.map((i, index) => {
                            return (
                                <div
                                    className="image-gallery-content bottom image-gallery-allcards"
                                    key={index}
                                >
                                    <Image
                                        className="position-absolute w-100 h-100 start-0 mlt-cover"
                                        src={i.url}
                                        alt=""
                                        layout="fill"
                                        sizes="50vw"
                                        onClick={() => {
                                            handleShowSingleImage()
                                            setStartIndex(index)
                                        }}
                                    />
                                </div>
                            )
                        })}
                    </div>
                </div>
            ) : undefined}
            {showSingleImage && (
                <div
                    className="hotel-room-image-card"
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        zIndex: 2000,
                        backgroundColor: '#1D2939'
                    }}
                >
                    <div
                        className="btn-close btn-close-white close-image-gallery-btn fs-5 opacity-100 position-absolute"
                        style={{ zIndex: 2010 }}
                        onClick={() => {
                            hideSingleImage()
                        }}
                    ></div>
                    <HotelCardImagesGallery
                        items={images}
                        infinite={false}
                        showBullets={false}
                        showFullscreenButton={false}
                        showPlayButton={false}
                        showThumbnails={false}
                        showIndex={false}
                        showNav={false}
                        isRTL={false}
                        startIndex={startIndex}
                    />
                </div>
            )}
        </>
    )
}
