import React, { useCallback, useEffect, useState } from 'react'
import { isMobileOnly } from 'react-device-detect'
import HTMLEllipsis from 'react-lines-ellipsis/lib/html'
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC'

export type TruncateProps = {
    className?: string
    html: string
    isOnlySellHotel?: boolean
    isTicketDescription: boolean
    maxNumberOfLines: number
}

export type ReadMoreLessButtonType = {
    isTruncated: boolean
    toggle: () => void
}

export const ReadMoreLessButton = ({
    isTruncated,
    toggle
}: ReadMoreLessButtonType): JSX.Element => {
    return (
        <span
            className={`readMore-showLess mouse-pointer ${isMobileOnly ? 'fs-14' : ''}`}
            id="show_less"
            onClick={toggle}
            role="button"
        >
            {isTruncated ? 'Read More' : 'Read Less'}
        </span>
    )
}

export const TruncationComponent = ({
    className,
    html,
    isOnlySellHotel = false,
    isTicketDescription,
    maxNumberOfLines
}: TruncateProps): JSX.Element => {
    const [isClamped, setIsClamped] = useState<boolean>(Boolean(true))
    const [isTruncated, setIsTruncated] = useState<boolean>(Boolean(true))
    const [showMoreLess, setShowMoreLess] = useState<boolean>(Boolean(true))
    const [textForHeight, setTextForHeight] = useState<number>(Number(0))
    const isNotClampedOrTruncated: boolean =
        isClamped === Boolean(false) && isTruncated === Boolean(true)

    const toggle = useCallback(() => {
        setIsTruncated((prevState) => !prevState)
    }, [])

    // Typed as 'any' to surpass component type error :(
    const ResponsiveEllipsisComponent = isOnlySellHotel
        ? HTMLEllipsis
        : responsiveHOC()(HTMLEllipsis)

    useEffect(() => {
        const containerElement: HTMLElement | null = document.getElementById('truncateParent')
        if (containerElement) {
            const containerHeight: number = containerElement.offsetHeight
            setTextForHeight(containerHeight)
        }
    }, [])

    useEffect(() => {
        if (isNotClampedOrTruncated) {
            setShowMoreLess(Boolean(false))
        } else {
            setShowMoreLess(Boolean(true))
        }
    }, [isClamped, isNotClampedOrTruncated, isTruncated])

    return (
        <>
            <ResponsiveEllipsisComponent
                basedOn="words"
                className={className}
                component="div"
                ellipsisHTML={isTruncated ? `... ` : ''}
                id="truncateParent"
                maxLine={isTruncated ? maxNumberOfLines : textForHeight}
                onReflow={({ clamped }: { clamped: boolean }) => {
                    setIsClamped(clamped)
                    const containerElement = document.getElementById('truncateParent')
                    if (containerElement) {
                        // 16 is the base size of our text at 16px or 1rem
                        const baseSize = isMobileOnly ? 14 : 16
                        const containerHeight = containerElement.offsetHeight / baseSize
                        setTextForHeight(containerHeight)
                    }
                }}
                trimRight
                unsafeHTML={html}
                winWidth={document.body.clientWidth}
            />
            {showMoreLess && !isTicketDescription && (
                <ReadMoreLessButton
                    isTruncated={isTruncated}
                    toggle={toggle}
                />
            )}
        </>
    )
}
