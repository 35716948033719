import { Modal } from 'react-bootstrap'
import Link from 'next/link'
import { faSpinner } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export type NewsletterModalProp = {
    flexCheckDefault: boolean
    firstName: string
    handleFlexCheckDefault: () => void
    handleSubmitBtn: () => void
    hideNewsletterPopUp: () => void
    hideThanksForSubscribingPopUp: () => void
    onChangeCheckBox: (e: any) => void
    onChangeFirstName: (e: any) => void
    onChangePhoneNumber: (e: any) => void
    onChangeZipCode: (e: any) => void
    phoneNumber: string
    showNewsletterPopUp: boolean
    showThanksForSubscribingPopUp: boolean
    phoneNumberError: string
    submitting: boolean
    zipCode: string
    zipCodeTips: string
}

export default function NewsletterModal({
    flexCheckDefault,
    firstName,
    handleFlexCheckDefault,
    handleSubmitBtn,
    hideNewsletterPopUp,
    hideThanksForSubscribingPopUp,
    onChangeCheckBox,
    onChangeFirstName,
    onChangePhoneNumber,
    onChangeZipCode,
    phoneNumber,
    phoneNumberError,
    showNewsletterPopUp,
    showThanksForSubscribingPopUp,
    submitting,
    zipCode,
    zipCodeTips
}: NewsletterModalProp) {
    return (
        <>
            <Modal
                className="modal reseat-modal-v2 newsletter-modal"
                id="Newsletter-Modal"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabindex="-1"
                onHide={hideNewsletterPopUp}
                show={showNewsletterPopUp}
                contentClassName="border-0 rounded-normal"
                dialogClassName="mx-sm-auto"
            >
                <Modal.Header className="border-0 modal-header pb-2">
                    <Modal.Title
                        as={() => {
                            return (
                                <>
                                    <h4 className="modal-title headline-modal fw-bold text-darker mt-3 px-md-3">
                                        Got it, thanks!
                                    </h4>
                                    <span
                                        className="btn-close end-0 me-3 mt-2 p-2 position-absolute"
                                        onClick={hideNewsletterPopUp}
                                    />
                                </>
                            )
                        }}
                    ></Modal.Title>
                </Modal.Header>

                <Modal.Body className="modal-body p-3 mx-md-3 mt-0 py-0 text-start">
                    <p className="text-normal paragrah-multi mb-75">
                        Now that we’re connected, tell us more about you:
                    </p>
                    <form action="">
                        <div className="newsletter-info-wrapper mb-20">
                            <div className="">
                                <label
                                    className="form-label"
                                    htmlFor=""
                                >
                                    First Name
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={firstName}
                                    onChange={onChangeFirstName}
                                />
                            </div>
                            <div>
                                <label
                                    className="form-label"
                                    htmlFor=""
                                >
                                    Postal Code
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={zipCode}
                                    onChange={onChangeZipCode}
                                />
                                {zipCodeTips.length !== 0 && (
                                    <div
                                        id="validationServer03Feedback"
                                        className="invalid-feedback mt-0"
                                        style={{ display: 'block', marginBottom: '.75rem' }}
                                    >
                                        {zipCodeTips}
                                    </div>
                                )}
                            </div>
                            <div>
                                <label
                                    className="form-label"
                                    htmlFor=""
                                >
                                    Phone Number
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={phoneNumber}
                                    onChange={onChangePhoneNumber}
                                />
                                {phoneNumberError !== '' && (
                                    <div
                                        id="validationServer03Feedback"
                                        className="invalid-feedback mt-0"
                                        style={{ display: 'block', marginBottom: '.75rem' }}
                                    >
                                        {phoneNumberError}
                                    </div>
                                )}
                            </div>

                            <div className="reseat-checkbox reseat-checkbox--borders fs-0">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value=""
                                    id="flexCheckDefault"
                                    onClick={handleFlexCheckDefault}
                                    checked={flexCheckDefault}
                                />
                                <label
                                    className="form-check-label"
                                    htmlFor="flexCheckDefault"
                                >
                                    <i
                                        className="check-border"
                                        aria-hidden="true"
                                    ></i>
                                    <span className="paragrah-multi-xs text-gray600">
                                        By signing up, you authorize us to use automated technology
                                        to send messages to the mobile number you provide. Your
                                        consent is not required to purchase something from us.
                                        Message and data rates apply. Up to 8 messages per month.
                                        Reply STOP to cancel or HELP for help.{' '}
                                        <Link href="/privacy-policy">
                                            <a
                                                className="td-n"
                                                target="_blank"
                                            >
                                                Privacy Policy Terms
                                            </a>
                                        </Link>
                                    </span>
                                </label>
                            </div>
                        </div>

                        <div className="interest-list-wrapper">
                            <p className="sub-headline-0">Music & Event Interests</p>
                            <div className="d-flex flex-wrap interest-list">
                                <div className="col-6">
                                    <div className="reseat-checkbox reseat-checkbox--borders fs-0">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value="Alternative"
                                            id="alternativeCheck"
                                            onChange={onChangeCheckBox}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="alternativeCheck"
                                        >
                                            <i
                                                className="check-border"
                                                aria-hidden="true"
                                            ></i>
                                            <span className="paragrah-xl">Alternative</span>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="reseat-checkbox reseat-checkbox--borders fs-0">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value="Metal"
                                            id="metalCheck"
                                            onChange={onChangeCheckBox}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="metalCheck"
                                        >
                                            <i
                                                className="check-border"
                                                aria-hidden="true"
                                            ></i>
                                            <span className="paragrah-xl">Metal</span>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="reseat-checkbox reseat-checkbox--borders fs-0">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value="Country"
                                            id="CountryCheck"
                                            onChange={onChangeCheckBox}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="CountryCheck"
                                        >
                                            <i
                                                className="check-border"
                                                aria-hidden="true"
                                            ></i>
                                            <span className="paragrah-xl">Country</span>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="reseat-checkbox reseat-checkbox--borders fs-0">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value="Pop"
                                            id="PopCheck"
                                            onChange={onChangeCheckBox}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="PopCheck"
                                        >
                                            <i
                                                className="check-border"
                                                aria-hidden="true"
                                            ></i>
                                            <span className="paragrah-xl">Pop</span>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="reseat-checkbox reseat-checkbox--borders fs-0">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value="EDM / Dance"
                                            id="EDMCheck"
                                            onChange={onChangeCheckBox}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="EDMCheck"
                                        >
                                            <i
                                                className="check-border"
                                                aria-hidden="true"
                                            ></i>
                                            <span className="paragrah-xl">EDM / Dance</span>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="reseat-checkbox reseat-checkbox--borders fs-0">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value="R&B"
                                            id="RBCheck"
                                            onChange={onChangeCheckBox}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="RBCheck"
                                        >
                                            <i
                                                className="check-border"
                                                aria-hidden="true"
                                            ></i>
                                            <span className="paragrah-xl">R&B</span>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="reseat-checkbox reseat-checkbox--borders fs-0">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value="Hip-Hop"
                                            id="HipCheck"
                                            onChange={onChangeCheckBox}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="HipCheck"
                                        >
                                            <i
                                                className="check-border"
                                                aria-hidden="true"
                                            ></i>
                                            <span className="paragrah-xl">Hip-Hop</span>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="reseat-checkbox reseat-checkbox--borders fs-0">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value="Reggae"
                                            id="ReggaeCheck"
                                            onChange={onChangeCheckBox}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="ReggaeCheck"
                                        >
                                            <i
                                                className="check-border"
                                                aria-hidden="true"
                                            ></i>
                                            <span className="paragrah-xl">Reggae</span>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="reseat-checkbox reseat-checkbox--borders fs-0">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value="Latin"
                                            id="LatinCheck"
                                            onChange={onChangeCheckBox}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="LatinCheck"
                                        >
                                            <i
                                                className="check-border"
                                                aria-hidden="true"
                                            ></i>
                                            <span className="paragrah-xl">Latin</span>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="reseat-checkbox reseat-checkbox--borders fs-0">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value="Rock"
                                            id="RockCheck"
                                            onChange={onChangeCheckBox}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="RockCheck"
                                        >
                                            <i
                                                className="check-border"
                                                aria-hidden="true"
                                            ></i>
                                            <span className="paragrah-xl">Rock</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer className="modal-footer flex-fill border-0 pt-0 p-3 mx-md-3 mb-md-3 mt-0 justify-content-between">
                    <button
                        aria-label='Submit Button'
                        className="btn btn-block btn-purple m-0"
                        data-bs-toggle="modal"
                        onClick={handleSubmitBtn}
                    >
                        {submitting ? (
                            <FontAwesomeIcon
                                icon={faSpinner}
                                className="fa-spin"
                            />
                        ) : (
                            'Submit'
                        )}
                    </button>
                </Modal.Footer>
            </Modal>

            <Modal
                className="reseat-modal-v2 submitted-modal"
                show={showThanksForSubscribingPopUp}
                contentClassName="border-0 rounded-normal"
                dialogClassName="modal-dialog-centered mx-sm-auto"
                onHide={hideThanksForSubscribingPopUp}
            >
                <Modal.Body className="p-3 m-md-3">
                    <h3 className="headline-modal fw-bold mb-2 mt-1 mt-md-0">
                        Thanks for Signing Up!
                    </h3>
                    <p className="paragrah-multi">Watch your inbox for upcoming news.</p>
                    <button
                        className="btn btn-block btn-purple m-0 w-100"
                        onClick={hideThanksForSubscribingPopUp}
                    >
                        Close
                    </button>
                </Modal.Body>
            </Modal>
        </>
    )
}
