// Keep this file alphabetized and organized
export const errorSignatures = [
    '(unknown)',
    '(unknown): Script error.',
    // 'Cannot read property of undefined/null expression',
    // "Can't find variable",
    // 'Item sent with null or missing arguments.',
    'Load failed',
    'Script error',
    'SyntaxError:',
    'TypeError:',
    'TypeError',
    'Uncaught RangeError:',
    'Uncaught SyntaxError:'
]
