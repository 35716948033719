import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react'
import { EventJSON } from '../../../events-service/src/models/event'
import { TourEventJSON, TourJSON } from '../../../events-service/src/models/tour'
import { ApolloError } from '@apollo/client'
import { ACTIVE_EVENTS_AND_TOURS } from 'graphQL/home/getToursAndEventsQuery.graphql'
import { eventsWithoutJwtClient } from 'apollo-client'
import { cloneDeep } from '@apollo/client/utilities'
import { getEarliestDate } from 'util/dates'
import dayjs from 'dayjs'

export type SettingsType = {
    headerImage: string
    headline: string
    meta: {
        title: string
    }
}

export type SnackbarErrorType = {
    error: ApolloError | undefined
    message: string
}

export type HomeReturnType = {
    eventId: string
    events: EventJSON[]
    handleScrollPosition: () => void
    getData: () => Promise<void>
    isLoadingEventsAndTours: boolean
    isLoadingMore: boolean
    isPageLoading: boolean
    isSnackbarOpen: boolean
    setEventId: Dispatch<SetStateAction<string>>
    setIsLoadingEventsAndTours: Dispatch<SetStateAction<boolean>>
    setIsPageLoading: Dispatch<SetStateAction<boolean>>
    setIsSnackbarOpen: Dispatch<SetStateAction<boolean>>
    setSnackbarError: Dispatch<SetStateAction<SnackbarErrorType>>
    setTourId: Dispatch<SetStateAction<string>>
    snackbarError: SnackbarErrorType
    tourId: string
    tours: TourJSON[]
}

export const useHome = (
    offset?: number,
    setOffset?: Dispatch<SetStateAction<number>>
): HomeReturnType => {
    const [snackbarError, setSnackbarError] = useState<{
        error: ApolloError | undefined
        message: string
    }>({
        error: undefined,
        message: ''
    })
    const [eventId, setEventId] = useState<string>('')
    const [tourId, setTourId] = useState<string>('')
    const [events, setEvents] = useState<EventJSON[]>([])
    const [isPageLoading, setIsPageLoading] = useState<boolean>(true)
    const [isLoadingEventsAndTours, setIsLoadingEventsAndTours] = useState<boolean>(true)
    const [isLoadingMore, setIsLoadingMore] = useState<boolean>(true)
    const [isSnackbarOpen, setIsSnackbarOpen] = useState<boolean>(false)
    const [tours, setTours] = useState<TourJSON[]>([])
    const count = useRef<number>(0)
    const loadingEventsLock = useRef<boolean>(false)
    const prevScrollY = useRef<number>(0)
    const total = useRef<number>(0)

    const getData = useCallback(async () => {
        try {
            const { data } = await eventsWithoutJwtClient.query({
                query: ACTIVE_EVENTS_AND_TOURS,
                fetchPolicy: 'no-cache',
                variables: {
                    desc: 'ASC',
                    limit: 15,
                    offset,
                    orderBy: 'startdate,name'
                }
            })

            const fetchedData = data.listActiveToursAndEvents
            let newTours = fetchedData.data.tours as TourJSON[]
            let newEvents = fetchedData.data.events as EventJSON[]
            count.current += fetchedData.count
            total.current = fetchedData.total

            const sortTours = (tours: TourJSON[]) => {
                const clonedTours = cloneDeep(tours)

                const toursToSort = clonedTours.sort((a, b) => {
                    const upcomingDatesA = (a.events as TourEventJSON[])
                        .filter((event) => dayjs(event.realEventEndDate) >= dayjs())
                        .map((event) => dayjs(event.realEventStartDate))
                    const earliestDateA = getEarliestDate(upcomingDatesA)

                    const upcomingDatesB = (b.events as TourEventJSON[])
                        .filter((event) => dayjs(event.realEventEndDate) >= dayjs())
                        .map((event) => dayjs(event.realEventStartDate))
                    const earliestDateB = getEarliestDate(upcomingDatesB)

                    return earliestDateA - earliestDateB
                })

                return toursToSort
            }

            setTours((oldTours) => {
                const mergedTours = [...oldTours, ...sortTours(newTours)]
                const uniqueTours = mergedTours.filter(
                    (tour, index, self) => index === self.findIndex((t) => t.id === tour.id)
                )
                return uniqueTours
            })

            setEvents((oldEvents) => {
                const mergedEvents = [...oldEvents, ...newEvents]
                const uniqueEvents = mergedEvents.filter(
                    (event, index, self) => index === self.findIndex((e) => e.id === event.id)
                )
                return uniqueEvents
            })
            setIsLoadingEventsAndTours(false)
            setIsLoadingMore(false)
            setIsPageLoading(false)
            loadingEventsLock.current = false
        } catch (error: any) {
            console.error('Error getting or setting homepage data', error)
            setSnackbarError({ error: error, message: 'Error fetching ACTIVE_EVENTS_AND_TOURS' })
        }
    }, [offset])

    const handleScrollPosition = useCallback(() => {
        const browserViewHeight = document.documentElement.clientHeight
        const lastEventRect = document
            .querySelector('#events-container > div > div > :last-child')
            ?.getBoundingClientRect()
        const position = window.scrollY
        if (
            position > 0 &&
            position > prevScrollY.current &&
            lastEventRect &&
            browserViewHeight - (lastEventRect?.bottom || 0) >= 0 &&
            !loadingEventsLock.current &&
            count.current < total.current
        ) {
            setIsLoadingMore(true)
            setOffset && setOffset((prevOffset: number) => prevOffset + 10)
            loadingEventsLock.current = true
        }
        prevScrollY.current = position
    }, [setOffset])

    useEffect(() => {
        if (snackbarError.error) {
            console.error('There is an error in the useHome hook', snackbarError.message)
            setIsSnackbarOpen(true)
        }
    }, [snackbarError])

    useEffect(() => {
        window.addEventListener('scroll', handleScrollPosition, { passive: true })
        return () => {
            window.removeEventListener('scroll', handleScrollPosition)
        }
    }, [handleScrollPosition])

    return {
        eventId,
        events,
        getData,
        handleScrollPosition,
        isLoadingEventsAndTours,
        isLoadingMore,
        isPageLoading: isPageLoading,
        isSnackbarOpen,
        setEventId,
        setIsLoadingEventsAndTours,
        setIsPageLoading,
        setIsSnackbarOpen,
        snackbarError,
        setSnackbarError,
        setTourId,
        tourId,
        tours
    }
}
