import { FC } from 'react'
import { COLOR_BLACK } from 'styles/theme'

type Props = {
    fill?: string
    height?: string
    width?: string
}

export const TikTokIcon: FC<Props> = ({ fill = COLOR_BLACK }: Props): JSX.Element => (
    <svg
        width="100%"
        height="100%"
        viewBox="0 0 21 24"
        fill={fill}
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M15.0725 0H11.0278V16.3478C11.0278 18.2957 9.47219 19.8957 7.53626 19.8957C5.60034 19.8957 4.04469 18.2957 4.04469 16.3478C4.04469 14.4348 5.56577 12.8695 7.43257 12.8V8.69567C3.31872 8.7652 0 12.1391 0 16.3478C0 20.5913 3.38786 24 7.57085 24C11.7538 24 15.1416 20.5565 15.1416 16.3478V7.9652C16.6627 9.07827 18.5295 9.73913 20.5 9.77393V5.66957C17.4579 5.56522 15.0725 3.06087 15.0725 0Z"
            fill="#E4E7EC"
        />
    </svg>
)
