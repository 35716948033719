import { useCallback, useEffect, useRef, useState } from 'react'
import { gql } from '@apollo/client'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faApple, faFacebookSquare } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useRouter } from 'next/router'
import Script from 'next/script'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { usersClient } from '../apollo-client'
import { NavbarState, OrderState } from '../store/index'
import { sendMultiStateCustomEventToMparticle } from '../util/handlemParticle'
import getConfig from 'next/config'
import { get } from '../store/actions/fetchOrders'
import { GoogleOAuthProvider, GoogleLogin, CredentialResponse } from '@react-oauth/google'
import { SignInConfirmedComponent } from './SignInConfirmed'
import { useUser, useWindowWidth } from 'hooks'
import { useStyles } from './SignInConfirmed/SignIn.styles'
import { googleAnalyticsEvent, GoogleAnalyticsEventActionType } from './Google/GoogleAnalytics'
import { parseJwt } from 'util/parse'
import TextFieldComponent from './TextField/TextFieldComponent'
import { ButtonComponent } from './Button'
import { RESEND_CONFIRM_EMAIL } from 'graphQL/user/userResendConfirmEmail.graphql'
import { LogoColorType, LogoComponent } from 'components/Logo/LogoComponent'

const { publicRuntimeConfig, serverRuntimeConfig } = getConfig()
const faFacebookSquareSvg = faFacebookSquare as IconProp
const faAppleSvg = faApple as IconProp

const SIGN_IN = gql`
    query login($email: String!, $password: String!) {
        login(email: $email, password: $password) {
            token
            refresh
        }
    }
`

const OAUTH = gql`
    query Oauth(
        $token: String!
        $source: String!
        $profileImage: String
        $lastName: String
        $firstName: String
        $name: String
    ) {
        oauth(
            token: $token
            source: $source
            profileImage: $profileImage
            lastName: $lastName
            firstName: $firstName
            name: $name
        ) {
            token
            refresh
        }
    }
`

function SignIn() {
    const router = useRouter()
    const { handleJWT, isSuccessful, setIsSuccessful, handleAppleLogin } = useUser()
    const [email, setEmail] = useState('')
    const emailReg = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9_-]+)+$/
    const [emailError, setEmailError] = useState<string>('')
    const [password, setPassword] = useState('')
    const [passwordError, setPasswordError] = useState<string>('')
    const [serverError, setServerError] = useState<string>('')
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const dispatch = useDispatch()
    const navState: NavbarState = useSelector((state: RootStateOrAny) => state.navbarReducer)
    const [facebookLoginError, setFacebookLoginError] = useState<string>('')
    const isSignInLoading: boolean = useSelector(
        (state: RootStateOrAny) => state.pageLoadingReducer.loading
    )
    const order: OrderState = useSelector((state: RootStateOrAny) => state.orderReducer)
    const fbRef = useRef(null)
    const [googleWidth, setGoogleWidth] = useState<number>(0)
    const isNotLoadingOrSuccessful = !isLoading && !isSuccessful
    const { classes } = useStyles()
    const [shouldShowSignUpSuccessMessage, setShouldShowSignUpSuccessMessage] = useState<boolean>(false)
    const [shouldShowVerifyEmailMessage, setShouldShowVerifyEmailMessage] = useState<boolean>(false)
    const { isMobileWidth, windowWidth } = useWindowWidth()
    const [unVerifyEmail, setUnVerifyEmail] = useState<string>('')
    const signInRef = useRef(null)
    
    useEffect(() => {
        if (windowWidth) {
            if (windowWidth >= 768 && navState.expandedNav) {
                dispatch({ type: 'navbar/updateState', payload: { expandedNav: false } })
            } else if (windowWidth < 768 && !navState.expandedNav) {
                dispatch({ type: 'navbar/updateState', payload: { expandedNav: true } })
            }
        }
    }, [windowWidth])

    useEffect(() => {
        if (signInRef.current) {
            (signInRef.current as HTMLElement).style.pointerEvents = 'none';
            const timer = setTimeout(() => {
                if (signInRef.current) {
                    (signInRef.current as HTMLElement).style.pointerEvents = 'auto';
                }
            }, 300);
            return () => clearTimeout(timer);
        }
    }, []);

    useEffect(() => {
        const handleResize = () => {
            if (fbRef.current) {
                const fbRefWidth = (fbRef.current as HTMLElement).offsetWidth
                setGoogleWidth(fbRefWidth)
            }
        };
        const resizeObserver = new ResizeObserver(handleResize);
        if (fbRef.current) {
            resizeObserver.observe(fbRef.current)
            handleResize()
        }
        return () => {
            if (fbRef.current) {
                resizeObserver.unobserve(fbRef.current);
            }
        };
    }, [fbRef])

    useEffect(() => {
        if (navState.signUpSuccessEmail) {
            setShouldShowSignUpSuccessMessage(true)
        }
    }, [])

    const closeSignInModal = useCallback(() => {
        if (navState.expandedNav) {
            dispatch({ type: 'navbar/setState', payload: { expandedNav: false, signIn: false } })
        } else {
            dispatch({ type: 'navbar/setState', payload: { signIn: false } })
        }
    }, [dispatch, navState.expandedNav])

    const loginRedirect = useCallback(() => {
        const { login_redirect } = router.query
        if (login_redirect) {
            router.push(login_redirect.toString())
        }
    }, [router])

    const handleGoogleLogin = async (googleData: CredentialResponse | any) => {
        sendMultiStateCustomEventToMparticle(googleData, 'Sign In', 'Attempted')
        dispatch({ type: 'pageLoading/setState', payload: { loading: true } })
        if (!googleData.credential) {
            // add log to check why this hit in private mode
            console.error(googleData)
            sendMultiStateCustomEventToMparticle(
                googleData + { error_message: '' },
                'Sign In',
                'Failed'
            )
            dispatch({ type: 'pageLoading/setState', payload: { loading: false } })
        } else {
            const { memberID } = router.query

            if (router.pathname === '/invite/submit') {
                const googleUserEmail = parseJwt(googleData.credential).email
                const member = order.members.find((m) => m.ID === memberID)
                if (member?.Email.toLowerCase() !== googleUserEmail.toLowerCase()) {
                    setEmailError('Please enter the correct email address.')
                    sendMultiStateCustomEventToMparticle(
                        googleData +
                            {
                                error_message:
                                    "Invited member email doesn't match Google User Email"
                            },
                        'Sign In',
                        'Failed'
                    )
                    dispatch({ type: 'pageLoading/setState', payload: { loading: false } })
                    return
                }
            } else if (router.asPath.indexOf('/?login_redirect=/orders') !== -1) {
                let orderUrl: string =
                    (typeof window === 'undefined'
                        ? serverRuntimeConfig.NEXT_PUBLIC_BACKEND_ORDERS_URL!
                        : publicRuntimeConfig.NEXT_PUBLIC_ORDERS_URL!) || ''
                const routerQuery = router.query
                const loginRedirectList = routerQuery['login_redirect']?.toString().split('/')
                if (loginRedirectList) {
                    let orderData = await get<OrderState>(
                        orderUrl,
                        `/order/${loginRedirectList[loginRedirectList.length - 1]}`
                    )
                    if (
                        orderData.members.filter(
                            (i) => i.Email.toLowerCase() === email.toLowerCase()
                        ).length <= 0
                    ) {
                        setEmailError('Please enter the correct email address.')
                        dispatch({ type: 'pageLoading/setState', payload: { loading: false } })
                        return
                    }
                }
            }
            const r = await usersClient.query<{ oauth: { token: string; refresh: string } }>({
                fetchPolicy: 'no-cache',
                query: OAUTH,
                variables: {
                    token: googleData.credential,
                    source: 'google'
                }
            })
            handleJWT(r.data.oauth)
            sendMultiStateCustomEventToMparticle(googleData, 'Sign In', 'Succeeded')
            setIsSuccessful(true)
        }
    }

    const handleFacebookLogin = async (FB: any) => {
        let data = {}
        const facebookAuthType = window.localStorage.getItem('facebookAuthType') || undefined
        if (facebookAuthType) {
            data = { scope: 'email,', auth_type: facebookAuthType }
        } else {
            data = { scope: 'email,' }
        }
        FB.login(function (res: any) {
            sendMultiStateCustomEventToMparticle({ source: 'Facebook' }, 'Sign In', 'Attempted')
            if (res.authResponse) {
                FB.api(
                    '/me',
                    'GET',
                    { fields: 'email,picture' },
                    async (data: { accessToken: string; email: string; picture: any }) => {
                        if (data.email) {
                            window.localStorage.removeItem('facebookAuthType')
                            const { memberID } = router.query
                            if (router.pathname === '/invite/submit') {
                                const member = order.members.find((m) => m.ID === memberID)
                                if (member?.Email.toLowerCase() !== data.email.toLowerCase()) {
                                    setEmailError('Please enter the correct email address.')
                                    sendMultiStateCustomEventToMparticle(
                                        {
                                            source: 'Facebook',
                                            error_message:
                                                "Invited member email doesn't match Facebook User Email"
                                        },
                                        'Sign In',
                                        'Failed'
                                    )
                                    dispatch({
                                        type: 'pageLoading/setState',
                                        payload: { loading: false }
                                    })
                                    return
                                }
                            } else if (router.asPath.indexOf('/?login_redirect=/orders') !== -1) {
                                let orderUrl: string =
                                    (typeof window === 'undefined'
                                        ? serverRuntimeConfig.NEXT_PUBLIC_BACKEND_ORDERS_URL!
                                        : publicRuntimeConfig.NEXT_PUBLIC_ORDERS_URL!) || ''
                                const routerQuery = router.query
                                const loginRedirectList = routerQuery['login_redirect']
                                    ?.toString()
                                    .split('/')
                                if (loginRedirectList) {
                                    let orderData = await get<OrderState>(
                                        orderUrl,
                                        `/order/${loginRedirectList[loginRedirectList.length - 1]}`
                                    )
                                    if (
                                        orderData.members.filter(
                                            (i) => i.Email.toLowerCase() === email.toLowerCase()
                                        ).length <= 0
                                    ) {
                                        setEmailError('Please enter the correct email address.')
                                        dispatch({
                                            type: 'pageLoading/setState',
                                            payload: { loading: false }
                                        })
                                        return
                                    }
                                }
                            }
                            try {
                                const response = await fetch(data.picture?.data?.url || '')
                                const blobResponse = await response.blob()
                                const reader = new FileReader()
                                reader.readAsDataURL(blobResponse)
                                reader.onload = async function (e) {
                                    const base64Img = e!.target!.result
                                    let isBase64 = false
                                    if (
                                        typeof base64Img == 'string' &&
                                        base64Img.indexOf('base64,') !== -1
                                    ) {
                                        isBase64 = true
                                    }
                                    const r = await usersClient.query<{
                                        oauth: { token: string; refresh: string }
                                    }>({
                                        fetchPolicy: 'no-cache',
                                        query: OAUTH,
                                        variables: {
                                            token: res.authResponse.accessToken,
                                            source: 'facebook',
                                            profileImage: isBase64 ? base64Img : ''
                                        }
                                    })
                                    handleJWT(r.data.oauth)
                                    sendMultiStateCustomEventToMparticle(
                                        r.data.oauth,
                                        'Sign In',
                                        'Succeeded'
                                    )
                                    setIsSuccessful(true)
                                }
                            } catch (error) {
                                sendMultiStateCustomEventToMparticle(
                                    { source: 'Facebook', error_message: error },
                                    'Sign In',
                                    'Failed'
                                )
                                console.log(error)
                                dispatch({
                                    type: 'pageLoading/setState',
                                    payload: { loading: false }
                                })
                            }
                        } else {
                            console.log('setItem rerequest...')
                            window.localStorage.setItem('facebookAuthType', 'rerequest')
                            dispatch({ type: 'pageLoading/setState', payload: { loading: false } })
                            if (res?.status === 'connected') {
                                sendMultiStateCustomEventToMparticle(
                                    {
                                        source: 'Facebook',
                                        error_message:
                                            'You can not login with Facebook without sharing your email information.'
                                    },
                                    'Sign In',
                                    'Failed'
                                )
                                setFacebookLoginError(
                                    'You can not login with Facebook without sharing your email information.'
                                )
                            }
                        }
                    }
                )
            } else {
                // Close the authorization window
                dispatch({ type: 'pageLoading/setState', payload: { loading: false } })
            }
        }, data)
    }

    const handleEmailLogin = async () => {
        setShouldShowSignUpSuccessMessage(false)
        setShouldShowVerifyEmailMessage(false)
        const emailIsValid = emailReg.test(email)
        const passwordIsExist = password.trim()
        sendMultiStateCustomEventToMparticle({ source: 'Email' }, 'Sign In', 'Attempted')
        setServerError('')
        setIsLoading(true)
        if (!emailIsValid) {
            setEmailError('Please enter a valid email address.')
            sendMultiStateCustomEventToMparticle(
                { source: 'Email', error_message: 'Please enter a valid email address.' },
                'Sign In',
                'Failed'
            )
            setIsLoading(false)
        } else {
            setEmailError('')
        }

        if (!passwordIsExist) {
            setPasswordError('Please enter a valid password.')
            sendMultiStateCustomEventToMparticle(
                { source: 'Email', error_message: 'Please enter a valid password.' },
                'Sign In',
                'Failed'
            )
            setIsLoading(false)
        } else {
            setPasswordError('')
        }

        if (!emailIsValid || !passwordIsExist) {
            return
        }

        if (router.pathname === '/invite/submit') {
            const { memberID } = router.query
            const member = order.members.find((m) => m.ID === memberID)
            if (member?.Email.toLowerCase() !== email.toLowerCase()) {
                setEmailError('Please enter the correct email address.')
                sendMultiStateCustomEventToMparticle(
                    {
                        source: 'Email',
                        error_message: "Invited member email doesn't match entered email"
                    },
                    'Sign In',
                    'Failed'
                )
                setIsLoading(false)
                return
            }
        } else if (router.asPath.indexOf('/?login_redirect=/orders') !== -1) {
            let orderUrl: string =
                (typeof window === 'undefined'
                    ? serverRuntimeConfig.NEXT_PUBLIC_BACKEND_ORDERS_URL!
                    : publicRuntimeConfig.NEXT_PUBLIC_ORDERS_URL!) || ''
            const routerQuery = router.query
            const loginRedirectList = routerQuery['login_redirect']?.toString().split('/')
            if (loginRedirectList) {
                let orderData = await get<OrderState>(
                    orderUrl,
                    `/order/${loginRedirectList[loginRedirectList.length - 1]}`
                )
                if (
                    orderData.members.filter((i) => i.Email.toLowerCase() === email.toLowerCase())
                        .length <= 0
                ) {
                    setEmailError('Please enter the correct email address.')
                    setIsLoading(false)
                    return
                }
            }
        }

        try {
            const response = await usersClient.query<{ login: { token: string; refresh: string } }>(
                {
                    fetchPolicy: 'no-cache',
                    query: SIGN_IN,
                    variables: {
                        email,
                        password
                    }
                }
            )
            handleJWT(response.data.login)
            sendMultiStateCustomEventToMparticle({ source: 'Email' }, 'Sign In', 'Succeeded')
            setIsSuccessful(true)
        } catch (error: any) {
            if (error.graphQLErrors.length) {
                if (error.graphQLErrors[0].message.includes('This email address has not yet been verified')) {
                    setUnVerifyEmail(email)
                    setShouldShowVerifyEmailMessage(true)
                    await usersClient.mutate({
                        fetchPolicy: 'no-cache',
                        mutation: RESEND_CONFIRM_EMAIL,
                        variables: { email: email }
                    })
                }
                else if (error.graphQLErrors[0].message.includes('email')) {
                    setEmailError(error.graphQLErrors[0].message)
                } else {
                    setServerError(error.graphQLErrors[0].message)
                }
                sendMultiStateCustomEventToMparticle(
                    { source: 'Email', error_message: error.graphQLErrors[0].message },
                    'Sign In',
                    'Failed'
                )
                setIsLoading(false)
            } else {
                sendMultiStateCustomEventToMparticle(
                    { source: 'Email', error_message: 'Internal Server Error' },
                    'Sign In',
                    'Failed'
                )
                setServerError('Internal Server Error')
                setIsLoading(false)
            }
        }
    }

    useEffect(() => {
        if (isSuccessful) {
            const timeoutId = setTimeout(() => {
                dispatch({ type: 'pageLoading/setState', payload: { loading: false } })
                closeSignInModal()
                loginRedirect()
            }, 500)

            return () => clearTimeout(timeoutId)
        }
    }, [isSuccessful])

    useEffect(() => {
        googleAnalyticsEvent({
            action: GoogleAnalyticsEventActionType.PAGE_VIEW,
            category: 'Sign In',
            label: window.location.href.split('?')[0],
            pageTitle: 'Sign In'
        })
    }, [])

    const renderTipMessage = () => {
        if (!shouldShowSignUpSuccessMessage && !shouldShowVerifyEmailMessage) {
            return null
        }
        let message = ''
        if (shouldShowSignUpSuccessMessage) {
            message = `A verification email has been sent to ${navState.signUpSuccessEmail}. Please verify your email first then log in.`
        } else {
            message = `Your email must be verified before logging in. A verification email has been resent to ${unVerifyEmail}. Please click on the link in the email to complete verification.`
        }
        return (
            <div className={classes.tipText}>
                {message}
            </div>
        )
    }

    return (
        <>
            {!isSuccessful && (
                <div className="login-wrapper p-3" ref={signInRef}>
                    <div className={classes.googleLoginButton}>
                        <GoogleOAuthProvider
                            clientId={publicRuntimeConfig.NEXT_PUBLIC_GOOGLE_OAUTH_CLIENT_ID!}
                        >
                            <GoogleLogin
                                width={googleWidth}
                                locale="en-US"
                                text="continue_with"
                                shape="square"
                                size="large"
                                theme="outline"
                                onSuccess={handleGoogleLogin}
                                onError={() => {
                                    console.error('Login Failed')
                                    dispatch({
                                        type: 'pageLoading/setState',
                                        payload: { loading: false }
                                    })
                                }}
                                auto_select={false}
                            />
                        </GoogleOAuthProvider>
                    </div>
                    <Script
                        crossOrigin="anonymous"
                        src="https://connect.facebook.net/en_US/sdk.js"
                        onLoad={() => {
                            ;(window as any).fbAsyncInit = function () {
                                const FB = (window as any).FB
                                FB.init({
                                    appId: 4826926720709866,
                                    cookie: false,
                                    version: 'v14.0'
                                })
                            }
                        }}
                    ></Script>
                    <div
                        className={classes.facebookLoginButton}
                        ref={fbRef}
                        onClick={async () => {
                            const FB = (window as any).FB
                            if (isSignInLoading || !FB) return
                            setFacebookLoginError('')
                            dispatch({ type: 'pageLoading/setState', payload: { loading: true } })
                            handleFacebookLogin(FB)
                        }}
                    >
                        <div className="align-items-center d-flex">
                            <FontAwesomeIcon
                                icon={faFacebookSquareSvg}
                                size="xl"
                                className="text-facebook-blue"
                                width={18}
                                height={18}
                                style={{ marginLeft: '-2px' }}
                            />
                            <span className={classes.thirdPartyLoginText}>Continue with Facebook</span>
                        </div>
                    </div>

                    <Script
                        src="https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js"
                        onLoad={() => {
                            const params = {
                                clientId: 'com.vibee.login',
                                usePopup: true,
                                scope: 'name email',
                                state: window.location.href,
                                redirectURI:
                                    window.location.protocol +
                                    '//' +
                                    window.location.hostname +
                                    '/api/login'
                                // hard code first, need check apple config
                            }
                            ;(window as any).AppleID.auth.init(params)
                        }}
                    />
                    <div
                        className={classes.appleLoginButton}
                        onClick={async () => {
                            const AppleID = (window as any).AppleID
                            if (isSignInLoading || !AppleID) return
                            dispatch({ type: 'pageLoading/setState', payload: { loading: true } })
                            try {
                                sendMultiStateCustomEventToMparticle(
                                    { source: 'Apple' },
                                    'Sign In',
                                    'Attempted'
                                )
                                const data = await AppleID.auth.signIn()
                                // Apple only returns the user object the first time the user authorizes the app.
                                await handleAppleLogin(
                                    data.authorization.id_token,
                                    data?.user?.firstName ?? '',
                                    data?.user?.lastName ?? ''
                                )
                                sendMultiStateCustomEventToMparticle(
                                    { source: 'Apple' },
                                    'Sign In',
                                    'Succeeded'
                                )
                            } catch (e) {
                                sendMultiStateCustomEventToMparticle(
                                    { source: 'Apple', error_message: e },
                                    'Sign In',
                                    'Failed'
                                )
                                dispatch({
                                    type: 'pageLoading/setState',
                                    payload: { loading: false }
                                })
                            }
                        }}
                    >
                        <div className="align-items-center d-flex">
                            <FontAwesomeIcon
                                icon={faAppleSvg}
                                className="text-dark"
                                size="xl"
                                width={18}
                                height={18}
                                style={{ marginLeft: '-2px' }}
                            />
                            <span className={classes.thirdPartyLoginText}>Continue with Apple</span>
                        </div>
                    </div>
                    <span className={`divider-or ${classes.signInDividerOr}`}></span>
                    {renderTipMessage()}
                    <form
                        className="LoginForm"
                        id="registrationForm"
                        noValidate
                        autoComplete="off"
                        autoCapitalize="off"
                        onSubmit={(event) => {
                            event.preventDefault()
                        }}
                    >
                        <div className={classes.inputWrapper}>
                            <TextFieldComponent
                                errorMsg={emailError}
                                label="Email"
                                onChange={(event) => setEmail(event.target.value.trim())}
                                value={email}
                            />
                        </div>
                        <div className={classes.inputWrapper}>
                            <TextFieldComponent
                                errorMsg={
                                    passwordError || serverError || facebookLoginError
                                }
                                label="Password"
                                onChange={(event) => setPassword(event.target.value.trim())}
                                type="password"
                                value={password}
                            />
                        </div>
                        <ButtonComponent
                            circularProgressSize={24}
                            className={classes.logInButton}
                            isLoading={isLoading}
                            onClick={() => {
                                if (isLoading) {
                                    return
                                }
                                handleEmailLogin()
                            }}
                            title="Sign In"
                            type="submit"
                        />
                    </form>
                    <p className={classes.forgotPasswordButton}>
                        <span
                            className={classes.tipLink}
                            onClick={() => {
                                if (isLoading) {
                                    return
                                }
                                if (navState.expandedNav) {
                                    dispatch({
                                        type: 'navbar/setState',
                                        payload: { expandedNav: true, forgotPassword: true }
                                    })
                                } else {
                                    dispatch({
                                        type: 'navbar/setState',
                                        payload: { forgotPassword: true }
                                    })
                                }
                            }}
                        >
                            Forgot Password
                        </span>
                    </p>
                    <p className={classes.signInTipButton}>
                        Don’t have an account?{' '}
                        <span
                            className={classes.tipLink}
                            onClick={() => {
                                if (isLoading) {
                                    return
                                }
                                if (navState.expandedNav) {
                                    dispatch({
                                        type: 'navbar/setState',
                                        payload: { expandedNav: true, signUp: true }
                                    })
                                } else {
                                    dispatch({
                                        type: 'navbar/setState',
                                        payload: { signUp: true }
                                    })
                                }
                            }}
                        >
                            Sign Up
                        </span>
                    </p>
                    {isMobileWidth && (
                        <div className={classes.logo}>
                            <LogoComponent
                                color={LogoColorType.BLACK}
                                height='31px'
                                width='80px'
                            />
                        </div>
                    )}
                </div>
            )}
            {isSuccessful ? (
                <SignInConfirmedComponent
                    isLoading={isLoading}
                    isSuccessful={isSuccessful}
                />
            ) : null}
        </>
    )
}

export default SignIn
