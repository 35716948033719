import Box from '@mui/material/Box'
import { useWindowWidth } from 'hooks'
import { Dispatch, SetStateAction } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import { ModalComponent } from 'components/Modal'
import { ButtonComponent } from 'components/Button'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import {
    COLOR_GRAY_500,
    COLOR_GRAY_800,
    COLOR_WHITE,
    FONT_SIZE_14,
    FONT_SIZE_18,
    FONT_WEIGHT_400,
    FONT_WEIGHT_600,
    SIZE_8,
    SIZE_16,
    SIZE_20,
    SIZE_24,
    SIZE_28,
    SIZE_32,
    FULL_WIDTH
} from 'styles/theme'

type EventNotIncludeTicketModalPropType = {
    addToLocalCartInfo: (ticketId: string) => void
    isReserving: boolean
    setIsReserving: Dispatch<SetStateAction<boolean>>
    setSubmitting: Dispatch<SetStateAction<boolean>>
    setShowEventNotIncludeTicketModal: Dispatch<SetStateAction<boolean>>
    show: boolean
    submitTicketId: string
}

export const EventNotIncludeTicketModal = ({
    addToLocalCartInfo,
    isReserving,
    setIsReserving,
    setSubmitting,
    setShowEventNotIncludeTicketModal,
    show,
    submitTicketId
}: EventNotIncludeTicketModalPropType) => {
    const { isMobileWidth } = useWindowWidth()

    return (
        <ModalComponent
            open={show}
            sx={{
                borderRadius: SIZE_16,
                margin: 'auto !important'
            }}
            style={{ zIndex: 1000 }}
        >
            <DialogTitle
                sx={{
                    display: 'flex',
                    fleShrink: 0,
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: `${SIZE_16} ${SIZE_16} ${SIZE_8}`
                }}
            >
                <Box
                    sx={{
                        fontSize: FONT_SIZE_18,
                        lineHeight: SIZE_28,
                        fontWeight: FONT_WEIGHT_600,
                        color: COLOR_GRAY_800,
                        padding: `0px ${isMobileWidth ? SIZE_8 : SIZE_16}`,
                        marginTop: SIZE_16
                    }}
                >
                    Event tickets not included
                </Box>
                <IconButton
                    aria-label="close"
                    onClick={() => {
                        setSubmitting(false)
                        setShowEventNotIncludeTicketModal(false)
                    }}
                    sx={{
                        position: 'absolute',
                        right: SIZE_32,
                        top: SIZE_32,
                        width: SIZE_24,
                        height: SIZE_24,
                        padding: SIZE_8,
                        ':hover': {
                            backgroundColor: COLOR_WHITE
                        }
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent
                sx={{
                    position: 'relative',
                    textAlign: 'left',
                    flex: '1 1 auto',
                    padding: `0px ${SIZE_16}`,
                    margin: `0 ${isMobileWidth ? SIZE_8 : SIZE_16} ${
                        isMobileWidth ? SIZE_24 : SIZE_28
                    }`
                }}
            >
                <Box
                    sx={{
                        maxWidth: '322px',
                        fontSize: FONT_SIZE_14,
                        fontWeight: FONT_WEIGHT_400,
                        lineHeight: SIZE_20,
                        color: COLOR_GRAY_500
                    }}
                >
                    The package you&apos;ve selected does not include event tickets.
                </Box>
            </DialogContent>
            <DialogActions sx={{ padding: `0 ${isMobileWidth ? SIZE_24 : SIZE_32} ${SIZE_32}` }}>
                <ButtonComponent
                    circularProgressSize={24}
                    isLoading={isReserving}
                    onClick={() => {
                        addToLocalCartInfo(submitTicketId)
                        setIsReserving(true)
                    }}
                    style={{
                        color: COLOR_WHITE,
                        textTransform: 'none !important',
                        width: FULL_WIDTH
                    }}
                    title="Continue to Reserve"
                />
            </DialogActions>
        </ModalComponent>
    )
}
