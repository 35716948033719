import { gql } from '@apollo/client'

export const REGISTER_QUERY = gql`
    mutation Register(
        $email: String!
        $password: String!
        $firstName: String
        $lastName: String
        $name: String!
        $phone: String!
        $isActive: Boolean!
        $isManualRegistration: Boolean!
    ) {
        register(
            email: $email
            password: $password
            firstName: $firstName
            lastName: $lastName
            name: $name
            phone: $phone
            isActive: $isActive
            isManualRegistration: $isManualRegistration
        ) {
            token
            refresh
        }
    }
`