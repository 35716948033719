import router from 'next/router';
import {isMobile} from 'react-device-detect';

export default function EventFeaturesAndBenefitsSection({ featuresAndBenefits }: { featuresAndBenefits: string }) {

  return (
    <div className={`row gx-0 gx-md-4-2`}>
      <div className="col-sm-12 gx-4-2 gx-md-0">
        <div className="row gx-0 gx-md-3 event-info-wrapper">
          <div className="event-info border-0 rounded-0">
            <div
              className="card-text event-sub-intro mb-0"
              dangerouslySetInnerHTML={{ __html: featuresAndBenefits }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
