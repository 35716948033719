import { Box } from '@mui/material'
import { isMobileOnly } from 'react-device-detect'
import {
    COLOR_PRIMARY_25,
    COLOR_PRIMARY_700,
    FONT_SIZE_12,
    FONT_SIZE_14,
    FONT_WEIGHT_400,
    FONT_WEIGHT_500,
    FONT_WEIGHT_600,
    FULL_WIDTH,
    SIZE_12,
    SIZE_18,
    SIZE_20,
    SIZE_6
} from 'styles/theme'

export const EventNotIncludeTicketBanner = () => {
    const colorStyle = {
        backgroundColor: COLOR_PRIMARY_25,
        color: COLOR_PRIMARY_700
    }
    const positionStyle = {
        position: 'relative',
        top: 0,
        right: 0,
        left: 0,
        zIndex: 9
    }
    const textStyle = {
        fontSize: isMobileOnly ? FONT_SIZE_12 : FONT_SIZE_14,
        fontWeight: isMobileOnly ? FONT_WEIGHT_400 : FONT_WEIGHT_500,
        lineHeight: isMobileOnly ? SIZE_18 : SIZE_20,
        textAlign: 'center'
    }
    return (
        <Box
            sx={{
                ...colorStyle,
                ...positionStyle,
                ...textStyle,
                padding: isMobileOnly ? SIZE_6 : SIZE_12,
                width: FULL_WIDTH,
                transition: 'all 0.4s ease-in-out',
                transform: isMobileOnly ? 'translateY(272%)' : 'translateY(182%)'
            }}
        >
            <div>
                Event tickets are
                <span style={{ fontWeight: FONT_WEIGHT_600 }}> not included</span> in the selected
                package.
            </div>
        </Box>
    )
}
