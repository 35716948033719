import { LogoIcon } from 'components/Icons/VibeeLogoIcon'
import { COLOR_BLACK, COLOR_WHITE } from 'styles/theme'

export enum LogoColorType {
    BLACK = 'BLACK',
    WHITE = 'WHITE'
}

export type LogoProps = {
    color: LogoColorType
}

const setLogoColor = (color: string) => {
    switch (color) {
        case LogoColorType.BLACK:
            return COLOR_BLACK
        case LogoColorType.WHITE:
            return COLOR_WHITE
        default: {
            const exhaustiveCheck = color
            throw new Error(`Unhandled logo color case: ${exhaustiveCheck}`)
        }
    }
}

type LogoComponentProps = {
    color: string
    height: string
    width: string
}

export const LogoComponent = ({ color, height, width }: LogoComponentProps) => {
    return (
        <div
            style={{
                cursor: 'pointer'
            }}
        >
            <LogoIcon
                fill={setLogoColor(color)}
                height={height}
                width={width}
            />
        </div>
    )
}
