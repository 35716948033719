import ExpandedNav from 'components/expandedNav'
import { SIZE_8 } from 'styles/theme'

export const OffCanvasNavComponent = () => {
    return (
        <div
            className="navbar-offcanvas show"
            id="navbar-canvas"
            style={{ marginRight: 0, overflowY: 'auto', padding: `0 ${SIZE_8}`, zIndex: 9000 }}
        >
            <ExpandedNav />
        </div>
    )
}
