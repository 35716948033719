export const GROUP_SIZE_OPTIONS = [
    { groupSize: 1, label: '1 Person', isSelected: false },
    { groupSize: 2, label: '2 People', isSelected: false },
    { groupSize: 3, label: '3 People', isSelected: false },
    { groupSize: 4, label: '4 People', isSelected: false },
    { groupSize: 5, label: '5 People', isSelected: false },
    { groupSize: 6, label: '6 People', isSelected: false },
    { groupSize: 7, label: '7 People', isSelected: false },
    { groupSize: 8, label: '8 People', isSelected: false }
]
