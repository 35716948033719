import React, { useRef, useEffect } from 'react';
import * as jdenticon from 'jdenticon'

export default function Jdenticon({
    value,
    size,
    style
}: {
    value: any,
    size : number,
    style?: React.CSSProperties
  }) {
  const icon = useRef(null);
  useEffect(() => {
    jdenticon?.update(icon.current!, value);
  }, [value]);

  return (
    <div style={style}>
      <svg data-jdenticon-value={value} height={size} ref={icon} width={size} />
    </div>
  );
};
