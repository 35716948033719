import { FunctionComponent, MouseEvent, useCallback, useEffect, useRef, useState } from 'react'
import { FULL_WIDTH, useStyles } from './datepicker.styles'
import { ClickAwayListener } from '@mui/base'
import {
    DateRange,
    DateRangeCalendarSlotsComponentsProps,
    DateRangePicker,
    DateRangePickerDayProps,
    DateRangeValidationError
} from '@mui/x-date-pickers-pro/'
import dayjs, { Dayjs } from 'dayjs'
import { useMediaQuery } from '@mui/material'
import { COLOR_GRAY_500, COLOR_PRIMARY_200, FONT_SIZE_14 } from 'styles/theme'
import { DateRangeDayComponent } from './DateRangePickerDay'
import { isMobile, isMobileOnly } from 'react-device-detect'
import timezone from 'dayjs/plugin/timezone'
import { getLocalTimeZone, updateShoulderNightRanges } from 'util/dates'
import { DateRangePickerFieldInputProps, DateRangePickerFields } from './DateRangePickerFields'
import { HotelJSON } from '../../../../events-service/src/models/hotel'
import { dateRangePickerModifiers } from './dateRangePickerModifiers'
import { DateRangePickerToolBarComponent } from './DateRangePickerToolbarComponent'

dayjs.extend(timezone)

export enum CheckInOutType {
    CHECK_IN = 'Check-in',
    CHECK_OUT = 'Check-out'
}

export type PopperPropsType = {
    popper: {
        popperOptions: {
            placement: string
        }
    }
}

type DayPropType = DateRangeCalendarSlotsComponentsProps<Dayjs> & {
    selectedDay: DateRange<Dayjs> | [null, null]
    sx: {
        '& .MuiButtonBase-root': {
            fontSize: typeof FONT_SIZE_14
        }
    }
}

type ShoulderNightRangeType = {
    checkInDistance: number | undefined
    checkOutDistance: number | undefined
}

// Keep props alphabetized
export type DateRangePickerProps = {
    autoFocus?: boolean
    isCalendarOpen: boolean
    cancelButtonTitle: string
    checkInDate: string | Date
    checkOutDate: string | Date
    closeOnSelect?: boolean
    confirmButtonTitle: string
    defaultValueRange: DateRange<Dayjs>
    eventHotels: HotelJSON[] | undefined
    eventTimezone: string
    isFirstClickModal: boolean
    format?: string
    groupSize: number
    hasAddToCart: boolean
    hotelId: string
    inventory: Record<string, number>
    isDisabled: boolean
    isHotelPageSelected: boolean
    isMobileWidth?: boolean
    isSameHotel: boolean
    localeText?: {
        end: string
        start: string
    }
    maxDate?: Dayjs
    minDate?: Dayjs
    onAcceptDates?: (value: DateRange<Dayjs>) => void
    onCalendarOpen: () => void
    onConfirm: () => void
    onDatesChange: (value: DateRange<Dayjs>) => void
    onResetDates: () => void
    props?: PopperPropsType
    ticketId: string
    value?: DateRange<Dayjs> | [null, null]
}

export const DateRangePickerComponent = ({
    autoFocus = false,
    isCalendarOpen = false,
    cancelButtonTitle = 'Cancel',
    checkInDate = '',
    checkOutDate = '',
    closeOnSelect = false,
    confirmButtonTitle = 'Confirm',
    defaultValueRange = [null, null],
    eventHotels = [],
    eventTimezone = '',
    isFirstClickModal = false,
    format = '',
    groupSize = 0,
    hasAddToCart = false,
    hotelId = '',
    inventory = {},
    isDisabled = false,
    isHotelPageSelected = false,
    isMobileWidth = false,
    isSameHotel = false,
    localeText = {
        end: CheckInOutType.CHECK_OUT,
        start: CheckInOutType.CHECK_IN
    },
    maxDate,
    minDate,
    onAcceptDates,
    onCalendarOpen,
    onConfirm,
    onDatesChange,
    onResetDates,
    ticketId = '',
    value = [null, null],
    ...props
}: DateRangePickerProps) => {
    const popperElementRef = useRef<HTMLDivElement>(null)
    const [calendarPosition, setCalendarPosition] = useState<2 | 1 | 3 | undefined>(2)
    const [clickDatesCount, setClickDatesCount] = useState(1)
    const [crossYearPosition, setCrossYearPosition] = useState(isHotelPageSelected ? 0 : 1)
    const [displayNumberOfWeeks, setDisplayNumberOfWeeks] = useState(0)
    const [error, setError] = useState<DateRangeValidationError | null>(null)
    const [isSameMonth, setIsSameMonth] = useState<boolean>(true)
    const [isWindowHeightSmall, setIsWindowHeightSmall] = useState<boolean>(false)
    const [isValueNull, setIsValueNull] = useState<boolean>(true)
    const [previousRanges, setPreviousRanges] = useState<DateRange<Dayjs>>(
        isSameHotel ? value : defaultValueRange
    )
    const [shouldHighlightEndInput, setShouldHighlightEndInput] = useState<boolean>(false)
    const [shouldHighlightStartInput, setShouldHighlightStartInput] = useState<boolean>(false)
    const [shouldUpdatePosition, setShouldUpdatePosition] = useState<boolean>(false)
    const flag: Record<number, DateRange<Dayjs>> = {
        0: isSameHotel ? value : defaultValueRange
    }

    const { classes } = useStyles()
    // 891 is the size of the window height under which
    // the datepicker gets cut off
    const isMobileDevice = useMediaQuery('(max-width: 767px)')
    const checkInDateStamp = getLocalTimeZone(checkInDate, eventTimezone).toDate().getTime()
    const checkOutDateStamp = getLocalTimeZone(checkOutDate, eventTimezone).toDate().getTime()
    const firstValue: Dayjs | null = value?.[0] ?? null
    const lastValue: Dayjs | null = value?.[value.length - 1] ?? null
    const shoulderNightRangeInit = isValueNull
        ? { checkInDistance: undefined, checkOutDistance: undefined }
        : updateShoulderNightRanges(
              checkInDate,
              checkOutDate,
              eventTimezone,
              eventHotels,
              groupSize,
              hotelId,
              inventory,
              firstValue,
              lastValue,
              ticketId
          )
    const shouldReset =
        firstValue !== null && lastValue !== null
            ? firstValue.startOf('day').toDate().getTime() < checkInDateStamp ||
              lastValue.startOf('day').toDate().getTime() > checkOutDateStamp
            : false
    const [canReset, setCanReset] = useState<boolean>(shouldReset)
    const [shoulderNightRange, setShoulderNightRange] =
        useState<ShoulderNightRangeType>(shoulderNightRangeInit)
    const inputRef = useRef<HTMLInputElement>(null)
    const differentMinDate = minDate?.get('month') !== defaultValueRange[0]?.get('month')
    const sameAsPrimaryFlag =
        flag[0][0] != null && flag[0][0]?.get('month') !== defaultValueRange[0]?.get('month')
    const crossYearDate =
        minDate?.get('year') !== defaultValueRange[0]?.get('year') &&
        defaultValueRange[0]?.get('date') == 1
    const crossYearFirstMonth =
        minDate?.get('year') !== defaultValueRange[0]?.get('year') &&
        defaultValueRange[0]?.get('month') == 0

    const getWeeksInMonth = (month: number, year: number) => {
        const firstDay = new Date(year, month, 1)
        const lastDay = new Date(year, month + 1, 0)
        const numDays = lastDay.getDate()
        const firstDayOfWeek = firstDay.getDay()
        const lastDayOfWeek = lastDay.getDay()
        const totalDays = numDays + firstDayOfWeek + (6 - lastDayOfWeek)

        return Math.ceil(totalDays / 7)
    }

    const customDayOfWeekFormatter = (date: string) => {
        switch (date) {
            case 'Su':
                return 'Su'
            case 'Mo':
                return 'M'
            case 'Tu':
                return 'T'
            case 'We':
                return 'W'
            case 'Th':
                return 'Th'
            case 'Fr':
                return 'F'
            case 'Sa':
                return 'Sa'
            default: {
                const exhaustiveCheck = date
                throw new Error(`Unhandled day of week case: ${exhaustiveCheck}`)
            }
        }
    }

    const handleClose = useCallback(
        (event: Event | MouseEvent<Document | HTMLLIElement, MouseEvent>) => {
            const calendarElement = document.getElementsByClassName(
                'MuiPickersPopper-root'
            )[0] as HTMLDivElement
            const roomPageRootElement = document.getElementsByClassName(
                'room-selection pc-mb2'
            )[0] as HTMLDivElement
            const hotelPageModalRootElement = document.getElementsByClassName(
                'MuiDialog-container'
            )[0] as HTMLDivElement

            switch (true) {
                case !isCalendarOpen:
                    break

                case !calendarElement?.contains(event.target as HTMLDivElement) &&
                    roomPageRootElement?.contains(event.target as HTMLDivElement):
                    onConfirm()
                    setCrossYearPosition(1)
                    setCalendarPosition(2)
                    flag[0] = value
                    break

                case popperElementRef.current &&
                    hotelPageModalRootElement?.contains(event.target as HTMLDivElement):
                    onConfirm()
                    setCrossYearPosition(1)
                    setCalendarPosition(2)
                    flag[0] = value
                    break

                default:
                    break
            }
        },
        [onConfirm]
    )

    const forceAssignmentOfStartDate = (
        defaultStartTimes: dayjs.Dayjs | null,
        startRangeTimes: dayjs.Dayjs | null
    ) => {
        let tempTime = clickDatesCount
        switch (true) {
            case tempTime > 0: {
                let unassignedEndRangeTimes = true
                while (unassignedEndRangeTimes) {
                    const lastElement = flag?.[tempTime]?.[1]
                    const firstElement = flag?.[tempTime]?.[0]
                    switch (true) {
                        case lastElement && !lastElement.isAfter(defaultStartTimes):
                            startRangeTimes = lastElement
                            unassignedEndRangeTimes = false
                            break

                        case firstElement && !firstElement.isAfter(defaultStartTimes):
                            startRangeTimes = firstElement
                            unassignedEndRangeTimes = false
                            break

                        default:
                            break
                    }
                    if (tempTime == 0) break
                    tempTime--
                }
                break
            }

            default:
                startRangeTimes = defaultStartTimes
                break
        }
        return startRangeTimes
    }

    const forceAssignmentOfEndDate = (
        defaultEndTimes: dayjs.Dayjs | null,
        endRangeTimes: dayjs.Dayjs | null
    ) => {
        let tempTime = clickDatesCount
        switch (true) {
            case tempTime > 0: {
                let unassignedEndRangeTimes = true
                while (unassignedEndRangeTimes) {
                    const firstElement = flag?.[tempTime]?.[0]
                    const lastElement = flag?.[tempTime]?.[1]
                    switch (true) {
                        case firstElement &&
                            !firstElement.startOf('day').isBefore(defaultEndTimes!.startOf('day')):
                            endRangeTimes = firstElement
                            unassignedEndRangeTimes = false
                            break

                        case lastElement &&
                            !lastElement.startOf('day').isBefore(defaultEndTimes!.startOf('day')):
                            endRangeTimes = lastElement
                            unassignedEndRangeTimes = false
                            break

                        default:
                            break
                    }
                    if (tempTime == 0) break
                    tempTime--
                }
                break
            }
            default:
                endRangeTimes = defaultEndTimes
                break
        }
        return endRangeTimes
    }

    const handleDateChange = useCallback(
        (dates: DateRange<Dayjs>) => {
            const [startDate, endDate] = dates
            let startRangeTimes = defaultValueRange[0]
            let endRangeTimes = defaultValueRange[1]

            switch (true) {
                case endDate === null:
                    break
                case endDate?.get('month') === defaultValueRange?.[1]?.get('month'):
                    break
                default:
                    setCalendarPosition(undefined)
                    break
            }

            switch (true) {
                case dates === flag[clickDatesCount]:
                    break
                case startDate?.isSame(endDate):
                    break
                default:
                    setClickDatesCount(clickDatesCount + 1)
                    flag[clickDatesCount] = dates
                    break
            }

            const defaultStartTimes = defaultValueRange[0]
            const defaultEndTimes = defaultValueRange[1]
            if (startDate && endDate) {
                switch (true) {
                    case startDate.startOf('day').isBefore(defaultValueRange[1]!.startOf('day')) &&
                        endDate.startOf('day').isBefore(defaultValueRange[1]!.startOf('day')):
                        startRangeTimes = endDate
                        endRangeTimes = forceAssignmentOfEndDate(defaultEndTimes, endRangeTimes)
                        break

                    case startDate.startOf('day').isAfter(defaultValueRange[0]!.startOf('day')) &&
                        endDate.startOf('day').isAfter(defaultValueRange[0]!.startOf('day')):
                        endRangeTimes = startDate
                        startRangeTimes = forceAssignmentOfStartDate(
                            defaultStartTimes,
                            startRangeTimes
                        )
                        break

                    default:
                        startRangeTimes = startDate
                        endRangeTimes = endDate
                        break
                }
            } else if (startDate) {
                switch (true) {
                    case !startDate.startOf('day').isAfter(defaultStartTimes!.startOf('day')):
                        startRangeTimes = startDate
                        endRangeTimes = forceAssignmentOfEndDate(defaultEndTimes, endRangeTimes)
                        break

                    default:
                        endRangeTimes = startDate
                        startRangeTimes = forceAssignmentOfStartDate(
                            defaultStartTimes,
                            startRangeTimes
                        )
                        break
                }
            } else if (endDate) {
                switch (true) {
                    case !endDate.startOf('day').isBefore(defaultEndTimes!.startOf('day')):
                        endRangeTimes = endDate
                        startRangeTimes = defaultValueRange[0]
                        break

                    default:
                        endRangeTimes = defaultValueRange[1]
                        startRangeTimes = endDate
                        break
                }
            }

            setShoulderNightRange(
                updateShoulderNightRanges(
                    checkInDate,
                    checkOutDate,
                    eventTimezone,
                    eventHotels,
                    groupSize,
                    hotelId,
                    inventory,
                    startRangeTimes!,
                    endRangeTimes!,
                    ticketId
                )
            )
            setPreviousRanges([startRangeTimes, endRangeTimes])
            onDatesChange([startRangeTimes, endRangeTimes])
            if (startRangeTimes !== null && endRangeTimes !== null) {
                const startDateStamp = startRangeTimes.toDate().getTime()
                const endDateStamp = endRangeTimes.toDate().getTime()
                const canReset =
                    startDateStamp < checkInDateStamp! || endDateStamp > checkOutDateStamp!
                setCanReset(canReset)
            } else setCanReset(true)
        },
        [value]
    )

    const shouldDisableDate = (date: Dayjs): boolean => {
        if (isValueNull) {
            return false
        }
        const dateStamp = date.startOf('day').toDate().getTime()

        switch (true) {
            case previousRanges[1]?.isSame(date) || previousRanges[0]?.isSame(date):
                return true

            case shoulderNightRange.checkInDistance !== undefined && dateStamp < checkInDateStamp:
                const checkInTime = getLocalTimeZone(checkInDate, eventTimezone)
                    .subtract(shoulderNightRange.checkInDistance!, 'day')
                    .startOf('day')
                    .toDate()
                    .getTime()
                return dateStamp < checkInTime

            case shoulderNightRange.checkOutDistance !== undefined && dateStamp > checkOutDateStamp:
                const checkOutTime = getLocalTimeZone(checkOutDate, eventTimezone)
                    .add(shoulderNightRange.checkOutDistance!, 'day')
                    .startOf('day')
                    .toDate()
                    .getTime()
                return dateStamp > checkOutTime

            default:
                return dateStamp > checkInDateStamp && dateStamp < checkOutDateStamp
        }
    }

    const calendarValue = () => {
        let result
        switch (true) {
            case !hasAddToCart:
                result = value
                break

            case isFirstClickModal && !isSameHotel:
                result = value
                break

            default:
                result = previousRanges
                break
        }
        return result
    }

    const renderCurrentMonthCalendarPosition = () => {
        switch (true) {
            case crossYearPosition > 3 && isHotelPageSelected:
                return 1
            case crossYearPosition > 2:
                return 1
            case sameAsPrimaryFlag:
                return 1
            case shouldUpdatePosition:
                return calendarPosition
            default:
                return 2
        }
    }

    const getCalendarConfirmClassName = (isSameMonth: boolean) => {
        let calendarConfirmClassName = ''
        if (!isSameMonth) {
            switch (true) {
                case isMobileDevice:
                    calendarConfirmClassName = classes.mobileCalendarConfirm
                    break
                default:
                    calendarConfirmClassName = classes.calendarConfirm
                    break
            }
        }
        return calendarConfirmClassName
    }

    const getIsSameMonthCalendarClassName = (displayNumberOfWeeks: number) => {
        let isMobileDeviceCalendarClassName = ''
        if (displayNumberOfWeeks !== 6) {
            switch (true) {
                case !isMobileDevice:
                    isMobileDeviceCalendarClassName = ` isSameMonth-calendar ${classes.isSameMonthCalendar}`
                    break

                default:
                    isMobileDeviceCalendarClassName = ' isSameMonth-calendar '
                    break
            }
        }
        return `${isMobileDeviceCalendarClassName} MuiPickersPopper-root`
    }

    const getCalendarPopperClassName = (isMobileDevice: boolean) => {
        let calendarPopperClassName = ''
        if (isMobileDevice) {
            calendarPopperClassName = ' mobileCalendar ' + classes.mobileCalendar
        }
        return calendarPopperClassName
    }

    const handleRangePosition = () => {
        switch (true) {
            case defaultValueRange?.[0]?.get('date') !== 1 && crossYearFirstMonth:
                return 'end'
            case !crossYearDate:
                return undefined
            case value?.[0]?.get('year') !== minDate?.get('year'):
                return 'end'
            default:
                return 'start'
        }
    }

    useEffect(() => {
        const updateWindowHeight = () => {
            setIsWindowHeightSmall(window.innerHeight < 891)
        }
        updateWindowHeight()
        window.addEventListener('resize', updateWindowHeight)
        return () => {
            window.removeEventListener('resize', updateWindowHeight)
        }
    }, [])

    useEffect(() => {
        if (maxDate && minDate) {
            const minDateMonth = minDate.month() + 1
            const maxDateMonth = maxDate.month() + 1

            if (minDateMonth !== maxDateMonth) setIsSameMonth(false)

            const minDateOfMonth = minDate.toDate().getMonth()
            const minDateOfYear = minDate.toDate().getFullYear()
            const maxDateOfMonth = maxDate.toDate().getMonth()
            const maxDateOfYear = maxDate.toDate().getFullYear()
            const displayNumberOfMinWeeks = getWeeksInMonth(minDateOfMonth, minDateOfYear)
            const displayNumberOfMaxDateWeeks = getWeeksInMonth(maxDateOfMonth, maxDateOfYear)
            const isDisplayNumberOfMinWeeksLessThanMaxDateWeeks =
                displayNumberOfMinWeeks < displayNumberOfMaxDateWeeks
            const numberOfWeeks = isDisplayNumberOfMinWeeksLessThanMaxDateWeeks
                ? displayNumberOfMaxDateWeeks
                : displayNumberOfMinWeeks
            setDisplayNumberOfWeeks(numberOfWeeks)
        }
    }, [maxDate, minDate])

    useEffect(() => {
        if (calendarPosition == undefined) {
            onConfirm()
            setShouldUpdatePosition(true)
            onCalendarOpen()
        } else {
            setShouldUpdatePosition(false)
        }
    }, [calendarPosition])

    useEffect(() => {
        if (isCalendarOpen) {
            if (crossYearDate) {
                setCrossYearPosition((position) => position + 1)
            } else {
                setCrossYearPosition(-9999999999)
            }
        }
    }, [isCalendarOpen, crossYearDate])

    useEffect(() => {
        const isNullValue = value?.every((val) => val === null)
        if (isNullValue) {
            setIsValueNull(true)
        } else {
            setIsValueNull(false)
        }
    }, [value])

    return (
        <ClickAwayListener onClickAway={handleClose}>
            <DateRangePicker
                autoFocus={autoFocus}
                calendars={isSameMonth ? 1 : 2}
                closeOnSelect={closeOnSelect}
                currentMonthCalendarPosition={
                    differentMinDate ? renderCurrentMonthCalendarPosition() : 1
                }
                dayOfWeekFormatter={customDayOfWeekFormatter}
                desktopModeMediaQuery={'@media (min-width: 320px)'}
                disableAutoMonthSwitching
                disabled={isDisabled}
                disablePast
                localeText={localeText}
                maxDate={maxDate}
                minDate={minDate}
                onAccept={onAcceptDates}
                onChange={handleDateChange}
                onError={(error) => error.forEach((e) => setError(error))}
                onOpen={onCalendarOpen}
                open={isCalendarOpen}
                rangePosition={handleRangePosition()}
                ref={popperElementRef}
                shouldDisableDate={shouldDisableDate}
                showDaysOutsideCurrentMonth={false}
                value={calendarValue()}
                slots={{
                    actionBar: (props) => (
                        <DateRangePickerToolBarComponent
                            {...props}
                            canReset={canReset}
                            cancelButtonTitle={cancelButtonTitle}
                            clickDatesCount={clickDatesCount}
                            confirmButtonTitle={confirmButtonTitle}
                            defaultValueRange={defaultValueRange}
                            flag={flag}
                            getCalendarConfirmClassName={getCalendarConfirmClassName}
                            isMobileOnly={isMobileOnly}
                            isSameMonth={isSameMonth}
                            onConfirm={onConfirm}
                            onResetDates={onResetDates}
                            setCanReset={setCanReset}
                            setCalendarPosition={setCalendarPosition}
                            setClickDatesCount={setClickDatesCount}
                            setCrossYearPosition={setCrossYearPosition}
                            setPreviousRanges={setPreviousRanges}
                            setShoulderNightRange={setShoulderNightRange}
                            value={value}
                        />
                    ),
                    day: DateRangeDayComponent as FunctionComponent<DateRangePickerDayProps<Dayjs>>,
                    field: DateRangePickerFields as FunctionComponent<DateRangePickerFieldInputProps>,
                    fieldSeparator: () => null,
                    nextIconButton: () => null,
                    previousIconButton: () => null,
                    textField: () => null,
                    toolbar: () => null
                }}
                slotProps={{
                    ...props,
                    day: {
                        selectedDay: value,
                        sx: {
                            '& .MuiButtonBase-root': {
                                fontSize: FONT_SIZE_14
                            }
                        }
                    } as DayPropType,
                    desktopTransition: {
                        timeout: 10
                    },
                    layout: {
                        className:
                            classes.layout + getIsSameMonthCalendarClassName(displayNumberOfWeeks)
                    },
                    popper: {
                        anchorEl: inputRef.current,
                        className: classes.calendar + getCalendarPopperClassName(isMobileDevice),
                        disablePortal: false,
                        modifiers: dateRangePickerModifiers,
                        placement: isWindowHeightSmall ? 'left-start' : 'bottom-start',
                        sx: {
                            position: isMobileDevice ? 'fixed !important' : 'absolute',
                            '&.mobileCalendar .MuiDayCalendar-slideTransition': {
                                minHeight:
                                    displayNumberOfWeeks === 5
                                        ? 'calc(72vw - 20px)'
                                        : 'calc(85.7vw - 20px)'
                            },
                            zIndex: 1155
                        }
                    },
                    field: {
                        isDayEndSelected: shouldHighlightEndInput,
                        isDayStartSelected: shouldHighlightStartInput,
                        onCalendarOpen,
                        isValueNull,
                        inputRef
                        // TODO: need to update the proptype here
                    } as any,
                    toolbar: {
                        hidden: false,
                        toolbarFormat: format,
                        toolbarPlaceholder: CheckInOutType.CHECK_IN
                    }
                }}
                sx={{
                    '&.Mui-disabled': {
                        backgroundColor: COLOR_PRIMARY_200
                    },
                    '& .MuiFormControl-root .MuiFormLabel-root': {
                        cursor: isDisabled ? 'not-allowed' : 'pointer'
                    },
                    '& .MuiFormControl-root .MuiInputBase-inputAdornedStart': {
                        cursor: isDisabled ? 'not-allowed' : 'pointer'
                    },
                    '& .Mui-error': {
                        color: `${COLOR_GRAY_500} !important`
                    },
                    alignItems: !isMobileWidth || !isMobile ? 'end' : undefined,
                    justifyContent: !isMobileWidth || !isMobile ? 'end' : undefined,
                    width: isMobileWidth || isMobile ? FULL_WIDTH : undefined
                }}
            />
        </ClickAwayListener>
    )
}
