import { Modal } from 'react-bootstrap'

type ExpiresResetsLeftProp = {
    onNoClick: () => void
    onYesClick: () => void
    show: boolean
}

const ExpiresResetsLeft = ({ onNoClick, onYesClick, show }: ExpiresResetsLeftProp) => {
    return (
        <Modal
            animation={false}
            aria-label="ExpiresResetsLeft Modal" 
            aria-modal="true"
            backdrop="static"
            centered
            className="modal reseat-modal reseat-modal-v2 zIndex1200"
            contentClassName="border-0 rounded-normal p-2 p-md-3"
            tabindex="-1"
            role="dialog"
            show={show}
        >
            <Modal.Body className="">
                <div className="headline-3 fw-600 text-darker mb-2">Do you need more time?</div>
                <p className="paragrah mb-4">The time to complete your reservation has run out.</p>
                <div className="text-center">
                    <button
                        className="btn btn-purple w-100  m-0 btn-block"
                        data-bs-toggle="modal"
                        data-bs-target="#select-guest-Modal"
                        onClick={onYesClick}
                    >
                        Add More Time
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ExpiresResetsLeft
