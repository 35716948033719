import { Button, CircularProgress } from '@mui/material'
import {
    BORDER_RADIUS,
    COLOR_PRIMARY_500,
    COLOR_WHITE,
    ColorPalette,
    FONT_SIZE,
    SIZE_10,
    SIZE_18,
    SIZE_24,
    Sizes,
    theme
} from 'styles/theme'
import { useStyles } from './button.styles'

export enum ButtonVariant {
    CONTAINED = 'contained',
    OUTLINED = 'outlined',
    TEXT = 'text'
}

export type ButtonComponentPropType = {
    circularProgressSize?: number
    className?: string
    color?: ColorPalette
    disabled?: boolean
    isLoading?: boolean
    onClick: (event?: any) => void
    size?: Sizes
    style?: {}
    title: string | JSX.Element
    type?: 'button' | 'submit' | 'reset'
    variant?: ButtonVariant
}

export const ButtonComponent = ({
    circularProgressSize,
    className,
    color = ColorPalette.PRIMARY,
    disabled = false,
    isLoading = false,
    onClick,
    size,
    style,
    title = '',
    type = 'button',
    variant = ButtonVariant.CONTAINED
}: ButtonComponentPropType) => {
    const { classes } = useStyles()
    const isContainedVariant = variant === ButtonVariant.CONTAINED
    const getDisabledStyle = {
        backgroundColor: isContainedVariant ? theme.palette.primary.light : undefined,
        borderColor: isContainedVariant ? theme.palette.primary.light : undefined,
        color: isContainedVariant ? COLOR_WHITE : undefined
    }
    return (
        <Button
            color={color}
            className={className || classes.button}
            disabled={disabled}
            disableElevation
            disableRipple
            onClick={onClick}
            size={size}
            sx={{
                borderRadius: BORDER_RADIUS,
                fontSize: FONT_SIZE,
                lineHeight: SIZE_24,
                padding: `${SIZE_10} ${SIZE_18}`,
                textTransform: 'capitalize',
                '&.Mui-disabled': getDisabledStyle,
                '&.MuiButton-disableElevation:hover': {
                    backgroundColor: COLOR_PRIMARY_500,
                    borderColor: COLOR_PRIMARY_500
                },
                ...style
            }}
            type={type}
            variant={variant}
        >
            {isLoading ? (
                <CircularProgress
                    size={circularProgressSize}
                    sx={{ color: COLOR_WHITE }}
                />
            ) : (
                title
            )}
        </Button>
    )
}
