import { pickersLayoutClasses } from '@mui/x-date-pickers'
import {
    BORDER_RADIUS,
    COLOR_GRAY_50,
    COLOR_GRAY_200,
    COLOR_GRAY_300,
    COLOR_GRAY_400,
    COLOR_GRAY_800,
    COLOR_PRIMARY_600,
    COLOR_WHITE,
    FONT_SIZE,
    FONT_SIZE_12,
    FONT_SIZE_14,
    SIZE_16,
    SIZE_20,
    SIZE_24,
    SIZE_8,
    theme
} from '../../styles/theme'
import { makeStyles } from 'tss-react/mui'

export const BORDER = '1px solid lightgray'

export const FULL_WIDTH = '100%'

export const useStyles = makeStyles()({
    badge: {
        '& span.MuiBadge-dot': {
            bottom: '20%',
            left: '50%',
            height: '2px',
            minWidth: '2px'
        },
        '&.MuiBadge-root .MuiDateRangePickerDay-outsideCurrentMonth': {
            backgroundColor: 'transparent !important'
        },
        '&.MuiBadge-root .MuiDateRangePickerDay-outsideCurrentMonth+span': {
            backgroundColor: 'transparent !important'
        },
        '&.MuiBadge-root .MuiDateRangePickerDay-outsideCurrentMonth::after': {
            backgroundColor: 'transparent !important'
        },
        '&.MuiBadge-root .MuiPickersDay-hiddenDaySpacingFiller': {
            backgroundColor: 'transparent !important'
        },
        '&.MuiBadge-root .MuiDateRangePickerDay-dayInsideRangeInterval': {
            color: '#7F56D9 !important'
        }
    },
    calendar: {
        '& .MuiPaper-root': {
            borderRadius: '10px',
            minWidth: '328px',
            '& .MuiDateRangeCalendar-monthContainer': {
                width: '100%',
                '& .MuiTypography-subtitle1': {
                    margin: 'auto'
                }
            },
            '& span.MuiTypography-root': {
                color: COLOR_GRAY_800,
                fontSize: FONT_SIZE_14,
                fontWeight: 500,
                lineHeight: SIZE_20
            }
        }
    },
    mobileCalendar: {
        height: '100%',
        width: '100%',
        transform: 'translateY(0px) !important',
        alignItems: 'center',
        '&.MuiPickersPopper-root .MuiDateRangeCalendar-root': {
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'wrap',
            width: '100%',
            overflow: 'hidden'
        },
        '&.mobileCalendar .MuiPickersPopper-root': {
            paddingBottom: '85px',
            display: 'flex',
            overflow: 'scroll',
            position: 'relative'
        },
        '&.mobileCalendar .MuiPickersPopper-paper': {
            height: '100%',
            borderRadius: '0px !important',
            overflow: 'auto'
        },
        '&.mobileCalendar .MuiPickersLayout-contentWrapper': {
            borderBottom: 'none !important'
        },
        '&.mobileCalendar .MuiPickersCalendarHeader-root': {
            margin: '0px',
            padding: '85px 0px 16px'
        },
        '&.mobileCalendar .MuiContainer-root': {
            position: 'fixed',
            bottom: '0px',
            margin: '0px',
            padding: '12px 16px 48px',
            borderTop: '1px solid #E4E7EC',
            backgroundColor: 'white',
            zIndex: 1
        },
        '&.mobileCalendar .MuiDayCalendar-weekContainer,.MuiDayCalendar-header': {
            width: 'calc(100% - 30px)',
            margin: '0 15px'
        },
        '&.mobileCalendar .MuiDayCalendar-weekContainer .MuiBadge-root,.MuiDayCalendar-header .MuiTypography-caption':
            {
                flex: '0  0 14.3%',
                justifyContent: 'center',
                display: 'flex',
                margin: '0px auto'
            },
        '&.mobileCalendar .MuiDayCalendar-weekContainer .MuiDateRangePickerDay-root': {
            padding: '0 25px',
            width: '100%',
            height: 0,
            paddingBottom: '100%',
            position: 'relative'
        },
        '&.mobileCalendar .MuiDayCalendar-weekContainer .MuiDateRangePickerDay-rangeIntervalPreview':
            {
                position: 'absolute',
                inset: '2px',
                zIndex: 1
            },
        '&.mobileCalendar .MuiDayCalendar-weekContainer .MuiPickersDay-root': {
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center'
        }
    },
    calendarConfirm: {
        width: '328px !important',
        marginRight: '0px !important'
    },
    mobileCalendarConfirm: {
        width: '100% !important'
    },
    isSameMonthCalendar: {
        '&.isSameMonth-calendar .MuiDayCalendar-slideTransition': {
            minHeight: '224px'
        }
    },
    dateInput: {
        '& .MuiFilledInput-root': {
            backgroundColor: 'transparent',
            border: '1px solid #e2e2e1',
            borderRadius: BORDER_RADIUS,
            color: theme.palette.secondary.dark,
            height: 48,
            lineHeight: SIZE_24,
            overflow: 'hidden',
            paddingLeft: 4,
            transition: theme.transitions.create([
                'border-color',
                'background-color',
                'box-shadow'
            ]),
            '&:hover': {
                backgroundColor: 'transparent',
                '&: fieldset': {
                    borderColor: 'unset'
                }
            },
            '&.Mui-focused': {
                backgroundColor: 'transparent',
                borderColor: theme.palette.primary.main
            }
        },
        '& .MuiFormLabel-root': {
            color: COLOR_GRAY_400,
            fontSize: FONT_SIZE_12,
            fontWeight: 400,
            left: 28,
            lineHeight: SIZE_16
        },
        '& .MuiInputAdornment-root.MuiInputAdornment-positionStart.MuiInputAdornment-root:not(.MuiInputAdornment-hiddenLabel)':
            {
                marginRight: 4,
                marginTop: 0
            },
        width: 'unset'
    },
    layout: {
        '&.MuiPickersLayout-root': {
            gridAutoColumns: 'auto'
        },
        [`.${pickersLayoutClasses?.actionBar}`]: {
            gridColumn: 1,
            gridRow: 3
        },
        [`.${pickersLayoutClasses?.contentWrapper}`]: {
            borderBottom: BORDER,
            gridColumn: 1,
            gridRow: 1,
            margin: '0 auto',
            width: '100%',
            '& div.MuiPickersCalendarHeader-root': {
                marginRight: 'unset',
                '& div.MuiPickersCalendarHeader-labelContainer': {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginRight: 'unset',
                    width: '100%'
                },
                '& div.MuiPickersArrowSwitcher-root': {
                    display: 'none'
                }
            },

            '& div.MuiPickersCalendarHeader-label': {
                color: COLOR_GRAY_800,
                fontSize: FONT_SIZE_14,
                lineHeight: SIZE_20
            }
        },
        [`.${pickersLayoutClasses?.toolbar}`]: {
            gridColumn: 1,
            gridRow: 1
        }
    },
    toolbar: {
        '& .MuiDateRangePickerToolbar-container': {
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%'
        },
        '& span:first-of-type': {
            fontSize: FONT_SIZE
        },
        margin: SIZE_8
    }
})

export const getDateRangePickerDaySx = (
    isHighlighting: boolean,
    isStartOrEndOfHighlighting: boolean,
    isStartOrEndOfPreviewing: boolean,
    isPreviewing: boolean,
    isSelected: boolean,
    isStartOfPreviewing: boolean,
    isEndOfPreviewing: boolean
) => {
    return {
        '& button.Mui-disabled': {
            fontWeight: '400 !important',
            pointerEvents: 'auto',
            cursor: 'not-allowed',
            color: isHighlighting ? COLOR_WHITE : COLOR_GRAY_300 + ' !important'
        },
        '& button.Mui-disabled:hover': {
            backgroundColor: '#F9F5FF',
            border: 'none !important'
        },
        '& button.Mui-selected': {
            opacity: '1 !important',
            cursor: 'pointer !important',
            border: 'none !important'
        },
        '& button.Mui-selected:hover': {
            backgroundColor: 'rgba(127, 86, 217, 1)',
            cursor: 'pointer !important',
            border: 'none !important'
        },
        '& button:focus:not(:focus-visible)': {
            backgroundColor: 'transparent',
            cursor: 'pointer !important'
        },
        '& button.MuiButtonBase-root': {
            color:
                isHighlighting && !isStartOrEndOfHighlighting && !isStartOrEndOfPreviewing
                    ? COLOR_PRIMARY_600
                    : COLOR_GRAY_800,
            fontSize: FONT_SIZE_14,
            fontWeight: isHighlighting ? 400 : 500,
            lineHeight: SIZE_20,
            '&.MuiButtonBase-root:hover': {
                border: '1px solid #B692F6'
            },
            '&.MuiDateRangePickerDay-dayOutsideRangeInterval:hover': {
                backgroundColor: COLOR_WHITE,
                color: COLOR_PRIMARY_600,
                border: '1px solid #B692F6'
            }
        },
        '&.Mui-selected': {
            color: 'white !important'
        },
        '&.MuiDateRangePickerDay-root': {
            fontSize: FONT_SIZE_14,
            color: COLOR_GRAY_200,
            lineHeight: SIZE_20
        },
        '& .MuiBadge-root .MuiDateRangePickerDay-outsideCurrentMonth': {
            backgroundColor: 'transparent !important'
        },
        '&.MuiDateRangePickerDay-rangeIntervalDayHighlight': {
            backgroundColor: 'rgba(249, 245, 255, 1)'
        },
        '&.MuiDateRangePickerDay-rangeIntervalDayHighlightStart::after': {
            content: "''",
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: '#F9F5FF',
            borderRadius: '100%',
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            minHeight: '100%'
        },
        '&.MuiDateRangePickerDay-rangeIntervalDayHighlightEnd::after': {
            content: "''",
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: '#F9F5FF',
            borderRadius: '100%',
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            minHeight: '100%'
        },
        '&.MuiDateRangePickerDay-root:first-of-type:last-of-type': {
            borderRadius:
                isHighlighting && !isStartOrEndOfHighlighting && !isStartOrEndOfPreviewing
                    ? 0
                    : undefined
        },
        '&.MuiDateRangePickerDay-rangeIntervalDayHighlightStart .Mui-selected': {
            backgroundColor: '#7f56d9',
            color: 'white !important',
            position: 'relative',
            zIndex: 1
        },
        '&.MuiDateRangePickerDay-rangeIntervalDayHighlightEnd .Mui-selected': {
            backgroundColor: '#7f56d9',
            color: 'white !important',
            position: 'relative',
            zIndex: 1
        },
        '&.MuiDateRangePickerDay-root .MuiDateRangePickerDay-rangeIntervalDayPreview': {
            border: '2px dashed transparent',
            borderRadius: !isStartOrEndOfPreviewing && isPreviewing ? 0 : '50%',
            borderLeftColor: isStartOfPreviewing ? 'transparent' : 'transparent',
            borderRightColor: isEndOfPreviewing ? 'transparent' : 'transparent',
            borderBottomLeftRadius: isStartOfPreviewing ? '50%' : 0,
            borderTopLeftRadius: isStartOfPreviewing ? '50%' : 0,
            borderBottomRightRadius: isEndOfPreviewing ? '50%' : 0,
            borderTopRightRadius: isEndOfPreviewing ? '50%' : 0,
            color: COLOR_GRAY_50,
            '&:hover': {
                backgroundColor: !isSelected || !isHighlighting ? COLOR_WHITE : undefined,
                borderLeftColor: 'transparent',
                borderRightColor: 'transparent'
            }
        }
    }
}
