import { DocumentNode, gql } from '@apollo/client'

export const UPDATE_USER: DocumentNode = gql`
    mutation Update(
        $name: String!
        $firstName: String!
        $lastName: String!
        $phone: String!
        $billingAddress: AddressInput
        $braintreePaymentInfo: BraintreePaymentInfoInput
        $shippingAddress: AddressInput
        $stripePaymentMethodId: String
        $stripeCustomerId: String
    ) {
        update(
            name: $name
            firstName: $firstName
            lastName: $lastName
            phone: $phone
            billingAddress: $billingAddress
            braintreePaymentInfo: $braintreePaymentInfo
            shippingAddress: $shippingAddress
            stripePaymentMethodID: $stripePaymentMethodId
            stripeCustomerID: $stripeCustomerId
        ) {
            success
            user {
                id
                email
                emailVerified
                name
                firstName
                lastName
                phone
                profileImage
                source
                roles
                billingInfo {
                    billingAddress {
                        firstName
                        lastName
                        address
                        address2
                        city
                        state
                        zip
                        country
                        phone
                    }
                    shippingAddress {
                        firstName
                        lastName
                        address
                        address2
                        city
                        state
                        zip
                        country
                        phone
                    }
                    stripeCustomerID
                    stripePaymentMethodID
                    last4
                    expiration
                    brand
                }
                stripeClientSecret
            }
            token
            refresh
        }
    }
`
