import { CSSProperties, RefObject, forwardRef } from 'react'
import Box from '@mui/material/Box'
import ButtonBase from '@mui/material/ButtonBase'
import InputAdornment from '@mui/material/InputAdornment'
import { TextField } from '@mui/material'
import { TextFieldProps } from '@mui/material/TextField'
import {
    BORDER_RADIUS,
    COLOR_GRAY_400,
    COLOR_GRAY_500,
    FILLED_INPUT_VARIANT,
    FONT_SIZE,
    SIZE_16,
    SIZE_20,
    Sizes
} from 'styles/theme'
import { CheckInOutType } from './DateRangePickerComponent'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import { useStyles } from './datepicker.styles'
import dayjs, { Dayjs } from 'dayjs'
import { DATE_FORMAT, MOBILE_DATE_FORMAT } from 'enums'
import { useWindowWidth } from 'hooks/useWidth'

export type DateRangePickerFieldType = {
    id?: string
    inputRef?: RefObject<HTMLInputElement>
    isDayStartOrEndBeingSelected: boolean
    isDisabled?: boolean
    isValueNull: boolean
    label: string
    onCalendarOpen: () => void
    style?: CSSProperties
    value: Dayjs | null
}

export type DateRangePickerFieldInputProps = TextFieldProps & {
    isDayEndSelected?: boolean
    isDayStartSelected?: boolean
    isValueNull: boolean
    onCalendarOpen: () => void
    ownerState?: any
    value: [Dayjs | null, Dayjs | null] | null
}

const STARTING_POSITION = 'start'

export const DateRangePickerField = ({
    id = undefined,
    inputRef,
    isDayStartOrEndBeingSelected,
    isDisabled,
    isValueNull,
    label,
    onCalendarOpen,
    style,
    value
}: DateRangePickerFieldType) => {
    const { classes } = useStyles()
    const { isMobileWidth } = useWindowWidth()
    const formatDate = isMobileWidth ? MOBILE_DATE_FORMAT : DATE_FORMAT
    const InputProps = {
        disableUnderline: true,
        readOnly: true,
        startAdornment: (
            <InputAdornment
                position={STARTING_POSITION}
                style={{
                    cursor: isDisabled ? 'not-allowed' : 'pointer',
                    height: 'fit-content',
                    width: 'fit-content'
                }}
            >
                <ButtonBase
                    aria-label="open picker"
                    disabled={isDisabled}
                    onClick={onCalendarOpen}
                    style={{
                        color: isDisabled ? COLOR_GRAY_400 : COLOR_GRAY_500,
                        marginLeft: '5px',
                        marginRight: '5px'
                    }}
                >
                    <CalendarTodayIcon fontSize={Sizes.SMALL} />
                </ButtonBase>
            </InputAdornment>
        )
    }

    return (
        <TextField
            className={classes?.dateInput}
            disabled={isDisabled}
            focused={isDayStartOrEndBeingSelected}
            id={id}
            label={!isValueNull ? label : null}
            InputProps={InputProps}
            onClick={isDisabled ? undefined : onCalendarOpen}
            ref={inputRef}
            style={style}
            sx={{
                borderRadius: BORDER_RADIUS,
                fontSize: FONT_SIZE,
                height: '48px',
                maxWidth: '180px',
                '& .MuiInputBase-input': {
                    color: isValueNull ? COLOR_GRAY_400 : undefined,
                    cursor: isDisabled ? 'not-allowed' : 'pointer',
                    padding: isValueNull ? '8px 12px 8px 0' : undefined,
                    paddingTop: !isValueNull ? SIZE_20 : undefined
                },
                '& .Mui-disabled': {
                    backgroundColor: 'transparent',
                    cursor: 'not-allowed'
                },
                '& .MuiFormLabel-root': {
                    cursor: isDisabled ? 'not-allowed' : 'pointer'
                }
            }}
            value={isValueNull ? label : dayjs(value).format(formatDate)}
            variant={FILLED_INPUT_VARIANT}
        />
    )
}

export const DateRangePickerFields = forwardRef((props: DateRangePickerFieldInputProps, ref) => {
    const {
        disabled: isDisabled,
        inputRef,
        isDayEndSelected,
        isDayStartSelected,
        isValueNull,
        onCalendarOpen,
        value
    } = props

    const endValue: any = value?.[1] ?? null
    const startValue: any = value?.[0] ?? null

    return (
        <Box
            ref={ref}
            style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row'
            }}
        >
            <DateRangePickerField
                id="date-picker-start"
                inputRef={inputRef as RefObject<HTMLInputElement>}
                isDayStartOrEndBeingSelected={isDayStartSelected ?? false}
                isDisabled={isDisabled}
                isValueNull={isValueNull ?? false}
                label={CheckInOutType.CHECK_IN}
                onCalendarOpen={onCalendarOpen}
                value={startValue}
            />
            <DateRangePickerField
                id="date-picker-end"
                isDayStartOrEndBeingSelected={isDayEndSelected ?? false}
                isDisabled={isDisabled}
                isValueNull={isValueNull ?? false}
                label={CheckInOutType.CHECK_OUT}
                onCalendarOpen={onCalendarOpen}
                style={{
                    marginLeft: SIZE_16
                }}
                value={endValue}
            />
        </Box>
    )
})

DateRangePickerFields.displayName = 'DateRangePickerFields'
