import {
    BORDER,
    BOX_SHADOW,
    CENTER,
    COLOR_BLACK,
    COLOR_BLUE_100,
    COLOR_BLUE_200,
    COLOR_BLUE_GRAY_800,
    COLOR_ERROR,
    COLOR_GRAY_300,
    COLOR_GRAY_500,
    COLOR_GRAY_800,
    COLOR_GREEN_25,
    COLOR_GREEN_300,
    COLOR_GREEN_600,
    COLOR_PRIMARY_500,
    COLOR_PRIMARY_600,
    COLOR_WHITE,
    FLEX,
    FONT_FAMILY_INTER,
    FONT_FAMILY_GOOGLE_SANS,
    FONT_SIZE,
    FONT_SIZE_8,
    FONT_SIZE_12,
    FONT_SIZE_24,
    FONT_SIZE_32,
    FONT_WEIGHT_500,
    FONT_WEIGHT_400,
    FONT_WEIGHT_700,
    FULL_WIDTH,
    HALF_WIDTH,
    SIZE_4,
    SIZE_8,
    SIZE_6,
    SIZE_10,
    SIZE_12,
    SIZE_14,
    SIZE_16,
    SIZE_20,
    SIZE_18,
    SIZE_24,
    SIZE_46
} from 'styles/theme'
import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()({
    appleLoginButton:{
        backgroundColor: COLOR_WHITE,
        border: BORDER,
        borderRadius: SIZE_8,
        boxShadow: 'none',
        color: COLOR_BLUE_GRAY_800,
        display: 'block',
        fontFamily: FONT_FAMILY_GOOGLE_SANS,
        fontSize: SIZE_14,
        fontWeight: FONT_WEIGHT_500,
        letterSpacing: 0.25,
        lineHeight: SIZE_18,
        marginBottom: FONT_SIZE,
        padding: `${SIZE_10} ${SIZE_14}`,
        position: 'relative',
        textAlign: 'left',
        '&:focus, &:hover': {
            backgroundColor: COLOR_BLUE_100,
            borderColor: COLOR_BLUE_200,
            cursor: 'pointer'
        }
    },
    createAccountButton: {
        '&.MuiButton-root': {
            borderRadius: SIZE_8,
            color: COLOR_WHITE,
            height: SIZE_46,
            fontSize: FONT_SIZE,
            lineHeight: FONT_SIZE_24,
            marginBottom: FONT_SIZE_24,
            padding: `${SIZE_10} ${SIZE_14}`,
            width: FULL_WIDTH
        },
        '&.MuiButton-root:hover': {
            backgroundColor: COLOR_PRIMARY_500
        }
    },
    errorMessage: {
        color: COLOR_ERROR,
        fontSize: SIZE_12,
        lineHeight: SIZE_16,
        margin: 0
    },
    facebookLoginButton: {
        backgroundColor: COLOR_WHITE,
        border: BORDER,
        borderRadius: SIZE_8,
        boxShadow: 'none',
        color: COLOR_BLUE_GRAY_800,
        display: 'block',
        fontFamily: FONT_FAMILY_GOOGLE_SANS,
        fontSize: SIZE_14,
        fontWeight: FONT_WEIGHT_500,
        letterSpacing: 0.25,
        lineHeight: SIZE_18,
        marginBottom: FONT_SIZE,
        padding: `${SIZE_10} ${SIZE_14}`,
        position: 'relative',
        textAlign: 'left',
        '&:focus, &:hover': {
            backgroundColor: COLOR_BLUE_100,
            borderColor: COLOR_BLUE_200,
            cursor: 'pointer'
        }
    },
    firstNameInput: {
        marginRight: SIZE_8,
        width: HALF_WIDTH
    },
    forgotPasswordButton: {
        marginBottom: SIZE_24,
        textAlign: CENTER
    },
    googleLoginButton: {
        backgroundColor: COLOR_WHITE,
        borderColor: COLOR_GRAY_300,
        boxShadow: 'none',
        color: COLOR_BLUE_GRAY_800,
        fontFamily: FONT_FAMILY_GOOGLE_SANS,
        fontSize: SIZE_14,
        fontWeight: FONT_WEIGHT_500,
        letterSpacing: 0.25,
        lineHeight: SIZE_18,
        marginBottom: FONT_SIZE,
        position: 'relative',
        textAlign: 'left',
        '& div': {
            borderRadius: SIZE_8,
            maxWidth: FULL_WIDTH + '!important',
        },
        '&:focus, &:hover': {
            color: COLOR_PRIMARY_600,
        }
    },
    inputWrapper: {
        marginBottom: FONT_SIZE
    },
    lastNameInput: {
        marginLeft: SIZE_8,
        width: HALF_WIDTH
    },
    logInButton: {
        '&.MuiButton-root': {
            borderRadius: SIZE_8,
            color: COLOR_WHITE,
            height: SIZE_46,
            fontSize: FONT_SIZE,
            lineHeight: FONT_SIZE_24,
            marginBottom: SIZE_16,
            padding: `${SIZE_10} ${SIZE_14}`,
            width: FULL_WIDTH
        },
        '&.MuiButton-root:hover': {
            backgroundColor: COLOR_PRIMARY_500
        }
    },
    logo: {
        display: FLEX,
        flex: 1,
        justifyContent: CENTER
    },
    nameBox: {
        display: FLEX,
        justifyContent: 'space-between',
        marginBottom: FONT_SIZE
    },
    navbarBody: {
        height: FULL_WIDTH,
        overflowY: 'auto',
        '& .login-buttons': {
            padding: `${FONT_SIZE_24} ${FONT_SIZE}`
        },
        '& .navbar-nav': {
            color: COLOR_GRAY_800,
            '& .nav-item': {
                boxShadow: BOX_SHADOW,
                marginBottom: SIZE_4,
                minHeight: SIZE_24,
                '& .nav-link': {
                    padding: `${FONT_SIZE_12} ${FONT_SIZE}`,
                    color: 'inherit',
                    '& .fa-regular': {
                        color: `${COLOR_PRIMARY_600}a2`,
                        display: 'inline-block',
                        fontSize: SIZE_18,
                        marginRight: FONT_SIZE_12,
                        width: SIZE_20
                    }
                }
            }
        }
    },
    signInDividerOr: {
        ':before': {
            fontFamily: 'Inter !important',
            fontSize: '14px !important',
            fontWeight: '500 !important',
            color: '#667085 !important'
        }
    },
    signInInput: {
        color: 'rgba(0, 0, 0, 0.87)'
    },
    signInTipButton: {
        color: `${COLOR_BLACK}de`,
        fontSize: SIZE_14,
        lineHeight: SIZE_20,
        textAlign: CENTER,
        '@media (max-width: 767px)': {
            marginBottom: FONT_SIZE_32
        }
    },
    signUpTipButton: {
        color: `${COLOR_BLACK}de`,
        fontSize: SIZE_14,
        lineHeight: SIZE_20,
        textAlign: CENTER,
        '@media (max-width: 767px)': {
            marginBottom: FONT_SIZE_32
        }
    },
    textFieldBox: {
        appearance: 'none',
        background: 'none',
        borderColor: COLOR_GRAY_300,
        borderRadius: FONT_SIZE_8,
        boxShadow: BOX_SHADOW,
        color: COLOR_GRAY_800,
        display: 'block',
        fontSize: FONT_SIZE,
        fontWeight: FONT_WEIGHT_400,
        height: SIZE_46,
        lineHeight: FONT_SIZE_24,
        transition: 'border-color .15s ease-in-out,box-shadow .15s ease-in-out',
        width: FULL_WIDTH,
        '&.MuiFormControl-root:has(.MuiInputBase-input:autofill) label': {
            fontSize: SIZE_14,
            top: '-10%',
            color: `${COLOR_GRAY_500}dd`,
            transform: `translate(${SIZE_12}, ${SIZE_6}) scale(0.75)`
        },
        '& .MuiInputLabel-root.Mui-focused': {
            color: `${COLOR_GRAY_500}dd`
        },
        '& .MuiFilledInput-root.Mui-focused': {
            backgroundColor: COLOR_WHITE
        },
        '& .MuiInputBase-root': {
            backgroundColor: COLOR_WHITE,
            border: `1px solid ${COLOR_GRAY_300}`,
            borderRadius: SIZE_8,
            borderColor: COLOR_GRAY_300,
            height: FULL_WIDTH,
            '&::before': {
                display: 'none'
            },
            '&::after': {
                display: 'none'
            },
            ':hover': {
                backgroundColor: COLOR_WHITE
            }
        },
        '& .MuiFilledInput-input': {
            borderRadius: SIZE_8,
            height: 'auto',
            lineHeight: SIZE_24,
            padding: `${SIZE_16} ${SIZE_14} ${SIZE_4} ${SIZE_14}`
        },
        '& .MuiOutlinedInput-input': {
            height: FULL_WIDTH,
            padding: `0px ${SIZE_14}`
        },
        '& .MuiFormLabel-root': {
            color: COLOR_GRAY_800,
            lineHeight: SIZE_24,
            top: '-10%'
        },
        '& .MuiOutlinedInput-root': {
            height: FULL_WIDTH,
            width: FULL_WIDTH
        },
        '.MuiInputLabel-shrink': {
            color: `${COLOR_GRAY_500}dd`,
            fontSize: SIZE_14,
            top: '-10%'
        }
    },
    thirdPartyLoginText: {
        display: FLEX,
        flex: 1,
        justifyContent: CENTER
    },
    tipText: {
        backgroundColor: COLOR_GREEN_25,
        borderRadius: SIZE_8,
        border: `1px solid ${COLOR_GREEN_300}`,
        color: COLOR_GREEN_600,
        fontFamily: FONT_FAMILY_INTER,
        fontSize: SIZE_14,
        fontWeight: FONT_WEIGHT_500,
        lineHeight: SIZE_20,
        marginBottom: FONT_SIZE,
        padding: SIZE_16,
    },
    tipLink: {
        color: COLOR_PRIMARY_600,
        cursor: 'pointer',
        display: 'inline-block',
        fontWeight: FONT_WEIGHT_700,
        textDecoration: 'none'
    }
})
