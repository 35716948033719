import { Dispatch, MouseEvent, SetStateAction, useContext } from 'react'
import { AuthContext } from '../auth/context'
import { AccountMenu } from './UserAccount/'
import { useRouter } from 'next/router'
import { COLOR_BLACK, COLOR_WHITE, SIZE_12, SIZE_14, SIZE_16 } from 'styles/theme'
import ButtonBase from '@mui/material/ButtonBase'
import Typography from '@mui/material/Typography'
import { UserProfileAvatar } from './UserAccount/'

export type MyAccountProps = {
    anchorEl: HTMLElement | null
    isHovered: boolean
    isMenuOpen: boolean
    isNotHomePage: boolean
    isScrolled: boolean
    logout: () => void
    setAnchorEl: Dispatch<SetStateAction<HTMLElement | null>>
    shouldShowShoppingBag: boolean
    userName: string
    userProfileImage: string
}

export const MyAccount = ({
    anchorEl,
    isHovered,
    isMenuOpen,
    isNotHomePage,
    isScrolled,
    logout,
    setAnchorEl,
    shouldShowShoppingBag,
    userName,
    userProfileImage
}: MyAccountProps) => {
    const router = useRouter()
    const [_, setAuthState] = useContext(AuthContext)
    const hasUserName: boolean = userName !== ''

    const handleOpenMenu = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleUrl = () => {
        if (router.pathname === '/orders/[orderID]') {
            router.push('/?login_redirect=' + router.asPath)
        }
    }

    const setUserNameColor = () => {
        const isScrolledOrHovered = isScrolled || isHovered
        isScrolledOrHovered || isMenuOpen || isNotHomePage
        if (!isNotHomePage && !isScrolledOrHovered) {
            return COLOR_WHITE
        } else if (!isNotHomePage && isScrolledOrHovered) {
            return COLOR_BLACK
        }
        if (isMenuOpen) {
            return COLOR_BLACK
        }
        if (isNotHomePage) {
            return COLOR_BLACK
        }
        return COLOR_WHITE
    }

    return (
        <div
            className={`${shouldShowShoppingBag ? SIZE_14 : SIZE_12}`}
            id="user-account"
            style={{ alignItems: 'center', display: 'flex', flexDirection: 'row' }}
        >
            <ButtonBase
                aria-controls={isMenuOpen ? 'account-menu' : undefined}
                aria-expanded={isMenuOpen ? 'true' : undefined}
                aria-haspopup="true"
                aria-label="Open Account Menu Button"
                onClick={handleOpenMenu}
            >
                <div style={{ marginLeft: SIZE_16, marginRight: userName ? SIZE_16 : undefined }}>
                    <UserProfileAvatar
                        profileImageUrl={userProfileImage}
                        userName={userName}
                    />
                </div>
                {userName && (
                    <Typography
                        sx={{
                            color: setUserNameColor()
                        }}
                    >
                        {userName}
                    </Typography>
                )}
            </ButtonBase>
            <AccountMenu
                anchorEl={anchorEl}
                handleUrl={handleUrl}
                hasUserName={hasUserName}
                isMenuOpen={isMenuOpen}
                isNotHomePage={isNotHomePage}
                logout={logout}
                setAuthState={setAuthState}
                setShouldShowDropdown={handleClose}
            />
        </div>
    )
}
