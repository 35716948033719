import { faBagShopping } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { isMobileOnly } from 'react-device-detect'
import { COLOR_BLACK, COLOR_WHITE } from 'styles/theme'

function ShoppingBag({
    clickCallback,
    isScrolled
}: {
    clickCallback: () => void
    isScrolled?: boolean
}) {
    return (
        <>
            <div
                className={`navbar-bag position-relative ${isMobileOnly ? 'ml-1' : 'ml-21'}`}
                style={{ cursor: 'pointer' }}
                onClick={clickCallback}
            >
                <FontAwesomeIcon
                    icon={faBagShopping}
                    style={{
                        color: isScrolled ? COLOR_WHITE : COLOR_BLACK
                    }}
                />
                <span className="position-absolute top-0 end-0 badge rounded-pill">
                    1<span className="visually-hidden">unread messages</span>
                </span>
            </div>
        </>
    )
}

export default ShoppingBag
