import { useEffect, useMemo, useState } from 'react'
import { EventJSON } from '../../../events-service/src/models/event'
import { getTimeZone, timeZoneString } from 'util/dates'
import { HotelJSON } from '../../../events-service/src/models/hotel'
import { newAssignRoom } from 'util/parse'
import { cardButtonStatus } from 'enums'
import { ShoppingCartItem } from 'store'
import {
    getAvailableRooms,
    getEarliestRoomOnSaleDate,
    isAllRoomsSoldOnFuture,
    isAllRoomsSoldOut
} from 'util/event'
import { CheapestPriceJSON, hotelTableJSON, roomTableJSON } from 'util/customizeJsonType'
import { HotelRoomJSON } from '../../../events-service/src/models/hotelRoom'

const peopleNumber = 2

type UseHotelStatusType = {
    calculateCheapestPrice: (
        allChoicesArray: any[],
        associateTicketAvailableRoom: HotelRoomJSON[],
        groupSize: number,
        hotelId: string,
        isOnlySellHotel: boolean,
        newTotalTables: Record<number, Record<number, [roomTableJSON[]]>>,
        overBaseOccupancyFee: number,
        unlimitedInventoryRoomTiers?: Record<string, boolean>
    ) => CheapestPriceJSON
    event: EventJSON
    groupSize?: number
    inPreSaleInterval?: boolean
    inventory: Record<string, number>
    isCodeValid?: boolean
    isOnlySellHotel: boolean
    isPreSale?: boolean
    shouldGetHotelsStatus?: boolean
    ticketExperienceItems?: ShoppingCartItem[]
    ticketItems?: ShoppingCartItem[]
    totalTiersData: (excludingInventory: boolean) => hotelTableJSON[]
}
export const defaultHotelState = {
    buttonStatus: '',
    hotelId: '',
    price: 0,
    saleStatusOnHotelCard: '',
    shouldShowButton: true,
    shouldShowPrice: true
}
export type HotelStatusType = typeof defaultHotelState

type HotelStatusReturnType = HotelStatusType[]

export const useHotelStatus = ({
    calculateCheapestPrice,
    event,
    groupSize = peopleNumber,
    inPreSaleInterval = false,
    inventory,
    isCodeValid = false,
    isOnlySellHotel,
    isPreSale = false,
    shouldGetHotelsStatus = true,
    ticketExperienceItems = [],
    ticketItems = [],
    totalTiersData
}: UseHotelStatusType): HotelStatusReturnType => {
    const selectedTicketNumber = ticketItems[0]?.parent || ''
    const [hotelsStatus, setHotelsStatus] = useState<HotelStatusType[]>([])
    const { hotels, timezone } = event
    const shippingFee = useMemo(() => {
        return event.addOnItems.reduce((carry, addOn) => {
            if (addOn.required) {
                return (carry += addOn.price + addOn.taxesAndFees)
            }
            return carry
        }, 0)
    }, [event])
    const getTicketPrice = () => {
        if (isOnlySellHotel) return 0
        let ticketPrice = 0
        let ticketExperiencesPrice = 0
        ticketItems.forEach((item) => {
            ticketPrice += (item.price + item.taxesAndFees) * item.quantity
        })
        ticketExperienceItems.forEach((item) => {
            ticketExperiencesPrice += (item.price + item.taxesAndFees) * item.quantity
        })
        return ticketPrice + ticketExperiencesPrice
    }

    const getHotelPrice = (groupSize: number, hotel: HotelJSON) => {
        let priceData = 0
        let priceDataError = ''
        let priceDataExcludingInventory = 0
        let unlimitedInventoryRoomTiers: Record<string, boolean> = {}
        const availableRooms = getAvailableRooms(inventory, true, hotel.rooms, selectedTicketNumber)
        if (availableRooms.every((room) => room.minimumOccupancy > groupSize)) priceData = 99999997
        availableRooms.forEach((room) => {
            room.tiers.forEach((tiers) => {
                unlimitedInventoryRoomTiers[tiers.id] = tiers.prices.every(
                    (price) => price.quantityAvailable == -1
                )
            })
        })
        const allChoicesArray = newAssignRoom(groupSize)
        const totalTiersPriceData = totalTiersData(false).find(
            (tierData) => tierData.hotelID === hotel.id
        )
        if (totalTiersPriceData && !isOnlySellHotel) {
            const cheapestPriceData = calculateCheapestPrice(
                allChoicesArray,
                availableRooms,
                groupSize,
                hotel.id,
                isOnlySellHotel,
                totalTiersPriceData.hotelTable,
                hotel.overBaseOccupancyFee,
                unlimitedInventoryRoomTiers
            )
            priceData = cheapestPriceData.price
            priceDataError = cheapestPriceData.error
        }

        const totalTiersPriceDataExcludingInventory = totalTiersData(true).find(
            (tierData) => tierData.hotelID === hotel.id
        )
        if (totalTiersPriceDataExcludingInventory) {
            const cheapestPriceData = calculateCheapestPrice(
                allChoicesArray,
                availableRooms,
                groupSize,
                hotel.id,
                isOnlySellHotel,
                totalTiersPriceDataExcludingInventory.hotelTable,
                hotel.overBaseOccupancyFee,
                unlimitedInventoryRoomTiers
            )
            priceDataExcludingInventory = cheapestPriceData.price
        }
        return { priceData, priceDataError, priceDataExcludingInventory }
    }
    const getHotelsStatus = (groupSize: number) => {
        const hotelsStatus: HotelStatusType[] = []
        hotels.forEach((hotel) => {
            if (inPreSaleInterval && isPreSale && !isCodeValid) {
                hotelsStatus.push({
                    buttonStatus: cardButtonStatus.UNLOCK,
                    hotelId: hotel.id,
                    price: 0,
                    saleStatusOnHotelCard: cardButtonStatus.UNLOCK,
                    shouldShowButton: true,
                    shouldShowPrice: false
                })
                return
            }

            const isAllRoomNotOnSale = hotel.rooms.every(
                (room) => !room.onSale || room.tiers.every((tier) => !tier.onSale)
            )
            if (isAllRoomNotOnSale) {
                hotelsStatus.push({
                    buttonStatus: cardButtonStatus.SOLD_OUT,
                    hotelId: hotel.id,
                    price: 0,
                    saleStatusOnHotelCard: cardButtonStatus.SOLD_OUT,
                    shouldShowButton: true,
                    shouldShowPrice: false
                })
                return
            }

            const { priceData, priceDataError, priceDataExcludingInventory } = getHotelPrice(
                groupSize,
                hotel
            )
            const cheapestHotelPrice = priceDataExcludingInventory
            const ticketPrice = getTicketPrice()
            const price = (cheapestHotelPrice + ticketPrice + shippingFee) / groupSize / 100
            const availableRooms = getAvailableRooms(
                inventory,
                false,
                hotel.rooms,
                selectedTicketNumber
            )
            let buttonStatus = ''
            let saleStatusOnHotelCard = ''
            let shouldShowPrice = true
            let shouldShowButton = true
            switch (true) {
                case isAllRoomsSoldOnFuture(availableRooms) && !isPreSale && !isCodeValid:
                    const earliestRoomOnSaleDate = getEarliestRoomOnSaleDate(availableRooms)
                    shouldShowButton = false
                    saleStatusOnHotelCard = `On Sale ${getTimeZone(
                        earliestRoomOnSaleDate,
                        timezone
                    ).format('MM/DD/YY @ hh:mm A')} ${timeZoneString(timezone)}`
                    break
                case priceDataExcludingInventory === 99999999:
                    shouldShowPrice = false
                    buttonStatus = cardButtonStatus.SOLD_OUT
                    break
                case !isOnlySellHotel:
                    switch (priceData) {
                        // when price is 99999997, Button should display unavailable
                        case 99999997:
                            shouldShowPrice = false
                            buttonStatus = cardButtonStatus.UNAVAILABLE
                            break
                        // when price is 99999999, Will get a priceDataError and display on button
                        case 99999999:
                            buttonStatus = priceDataError
                            break
                        default:
                            buttonStatus = isAllRoomsSoldOut(inventory, availableRooms)
                                ? cardButtonStatus.SOLD_OUT
                                : cardButtonStatus.SELECT_HOTEL
                            break
                    }
                    break
                case isOnlySellHotel:
                    if (priceDataExcludingInventory === 1000000000) {
                        saleStatusOnHotelCard = cardButtonStatus.UNAVAILABLE
                        shouldShowButton = false
                        break
                    }
                    buttonStatus = isAllRoomsSoldOut(inventory, availableRooms)
                        ? cardButtonStatus.SOLD_OUT
                        : cardButtonStatus.SELECT_HOTEL
                    break
                default:
                    break
            }
            hotelsStatus.push({
                buttonStatus,
                hotelId: hotel.id,
                price,
                saleStatusOnHotelCard,
                shouldShowButton,
                shouldShowPrice
            })
        })
        return hotelsStatus
    }
    useEffect(() => {
        if (!shouldGetHotelsStatus) return
        const hotelsStatus = getHotelsStatus(groupSize)
        setHotelsStatus(hotelsStatus)
    }, [
        event,
        isCodeValid,
        isOnlySellHotel,
        isPreSale,
        inPreSaleInterval,
        inventory,
        shouldGetHotelsStatus
    ])
    return hotelsStatus
}
