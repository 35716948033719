import { useEffect, useState } from 'react'
import { faSpinner } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import NewsletterModal from '../../newsLetterModal'
import mParticle from '@mparticle/web-sdk'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import {
    BORDER_RADIUS,
    COLOR_ERROR,
    COLOR_PRIMARY_500,
    COLOR_WHITE,
    ColorPalette,
    FONT_SIZE,
    SIZE_12,
    SIZE_24,
    SIZE_8,
    theme
} from 'styles/theme'
import { useStyles } from './newsletter.styles'

declare var HIVE_SDK: any

export const Newsletter = ({
    isMobile,
    routerPathName,
    isF1
}: {
    isMobile: boolean | null
    routerPathName: any
    isF1: boolean
}) => {
    const emailReg = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9_-]+)+$/
    const [email, setEmail] = useState<string>('')
    const [isEmailValid, setIsEmailValid] = useState<boolean | undefined>(undefined)
    const [emailError, setEmailError] = useState<string>('')
    const [showNewsletterPopUp, setShowNewsletterPopUp] = useState<boolean>(false)
    const [showThanksForSubscribingPopUp, setShowThanksForSubscribingPopUp] =
        useState<boolean>(false)
    const [flexCheckDefault, setFlexCheckDefault] = useState<boolean>(true)
    const [firstName, setFirstName] = useState<string>('')
    const [zipCode, setZipCode] = useState<string>('')
    const [zipCodeTips, setZipCodeTips] = useState<string>('')
    const [phoneNumber, setPhoneNumber] = useState<string>('')
    const [phoneNumberError, setPhoneNumberError] = useState<string>('')
    const [selectedCheckBox, setSelectedCheckBox] = useState<any[]>([])
    const [submitting, setSubmitting] = useState<boolean>(false)
    const { classes } = useStyles()

    useEffect(() => {
        setIsEmailValid(undefined)
        setEmailError('')
    }, [routerPathName])

    const onSubscribeClick = () => {
        const valid = emailReg.test(email)
        if (!valid) {
            setIsEmailValid(false)
            setEmailError('Please enter a valid email address.')
        } else {
            setIsEmailValid(undefined)
            setEmailError('')
            setSubmitting(true)
            HIVE_SDK(
                'emailSignup',
                {
                    email: email
                },
                function (data: any) {
                    setShowNewsletterPopUp(true)
                    setIsEmailValid(undefined)
                    setSubmitting(false)
                    if (window.mParticle) {
                        window.mParticle.ready(() => {
                            window.mParticle.logEvent(
                                'Subscribed to Newsletter',
                                window.mParticle.EventType.Social,
                                {
                                    page: window.location.href.split('?')[0],
                                    email: email
                                }
                            )
                        })
                    }
                },
                function (data: any) {
                    setIsEmailValid(false)
                    setEmailError('Please enter a valid email address.')
                    setSubmitting(false)
                }
            )
        }
    }

    const hideNewsletterPopUp = () => {
        setShowNewsletterPopUp(false)
        setEmail('')
        setFirstName('')
        setZipCode('')
        setPhoneNumber('')
        setFlexCheckDefault(true)
        setSelectedCheckBox([])
        setPhoneNumberError('')
        setZipCodeTips('')
    }

    const hidThanksForSubscribingPopUp = () => {
        setShowThanksForSubscribingPopUp(false)
        setFlexCheckDefault(true)
    }

    const handleFlexCheckDefault = () => {
        setFlexCheckDefault(!flexCheckDefault)
    }

    const onChangeCheckBox = (e: any) => {
        if (e.target.checked && selectedCheckBox.indexOf(e.target.value) === -1) {
            selectedCheckBox.push(e.target.value)
        } else if (e.target.checked === false && selectedCheckBox.indexOf(e.target.value) !== -1) {
            selectedCheckBox.splice(
                selectedCheckBox.findIndex((item) => item === e.target.value),
                1
            )
        }
    }

    const handleSubmitBtn = () => {
        setSubmitting(true)
        HIVE_SDK(
            'emailSignup',
            {
                email: email,
                firstName: firstName,
                zipCode: zipCode,
                phoneNumber: phoneNumber,
                didSmsOptIn: flexCheckDefault
            },
            function (data: any) {
                setSubmitting(false)
                setShowNewsletterPopUp(false)
                setShowThanksForSubscribingPopUp(true)
                setEmail('')
                setFirstName('')
                setZipCode('')
                setPhoneNumber('')
                setPhoneNumberError('')
                setZipCodeTips('')
                if (selectedCheckBox.length !== 0) {
                    for (let i = 0; i < selectedCheckBox.length; i++) {
                        HIVE_SDK(
                            'addToSegment',
                            'Interests - ' + selectedCheckBox[i],
                            function (data: any) {
                                console.log('checkbox success', data)
                                setSelectedCheckBox([])
                            },
                            function (data: any) {
                                console.log('checkbox failed', data)
                            }
                        )
                    }
                }
                if (flexCheckDefault) {
                    HIVE_SDK(
                        'addToSegment',
                        'Newsletter Signups - From the Footer_Signup_Popup_Checkbox',
                        function (data: any) {
                            console.log('signUpCheckBox success')
                        },
                        function (data: any) {
                            console.log('signUpCheckBox failed', data)
                        }
                    )
                }
            },
            function (data: any) {
                console.log('submit failed', data)
                setSubmitting(false)
                setPhoneNumberError(data)
            }
        )
    }

    // useeffect for making sure the textfield error is clear when the email is valid again after the user has typed in a valid email
    useEffect(() => {
        if (isEmailValid === false) {
            setIsEmailValid(undefined)
            setEmailError('')
        }
    }, [email])

    return (
        <>
            <div>
                <Typography
                    sx={{
                        fontWeight: 500,
                        marginBottom: SIZE_8,
                        textAlign: isMobile ? 'center' : undefined,
                        whiteSpace: 'nowrap'
                    }}
                >
                    Get special offers direct to your inbox
                </Typography>
                <Box
                    display="flex"
                    flexDirection={isMobile ? 'column' : 'row'}
                >
                    <TextField
                        color="secondary"
                        error={isEmailValid}
                        focused
                        FormHelperTextProps={{
                            style: {
                                color: COLOR_ERROR
                            }
                        }}
                        helperText={isEmailValid !== undefined ? emailError : undefined}
                        InputProps={{
                            classes: {
                                notchedOutline:
                                    isEmailValid === undefined
                                        ? classes.defaultBorder
                                        : classes.errorBorder
                            },
                            style: {
                                borderRadius: BORDER_RADIUS
                            }
                        }}
                        inputProps={{
                            'aria-label': 'Email Address Input: Please enter your email address'
                        }}
                        onChange={(e) => setEmail(e.currentTarget.value)}
                        placeholder="Email Address"
                        size="small"
                        style={{
                            marginBottom: isMobile ? SIZE_24 : undefined,
                            minWidth: !isMobile ? '224px' : undefined,
                            width: isMobile ? '100%' : undefined
                        }}
                        sx={{
                            input: {
                                color: 'white',
                                '&::placeholder': {
                                    opacity: 1
                                }
                            }
                        }}
                        type="email"
                        value={email}
                        variant="outlined"
                    />
                    <Button
                        arial-label="Subscribe Button"
                        color={ColorPalette.PRIMARY}
                        disabled={submitting}
                        onClick={onSubscribeClick}
                        sx={{
                            backgroundColor: theme.palette.primary.main,
                            borderRadius: BORDER_RADIUS,
                            color: COLOR_WHITE,
                            fontSize: FONT_SIZE,
                            fontFamily: 'Inter',
                            fontWeight: 500,
                            height: '40px',
                            marginLeft: isMobile ? undefined : SIZE_8,
                            padding: `${SIZE_12} ${SIZE_12}`,
                            textTransform: 'capitalize',
                            width: isMobile ? '100%' : '100px',
                            '&.Mui-disabled': {
                                backgroundColor: COLOR_PRIMARY_500,
                                color: COLOR_WHITE
                            },
                            '&.MuiButton-disableElevation:hover': {
                                backgroundColor: COLOR_PRIMARY_500,
                                color: COLOR_WHITE
                            },
                            '&:hover': {
                                backgroundColor: COLOR_PRIMARY_500
                            }
                        }}
                        type="submit"
                    >
                        {submitting ? (
                            <FontAwesomeIcon
                                icon={faSpinner}
                                className="fa-spin"
                            />
                        ) : (
                            'Subscribe'
                        )}
                    </Button>
                </Box>
            </div>
            <NewsletterModal
                firstName={firstName}
                flexCheckDefault={flexCheckDefault}
                handleFlexCheckDefault={handleFlexCheckDefault}
                handleSubmitBtn={handleSubmitBtn}
                hideNewsletterPopUp={hideNewsletterPopUp}
                hideThanksForSubscribingPopUp={hidThanksForSubscribingPopUp}
                onChangeCheckBox={onChangeCheckBox}
                onChangeFirstName={(e: any) => setFirstName(e.currentTarget.value)}
                onChangePhoneNumber={(e: any) => setPhoneNumber(e.currentTarget.value)}
                onChangeZipCode={(e: any) => setZipCode(e.currentTarget.value)}
                phoneNumber={phoneNumber}
                phoneNumberError={phoneNumberError}
                showNewsletterPopUp={showNewsletterPopUp}
                showThanksForSubscribingPopUp={showThanksForSubscribingPopUp}
                submitting={submitting}
                zipCode={zipCode}
                zipCodeTips={zipCodeTips}
            />
        </>
    )
}
