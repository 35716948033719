import NextImage, { ImageProps } from 'next/image'

const normalizeSrc = (src: string) => {
    return src.startsWith('/') ? src.slice(1) : src
}

const cloudflareLoader = ({ src, width, quality }: { src: string; width: number; quality?: number }) => {
    const params = [`width=${width}`]
    if (quality) {
        params.push(`quality=${quality}`)
    }
    const paramsString = params.join(',')
    return `https://vibee.com/cdn-cgi/image/${paramsString}/${normalizeSrc(src)}`
}

const Image = (props: ImageProps) => {
    return (
        <NextImage
            loader={cloudflareLoader}
            {...props}
        />
    )
}

export default Image
