import { useDispatch, useSelector } from 'react-redux'
import type { TypedUseSelectorHook } from 'react-redux'
import type { AppDispatch, RootState } from '../store'

export * from './checkout'
export * from './useAdditionalAddOns'
export * from './useBraintree'
export * from './useContactUs'
export * from './useCart'
export * from './useExperienceDetail'
export * from './useLocalStorage'
export * from './useHome'
export * from './useMyExperience'
export * from './useNav'
export * from './useTripSummary'
export * from './useUser'
export * from './useTravelGuard'
export * from './useWidth'

export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
