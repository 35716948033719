import {
    BORDER_TRANSPARENT,
    CENTER,
    COLOR_GRAY_100,
    COLOR_GRAY_200,
    COLOR_GRAY_400,
    COLOR_GRAY_500,
    COLOR_GRAY_800,
    COLOR_PRIMARY_700,
    COLOR_WHITE,
    FLEX,
    FONT_SIZE,
    FONT_SIZE_4,
    FONT_SIZE_8,
    FONT_SIZE_12,
    FONT_SIZE_14,
    FONT_SIZE_18,
    FONT_SIZE_24,
    FONT_SIZE_32,
    FONT_WEIGHT_500,
    FONT_WEIGHT_600,
    FULL_WIDTH,
    HALF_WIDTH,
    SIZE_2,
    SIZE_4,
    SIZE_8,
    SIZE_10,
    SIZE_12,
    SIZE_14,
    SIZE_16,
    SIZE_20,
    SIZE_24,
    SIZE_28
} from 'styles/theme'
import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()({
    button: {
        border: BORDER_TRANSPARENT,
        borderRadius: SIZE_8,
        display: 'block',
        padding: `${SIZE_10} ${SIZE_14}`,
        transition: 'none',
        width: FULL_WIDTH,
        '&.Mui-disabled': {
            backgroundColor: COLOR_GRAY_100,
            borderColor: COLOR_GRAY_100,
            boxShadow: `0px 1px 2px ${COLOR_GRAY_800}0d`,
            color: COLOR_GRAY_400,
            opacity: 0.65,
            pointerEvents: 'none'
        },
        '& .MuiCircularProgress-root': {
            position: 'relative',
            top: SIZE_4
        }
    },
    dateDisplay: {
        color: COLOR_PRIMARY_700,
        fontWeight: FONT_WEIGHT_500,
        fontSize: FONT_SIZE_14,
        lineHeight: SIZE_20,
        marginBottom: FONT_SIZE_8
    },
    description: {
        color: COLOR_GRAY_500,
        marginBottom: FONT_SIZE_12,
        '@media (min-width: 768px)': {
            display: FLEX,
            flex: '1 1 auto'
        }
    },
    hotelItemLink: {
        color: 'inherit',
        textDecoration: 'none',
        '@media (min-width: 768px)': {
            display: FLEX,
            flexDirection: 'column',
            height: FULL_WIDTH
        }
    },
    hotelItemWrapper: {
        backgroundClip: 'border-box',
        backgroundColor: COLOR_WHITE,
        border: 'none',
        borderRadius: SIZE_8,
        boxShadow: `0px 1px 3px ${COLOR_GRAY_800}1a, 0px 1px 2px ${COLOR_GRAY_800}0f`,
        color: COLOR_GRAY_800,
        display: FLEX,
        flexDirection: 'column',
        minWidth: 0,
        overflow: 'hidden',
        position: 'relative',
        wordWrap: 'break-word',
        '@media (min-width: 768px)': {
            borderRadius: SIZE_10,
            height: FULL_WIDTH
        },
        '.slick-next': {
            color: COLOR_WHITE,
            fontSize: SIZE_24,
            top: 0,
            zIndex: 1
        },
        '.slick-prev': {
            color: COLOR_WHITE,
            fontSize: SIZE_24,
            top: 0,
            zIndex: 1
        },
        '.slick-prev.slick-disabled': {
            cursor: 'default',
            opacity: 0.2
        }
    },
    imageContainer: {
        backfaceVisibility: 'hidden',
        borderTopLeftRadius: SIZE_10,
        borderTopRightRadius: SIZE_10,
        cursor: 'pointer',
        display: 'inline-block',
        float: 'left',
        height: 0,
        marginRight: `-${FULL_WIDTH}`,
        overflow: 'hidden',
        paddingBottom: '64%',
        position: 'relative',
        transition: 'transform 0.6s ease-in-out',
        width: FULL_WIDTH,
        '@media (max-width: 767px)': {
            borderTopLeftRadius: SIZE_8,
            borderTopRightRadius: SIZE_8,
            paddingBottom: '56%'
        },
        '& img': {
            backgroundColor: COLOR_GRAY_200,
            objectFit: 'cover'
        }
    },
    imageWrapper: {
        overflow: 'hidden',
        position: 'relative',
        width: FULL_WIDTH,
        '::after': {
            clear: 'both',
            content: '""',
            display: 'block'
        }
    },
    infoWrapper: {
        flex: '1 1 auto',
        padding: `${FONT_SIZE} ${FONT_SIZE} ${FONT_SIZE_24}`,
        '@media (min-width:768px)': {
            display: FLEX,
            flexDirection: 'column',
            padding: FONT_SIZE_24
        }
    },
    name: {
        fontSize: FONT_SIZE_18,
        fontWeight: FONT_WEIGHT_600,
        lineHeight: SIZE_28,
        marginBottom: FONT_SIZE_8
    },
    onlySellHotelWrapper: {
        flex: '0 0 auto',
        marginBottom: FONT_SIZE,
        width: FULL_WIDTH,
        '@media (min-width:768px)': {
            width: HALF_WIDTH
        },
        '@media (min-width: 1200px)': {
            width: '33.3%'
        }
    },
    pointer: {
        cursor: 'pointer'
    },
    presaleButton: {
        fontSize: FONT_SIZE_14,
        lineHeight: SIZE_20,
        padding: `${SIZE_10} ${SIZE_16} ${SIZE_10} ${SIZE_12}`
    },
    presaleButtonWrapper: {
        display: FLEX,
        justifyContent: 'flex-end'
    },
    priceText: {
        fontSize: FONT_SIZE,
        fontWeight: FONT_WEIGHT_600,
        lineHeight: SIZE_24
    },
    pricePerPerson: {
        color: COLOR_GRAY_800,
        fontSize: FONT_SIZE_14,
        fontWeight: FONT_WEIGHT_500,
        lineHeight: SIZE_20,
        marginBottom: FONT_SIZE_4
    },
    roomPropertyWrapper: {
        flexWrap: 'wrap',
        whiteSpace: 'nowrap'
    },
    totalPrice: {
        color: COLOR_GRAY_500,
        fontSize: FONT_SIZE_14,
        lineHeight: SIZE_20,
        marginBottom: FONT_SIZE_24,
        '@media (min-width: 768px)': {
            marginBottom: SIZE_20
        }
    },
    unlockIcon: {
        color: 'inherit',
        display: 'inline-block',
        marginRight: SIZE_4,
        textAlign: CENTER,
        width: SIZE_28,
        '.MuiSvgIcon-root': {
            bottom: SIZE_2,
            fontSize: FONT_SIZE,
            position: 'relative'
        }
    },

    wrapper: {
        flex: '0 0 auto',
        marginBottom: FONT_SIZE_32,
        width: FULL_WIDTH,
        '@media (min-width:768px)': {
            width: HALF_WIDTH
        },
        '@media (min-width: 992px)': {
            width: '33.3%'
        }
    }
})
