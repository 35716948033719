import Link from 'next/link'
import { EventJSON } from '../../../events-service/src/models/event'
import { getTimeZone } from 'util/dates'
import { LATEST_DATE_TO_BE_SOLD, ON_SALE, WILL_BE_SOLD } from 'consts'

export type SelectTicketNotificationType = {
    email: string
    event: EventJSON
    hiveState: any
    hotelNumInfos: any
    hotelOnly: boolean
    isCodeValid?: boolean
    isOnlySellTickets: boolean
    isPreSale: boolean
    onEmailChange: any
    onNotifyClick: any
    ticketNumInfos: any
}

export default function SelectTicketNotification({
    email,
    event,
    hiveState,
    hotelNumInfos,
    hotelOnly,
    isCodeValid = true,
    isOnlySellTickets,
    isPreSale,
    onEmailChange,
    onNotifyClick,
    ticketNumInfos
}: SelectTicketNotificationType) {
    const emailValidClass = hiveState?.emailValid === false ? 'is-invalid' : ''
    if (
        (ticketNumInfos?.[ON_SALE] <= 0 && !isCodeValid) ||
        (hotelNumInfos?.[ON_SALE] <= 0 && !isOnlySellTickets && !isCodeValid) ||
        (hotelOnly && hotelNumInfos?.[ON_SALE] <= 0 && !isCodeValid)
    ) {
        return (
            <div className="row">
                <div className="col-sm-12">
                    <div className="ticket-type-wrapper">
                        <div className="ticket-type-notification">
                            <div className="d-flex justify-content-md-between ticket-type-panel">
                                <div className="text-center text-md-start ticket-type-text">
                                    {ticketNumInfos?.[ON_SALE] <= 0 && !hotelOnly ? (
                                        (!isPreSale || !isCodeValid) &&
                                        (ticketNumInfos[WILL_BE_SOLD] > 0 ? (
                                            <>
                                                <h3 className="mb-3 text-white headline-5">{`On Sale Begins ${getTimeZone(
                                                    ticketNumInfos[LATEST_DATE_TO_BE_SOLD],
                                                    event.timezone
                                                ).format('MMMM DD, YYYY')}`}</h3>
                                                <p className="mb-0 paragrah-xxl">
                                                    Get notified as soon as tickets become
                                                    available.
                                                </p>
                                            </>
                                        ) : (
                                            <>
                                                <h3 className="mb-3 text-white headline-5">
                                                    Event Sold Out
                                                </h3>
                                                <p className="mb-0 paragrah-xxl">
                                                    Join the waitlist and be notified as packages
                                                    become available.
                                                </p>
                                            </>
                                        ))
                                    ) : hotelNumInfos[WILL_BE_SOLD] > 0 ? (
                                        <>
                                            <h3 className="mb-3 text-white headline-5">{`On Sale Begins ${getTimeZone(
                                                hotelNumInfos[LATEST_DATE_TO_BE_SOLD],
                                                event.timezone
                                            ).format('MMMM DD, YYYY')}`}</h3>
                                            <p className="mb-0 paragrah-xxl">
                                                Get notified as soon as tickets become available.
                                            </p>
                                        </>
                                    ) : (
                                        <>
                                            <h3 className="mb-3 text-white headline-5">
                                                Event Sold Out
                                            </h3>
                                            <p className="mb-0 paragrah-xxl">
                                                Join the waitlist and be notified as packages become
                                                available.
                                            </p>
                                        </>
                                    )}
                                </div>
                                <div
                                    className={`email-panel--wrapper center-up-and-down ${
                                        event.joinWaitList?.override && 'email-panel--wrapper_btn'
                                    }`}
                                >
                                    {event.joinWaitList?.override ? (
                                        <Link href={event.joinWaitList.url}>
                                            <a
                                                target={
                                                    event.joinWaitList.newTab ? '_blank' : '_self'
                                                }
                                            >
                                                <button
                                                    type="button"
                                                    className="mt-3 btn btn-purple mt-md-0"
                                                >
                                                    Join Waitlist
                                                </button>
                                            </a>
                                        </Link>
                                    ) : (
                                        (!isPreSale || !isCodeValid) && (
                                            <div className="email-panel">
                                                <input
                                                    aria-label="Email Address Input: Please enter an email address to be notified when tickets become available."
                                                    className={'form-control ' + emailValidClass}
                                                    id="inputEmail3"
                                                    onChange={(e) => {
                                                        onEmailChange(e.currentTarget.value)
                                                    }}
                                                    placeholder="Email Address"
                                                    type="email"
                                                    value={email}
                                                />
                                                <button
                                                    type="submit"
                                                    className="mt-3 btn btn-purple mt-md-0"
                                                    disabled={hiveState.notifying}
                                                    onClick={onNotifyClick}
                                                >
                                                    Notify Me
                                                </button>
                                                <div
                                                    id="validationServer03Feedback"
                                                    className="mt-0 invalid-feedback"
                                                >
                                                    {hiveState.emailError}
                                                </div>
                                                <div
                                                    className="mt-0"
                                                    style={{
                                                        width: '100%',
                                                        marginTop: '0.25rem',
                                                        fontSize: '0.875em',
                                                        color: '#177e40'
                                                    }}
                                                >
                                                    {hiveState.notifyResult}
                                                </div>
                                            </div>
                                        )
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    } else {
        return <></>
    }
}
