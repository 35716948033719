export const dateRangePickerModifiers = [
    {
        name: 'offset',
        options: {
            offset: [0, 10]
        }
    },
    {
        name: 'preventOverflow',
        enabled: true,
        options: {
            altAxis: true
        }
    },
    {
        name: 'computeStyles',
        options: {
            adaptive: true,
            roundOffsets: true,
            modifier: {
                offset: {
                    enabled: true,
                    offset: '0, 10px'
                },
                preventOverflow: {
                    enabled: true,
                    padding: 10
                },
                computeStyles: {
                    gpuAcceleration: false
                }
            }
        }
    }
]
