import { FONT_SIZE, FULL_WIDTH, SIZE_4, SIZE_12, SIZE_80, SIZE_84 } from 'styles/theme'
import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()({
    container: {
        margin: `${SIZE_80} auto 0`,
        width: FULL_WIDTH,
        '@media (min-width: 576px)': {
            maxWidth: '540px'
        },
        '@media (min-width: 768px)': {
            marginTop: SIZE_84,
            maxWidth: '720px',
            padding: `0 ${FONT_SIZE}`
        },
        '@media (min-width: 992px)': {
            maxWidth: '960px'
        },
        '@media (min-width: 1200px)': {
            maxWidth: '1140px'
        },
        '@media (min-width: 1400px)': {
            maxWidth: '1320px'
        }
    },
    desktopImage: {
        display: 'none !important',
        objectFit: 'cover',
        '@media (min-width: 768px)': {
            display: 'block !important'
        }
    },
    homepageImage: {
        display: 'block !important',
        objectFit: 'cover',
        '@media (min-width: 768px)': {
            display: 'none !important'
        }
    },
    imageContainer: {
        height: 0,
        paddingBottom: FULL_WIDTH,
        position: 'relative',
        minHeight: '320px',
        '@media (min-width: 768px)': {
            paddingBottom: '52.5%',
            marginTop: SIZE_4,
            minHeight: '360px'
        }
    },
    imageWrapper: {
        alignItems: 'center',
        display: 'flex',
        height: FULL_WIDTH,
        justifyContent: 'center',
        left: 0,
        position: 'absolute',
        top: 0,
        width: FULL_WIDTH,
        '@media (min-width: 768px)': {
            borderRadius: SIZE_12,
            overflow: 'hidden'
        }
    },
    skeleton: {
        minHeight: '320px',
        '@media (min-width: 768px)': {
            marginTop: SIZE_4,
            minHeight: '360px'
        }
    },
    videoWrapper: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        '@media (min-width: 768px)': {
            marginTop: SIZE_4,
            borderRadius: SIZE_12,
            overflow: 'hidden'
        }
    }
})
