import { makeStyles } from 'tss-react/mui'
import { COLOR_GRAY_800, COLOR_GRAY_200, COLOR_WHITE, SIZE_12, SIZE_24, theme } from 'styles/theme'

export const useStyles = makeStyles()({
    footer: {
        backgroundColor: COLOR_GRAY_800,
        color: theme.palette.common.white,
        paddingTop: theme.spacing(4)
    },
    footerWrapper: {
        [theme.breakpoints.down('md')]: {
            paddingLeft: SIZE_24,
            paddingRight: SIZE_24
        },
        [theme.breakpoints.up('md')]: {
            paddingLeft: '56px',
            paddingRight: '56px'
        },
        [theme.breakpoints.up('xl')]: {
            paddingLeft: SIZE_24,
            paddingRight: SIZE_24
        }
    },
    footerLogo: {
        cursor: 'pointer',
        '& img': {
            height: '100%',
            width: '100%'
        },
        marginBottom: '42px',
        height: 32,
        width: 94
    },
    link: {
        color: COLOR_GRAY_200,
        fontWeight: 500,
        lineHeight: '48px',
        textDecoration: 'none',
        transition: 'color .2s ease-in-out',
        '&:hover': {
            color: COLOR_WHITE,
            textDecoration: 'none'
        }
    },
    linkContainer: {
        marginRight: SIZE_24,
        '@media (max-width:768px)': {
            marginLeft: SIZE_12,
            marginRight: SIZE_12
        }
    },
    mobileFooterLogo: {
        marginBottom: SIZE_24,
        textAlign: 'center'
    },
    socialIconLink: {
        alignItems: 'center',
        color: COLOR_GRAY_200,
        display: 'flex',
        fontSize: SIZE_24,
        transition: 'color .2s ease-in-out',
        '&:hover': {
            color: COLOR_WHITE
        }
    }
})
