import { dateRange } from 'util/dates'
import { Skeleton } from '@mui/material'
import { transferRichTextToText } from 'util/parse'
import { TruncationComponent } from 'util/TruncationComponent'
import { useStyles } from 'components/Slug/styles/eventIntroduction.styles'
import {
    FONT_SIZE,
    FONT_WEIGHT_500,
    FULL_WIDTH,
    SIZE_100,
    SIZE_8,
    SIZE_16,
    SIZE_20,
    SIZE_24,
    SIZE_60
} from 'styles/theme'

export type EventIntroductionProps = {
    ageRequirement: string
    dateDisplay: string
    description: string
    endDate: string | Date
    isLoading: boolean
    isOnlySellHotel: boolean
    location: string
    locationDisplay: string
    name: string
    startDate: string | Date
    timezone: string
}

export const EventIntroduction = ({
    ageRequirement,
    dateDisplay,
    description,
    endDate,
    isLoading,
    isOnlySellHotel,
    location,
    locationDisplay,
    name,
    startDate,
    timezone
}: EventIntroductionProps) => {
    const { classes } = useStyles()
    const displayDate = dateDisplay || dateRange(startDate, endDate, timezone)
    const displayLocation = locationDisplay !== '' ? locationDisplay : location
    const isDescriptionEmpty = transferRichTextToText(description) === ''
    const skeletonRows = [1, 2, 3]

    const renderIntroduction = () => {
        if (isLoading) {
            return (
                <>
                    <Skeleton
                        animation="wave"
                        sx={{
                            height: SIZE_24,
                            marginBottom: SIZE_8,
                            width: '200px'
                        }}
                    />
                    <Skeleton
                        animation="wave"
                        sx={{
                            height: SIZE_60,
                            width: FULL_WIDTH
                        }}
                    />
                    <Skeleton
                        animation="wave"
                        sx={{
                            marginBottom: SIZE_16,
                            width: '200px'
                        }}
                    />
                    {skeletonRows.map((_, index) => {
                        return (
                            <Skeleton
                                animation="wave"
                                key={`truncationSkeleton_${index}`}
                                sx={{
                                    height: SIZE_20,
                                    marginBottom: index === 2 ? FONT_SIZE : undefined,
                                    width: FULL_WIDTH
                                }}
                            />
                        )
                    })}
                    <Skeleton
                        animation="wave"
                        sx={{
                            height: SIZE_20,
                            width: SIZE_100
                        }}
                    />
                </>
            )
        }
        return (
            <>
                <p className={classes.displayDate}>{displayDate}</p>
                <h1 className={classes.name}>
                    {name}
                    {ageRequirement !== '' && (
                        <span className={classes.ageRequirement}>{ageRequirement}</span>
                    )}
                </h1>
                <p style={{ fontWeight: FONT_WEIGHT_500 }}>{displayLocation}</p>
                {!isDescriptionEmpty && description && (
                    <TruncationComponent
                        className={classes.truncation}
                        html={description}
                        isOnlySellHotel={isOnlySellHotel}
                        isTicketDescription={false}
                        maxNumberOfLines={3}
                    />
                )}
            </>
        )
    }
    return (
        <div className={classes.container}>
            <div>{renderIntroduction()}</div>
        </div>
    )
}
