import { faSpinner } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal } from 'react-bootstrap'
import Select from 'react-select'

export interface GuestsModalProp {
    isF1: boolean
    onCancelClick: () => void
    onContinueClick: () => void
    onSelectedOptionChange: (newValue: any) => void
    options: { value: number; label: string; isDisabled: boolean }[]
    selectedOptionIsPlaceholder: boolean
    selectedValue: { value: number; label: string }
    submitting: boolean
    visible: boolean
}

const IndicatorSeparator = () => {
    return null
}

const GuestsModal = ({
    isF1,
    onCancelClick,
    onContinueClick,
    onSelectedOptionChange,
    options,
    selectedOptionIsPlaceholder,
    selectedValue,
    submitting,
    visible
}: GuestsModalProp) => {
    let cancelBtnClass = isF1 ? 'btn-outline-f1' : 'btn-outline-gray2'
    let continueBtnClass = isF1 ? 'btn-f1' : 'btn-purple'
    let customSelectStyle = {
        control: (provided: any, state: any) => {
            return {
                // none of react-select's styles are passed to <Control />
                ...provided,
                border: '1px solid #D0D5DD !important',
                boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
                borderRadius: '8px',
                padding: '3px 14px',
                cursor: 'pointer'
            }
        },
        valueContainer: (provided: any, state: any) => {
            return {
                ...provided,
                padding: 0
            }
        },
        option: (provided: any, state: any) => {
            return {
                ...provided,
                color: state.isDisabled ? '#98a2b3' : state.isSelected ? '#101828' : '#101828',
                cursor: 'pointer'
            }
        },
        singleValue: (provided: any, state: any) => {
            return {
                ...provided,
                color: selectedOptionIsPlaceholder ? '#667085' : '#101828',
                margin: 0
            }
        },
        indicatorsContainer: (provided: any, state: any) => {
            return {
                ...provided,
                transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : 'none'
            }
        },
        menu: (provided: any, state: any) => ({
            ...provided,
            margin: 0,
            boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
            borderRadius: '8px'
        }),
        menuList: (provided: any, state: any) => {
            return {
                ...provided,
                maxHeight: 'none'
            }
        }
    }

    return (
        <Modal
            className="d-block reseat-modal select-group-guest-modal"
            dialogClassName="m-3 m-sm-2 mx-sm-auto top-50 translate-middle-y"
            contentClassName="border-0 rounded-normal overflow-visible"
            show={visible}
            animation={false}
            scrollable
            centered
            onHide={onCancelClick}
        >
            <Modal.Body className="modal-form overflow-visible">
                <div className="px-2 pt-2 pb-0">
                    <div className="headline-3 mb-2 text-darker">Number of Guests</div>
                    <p className="paragrah mb-20">
                        Please provide the number of guests in your group.
                    </p>
                    <div>
                        <Select
                            aria-label='Number Of Guests Select Box: Please select the number of guests in your group.'
                            onChange={onSelectedOptionChange}
                            options={options as any}
                            value={selectedValue as any}
                            // defaultMenuIsOpen
                            menuPosition={'absolute'}
                            menuPlacement={'bottom'}
                            isSearchable={false}
                            styles={customSelectStyle}
                            components={{ IndicatorSeparator }}
                            // closeMenuOnSelect={false}
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                    ...theme.colors,
                                    primary25: '#FCFAFF',
                                    primary: '#FCFAFF'
                                }
                            })}
                        />
                    </div>
                </div>
            </Modal.Body>
            <div className="border-0 modal-footer pt-75 pb-4 px-4">
                <div className="w-100 m-0">
                    <div className="row row-sm my-0">
                        <div className="col-12 col-md-6">
                            <button
                                className={`btn btn-block ${cancelBtnClass} ms-0 mb75 mb-md-0 w-100`}
                                onClick={onCancelClick}
                            >
                                Cancel
                            </button>
                        </div>
                        <div className="col-12 col-md-6">
                            <button
                                aria-label='Continue Button'
                                className={`btn col-12 m-0 btn-block ${continueBtnClass} ${
                                    submitting ? 'disabled' : ''
                                }`}
                                onClick={onContinueClick}
                            >
                                {submitting ? (
                                    <FontAwesomeIcon
                                        icon={faSpinner}
                                        className="fa-spin"
                                    />
                                ) : (
                                    'Continue'
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default GuestsModal
