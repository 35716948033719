import { AddToCartItem, MemberInfo, OrderState } from '../store'
import { getTimeZone } from './dates'

type EntityMap = {
    [key: string]: string
    amp: string
    gt: string
    ldquo: string
    lt: string
    mdash: string
    nbsp: string
    quot: string
    rdquo: string
}

export const transferRichTextToText = (richText: string) => {
    const re1 = new RegExp('<.+?>', 'g')
    const arrEntities: EntityMap = {
        lt: '<',
        gt: '>',
        nbsp: ' ',
        amp: '&',
        quot: '"',
        ldquo: '“',
        mdash: '—',
        rdquo: '”'
    }

    const transferMarkdownToText = richText
        .replace(re1, '')
        .replace(/&(lt|gt|nbsp|amp|quot|ldquo|mdash|rdquo);/gi, function (_, t) {
            return arrEntities[t]
        })
    return transferMarkdownToText
}

export const pxToNumber = (px: string) => {
    var num = Number(px.replace('px', ''))
    return num
}

export function parseJwt(token: string) {
    var base64Url = token.split('.')[1]
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    var jsonPayload = decodeURIComponent(
        atob(base64)
            .split('')
            .map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
            })
            .join('')
    )

    return JSON.parse(jsonPayload)
}

export function formatMoney(value: number, currency: string) {
    let formatCurrency
    switch (currency) {
        case 'EUR':
            formatCurrency = 'EUR'
            break
        case 'GBP':
            formatCurrency = 'GBP'
            break
        default:
            formatCurrency = 'USD'
            break
    }
    return (
        new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: formatCurrency
        }).format(value) +
        ' ' +
        currency
    )
}

export function getClearTicketName(initialName: string) {
    let clearName = ''
    let isEndsWithTicket = false
    let isEndsWithTickets = false
    let splitName = initialName.split(' ')

    if (splitName.length > 1) {
        clearName = splitName[splitName.length - 1].toLowerCase()
        if (clearName === 'ticket') {
            isEndsWithTicket = true
            clearName = initialName.replace(' ticket', '').replace(' Ticket', '')
        } else if (clearName === 'tickets') {
            isEndsWithTickets = true
            clearName = initialName.replace(' tickets', '').replace(' Tickets', '')
        } else {
            clearName = initialName
        }
    } else {
        clearName = initialName
    }
    return clearName
}

export function safeEmail(email: string) {
    const [local, domain] = email.split('@')
    if (local && domain) {
        if (local.includes('**********')) {
            return local + domain
        } else if (local.length > 3) {
            return local.slice(0, 3) + '**********@' + domain
        } else {
            return local + '**********@' + domain
        }
    } else {
        return ''
    }
}

export const standardizeBrand = (brand: string) => {
    switch (brand) {
        case 'American Express':
            return 'Amex'
        case 'american_express':
            return 'Amex'
        case 'union_pay':
            return 'unionpay'
        default:
            return brand || ''
    }
}

export const brandImgUrl = (brand: string) => {
    switch (brand.toLocaleLowerCase()) {
        case 'visa':
            return '/static/img/icon/payment-icon/icon-visa.png'
        case 'discover':
            return '/static/img/icon/payment-icon/icon-discover.png'
        case 'amex':
            return '/static/img/icon/payment-icon/icon-express.png'
        case 'american express':
            return '/static/img/icon/payment-icon/icon-express.png'
        case 'american_express':
            return '/static/img/icon/payment-icon/icon-express.png'
        case 'mastercard':
            return '/static/img/icon/payment-icon/icon-mastercard.png'
        default:
            return '/static/img/icon/payment-icon/icon-credit-card-v1.png'
    }
}

export function brandImgSize(brand: string) {
    switch (brand.toLocaleLowerCase()) {
        case 'visa':
            return { height: '18px', width: '33px' }
        case 'discover':
            return { height: '18px', width: '33px' }
        case 'amex':
            return { height: '18px', width: '33px' }
        case 'american express':
            return { height: '18px', width: '33px' }
        case 'american_express':
            return { height: '18px', width: '33px' }
        case 'mastercard':
            return { height: '18px', width: '33px' }
        default:
            return { height: '26px', width: '33px' }
    }
}

export function scrollToElement(id: string) {
    let anchorElement = document.getElementById(id)
    if (anchorElement) {
        anchorElement.scrollIntoView({ block: 'start', behavior: 'smooth' })
    }
}

export function getDayOfWeek(date: any, timezone: string) {
    const dayOfWeek = getTimeZone(date, timezone).day()
    return isNaN(dayOfWeek)
        ? null
        : ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][dayOfWeek]
}

export function getRoomQty(addToCartItems: AddToCartItem[]) {
    const rooms = addToCartItems.filter((r) => r.type === 'room.tier')
    const roomQty = rooms.reduce((carry, currency) => {
        return carry + currency.quantity
    }, 0)
    return roomQty
}

export function getDeviceFromUserAgent(ua: string) {
    let isWindowsPhone = /(?:Windows Phone)/.test(ua)
    let isSymbian = /(?:SymbianOS)/.test(ua) || isWindowsPhone
    let isAndroid = /(?:Android)/.test(ua)
    let isFireFox = /(?:Firefox)/.test(ua)
    let isChrome = /(?:Chrome|CriOS)/.test(ua)
    let isTablet =
        /(?:iPad|PlayBook)/.test(ua) ||
        (isAndroid && !/(?:Mobile)/.test(ua)) ||
        (isFireFox && /(?:Tablet)/.test(ua))
    let isPhone = /(?:iPhone)/.test(ua) && !isTablet
    let isPc = !isPhone && !isAndroid && !isSymbian
    return {
        isTablet: isTablet,
        isMobile: isPhone || isAndroid,
        isPc: isPc
    }
}

export function handleAmountsPoint(amounts: string) {
    const removeDoubleZerosRegex = /\.00\b/g
    const result = amounts.replace(removeDoubleZerosRegex, '')
    return result
}

export const insertDecimal = (number: number, position = 2) => {
    const decimalMultiplier = Math.pow(10, position)
    const result = number / decimalMultiplier
    return parseFloat(result.toFixed(2))
}

export function totalSleeps(assignSleeps: any) {
    let sleep = 0
    for (let i = 0; i < assignSleeps.length; i++) {
        sleep += assignSleeps[i] * (i + 1)
    }
    return sleep
}

export const getGroupSizeOfDeposit = (orderData: OrderState, member: MemberInfo) => {
    let groupSize = orderData.groupSize
    const memberDepositIsEqual = orderData.members.every(
        (member) =>
            member.DepositAmount == orderData.members[0].DepositAmount &&
            member.DepositAmount !== orderData.purchaseTimeEventDepositAmount
    )
    switch (true) {
        case memberDepositIsEqual:
            groupSize = orderData.groupSize
            break
        case member.Primary:
            groupSize = orderData.groupSize - orderData.members.length + 1
            break
        default:
            groupSize = 1
            break
    }
    return groupSize
}

export function carryBit(choice: any, assignSleepMaxOccupancy: any, total: any, i: number) {
    let checkIfNeedToCarryBit = false
    if (choice[i] > assignSleepMaxOccupancy[i]) {
        checkIfNeedToCarryBit = true
    } else {
        if (totalSleeps(choice) - (i + 1) >= total) {
            checkIfNeedToCarryBit = true
        }
    }
    switch (true) {
        case choice[i] > assignSleepMaxOccupancy[i]:
            checkIfNeedToCarryBit = true
            break
        case totalSleeps(choice) - (i + 1) >= total:
            checkIfNeedToCarryBit = true
            break
        default:
            break
    }
    if (!checkIfNeedToCarryBit) return

    choice[i] = 0
    switch (i + 1) {
        case choice.length:
            choice.push(-1)
            break
        default:
            choice[i + 1] += 1
            carryBit(choice, assignSleepMaxOccupancy, total, i + 1)
            break
    }
}

export function nextAssignSleeps(
    thisAssignSleeps: any[],
    assignSleepMaxOccupancy: any,
    groupSize: number
) {
    let choice = [...thisAssignSleeps]
    while (true) {
        choice[0] += 1
        carryBit(choice, assignSleepMaxOccupancy, groupSize, 0)

        if (choice[choice.length - 1] == -1) {
            return null
        }

        if (totalSleeps(choice) == groupSize) {
            let sum = 0
            for (let i = 0; i < choice.length; i++) {
                sum += choice[i]
            }
            if (sum <= 4) return choice
        }
    }
}

export function newAssignRoom(groupSize: number) {
    let assignSleeps = []
    let assignSleepMaxOccupancy = []
    for (let i = 1; i <= 8; i++) {
        assignSleeps.push(0)
        assignSleepMaxOccupancy.push(Math.ceil(groupSize / i))
    }

    let assignRoomArray: any[] = []
    let eachChoice: any[] | null = [...assignSleeps]
    while (true) {
        eachChoice = nextAssignSleeps(eachChoice, assignSleepMaxOccupancy, groupSize)
        if (!eachChoice) {
            break
        }
        assignRoomArray.push(...[eachChoice])
    }
    return assignRoomArray
}

export function getRoomQuantity(list: any[]) {
    let roomIdArray = list.map((item) => {
        return item.roomID
    })

    var getRoomQuantityData: Record<string, number> = {}
    for (var i = 0; i < roomIdArray.length; i++) {
        var item = roomIdArray[i]
        getRoomQuantityData[item] = getRoomQuantityData[item] + 1 || 1
    }
    return getRoomQuantityData
}

export function isSubset(subsetArray: any[], largeSubsetArray: any[]) {
    return subsetArray.every((item) => {
        return largeSubsetArray.includes(item)
    })
}

export const capitalizeFirstLetter = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1)
}

export const deepCopy = (data: any) => {
    return JSON.parse(JSON.stringify(data))
}
