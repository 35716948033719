import { Box } from '@mui/material'
import Image from 'components/image'
import Jdenticon from 'components/jdenticon'
import { SIZE_32 } from 'styles/theme'

type Props = {
    profileImageUrl: string
    userName: string
}

export const UserProfileAvatar = ({ profileImageUrl, userName }: Props) => {
    return (
        <Box
            sx={{
                backgroundColor: '#f4ebff',
                borderRadius: '50%',
                height: SIZE_32,
                overflow: 'hidden',
                position: 'relative',
                width: SIZE_32
            }}
        >
            {profileImageUrl ? (
                <Image
                    src={profileImageUrl}
                    alt="profile image"
                    height={32}
                    width={32}
                    unoptimized={true}
                />
            ) : (
                <Jdenticon
                    size={32}
                    value={userName}
                />
            )}
        </Box>
    )
}
