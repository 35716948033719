import { DateRangePickerDay } from '@mui/x-date-pickers-pro'
import { useStyles, getDateRangePickerDaySx } from './datepicker.styles'
import { Badge } from '@mui/material'
import dayjs, { Dayjs } from 'dayjs'

export type DateRangeDayProps = {
    day: Dayjs
    isEndOfHighlighting: boolean
    isEndOfPreviewing: boolean
    isFirstVisibleCell: boolean
    isHighlighting: boolean
    isLastVisibleCell: boolean
    isPreviewing: boolean
    isStartOfHighlighting: boolean
    isStartOfPreviewing: boolean
    onDaySelect: (day: Dayjs) => void
    outsideCurrentMonth: boolean
    selected: boolean
}

export const DateRangeDayComponent = ({
    day = dayjs(),
    isEndOfHighlighting,
    isEndOfPreviewing,
    isHighlighting,
    isPreviewing,
    isStartOfHighlighting,
    isStartOfPreviewing,
    selected,
    ...props
}: DateRangeDayProps): JSX.Element => {
    const { classes } = useStyles()
    const isStartOrEndOfHighlighting = isEndOfHighlighting || isStartOfHighlighting
    const isStartOrEndOfPreviewing = isEndOfPreviewing || isStartOfPreviewing
    const badgeColor = isHighlighting && isStartOrEndOfHighlighting ? 'secondary' : 'primary'
    const dateRangePickerDaySx = getDateRangePickerDaySx(
        isHighlighting,
        isStartOrEndOfHighlighting,
        isStartOrEndOfPreviewing,
        isPreviewing,
        selected,
        isStartOfPreviewing,
        isEndOfPreviewing
    )

    return (
        <Badge
            anchorOrigin={{
                horizontal: 'left',
                vertical: 'bottom'
            }}
            badgeContent=" "
            className={classes.badge}
            color={badgeColor}
            invisible={!isHighlighting}
            overlap="circular"
            variant="dot"
        >
            <DateRangePickerDay
                {...props}
                day={day!}
                disableHighlightToday
                disableRipple
                disableTouchRipple
                isEndOfHighlighting={isEndOfHighlighting}
                isEndOfPreviewing={isEndOfPreviewing}
                isHighlighting={isHighlighting}
                isPreviewing={isPreviewing}
                isStartOfHighlighting={isStartOfHighlighting}
                isStartOfPreviewing={isStartOfPreviewing}
                sx={dateRangePickerDaySx}
            />
        </Badge>
    )
}
