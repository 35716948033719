import {
    COLOR_GRAY_800,
    COLOR_PRIMARY_100,
    COLOR_PRIMARY_500,
    COLOR_WHITE,
    FONT_SIZE,
    FONT_SIZE_12,
    FONT_SIZE_14,
    FONT_SIZE_18,
    FONT_SIZE_20,
    FONT_SIZE_24,
    FONT_SIZE_32,
    FONT_SIZE_64,
    FONT_SIZE_8,
    FONT_WEIGHT_500,
    FONT_WEIGHT_600,
    FONT_WEIGHT_700,
    FULL_WIDTH,
    HALF_WIDTH,
    SIZE_2,
    SIZE_4,
    SIZE_8,
    SIZE_12,
    SIZE_16,
    SIZE_20,
    SIZE_22,
    SIZE_24,
    SIZE_28,
    SIZE_30,
    SIZE_32,
    SIZE_36,
    SIZE_40,
    SIZE_56,
    SIZE_60,
    SIZE_100,
    SIZE_120,
    theme
} from 'styles/theme'
import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()({
    container: {
        flexShrink: 0,
        maxWidth: FULL_WIDTH,
        padding: `0 ${SIZE_16}`,
        width: FULL_WIDTH,
        '@media (min-width: 768px)': {
            flex: '0 0 auto'
        }
    },
    event: {
        padding: `${SIZE_36} ${SIZE_20} 0`,
        position: 'relative',
        '@media (min-width: 768px)': {
            padding: `${SIZE_56} ${SIZE_32} 0`,
            width: HALF_WIDTH
        },
        '::after': {
            background: COLOR_PRIMARY_500,
            borderRadius: SIZE_2,
            content: '""',
            display: 'block',
            height: SIZE_16,
            left: `-${SIZE_8}`,
            position: 'absolute',
            top: SIZE_4,
            transform: 'rotate(45deg)',
            width: SIZE_16
        },
        '::before': {
            color: COLOR_PRIMARY_500,
            content: 'attr(data-date)',
            display: 'block',
            fontSize: FONT_SIZE,
            fontWeight: FONT_WEIGHT_500,
            left: SIZE_22,
            lineHeight: SIZE_24,
            minWidth: SIZE_120,
            position: 'absolute',
            top: 0,
            '@media (min-width: 768px)': {
                color: COLOR_GRAY_800,
                fontSize: FONT_SIZE_18,
                fontWeight: FONT_WEIGHT_600,
                left: FONT_SIZE_32,
                lineHeight: SIZE_28,
                top: `-${SIZE_2}`
            }
        }
    },
    header: {
        fontSize: FONT_SIZE_20,
        fontWeight: FONT_WEIGHT_700,
        lineHeight: 1.5,
        marginBottom: FONT_SIZE_12,
        '@media (min-width: 768px)': {
            fontSize: SIZE_30,
            marginBottom: SIZE_40,
            textAlign: 'center'
        }
    },
    left: {
        '@media (min-width: 768px)': {
            right: 0,
            textAlign: 'right',
            '& li': {
                paddingRight: FONT_SIZE_24
            },
            '& ul': {
                paddingLeft: 0,
                paddingRight: FONT_SIZE
            },
            '::after': {
                left: 'auto',
                right: `-${SIZE_8}`
            },
            '::before': {
                left: 'auto',
                right: FONT_SIZE_32
            }
        }
    },

    marker: {
        display: 'none',
        '@media (min-width: 768px)': {
            background: theme.palette.secondary.light,
            border: `${SIZE_2} solid ${COLOR_PRIMARY_100}`,
            borderRadius: FULL_WIDTH,
            display: 'inline-block',
            fontSize: FONT_SIZE_12,
            height: SIZE_16,
            lineHeight: SIZE_16,
            position: 'absolute',
            right: 0,
            textAlign: 'center',
            top: FONT_SIZE_12,
            transform: 'translateY(-50%)',
            width: SIZE_16
        },
        '& svg': {
            color: COLOR_WHITE,
            fontSize: SIZE_8,
            left: HALF_WIDTH,
            position: 'absolute',
            top: HALF_WIDTH,
            transform: 'translate(-50%, -50%)'
        }
    },
    right: {
        '@media (min-width: 768px)': {
            left: HALF_WIDTH,
            '& li': {
                paddingLeft: FONT_SIZE_24
            },
            '& ul': {
                paddingLeft: FONT_SIZE
            }
        }
    },
    text: {
        fontSize: FONT_SIZE_14,
        lineHeight: SIZE_20,
        listStyle: 'disc',
        marginBottom: FONT_SIZE_8,
        '@media (min-width: 768px)': {
            fontSize: FONT_SIZE,
            lineHeight: SIZE_24,
            listStyle: 'none',
            position: 'relative',
            marginBottom: FONT_SIZE_12
        },
        ':last-of-type': {
            marginBottom: FONT_SIZE
        }
    },
    timeLine: {
        letterSpacing: '0.2px',
        listStyle: 'none',
        padding: `${FONT_SIZE_8} ${FONT_SIZE_32}`,
        paddingTop: SIZE_4,
        position: 'relative',
        margin: '0 auto',
        textAlign: 'left',
        '@media (min-width: 768px)': {
            padding: `${FONT_SIZE_64} ${FONT_SIZE_32}`
        },
        '::after': {
            background: COLOR_PRIMARY_500,
            borderRadius: SIZE_2,
            bottom: 0,
            content: '""',
            left: FONT_SIZE_32,
            marginLeft: `-${SIZE_2}`,
            position: 'absolute',
            top: 0,
            width: SIZE_4,
            '@media (min-width: 768px)': {
                left: HALF_WIDTH
            }
        }
    },
    wrapper: {
        margin: '0 auto',
        marginBottom: SIZE_60,
        padding: `0 ${SIZE_12}`,
        width: FULL_WIDTH,
        '@media (min-width: 576px)': {
            maxWidth: '540px'
        },
        '@media (min-width: 768px)': {
            marginBottom: SIZE_100,
            maxWidth: '720px'
        },
        '@media (min-width: 992px)': {
            maxWidth: '960px'
        },
        '@media (min-width: 1200px)': {
            maxWidth: '1140px'
        },
        '@media (min-width: 1400px)': {
            maxWidth: '1320px'
        }
    }
})
