import { EventJSON } from '../../../events-service/src/models/event'
import { OrderState, ShoppingCartState } from '../store'
import { HotelJSON } from '../../../events-service/src/models/hotel'
import { TourJSON } from '../../../events-service/src/models/tour'

export const getMparticleProducts = (
    cart: ShoppingCartState,
    event: EventJSON
): mParticle.Product[] => {
    const products: mParticle.Product[] = []
    const hotel = event!.hotels.find((h) => {
        return h.rooms.find((r) => {
            return cart.items.find((i) => i.itemID === r.id && i.type === 'room')
        })
    })!
    cart.items.forEach((cartItem, _) => {
        let item: mParticle.Product
        if (cartItem.type === 'room.tier') {
            const room = hotel.rooms.find((room) => room.id === cartItem.parent)!
            const tier = room.tiers.find((tier) => tier.id === cartItem.itemID)!
            const customAttributes = { tier: tier.name }
            // TODO:? separate into a function and use a model to test these arguments/parameters?
            item = window.mParticle.eCommerce.createProduct(
                room.name, // label
                room.id, // sku
                // TODO: check format should be decimal
                cartItem.price / 100, // price
                cartItem.quantity, // quantity
                undefined, // variant
                'accommodation', // category
                hotel.name, // brand
                undefined, // position
                undefined, // coupon code
                customAttributes
            )
            products.push(item)
        } else if (cartItem.type === 'ticket.tier' || cartItem.type === 'tier') {
            const ticket = event.tickets.find((ticket) => ticket.id === cartItem.parent)!
            const tier = ticket.tiers.find((tier) => tier.id === cartItem.itemID)!
            const customAttributes = { tier: tier.name }
            // TODO:? separate into a function and use a model to test these arguments/parameters?
            item = window.mParticle.eCommerce.createProduct(
                ticket.name, // label
                ticket.id, // sku
                // TODO: check format should be decimal
                cartItem.price / 100, // price
                cartItem.quantity, // quantity
                undefined, // variant
                'ticket', // category
                event.name, // brand
                undefined, // position
                undefined, // coupon code
                customAttributes
            )
            products.push(item)
        } else if (cartItem.type === 'item') {
            const addsOn = event.addOnItems.find((addOn) => addOn.id === cartItem.itemID)!
            // TODO:? separate into a function and use a model to test these arguments/parameters?
            item = window.mParticle.eCommerce.createProduct(
                addsOn.name, // label
                addsOn.id, // sku
                // TODO: check format should be decimal
                cartItem.price / 100, // price
                cartItem.quantity, // quantity
                undefined, // variant
                'addOn', // category
                event.name, // brand
                undefined, // position
                undefined // coupon code
            )
            products.push(item)
        }
    })

    return products
}

export const addAccommodationViewItemToMparticle = (hotel: HotelJSON) => {
    const rooms_available = hotel.rooms.filter(
        (room) => room.quantityAvailable > 0 && room.tiers.some((tier) => tier.onSale)
    )
    if (window.mParticle) {
        window.mParticle.ready(() => {
            window.mParticle.logPageView(
                'Accommodation Detail',
                {
                    page: window.location.toString(),
                    accommodation_type: 'hotel',
                    accommodation_name: hotel.name,
                    rooms_names: hotel.rooms.map((r) => r.name).toString(),
                    rooms_count: hotel.rooms.length,
                    rooms_available_count: rooms_available.length,
                    rooms_available: rooms_available.map((r) => r.name).toString()
                },
                {
                    'GA4.location': window.location.toString(),
                    'GA4.title': 'Accommodation Detail'
                }
            )
        })
    }
}

export const addTourDetailViewToMparticle = (tour: TourJSON) => {
    if (window.mParticle) {
        window.mParticle.ready(() => {
            window.mParticle.logPageView(
                'Tour Detail',
                {
                    page: window.location.toString(),
                    tour_name: tour.name
                },
                {
                    'GA4.location': window.location.toString(),
                    'GA4.title': 'Tour Detail'
                }
            )
        })
    }
}

export const addEventDetailViewToMparticle = (
    event: EventJSON,
    event_tickets_available?: any,
    event_accommodations_available?: any
) => {
    if (window.mParticle) {
        window.mParticle.ready(() => {
            window.mParticle.logPageView(
                'Event Detail',
                {
                    page: window.location.toString(),
                    event_name: event.name,
                    event_tags: event.tags.map((t) => t.label).toString(),
                    event_location: event.location,
                    event_venue: event.locationDisplay,
                    event_startDate: event.startDate.toString(),
                    event_endDate: event.endDate.toString(),
                    event_tickets_names: event.tickets?.map((t) => t.name).toString(),
                    event_tickets_count: event.tickets?.length,
                    event_tickets_available: event_tickets_available
                        ?.map((t: any) => t.name)
                        .toString(),
                    event_tickets_available_count: event_tickets_available?.length,
                    accommodation_types: 'hotel',
                    accommodation_names: event.hotels?.map((h) => h.name).toString(),
                    accommodation_ratings: event.hotels
                        ?.map((h) => h.rating.toString() + ' rating')
                        .toString(),
                    accommodations_count: event.hotels?.length,
                    accommodations_available_count: event_accommodations_available?.length,
                    accommodations_available: event_accommodations_available
                        ?.map((h: any) => h.name)
                        .toString()
                },
                {
                    'GA4.location': window.location.toString(),
                    'GA4.title': 'Event Detail'
                }
            )
        })
    }
}

export const addToCartMparticle = (products: mParticle.Product[]) => {
    if (products.length > 0) {
        if (window.mParticle) {
            window.mParticle.ready(() => {
                window.mParticle.eCommerce.logProductAction(
                    window.mParticle.ProductActionType.AddToCart,
                    products,
                    {
                        'GA4.location': window.location.toString(),
                        'GA4.title': 'Add to cart'
                    } // custom attributes
                )
                console.log('mParticle logProductAction AddToCart', products)
                return products
            })
        }
    }
}

export const removeFromCartMparticle = (products: mParticle.Product[]) => {
    if (products.length > 0) {
        if (window.mParticle) {
            window.mParticle.ready(() => {
                window.mParticle.eCommerce.logProductAction(
                    window.mParticle.ProductActionType.RemoveFromCart,
                    products,
                    {
                        'GA4.location': window.location.toString(),
                        'GA4.title': 'Remove from cart'
                    } // custom attributes
                )
                console.log('mParticle logProductAction RemoveFromCart', products)
                return products
            })
        }
    }
}

export const addOrderToMparticle = (
    event: EventJSON,
    order: OrderState,
    email: string,
    is_split_pay: boolean,
    is_installment: boolean
) => {
    const hotel = event!.hotels.find((h) => {
        return h.rooms.find((r) => {
            return r.tiers.find((t) => {
                return order.items.find((i) => i.itemID === t.id)
            })
        })
    })!

    let products: mParticle.Product[] = []
    order.items.forEach((item, _) => {
        let product: mParticle.Product
        if (item.type === 'room.tier') {
            const room = hotel.rooms.find((room) => room.id === item.parent)!
            const tier = room.tiers.find((tier) => tier.id === item.itemID)!
            const customAttributes = { tier: tier.name }
            // TODO:? separate into a function and use a model to test these arguments/parameters?
            product = window.mParticle.eCommerce.createProduct(
                `${event.name} ${room.name}`, // label
                room.id, // sku
                // TODO: check format should be decimal
                item.price / 100, // price
                item.quantity, // quantity
                undefined, // variant
                `${event.name} accommodation`, // category
                `${event.name} ${hotel.name}`, // brand
                undefined, // position
                undefined, // coupon code
                customAttributes
            )
            products.push(product)
        } else if (item.type === 'ticket.tier' || item.type === 'tier') {
            const ticket = event.tickets.find((ticket) => ticket.id === item.parent)!
            const tier = ticket.tiers.find((tier) => tier.id === item.itemID)!
            const customAttributes = { tier: tier.name }
            // TODO:? separate into a function and use a model to test these arguments/parameters?
            product = window.mParticle.eCommerce.createProduct(
                `${event.name} ${ticket.name}`, // name
                ticket.id, // sku
                // TODO: check format should be decimal
                item.price / 100, // price
                item.quantity, // quantity
                undefined, // variant
                `${event.name} ticket`, // category
                event.name, // brand
                undefined, // position
                undefined, // coupon code
                customAttributes
            )
            products.push(product)
        } else if (item.type === 'item') {
            const addsOn = event.addOnItems.find((addOn) => addOn.id === item.itemID)!
            // TODO:? separate into a function and use a model to test these arguments/parameters?
            product = window.mParticle.eCommerce.createProduct(
                `${event.name} ${addsOn.name}`, // label
                addsOn.id, // sku
                // TODO: check format should be decimal
                item.price / 100, // price
                item.quantity, // quantity
                undefined, // variant
                `${event.name} addOn`, // category
                event.name, // brand
                undefined, // position
                undefined // coupon code
            )
            products.push(product)
        }
    })

    const transactionAttributes = {
        Id: order.id,
        Revenue: order.total / 100, // total
        Tax: order.taxesAndFees / 100
    }
    if (window.mParticle) {
        window.mParticle.ready(() => {
            window.mParticle.eCommerce.logProductAction(
                window.mParticle.ProductActionType.Purchase,
                products,
                {
                    currency: 'USD',
                    paid_in_installments: is_installment,
                    paid_in_full: !is_installment,
                    split_payment: is_split_pay,
                    split_payment_group_size: is_split_pay ? order.members.length : undefined,
                    tax: order.taxesAndFees / 100,
                    transaction_id: order.id,
                    value: order.total / 100
                    // currency is currently set in _app.tsx
                }, // customAttributes
                {
                    'GA4.CommerceEventType': 'purchase',
                    'GA4.Location': window.location.toString(),
                    'GA4.Title': 'Purchase',
                    currency: 'USD',
                    transaction_id: order.id,
                    tax: order.taxesAndFees / 100,
                    value: order.total / 100
                }, // custom flags
                transactionAttributes
            )
        })
    }
}

// type ex Sign In, Sign Up, Set Password
// status = Attempted, Succeeded, Failed
export const sendMultiStateCustomEventToMparticle = (
    data: any,
    type: string,
    status?: '' | 'Attempted' | 'Succeeded' | 'Failed',
    mparticleEventType?: mParticle.EventType
) => {
    const attributes: mParticle.SDKEventAttrs = {
        page: window.location.toString()
    }

    if (type == 'Sign In' || type == 'Sign Up') {
        // Google
        if (data.profileObj?.email) {
            attributes.login_method = 'Google'
        } else if (data.source) {
            attributes.login_method = data.source
        }
    }

    let auth: any
    if (typeof localStorage !== 'undefined') {
        let authStr = '{"isLoggedIn":false,"jwt":"","refresh":"","user":{}}'
        authStr = localStorage.getItem('auth') || authStr
        auth = JSON.parse(authStr || '{}')
    }

    // login or log out of mParticle using their "IDSync"
    if (status == 'Succeeded' && auth.isLoggedIn) {
        var identityRequest: mParticle.IdentifyRequest

        if (type == 'Sign In') {
            identityRequest = {
                userIdentities: {
                    email: auth.user.email,
                    customerid: auth.user.id
                }
            }
            identityRequest.userIdentities.mobile_number = auth.user.phone ?? undefined

            var identityCallback = function (result: any) {
                if (result.getUser()) {
                    const user = result.getUser()
                    user.setUserAttribute('firstName', auth.user.firstName)
                    user.setUserAttribute('lastName', auth.user.lastName)
                    user.setUserAttribute('mobile', auth.user.billingInfo?.billingAddress?.phone)
                    user.setUserAttribute('address', auth.user.billingInfo?.billingAddress?.address)
                    user.setUserAttribute('city', auth.user.billingInfo?.billingAddress?.city)
                    user.setUserAttribute('state', auth.user.billingInfo?.billingAddress?.state)
                    user.setUserAttribute('zip', auth.user.billingInfo?.billingAddress?.zip)
                    user.setUserAttribute('country', auth.user.billingInfo?.billingAddress?.country)
                }
            }
            if (window.mParticle) {
                window.mParticle.ready(() => {
                    window.mParticle.Identity.login(identityRequest, identityCallback)
                })
            }
        }

        if (type == 'Update Email') {
            identityRequest = {
                userIdentities: {
                    email: data.user.email
                }
            }
            var identityCallback = function (result: any) {
                if (result.getUser()) {
                    // not sure if anything is needed here
                }
            }
            if (window.mParticle) {
                window.mParticle.ready(() => {
                    window.mParticle.Identity.modify(identityRequest, identityCallback)
                })
            }
        }
    }

    if (!mparticleEventType) {
        mparticleEventType = window.mParticle.EventType.Other
    }

    if (data.error_message) {
        attributes.error_message = data.error_message
    }

    if (data.faq_question && data.faq_answer) {
        attributes.faq_question = data.faq_question
        attributes.faq_answer = data.faq_answer
    }

    if (status) {
        if (window.mParticle) {
            window.mParticle.ready(() => {
                window.mParticle.logEvent(`${type} -- ${status}`, mparticleEventType, attributes)
            })
        }
    } else {
        if (window.mParticle) {
            window.mParticle.ready(() => {
                window.mParticle.logEvent(`${type}`, mparticleEventType, attributes)
            })
        }
    }
}
