import ButtonBase from '@mui/material/ButtonBase'
import Link from 'next/link'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import { ListItemIcon } from '@mui/material'
import { faUserAstronaut, faSpa } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    BORDER_RADIUS,
    BORDER,
    COLOR_BLACK,
    COLOR_PRIMARY_600,
    FONT_SIZE_18,
    MENU_BOX_SHADOW,
    SIZE_16,
    SIZE_24,
    SIZE_8
} from 'styles/theme'

type Props = {
    anchorEl: HTMLElement | null
    handleUrl: () => void
    hasUserName: boolean
    isMenuOpen: boolean
    isNotHomePage: boolean
    logout: () => void
    setAuthState: ({ isLoggedIn }: { isLoggedIn: boolean }) => void
    setShouldShowDropdown: (event: any) => void
}

export const AccountMenu = ({
    anchorEl,
    handleUrl,
    hasUserName,
    isMenuOpen,
    isNotHomePage,
    logout,
    setShouldShowDropdown
}: Props) => {
    const menuItems = [
        {
            href: '/account',
            icon: faUserAstronaut,
            text: 'Account'
        },
        {
            href: '/orders/my-experience',
            icon: faSpa,
            text: 'Experiences'
        },
        {
            onClick: () => {
                handleUrl()
                logout()
            },
            text: 'Sign Out'
        }
    ]
    return (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
                horizontal: 'left',
                vertical: 'bottom'
            }}
            disableScrollLock
            id="account-menu"
            transformOrigin={{
                horizontal: 'center',
                vertical: 'top'
            }}
            onClose={setShouldShowDropdown}
            open={isMenuOpen}
            PaperProps={{
                style: {
                    borderRadius: BORDER_RADIUS,
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: 0,
                    boxShadow: MENU_BOX_SHADOW,
                    width: '280px'
                }
            }}
            sx={{
                marginTop: SIZE_24,
                // This is the offset for the menu to align to the body container
                marginLeft: hasUserName ? '13px' : isNotHomePage ? '-56px' : '-71px'
            }}
        >
            {menuItems.map((item, index: number) => (
                <MenuItem
                    aria-label={`${item.text} menu item`}
                    disableRipple
                    key={index}
                    onClick={setShouldShowDropdown}
                    sx={{
                        border: '1px solid transparent',
                        borderBottom: index !== menuItems.length - 1 ? BORDER : undefined,
                        transition: '.2s ease-in-out',
                        '&:hover': {
                            backgroundColor: 'transparent'
                        },
                        width: '100%'
                    }}
                >
                    <Link
                        aria-label={`${item.text} menu item link`}
                        href={(item.href as string) ?? '/logout'}
                        passHref
                        style={{
                            boxShadow: '0 1px 0 #f2f4f7',
                            textDecoration: 'none'
                        }}
                    >
                        <a
                            aria-label={`${item.text} menu item link element`}
                            aria-current="page"
                            style={{
                                textAlign: 'left',
                                textDecoration: 'none',
                                width: '100%'
                            }}
                        >
                            {!item.onClick ? (
                                <div
                                    style={{
                                        alignItems: 'center',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        padding: `${SIZE_8} 0`
                                    }}
                                >
                                    <div
                                        style={{
                                            marginRight: SIZE_8
                                        }}
                                    >
                                        {item.icon && (
                                            <ListItemIcon
                                                style={{
                                                    color: COLOR_PRIMARY_600,
                                                    marginRight: SIZE_8,
                                                    minWidth: 'auto'
                                                }}
                                            >
                                                <FontAwesomeIcon
                                                    className="fa-regular fa-fw"
                                                    icon={item.icon}
                                                    style={{
                                                        fontSize: FONT_SIZE_18
                                                    }}
                                                />
                                            </ListItemIcon>
                                        )}
                                    </div>
                                    <div>
                                        <Typography
                                            style={{
                                                color: COLOR_BLACK,
                                                fontSize: FONT_SIZE_18
                                            }}
                                        >
                                            {item.text}
                                        </Typography>
                                    </div>
                                </div>
                            ) : (
                                <div
                                    style={{
                                        paddingTop: SIZE_16,
                                        paddingBottom: SIZE_8
                                    }}
                                >
                                    <ButtonBase
                                        aria-label="button icon"
                                        className="btn btn-light btn-block"
                                        onClick={item.onClick}
                                        sx={{
                                            '&:hover': {
                                                color: COLOR_PRIMARY_600
                                            },
                                            border: BORDER,
                                            borderRadius: BORDER_RADIUS,
                                            color: COLOR_BLACK,
                                            fontWeight: 500,
                                            padding: '16px',
                                            width: '100%'
                                        }}
                                    >
                                        {item.text}
                                    </ButtonBase>
                                </div>
                            )}
                        </a>
                    </Link>
                </MenuItem>
            ))}
        </Menu>
    )
}
