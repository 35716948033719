import { useEffect, useRef, useState } from 'react'
import { COLOR_GRAY_800, COLOR_WHITE, FONT_SIZE_14 } from 'styles/theme'

export type CountDownType = {
    format?: 'hh:mm:ss' | 'hh:mm' | 'mm:ss'
    isScrolled: boolean
    onExpiredCallBack?: () => void
    pause: boolean
    text: string
    time: string | Date
}

const getCountDown = (time: string | Date) => {
    const expiredTime = typeof time === 'string' ? new Date(time) : time
    const nowTime = new Date()
    const differenceOfSeconds = Math.floor((expiredTime.getTime() - nowTime.getTime()) / 1000)
    const hours = differenceOfSeconds >= 3600 ? Math.floor(differenceOfSeconds / 3600) : 0
    const minutes =
        differenceOfSeconds >= 60 ? Math.floor((differenceOfSeconds - hours * 3600) / 60) : 0
    const seconds =
        differenceOfSeconds > 0 ? Math.floor(differenceOfSeconds - hours * 3600 - minutes * 60) : 0
    return {
        hours: hours,
        minutes: minutes,
        seconds: seconds
    }
}

const formatTime = (t: number) => t.toString().padStart(2, '0')

const CountDown = ({
    isScrolled,
    time,
    text,
    pause,
    format = 'mm:ss',
    onExpiredCallBack
}: CountDownType) => {
    const ref = useRef<any>()
    const [timeDifference, setTimeDifference] = useState(() => getCountDown(time))

    useEffect(() => {
        if (!pause) {
            ref.current = setInterval(() => {
                setTimeDifference(() => {
                    return getCountDown(time)
                })
            }, 1000)
        }
        return () => {
            clearInterval(ref.current)
        }
    }, [pause, time])

    useEffect(() => {
        if (
            timeDifference.hours === 0 &&
            timeDifference.minutes === 0 &&
            timeDifference.seconds === 0
        ) {
            clearInterval(ref.current)
            onExpiredCallBack && onExpiredCallBack()
        }
    }, [timeDifference.hours, timeDifference.minutes, timeDifference.seconds])

    let formatText = ''
    switch (format) {
        case 'hh:mm:ss':
            formatText = `${formatTime(timeDifference.hours)}:${formatTime(
                timeDifference.minutes
            )}:${formatTime(timeDifference.seconds)}`
            break
        case 'hh:mm':
            formatText = `${formatTime(timeDifference.hours)}:${formatTime(timeDifference.minutes)}`
            break
        case 'mm:ss':
            formatText = `${timeDifference.minutes}:${formatTime(timeDifference.seconds)}`
            break
    }
    let reservedBar: any = null
    if (format === 'mm:ss') {
        const textStr = text.substring(0, text.indexOf('m'))
        reservedBar = (
            <div className="reserved-bar">
                <p
                    className="mb-0 text-start"
                    style={{
                        color: isScrolled ? COLOR_GRAY_800 : COLOR_WHITE,
                        fontSize: FONT_SIZE_14,
                        fontWeight: 500
                    }}
                >
                    {textStr}
                    <span
                        className="d-inline-block"
                        style={{ minWidth: '32px' }}
                    >
                        {formatText}
                    </span>
                </p>
            </div>
        )
    } else {
        reservedBar = (
            <div className="reserved-bar">
                <p
                    className="mb-0 text-center"
                    dangerouslySetInnerHTML={{ __html: text.replace(format, formatText) }}
                ></p>
            </div>
        )
    }
    return reservedBar
}

export default CountDown
