import ButtonBase from '@mui/material/ButtonBase'
import IconButton from '@mui/material/IconButton'
import { COLOR_BLACK, COLOR_WHITE, SIZE_8 } from 'styles/theme'
import { Fade as Hamburger } from 'hamburger-react'
import { isMobile } from 'react-device-detect'
import { UserProfileAvatar } from 'components/UserAccount'

export type MobileNavbarComponentType = {
    handleNavClick?: () => void
    isLoggedIn: boolean
    isNavExpanded: boolean
    isNotHomePage: boolean
    isScrolled: boolean
    userName: string
    userProfileImage: string
}

export const MobileNavbarComponent = ({
    handleNavClick,
    isLoggedIn,
    isNavExpanded,
    isNotHomePage,
    isScrolled,
    userName,
    userProfileImage
}: MobileNavbarComponentType): JSX.Element => {
    const isDarkColor = isNavExpanded || isScrolled || (isMobile && isScrolled) || isNotHomePage
    const hamburgerButton = (
        <Hamburger
            label="Mobile Nav Menu Button"
            color={isDarkColor ? COLOR_BLACK : COLOR_WHITE}
            direction="right"
            size={20}
            toggle={handleNavClick}
            toggled={isNavExpanded}
        />
    )

    return isLoggedIn ? (
        isNavExpanded ? (
            <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="mobile nav menu"
                style={{ padding: 0 }}
            >
                {hamburgerButton}
            </IconButton>
        ) : (
            <ButtonBase
                aria-label="Mobile Nav Menu"
                className="rounded-circle mine-portrait"
                onClick={handleNavClick}
                style={{ cursor: 'pointer', marginRight: SIZE_8 }}
            >
                <UserProfileAvatar
                    profileImageUrl={userProfileImage}
                    userName={userName}
                />
            </ButtonBase>
        )
    ) : (
        <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="mobile nav menu"
            style={{ marginRight: 0, padding: 0 }}
        >
            {hamburgerButton}
        </IconButton>
    )
}
